import { withoutCyrillicRegExp } from '@constants/regExp'
import * as yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'
import { StringSchema } from 'yup'

export const requiredRule = (errorMessage?: string): RequiredStringSchema<string | undefined> =>
  yup.string().trim().required(errorMessage)
export const notRequiredRule = (): StringSchema => yup.string().trim().notRequired()

const minSymbolTitle = 1
const maxSymbolTitle = 50
const minDigits = 1
const maxApartmentNumber = 4
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
const fileSize = 10485760
/* 10mb in bites */

export const schema = yup.object().shape({
  isEditProperty: yup.boolean(),
  title: requiredRule().matches(withoutCyrillicRegExp).min(minSymbolTitle).max(maxSymbolTitle),
  address: requiredRule('Value is required').matches(withoutCyrillicRegExp).when('isEditProperty', {
    is: true,
    then: yup.string().trim().notRequired(),
  }),
  price: yup
    .number()
    .positive('Must be more than 0')
    .integer('Must be more than 0')
    .required('This field is required')
    .min(minDigits)
    .test('length', '', (value) => value >= 1)
    .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
    .test('no-leading-zero', 'Leading zero is not allowed', (value, context) => {
      return context.originalValue && !context.originalValue.toString().startsWith('0')
    }),
  isApartment: yup.boolean(),
  seller: yup
    .string()
    .trim()
    .required('Value is required')
    .min(minSymbolTitle)
    .max(maxSymbolTitle)
    .when('isEditProperty', {
      is: true,
      then: yup.string().trim().notRequired().default(undefined),
    }),
  apartmentNumber: yup
    .string()
    .trim()
    .when('isEditProperty', {
      is: true,
      then: yup.string().trim().notRequired().default(undefined),
    })
    .when('isApartment', {
      is: true,
      then: requiredRule()
        .matches(/^[a-zA-Z0-9]+$/i)
        .min(minDigits)
        .max(maxApartmentNumber),
    }),
  image: yup
    .mixed()
    .when('isEditProperty', {
      is: true,
      then: yup.mixed().notRequired(),
    })
    .when('isEditProperty', {
      is: false,
      then: yup
        .mixed()
        .required()
        .test('isFile', 'Value is required', (value) => value.path != undefined)
        .test('fileSize', 'File Exceeds Maximum Allowed Value', (value) => value.size <= fileSize)
        .test('fileType', 'Invalid File Format', (value) => SUPPORTED_FORMATS.includes(value.type)),
    }),
})
