import { getAuth } from 'firebase/auth'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { FileImgType } from '@constants/types'
import { SORAGE_REF } from '@constants/dictionary/firebase'

export const getImgUrl = async (file: FileImgType, link: string): Promise<string> => {
  const { currentUser } = getAuth()
  const storage = getStorage()
  if (!file?.name) {
    return undefined
  }
  const sorageRef = ref(
    storage,
    `${link ? link : SORAGE_REF.IMAGES_PROPERTIES}${currentUser.uid}/${Date.now() + file.name}`,
  )
  const storageResults = await uploadBytes(sorageRef, file)
    .then(() => {
      return getDownloadURL(sorageRef)
    })
    .then((result) => result)
  return storageResults
}
