import styled from 'styled-components'

export const Container = styled.div`
  padding: 0
    ${(p) => {
      if (p.isMobile) return p.theme.indent.LG
      if (p.isTabletPortrait) return p.theme.indent.XXXL
      return p.theme.indent.XXXS
    }};
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.LG)};
`
