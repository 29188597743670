import {
  format,
  eachDayOfInterval,
  add,
  endOfWeek,
  startOfToday,
  isToday,
  eachHourOfInterval,
  startOfDay,
  startOfHour,
  isSunday,
  previousSunday,
  nextSunday,
  addHours,
  addMinutes,
} from 'date-fns'
import {
  START_DEFAULT_PERIOD,
  FINISH_DEFAULT_PERIOD,
  THIRTY_MINUTES,
  SIX_DAY,
  SEVEN_DAY,
  SELLER_TIMESLOTS_START_ON_MIDDLE_OF_DAY,
} from '@constants/dictionary'

const useDate = (): Record<string, unknown> => {
  const todayDate = format(new Date(), 'd LLLL y')

  const buyersWeek = (numberOfDays: number): Array<Date> =>
    eachDayOfInterval({
      start: new Date(),
      end: add(new Date(), { days: numberOfDays }),
    })

  const sunday = isSunday(new Date()) ? new Date() : previousSunday(new Date())
  const today = new Date()

  const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + SEVEN_DAY)

  const getSellersWeek = (): Array<Date> =>
    eachDayOfInterval({
      start: sunday,
      end: endOfWeek(new Date(), { weekStartsOn: 0 }),
    })
  const nextWeekSunday = startOfDay(nextSunday(new Date()))

  const getSellersNextWeek = (): Array<Date> =>
    eachDayOfInterval({
      start: nextWeekSunday,
      end: endOfWeek(nextWeek, { weekStartsOn: 0 }),
    })
  const buyerDaysList = buyersWeek(SIX_DAY).map((day) => ({
    dayName: format(day, 'ccc'),
    dayNumber: format(day, 'dd'),
    date: day,
    today: isToday(day),
    active: isToday(day),
    disabled: startOfToday() > day,
  }))

  const sellerDaysList = getSellersWeek().map((day) => {
    const arrEach30minutes: Date[] = []
    const start =
      startOfHour(addHours(new Date(), 1)) >= addHours(new Date(day), FINISH_DEFAULT_PERIOD)
        ? addHours(new Date(day), START_DEFAULT_PERIOD)
        : startOfHour(addHours(new Date(), SELLER_TIMESLOTS_START_ON_MIDDLE_OF_DAY))
    const arrHours = eachHourOfInterval({
      start: isToday(day) ? start : addHours(new Date(day), START_DEFAULT_PERIOD),
      end: addHours(new Date(day), FINISH_DEFAULT_PERIOD),
    })

    arrHours.forEach((item, index) => {
      arrEach30minutes.push(item)
      if (arrHours.length - 1 !== index) {
        arrEach30minutes.push(addMinutes(item, THIRTY_MINUTES))
      }
    })
    return {
      dayName: format(day, 'ccc'),
      dayNumber: format(day, 'dd'),
      date: day,
      today: isToday(day),
      active: isToday(day),
      disabled: startOfToday() > day,
      timePeriods: [],
      allHours: arrEach30minutes,
    }
  })

  const sellerDaysListNextWeek = getSellersNextWeek().map((day) => {
    const arrEach30minutes: Date[] = []
    const arrHours = eachHourOfInterval({
      start: addHours(new Date(day), START_DEFAULT_PERIOD),
      end: addHours(new Date(day), FINISH_DEFAULT_PERIOD),
    })

    arrHours.forEach((item, index) => {
      arrEach30minutes.push(item)

      if (arrHours.length - 1 !== index) {
        arrEach30minutes.push(addMinutes(item, THIRTY_MINUTES))
      }
    })
    return {
      dayName: format(day, 'ccc'),
      dayNumber: format(day, 'dd'),
      date: day,
      today: isToday(day),
      active: isSunday(day),
      disabled: startOfToday() > day,
      timePeriods: [],
      allHours: arrEach30minutes,
    }
  })

  return {
    todayDate,
    buyerDaysList,
    sellerDaysList,
    getSellersWeek,
    buyersWeek,
    getSellersNextWeek,
    sellerDaysListNextWeek,
  }
}

export default useDate
