import React, { useEffect } from 'react'
import { getUserProfiles } from '@utils/functions'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getUsersProfilesByType } from '@services/firebase'
import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import UsersTabContent from '../UsersTabContent'
import { Loader } from '@atoms'

const BuyersTabContent = (): React.FC => {
  const [result, loadingProfiles] = useCollection(getUsersProfilesByType('buyer'))
  const data = loadingProfiles ? [] : getUserProfiles(result)
  useEffect(() => {
    localStorage.setItem(LS_CONTSTANTS.selectedAgentId, '')
  })
  return (
    <Loader loaded={!loadingProfiles}>
      <UsersTabContent typeUser={USER_TYPES.buyer} data={data} user="Buyer" />
    </Loader>
  )
}

export default BuyersTabContent
