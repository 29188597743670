import React, { useCallback } from 'react'
import { format } from 'date-fns'
import { Typography } from '@atoms'
import { Dropdown } from '@molecules'
import { StyledRow, TimeSlotDropdownContainer } from './PeriodByDropdown.style'
import { PeriodByDropdownProps } from '@constants/types/organisms'

const PeriodByDropdown: React.FC<PeriodByDropdownProps> = ({
  slot,
  id,
  setUntilTime,
  setFromTime,
  allHourFrom,
  allHourUntil,
}) => {
  const onChangeValueFrom = useCallback(
    (setDate) => {
      setFromTime(id, setDate)
    },
    [setFromTime, id],
  )

  const onChangeValueUntil = useCallback(
    (val) => {
      setUntilTime(id, val)
    },
    [setUntilTime, id],
  )

  return (
    <StyledRow>
      <TimeSlotDropdownContainer>
        <Dropdown
          center
          id={id}
          handleValue={onChangeValueFrom}
          selectedValue={format(new Date(slot.fromDateTime), 'H:mm')}
          testid="Default"
        >
          {allHourFrom.map((item, index) => (
            <Dropdown.Option
              center
              key={`Dropdown-fromDateTime${index + 1}`}
              value={item}
              title={format(new Date(item), 'H:mm')}
            >
              {format(new Date(item), 'H:mm')}
            </Dropdown.Option>
          ))}
        </Dropdown>
      </TimeSlotDropdownContainer>
      <Typography.H3>—</Typography.H3>
      <TimeSlotDropdownContainer>
        <Dropdown
          id={id}
          center
          handleValue={onChangeValueUntil}
          selectedValue={format(new Date(slot.toDateTime), 'H:mm')}
          testid="Default"
        >
          {allHourUntil.map((item, index) => (
            <Dropdown.Option
              center
              key={`Dropdown-toDateTime${index + 1}`}
              value={item}
              title={format(new Date(item), 'H:mm')}
            >
              {format(new Date(item), 'H:mm')}
            </Dropdown.Option>
          ))}
        </Dropdown>
      </TimeSlotDropdownContainer>
    </StyledRow>
  )
}

export default PeriodByDropdown
