import { Typography } from '@atoms'
import styled from 'styled-components'

export const CollapseContainer = styled.div`
  padding: ${(p) => `${p.theme.indent.XL} ${p.theme.indent.XXXS}`};
`
export const CollapseLabel = styled(Typography.Body2)`
  font-weight: 400;
  color: ${(p) => p.theme.color.originalBlue};
  text-align: right;
  padding-right: ${(p) => p.theme.indent.XXS};
`
export const ContainerLabel = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(p) => (p.isMobile ? `0 ${p.theme.indent.LG}` : `0 ${p.theme.indent.XXXL}`)};
`
export const Container = styled.div`
  display: flex;
  cursor: pointer;
`
