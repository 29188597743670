import { ActionsSellerSlotsReducer } from '@constants/actions'
import { Action, State } from '@constants/types'
import {
  switchDay,
  addNewperiod,
  deletePeriod,
  editTimePeriod,
  setTimePeriod,
} from './sellerSlotsReducer.utils'
import { TIME_PERIOD, SWITCH_DAY } from './sellerSlotsReducer.constants'

const sellerSlotsReducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case ActionsSellerSlotsReducer.chooseDay:
      return switchDay(state, payload, SWITCH_DAY.TAP_ON_DAY)

    case ActionsSellerSlotsReducer.toPreviousDay:
      return switchDay(state, payload, SWITCH_DAY.TO_PREVIOUS_DAY)

    case ActionsSellerSlotsReducer.toNextDay:
      return switchDay(state, payload, SWITCH_DAY.TO_NEXT_DAY)

    case ActionsSellerSlotsReducer.addNewTimePeriod:
      return addNewperiod(state)

    case ActionsSellerSlotsReducer.removeTimePeriod:
      return deletePeriod(state, payload)

    case ActionsSellerSlotsReducer.addFromTime:
      return editTimePeriod(state, payload, TIME_PERIOD.ADD_FROM_TIME)

    case ActionsSellerSlotsReducer.minusFromTime:
      return editTimePeriod(state, payload, TIME_PERIOD.MINUS_FROM_TIME)

    case ActionsSellerSlotsReducer.addUntilTime:
      return editTimePeriod(state, payload, TIME_PERIOD.ADD_UNTIL_TIME)

    case ActionsSellerSlotsReducer.minusUtilTime:
      return editTimePeriod(state, payload, TIME_PERIOD.MINUS_UNTIL_TIME)

    case ActionsSellerSlotsReducer.setFromTime:
      return setTimePeriod(state, payload, TIME_PERIOD.SET_FROM_TIME)

    case ActionsSellerSlotsReducer.setUntilTime:
      return setTimePeriod(state, payload, TIME_PERIOD.SET_UNTIL_TIME)

    default:
      return state
  }
}
export default sellerSlotsReducer
