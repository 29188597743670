import React from 'react'
import { useTheme } from 'styled-components'
import { useMobile } from '@utils/hooks'
import { Icon } from '@atoms'
import Dropdown from '@molecules/Dropdown'
import {
  PaginationContainer,
  PaginationDropdown,
  PaginationInput,
  PaginationText,
} from './Pagination.style'
import { PaginationTypes } from '@constants/types'

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize },
  changePage,
}: PaginationTypes): JSX.Element => {
  const { color } = useTheme()
  const isMobile = useMobile()
  const TEN_ROWS = 10
  const TWENTY_ROWS = 20
  const ONE_HUNDRED_ROWS = 100

  const OptionsArray = [TEN_ROWS, TWENTY_ROWS, ONE_HUNDRED_ROWS]
  const SetPage = (e: React.ChangeEvent<HTMLInputElement>): undefined => {
    const Page = e.target.value ? Number(e.target.value) - 1 : 0
    gotoPage(Page)
  }
  return (
    <PaginationContainer isMobile={isMobile}>
      <Icon
        icon="arrow-left-filled"
        size="0.8rem"
        color={!canPreviousPage ? color.darkGrey : color.originalBlue}
        clickable={canPreviousPage}
        onClick={() => {
          previousPage()
          if (changePage) {
            changePage(-1)
          }
        }}
      />
      <PaginationText>
        <PaginationInput value={pageIndex + 1} onChange={SetPage} />
        of {pageOptions.length}
      </PaginationText>
      <Icon
        icon="arrow-right-filled"
        size="0.8rem"
        color={!canNextPage ? color.darkGrey : color.originalBlue}
        clickable={canNextPage}
        onClick={() => {
          nextPage()
          if (changePage) {
            changePage(1)
          }
        }}
      />
      {!changePage && (
        <PaginationDropdown>
          <Dropdown
            selectedValue={pageSize}
            handleValue={(optionValue: string) => {
              setPageSize(Number(optionValue))
            }}
            paginationDropdown
          >
            {OptionsArray.map((item) => (
              <Dropdown.Option key={item} value={item} title={item}>
                {item}
              </Dropdown.Option>
            ))}
          </Dropdown>
        </PaginationDropdown>
      )}
    </PaginationContainer>
  )
}

export default Pagination
