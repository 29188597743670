import React from 'react'
import { useMobile } from '@utils/hooks'
import { Typography, Icon } from '@atoms'
import { Button } from '@molecules'
import BaseModal from '../BaseModal'
import AttentionModal from '@assets/images/AttentionModal.svg'
import {
  Container,
  ButtonWrapper,
  ConfirmMessage,
  ConfirmText,
  ImgWrapper,
  StyledImg,
  CloseWrapper,
} from './ConfirmModal.style'

import { ConfirmModalTypes } from '@constants/types/modals'

const ConfirmModal: React.FC<ConfirmModalTypes> = ({
  onConfirm,
  onCancel,
  message = '',
  labelYes = '',
  labelNo = '',
  text = '',
  isShown,
  hide,
  isStatusModal,
  isClosedIcon,
}) => {
  const isMobile = useMobile()

  return (
    <BaseModal hide={hide} isShown={isShown}>
      <Container isMobile={isMobile}>
        {isClosedIcon && (
          <CloseWrapper>
            <Icon icon="close" color="originalBlue" clickable onClick={onCancel} />
          </CloseWrapper>
        )}
        {isStatusModal && (
          <ImgWrapper>
            <StyledImg src={AttentionModal} alt="warning" />
          </ImgWrapper>
        )}
        <ConfirmMessage>
          <Typography.H2 isMobile={isMobile}>{message}</Typography.H2>
        </ConfirmMessage>
        {text.length > 0 && (
          <ConfirmText>
            <Typography.Body2>{text}</Typography.Body2>
          </ConfirmText>
        )}
        <ButtonWrapper isStatusModal={isStatusModal}>
          <Button.Primary
            isMobile={isMobile}
            label={labelYes.length > 0 ? labelYes : 'Confirm'}
            onClick={onConfirm}
          />
          <Button.Secondary
            isMobile={isMobile}
            label={labelNo.length > 0 ? labelNo : 'Cancel'}
            onClick={onCancel}
          />
        </ButtonWrapper>
      </Container>
    </BaseModal>
  )
}

export default ConfirmModal
