import { Row } from 'react-table'
import { datesSorting, statusSorting } from './sorting'

export const getDatesSorting =
  <RowType extends Record<string>>(key: keyof RowType | string) =>
  (aRow: Row<RowType>, bRow: Row<RowType>): number => {
    const aDate: string = aRow.original[key]
    const bDate: string = bRow.original[key]
    return datesSorting(aDate, bDate)
  }

export const getStatusSorting =
  <RowType extends Record<string>>(key: keyof RowType | string) =>
  (aRow: Row<RowType>, bRow: Row<RowType>): number => {
    const aStatus: string = aRow.original[key]
    const bStatus: string = bRow.original[key]
    return statusSorting(aStatus, bStatus)
  }
