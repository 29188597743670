import React from 'react'
import { useParams } from 'react-router-dom'
import { useMobile } from '@utils/hooks'
import { EMPTY_TIME_SLOTS } from '@constants/mocks'
import SellerVisits from '../SellerVisits'
import NoSlots from '../NoSlots'
import { ContainerList } from './SellerList.styled'

const SellerList = (): JSX.Element => {
  const { tab } = useParams()
  const isMobile = useMobile()

  if (tab === 'slots') {
    if (EMPTY_TIME_SLOTS.length === 0) {
      return <NoSlots />
    }
  }

  if (tab === 'visits') {
    return <SellerVisits />
  }

  return <ContainerList isMobile={isMobile}>Insert content in SellerList component</ContainerList>
}

export default SellerList
