import React from 'react'
import { useMobile, useMobileLandscape } from '@utils/hooks'
import BaseModal from '../BaseModal'
import { Typography } from '@atoms'
import { Button } from '@molecules'
import { Container, StyledImg, MessageWrapper, WithLineBreak } from './SuccessModal.style'

import { SuccessModalTypes } from '@constants/types/modals'

const SuccessModal: React.FC<SuccessModalTypes> = ({
  withBaseModal,
  image,
  header,
  message,
  onConfirm,
  isShown,
  hide,
}) => {
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()

  const modalClickHandler: React.MouseEventHandler = (event) => event.stopPropagation()

  const renderModal = (): JSX.Element => (
    <Container
      isMobileLandscape={isMobileLandscape}
      isMobile={isMobile}
      onClick={modalClickHandler}
    >
      {!!image?.src && <StyledImg src={image?.src} alt={image?.alt} />}
      <MessageWrapper>
        <Typography.H2 isMobile={isMobile}>
          <WithLineBreak>{header}</WithLineBreak>
        </Typography.H2>
        <Typography.Body2 isMobile={isMobile}>
          <WithLineBreak>{message}</WithLineBreak>
        </Typography.Body2>
      </MessageWrapper>
      <Button.Primary isMobile={isMobile} label="OK" onClick={onConfirm} />
    </Container>
  )

  return withBaseModal ? (
    <BaseModal isShown={isShown} hide={hide}>
      {renderModal()}
    </BaseModal>
  ) : (
    renderModal()
  )
}

export default SuccessModal
