import React, { forwardRef } from 'react'
import { CommentArea } from './CommentArea.style'
import { CommentAreaTypes } from '@constants/types/atoms'

const CommentAreaComponent = forwardRef((props: CommentAreaTypes, ref) => {
  const { placeholder, maxLength, onChange, field, isNotes, value, isConfirmReason, tabIndex } =
    props

  return (
    <CommentArea
      {...field}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      placeholder={placeholder}
      maxLength={maxLength}
      ref={ref}
      isNotes={isNotes}
      value={value}
      isConfirmReason={isConfirmReason}
      data-testid="CommentArea-TestID"
      tabIndex={tabIndex || -1}
    />
  )
})

export default CommentAreaComponent
