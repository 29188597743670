import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div<{ isMobile: boolean; height?: string }>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  background-color: ${({ theme }) => theme.color.red_15};
  padding: ${({ theme }) => `${theme.indent.XS} ${theme.indent.MD}`};
  ${({ height }) => !!height && `height: ${height}`};
  svg {
    margin-right: ${({ theme }) => theme.indent.XS};
    min-height: 2.6rem;
    min-width: 2.6rem;
  }
`

export const ErrorMessage = styled(Typography.Body2)`
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  color: ${({ theme }) => theme.color.red};
`
