import React from 'react'
import { useTheme } from 'styled-components'

import { Icon, Loader } from '@atoms'
import { StatusContainer, StatusIcon, StatusCaption } from './ViewingStatus.style'
import { VIEWING_STATUSES } from '@constants/dictionary'
import { ViewingStatusProps } from '@constants/types/molecules'

const ViewingStatus: React.FC<ViewingStatusProps> = ({ isLoading, status, onClick }) => {
  const theme = useTheme()

  const StatusConfig = {
    [VIEWING_STATUSES.CONFIRM_VIEW.statusKey]: {
      backgroundColor: theme.color.white,
      iconName: 'notification-checked',
      iconColor: 'originalBlue',
      textColor: theme.color.originalBlue,
    },
    [VIEWING_STATUSES.CONFIRM_VIEW_DISABLED.statusKey]: {
      backgroundColor: theme.color.white,
      iconName: 'notification-checked',
      iconColor: 'darkGrey',
      textColor: theme.color.darkGrey,
    },
    [VIEWING_STATUSES.CANCELED.statusKey]: {
      backgroundColor: theme.color.darkGrey,
      iconName: 'notification-declined',
      iconColor: 'white',
      textColor: theme.color.white,
    },
    [VIEWING_STATUSES.CONFIRMED.statusKey]: {
      backgroundColor: theme.color.originalBlue,
      iconName: 'notification-checked',
      iconColor: 'white',
      textColor: theme.color.white,
    },
  }
  const { backgroundColor, textColor, iconName, iconColor } = StatusConfig[status.statusKey]

  return (
    <StatusContainer
      isLoading={isLoading}
      backgroundColor={backgroundColor}
      status={status.statusKey}
      onClick={onClick}
    >
      {isLoading ? (
        <Loader skeleton color={theme.color.originalBlue} />
      ) : (
        <>
          <StatusIcon>
            <Icon color={iconColor} icon={iconName} key={`${iconName}${iconColor}`} size="1.3rem" />
          </StatusIcon>
          <StatusCaption status={status.statusKey} textColor={textColor}>
            {status.value}
          </StatusCaption>
        </>
      )}
    </StatusContainer>
  )
}

export default ViewingStatus
