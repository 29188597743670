import { withoutCyrillicRegExp, passRegExp } from '@constants/regExp'
import { MIN_SYMBOLS, MAX_SYMBOLS, VALIDATION_ERRORS } from '@constants/dictionary'
import * as yup from 'yup'

export const requiredRule = (): string =>
  yup.string().trim().required(VALIDATION_ERRORS.PASSWORD_NOT_VALID)
export const schema = yup.object().shape({
  newPassword: requiredRule(VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .min(MIN_SYMBOLS, VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .max(MAX_SYMBOLS, VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .matches(withoutCyrillicRegExp, VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .matches(passRegExp, VALIDATION_ERRORS.PASSWORD_NOT_VALID),

  confirmPassword: requiredRule(VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .min(MIN_SYMBOLS, VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .max(MAX_SYMBOLS, VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .matches(passRegExp, VALIDATION_ERRORS.PASSWORD_NOT_VALID)
    .matches(withoutCyrillicRegExp, VALIDATION_ERRORS.PASSWORD_NOT_VALID),
})
