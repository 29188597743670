import { ROUTES } from '@constants/dictionary'
import { ItemsID, NavigationProps } from '@constants/types'

export const NavigationFields: Array<NavigationProps> = [
  {
    id: ItemsID.Homepage,
    title: 'Homepage',
    linkTo: ROUTES.AGENT,
    matchRoute: [
      ROUTES.AGENT_PROPERTIES,
      ROUTES.AGENT_VIEWINGS,
      ROUTES.AGENT_BUYERS,
      ROUTES.AGENT_SELLERS,
      ROUTES.AGENT_ANALYTICS,
      ROUTES.AGENT_ADD_PROPERTY,
      ROUTES.AGENT_ADD_USER,
    ],
  },
  {
    id: ItemsID.Help,
    title: 'Help',
    linkTo: ROUTES.AGENT_HELP_PAGE,
    matchRoute: [ROUTES.AGENT_HELP_PAGE],
  },
  {
    id: ItemsID.Notifications,
    title: 'Notifications',
  },
]

export const MobileFields: Array<NavigationProps> = [
  ...NavigationFields,
  {
    id: ItemsID.ProfileSettings,
    title: 'Profile Settings',
  },
  {
    id: ItemsID.SignOut,
    title: 'Sign Out',
  },
]
