import styled from 'styled-components'
import { Typography } from '@atoms'

export const TimeSlotButtonContainer = styled.div<{ noHover: boolean }>`
  border: 0.1rem solid ${(p) => (p.active ? p.theme.color.originalBlue : p.theme.color.grey)};
  border-color: ${(p) => (p.disabled ? p.theme.color.lightGrey : '')};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  padding: ${({ theme }) => theme.indent.XS};
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.indent.MD};
  cursor: ${(p) => (p.disabled || p.noHover ? 'default' : 'pointer')};
`
export const TimeSlotLabel = styled(Typography.H3)`
  color: ${(p) => (p.active ? p.theme.color.originalBlue : '')};
`
