import styled from 'styled-components'
import Calendar from 'react-calendar'
import { Typography } from '@atoms'

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: ${({ isMobile }) => `calc(100vh - ${isMobile ? '16rem' : '20rem'});`}
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.LG} 5rem 0`
    if (isTablet) return `${theme.indent.LG} ${theme.indent.XXXL} 0`
    if (isMobile) return `${theme.indent.LG} ${theme.indent.LG} 0`
  }};
`

export const Container = styled.div<{ isMobile: boolean; isDesktop: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => `0.1rem solid ${theme.color.lightGrey}`};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  padding: ${({ theme, isMobile }) => (isMobile ? theme.indent.MD : theme.indent.XL)};
  ${({ isDesktop }) => !isDesktop && 'flex-direction: column'};
`

export const CalendarContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '32.5rem')};
`

export const TimeSlotsContainer = styled.div<{ isDesktop: boolean; isMobile: boolean }>`
  width: 100%;
  ${({ isDesktop, theme, isMobile }) =>
    isDesktop
      ? `border-left: 0.1rem solid ${theme.color.lightGrey};
      margin-left: ${theme.indent.XXXL};
      padding-left: ${theme.indent.MD};
    `
      : `border-top: 0.1rem solid ${theme.color.lightGrey};
      margin-top: ${theme.indent.XL};
      padding-top: ${isMobile ? theme.indent.MD : theme.indent.XL};
      `};
`

export const Title = styled(Typography.Body2)`
  padding-bottom: ${({ theme }) => theme.indent.MD};
`

export const Header = styled(Typography.H3)`
  display: flex;
  padding-bottom: ${({ theme }) => theme.indent.MD};
  p {
    padding-left: ${({ theme }) => theme.indent.XXS};
  }
`

export const ButtonContainer = styled.div<{ isDesktop: boolean }>`
  padding-top: ${({ theme, isDesktop }) => (isDesktop ? theme.indent.MD : theme.indent.XL)};
`

export const CustomCalendar = styled(Calendar)<{ disabled: boolean }>`
  .react-calendar {
    width: 33.5rem;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 2.4rem;
    display: flex;
    padding: ${({ theme }) => `0 ${theme.indent.SM}`};

    .react-calendar__navigation__label {
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.color.lightGrey : theme.color.greyBlue};
      border-radius: ${({ theme }) => theme.borderRadius.SM};
      margin: ${({ theme }) => `0 ${theme.indent.MD}`};
      .react-calendar__navigation__label__labelText {
        font-size: ${({ theme }) => theme.fontSize.XS};
        font-weight: bold;
        color: ${({ theme, disabled }) => (disabled ? theme.color.darkGrey : theme.color.black)};
      }
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: none;
    }
  }
  .react-calendar__navigation button {
    min-width: 2.4rem;
    background-color: ${({ theme }) => theme.color.originalBlue};
    border-radius: ${({ theme }) => theme.borderRadius.SM};
    border: none;
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.MD};
    cursor: pointer;
  }

  .react-calendar__navigation__next-button:disabled,
  .react-calendar__navigation__prev-button:disabled {
    background-color: ${({ theme }) => theme.color.darkGrey};
    cursor: default;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.XS};
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.7rem;
    abbr[title] {
      text-decoration: none;
      ${({ disabled, theme }) => disabled && `color: ${theme.color.darkGrey}`};
    }
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.6rem;
    background: none;
    border: none;
    font-size: ${({ theme }) => theme.fontSize.XS};
    ${({ disabled, theme }) =>
      disabled &&
      `color: ${theme.color.darkGrey};
    pointer-events: none;
    `};
  }
  .react-calendar__tile--now {
    color: ${({ theme, disabled }) => (disabled ? theme.color.darkGrey : theme.color.originalBlue)};
  }
  .react-calendar__tile--active {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.color.darkGrey : theme.color.originalBlue};
    font-weight: bold;
    border-radius: ${({ theme }) => theme.borderRadius.XXXL};
    color: ${({ theme }) => theme.color.white};
    flex-basis: unset !important;
    margin: auto;
    width: 2.8rem;
    display: flex;
    justify-content: center;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: ${({ theme }) => theme.color.originalBlue};
  }
`
