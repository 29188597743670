import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useMobile } from '@utils/hooks'
import CancelVisitImg from '@assets/images/CancelVisitImg.svg'
import { ROUTES, CANCEL_VISIT } from '@constants/dictionary'
import { RadioButton, Button, TwoButtonRow } from '@molecules'
import {
  Container,
  CancelVisitImage,
  H2,
  LinkContainer,
  StyledRow,
} from './SellerCancelVisit.styled'
import { RadioButtonItems } from './SellerCancelVisit.constant'

const SellerCancelVisit = (): JSX.Element => {
  const isMobile = useMobile()
  const history = useHistory()
  const [selected, setSelected] = useState('')
  const [route, setRoute] = useState<string>('')
  const onClickNext = useCallback(() => history.push(route), [history, route])
  const onOptionChange = useCallback((value: string) => {
    setSelected(value)
    switch (value) {
      case CANCEL_VISIT.UNDER_OFFER:
        setRoute(ROUTES.SELLER_UNDER_OFFER)
        break
      case CANCEL_VISIT.URGENT_MATTERS:
        setRoute(ROUTES.SELLER_URGENT_MATTERS)
        break
      default:
        setRoute('')
    }
  }, [])

  return (
    <Container isMobile={isMobile}>
      <CancelVisitImage isMobile={isMobile} src={CancelVisitImg} />
      <H2 isMobile={isMobile}>{CANCEL_VISIT.HEADER}</H2>
      <StyledRow>
        <RadioButton
          RadioButtonItems={RadioButtonItems}
          onChange={onOptionChange}
          selected={selected}
          isMobile={isMobile}
        />
      </StyledRow>
      <TwoButtonRow
        firstButton={
          <LinkContainer to={ROUTES.SELLER_SLOTS}>
            <Button.Secondary isMobile={isMobile} label="Cancel" width="100%" />
          </LinkContainer>
        }
        secondButton={
          <Button.Primary
            isMobile={isMobile}
            label="Next"
            width="100%"
            onClick={onClickNext}
            disabled={!route}
          />
        }
      />
    </Container>
  )
}

export default SellerCancelVisit
