import styled from 'styled-components'

import { Typography } from '@atoms'
import { Link } from 'react-router-dom'

export const Container = styled.div<{
  isMobile: boolean
  isTabletPortrait: boolean
  showBorder: boolean
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  ${({ isMobile }) => !isMobile && 'justify-content: space-between'};
  align-items: center;
  width: 100vw;
  height: ${({ isMobile }) => (isMobile ? 19.6 : 6.4)}rem;
  background-color: ${({ theme }) => theme.color.whiteSmoke};
  ${({ theme, showBorder }) => showBorder && `border-top: 0.1rem solid ${theme.color.lightGrey}`};
  ${({ isMobile, isTabletPortrait }) => ({
    padding: '0 5rem',
    ...(isMobile && {
      padding: '0 2rem',
    }),
    ...(isTabletPortrait && {
      padding: '0 3.2rem',
    }),
  })};
`

export const Logo = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  ${({ isMobile }) => isMobile && 'padding-top: 1.4rem'};
  justify-content: left;
  align-items: center;
`

export const MenuWithCopyright = styled.div<{ isMobile: boolean; isTabletPortrait: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-around;
  ${({ isMobile }) => !isMobile && 'align-items: center'};
  width: 100%;
  ${({ isMobile }) => isMobile && 'margin-top: 1.7rem'};
  column-gap: ${({ isTabletPortrait }) => (isTabletPortrait ? 4.4 : 3)}rem;
`

export const FooterMenu = styled.div<{ isMobile: boolean }>`
  display: grid;
  ${({ isMobile }) => ({
    gridColumnGap: '3rem',
    gridTemplateColumns: 'repeat(4, max-content)',
    ...(isMobile && {
      gridTemplateColumns: 'repeat(2, max-content)',
      gridColumnGap: '4.1rem',
      gridRowGap: '2rem',
    }),
  })};
`

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.color.darkBlack};
  text-decoration: none;
`

export const Copyright = styled(Typography.Caption4)`
  ${({ isMobile }) => isMobile && 'padding-top: 2rem'};
  width: max-content;
`
