import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const updateUserData = async (
  props: { name: string; phoneNumber: string },
  userId: string,
): boolean => {
  try {
    const db = getFirestore()
    const userRef = doc(db, FS_COL_NAMES.USERS, userId)
    await updateDoc(userRef, { ...props })
    return true
  } catch (error) {
    return false
  }
}
