import _ from 'lodash'
import { format, isToday } from 'date-fns'

export const mapTimeSlots = (firestoreList: { docs: Array }): Array => {
  const source = firestoreList?.docs.map((timeSlots) => ({
    ...timeSlots.data(),
    groupByDay: timeSlots.data().fromDateTime.toDate().setHours(0, 0, 0),
    date: timeSlots.data().fromDateTime.toDate(),
    id: timeSlots.id,
  }))
  return _(source)
    .groupBy('groupByDay')
    .map((visits, date) => {
      return {
        dayOfWeek: new Date(+date),
        timeSlots: visits
          .map((visit) => {
            return {
              ...visit,
              fromTime: format(visit.fromDateTime.toDate(), 'HH:mm'),
              toTime: format(visit.toDateTime.toDate(), 'HH:mm'),
              disabled: visit.fromDateTime.toDate() < new Date(),
              currentTime: visit.fromDateTime,
              dateTime: visit.fromDateTime.toDate(),
              active: false,
            }
          })
          .sort((a, b) => a.dateTime - b.dateTime),
      }
    })
    .sort((current, previeous) => current.dayOfWeek - previeous.dayOfWeek)
    .filter((item) => item.dayOfWeek > new Date() || isToday(item.dayOfWeek))
    .value()
}
