import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const CardWrapper = styled.div`
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  padding: ${(p) => p.theme.indent.XL};
  background-color: ${({ theme }) => theme.color.white};
`
export const StatusWrapper = styled.div`
  position: absolute;
  min-width: 10rem;
  left: 1rem;
  top: 1rem;
`

export const PropertyImage = styled.div`
  height: 100%;
  min-height: 20rem;
  width: 100%;
  background-image: url('${({ path }) => path}');
  background-size: cover;
  background-position: center;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
`

export const DetailsWrapper = styled.div<{
  isMobile: boolean
  isTabletPortrait: boolean
  isTabletLandscape: boolean
  isDesktop: boolean
}>`
  margin-top: ${(p) =>
    p.isMobile || p.isTabletPortrait ? p.theme.indent.LG : p.theme.indent.XXXS};
  margin-left: ${(p) => (p.isDesktop || p.isTabletLandscape) && '5rem'};
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.isMobile || p.isTabletPortrait ? p.theme.indent.SM : p.theme.indent.MD)};
`
export const DetailsRow = styled(Row)<{ isMobile: boolean }>`
  display: grid;
  grid-template-areas: 'icon text';
  grid-template-columns: 2rem auto;
  align-items: center;
  gap: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
`

export const ContactsWrapper = styled.div`
  margin-top: 2.6rem;
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.isMobile || p.isTabletPortrait ? p.theme.indent.SM : p.theme.indent.MD)};
`

export const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const H3 = styled(Typography.H3)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const LinkTo = styled(Typography.Link)<{ noUnderline: boolean; isMobile: boolean }>`
  text-decoration: ${(p) => (p.noUnderline ? 'none' : 'underline')};
  overflow: hidden;
  text-overflow: ellipsis;
`
