import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const Container = styled.div`
  min-height: calc(100vh - 25.1rem);
  padding: 0 ${(p) => `${p.theme.indent.LG} ${p.theme.indent.XXXL}`};
`

export const StyledCol = styled(Col)`
  max-width: 36rem;
  margin-right: 4rem;
`

export const DropdownContainer = styled(Row)`
  padding-bottom: ${(p) => p.theme.indent.MD};
`

export const DropdownLabel = styled(Typography.Body3)`
  padding-bottom: ${(p) => p.theme.indent.XXS};
`

export const ButtonContainer = styled.div`
  margin-top: ${(p) => p.theme.indent.XXS};
`
