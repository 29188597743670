export const LS_CONTSTANTS = {
  uid: 'uid',
  userType: 'userType',
  redirectLink: 'redirectLink',
  oobCode: 'oobCode',
  err_new_pass: 'err_new_pass',
  propertyId: 'propertyId',
  agentId: 'agentId',
  visitId: 'visitId',
  error: 'error',
  profileImgUrl: 'profileImgUrl',
  agentIgnoredConflictedViewings: 'agentIgnoredConflictedViewings',
  selectedAgentId: 'selectedAgentId',
}
