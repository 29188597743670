import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DropZone, ChangeProfileImage } from '@molecules'

import { DropZoneFormProps } from '@constants/types/helpers'

const DropZoneForm: React.FC<DropZoneFormProps> = ({
  name,
  errorMessage,
  disabled,
  profile,
  imageUrl,
  children,
  getError,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange } }) =>
        profile ? (
          <ChangeProfileImage
            disabled={disabled}
            getError={getError}
            onChange={(e) => onChange(e.preview ? e : e.target.files[0])}
            errorMessage={errorMessage}
            imageUrl={imageUrl}
          >
            {children}
          </ChangeProfileImage>
        ) : (
          <DropZone
            disabled={disabled}
            onChange={(e) => onChange(e.preview ? e : e.target.files[0])}
            errorMessage={errorMessage}
            getError={getError}
          />
        )
      }
    />
  )
}

export default DropZoneForm
