import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'

export const ButtonWrapperModal = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  column-gap: ${(p) => p.theme.indent.MD};
  width: ${(p) => (p.isMobile ? '100%' : '50%')};
  justify-content: flex-end;
`

export const Header = styled.div<{ isMobile: boolean; isModalHeader: boolean }>`
  min-width: ${(p) => (p.isModalHeader ? '100%' : '126.6rem')};
  padding: ${(p) =>
    p.isMobile ? p.theme.indent.LG : `${p.theme.indent.MD} ${p.theme.indent.XXXL}`};
  display: flex;
  justify-content: space-between;
  ${(p) => (p.isMobile ? 'flex-direction: column-reverse;' : null)}
`

export const SearchContainer = styled.div<{
  isMobile: boolean
  marginLeft: boolean
  isModal: boolean
}>`
  width: ${({ isMobile, isModal }) => (isMobile || isModal ? '100%' : '31.5rem')};
  margin-left: ${(p) => (p.marginLeft && !p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXS)};
`

export const SearchWrapper = styled(Row)<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: ${(p) => (p.isMobile ? '100%' : '47.5rem')};
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXS)};
`

export const StyledCol = styled(Col)<{ isMobile: boolean }>`
  margin-bottom: ${(p) => p.isMobile && p.theme.indent.MD};
`
