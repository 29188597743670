import { collection, getFirestore, doc, updateDoc } from 'firebase/firestore'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { FS_COL_NAMES, FS_COL_GROUP_NAMES } from '@constants/dictionary/firebase'

export const isPropertyInShortList = (propertyProfileId: string): { error: string } => {
  const db = getFirestore()
  const buyerId = localStorage.getItem(LS_CONTSTANTS.uid)
  const user = doc(db, FS_COL_NAMES.USERS, buyerId)
  const colShortlistedProperties = collection(user, FS_COL_GROUP_NAMES.SHORT_LISTED_PROPERTIES)
  return doc(colShortlistedProperties, propertyProfileId)
}

export const upDatePropertyShortList = async (
  propertyProfileId: string,
  rating: number,
): { error: string } => {
  const db = getFirestore()
  const buyerId = localStorage.getItem(LS_CONTSTANTS.uid)
  const user = doc(db, FS_COL_NAMES.USERS, buyerId)
  const colShortlistedProperties = collection(user, FS_COL_GROUP_NAMES.SHORT_LISTED_PROPERTIES)
  const docRef = doc(colShortlistedProperties, propertyProfileId)
  await updateDoc(docRef, {
    rating,
  })
}
