import React from 'react'
import { useMobile } from '@utils/hooks'
import { Typography } from '@atoms'
import { Button } from '@molecules'
import NoSlotsImg from '@assets/images/NoSlotsImg.svg'
import { NO_SLOTS, ROUTES } from '@constants/dictionary'
import { Container, NoSlotsImage, TextWrapper, H1, LinkContainer } from './NoSlots.style'

type PropsTypes = {
  emptyFirstWeek: boolean
  isArchived?: boolean
}
const NoSlots = ({ emptyFirstWeek, isArchived }: PropsTypes): JSX.Element => {
  const isMobile = useMobile()

  return (
    <Container isMobile={isMobile}>
      {isArchived ? (
        <>
          <H1 bottomPadding isMobile={isMobile}>
            Your property is archived.
          </H1>
          <Typography.Body2 isMobile={isMobile}>
            Please contact your agency to renew time slots
          </Typography.Body2>
        </>
      ) : (
        <>
          <NoSlotsImage isMobile={isMobile} src={NoSlotsImg} />
          <H1 isMobile={isMobile}>{NO_SLOTS.header}</H1>
          <TextWrapper>
            <Typography.Body2 isMobile={isMobile}>{NO_SLOTS.message}</Typography.Body2>
          </TextWrapper>
          <LinkContainer
            to={{
              pathname: ROUTES.SELLER_CONFIG_SLOTS,
              state: { emptyFirstWeek },
            }}
          >
            <Button.Primary isMobile={isMobile} label="Add" width="100%" />
          </LinkContainer>
        </>
      )}
    </Container>
  )
}

export default NoSlots
