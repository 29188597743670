import React from 'react'
import { useMobile, useMobileLandscape } from '@utils/hooks'
import { useTheme } from 'styled-components'
import { Typography, Icon } from '@atoms'
import { Button } from '@molecules'
import BaseModal from '../BaseModal'
import CalendarModal from '@assets/images/CalendarModal.svg'
import { TIME_SLOTS_MODAL } from '@constants/dictionary'
import {
  Container,
  ConfirmMessage,
  DetailsRow,
  ContactsWrapper,
  StyledImg,
  IconWrapper,
  Body3,
} from './TimeSlotsModal.style'

import { TimeSlotsModalTypes } from '@constants/types/modals'

const TimeSlotsModal: React.FC<TimeSlotsModalTypes> = ({
  onConfirm,
  header = TIME_SLOTS_MODAL.header,
  message = TIME_SLOTS_MODAL.message,
  isShown,
  hide,
  onCancel,
  contacts,
}) => {
  const colors = useTheme().color
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()

  const modalClickHandler: React.MouseEventHandler = (event) => event.stopPropagation()

  return (
    <BaseModal hide={hide} isShown={isShown}>
      <Container
        isMobile={isMobile}
        isMobileLandscape={isMobileLandscape}
        onClick={modalClickHandler}
      >
        <IconWrapper>
          <Icon
            icon="close"
            color={colors.originalBlue}
            size="1.6rem"
            clickable
            onClick={onCancel}
          />
        </IconWrapper>
        <StyledImg src={CalendarModal} alt="calendar" />
        <ConfirmMessage>
          <Typography.H2 isMobile={isMobile}>{header}</Typography.H2>
          <Typography.Body2 isMobile={isMobile}>{message}</Typography.Body2>
        </ConfirmMessage>
        <ContactsWrapper isMobile={isMobile}>
          <DetailsRow isMobile={isMobile}>
            <Icon icon="avatar" color={colors.darkGrey} size="1.6rem" />
            <Body3>{contacts?.name}</Body3>
          </DetailsRow>
          <DetailsRow isMobile={isMobile}>
            <Icon icon="phone" color={colors.darkGrey} size="1.6rem" />
            <Typography.Body3>{contacts?.phoneNumber}</Typography.Body3>
          </DetailsRow>
          <DetailsRow isMobile={isMobile}>
            <Icon icon="email" color={colors.darkGrey} size="1.6rem" />
            <Body3>{contacts?.email}</Body3>
          </DetailsRow>
        </ContactsWrapper>
        <Button.Primary isMobile={isMobile} label={TIME_SLOTS_MODAL.label} onClick={onConfirm} />
      </Container>
    </BaseModal>
  )
}

export default TimeSlotsModal
