import React, { useCallback, useState } from 'react'
import { Typography, Icon, BookingStatus, Loader } from '@atoms'
import {
  BOOKING_STATUSES,
  SEND_MODAL,
  USER_TYPES,
  ROUTES,
  LS_CONTSTANTS,
} from '@constants/dictionary'
import { useTabletPortrait, useModal } from '@utils/hooks'
import { useTheme } from 'styled-components'
import { TimeSlotsModal, AddModal, SendModal } from '@modals'
import { getPropertyBuyerTimeSlots, getSellerProfile } from '@services/firebase'
import {
  CardImg,
  CardWrapper,
  CardInfoWrapper,
  Title,
  Address,
  PriceRow,
  LinkRow,
  LinkContainer,
  LinkText,
  StatusLabel,
  SendLinkWrapper,
  SendLinkLoader,
} from './AgentPropertyCard.style'
import { AgentPropertyTypes } from '@constants/types/organisms'

const AgentPropertyCard: React.FC<AgentPropertyTypes> = (props): JSX.Element => {
  const {
    imageUrl = '',
    title = '',
    address = '',
    price = '',
    id = '',
    propertyStatus = BOOKING_STATUSES.ACTIVE,
    isListView = false,
    isDetailsDisabled = false,
    propertyId,
    sellerId,
    profileId,
    agentId,
  } = props
  const isTabletPortrait = useTabletPortrait()
  const colors = useTheme().color
  const colorDesabled = (isDisabled: boolean): string =>
    isDisabled ? colors.darkGrey : colors.originalBlue

  const { isShown, toggle } = useModal()
  const [loadingAdditionalInfo, setLoadingAdditionalInfo] = useState<boolean>(false)
  const [additionalInfo, setAdditionalInfo] = useState({ visits: [], sellerProfile: {} })

  const [buyer, setBuyer] = useState<{ id: string; name: string }>({ name: '', id: '' })
  const getBuyer = (name: string, id: string): void => {
    setBuyer({ name, id })
    sendRequest('link')
  }

  const [isSended, setSended] = useState(false)
  const [request, setRequest] = useState('')

  const setPropertyIdToLocalStorage = useCallback((): void => {
    localStorage.setItem(LS_CONTSTANTS.propertyId, propertyId)
    localStorage.setItem(LS_CONTSTANTS.selectedAgentId, agentId)
  }, [propertyId, agentId])
  const isActiveStatus = propertyStatus === BOOKING_STATUSES.ACTIVE

  const sendLinkHandler = useCallback(async (): Promise<void> => {
    if (isActiveStatus) {
      setLoadingAdditionalInfo(true)

      const futureVisits = await getPropertyBuyerTimeSlots(propertyId, true)

      let sellerProfile: Array<unknown> = []
      if (futureVisits.length === 0) {
        sellerProfile = await getSellerProfile(sellerId)
      }
      setAdditionalInfo({ visits: futureVisits, sellerProfile: sellerProfile[0] })
      setLoadingAdditionalInfo(false)
      setPropertyIdToLocalStorage()
      toggle()
    }
  }, [isActiveStatus, propertyId, sellerId, setPropertyIdToLocalStorage, toggle])

  const sendRequest = (type: string): void => {
    setRequest(type)
    setSended(true)
    return toggle()
  }

  return (
    <CardWrapper isListView={isListView}>
      <CardImg isListView={isListView} path={imageUrl} isTabletPortrait={isTabletPortrait}>
        <StatusLabel isListView={isListView} isTabletPortrait={isTabletPortrait}>
          <BookingStatus
            isPropertyCard
            isListView={isListView}
            status={propertyStatus}
            radius="0.8rem"
            shadow
            uppercase
          />
        </StatusLabel>
      </CardImg>
      <CardInfoWrapper isListView={isListView}>
        <Title isListView={isListView}>{title}</Title>
        <Address isListView={isListView}>{address}</Address>
        <PriceRow isListView={isListView}>
          <span>
            <Typography.H3>Price</Typography.H3>
            <Typography.Body2>£{price}</Typography.Body2>
          </span>
          <span>
            <Typography.H3>ID</Typography.H3>
            <Typography.Body2>{id}</Typography.Body2>
          </span>
        </PriceRow>
        <LinkRow isListView={isListView}>
          <LinkContainer
            onClick={() => setPropertyIdToLocalStorage()}
            to={`${ROUTES.AGENT_PROPERTIES}/${profileId}/property-info`}
          >
            <Icon
              icon="propertydetails"
              clickable
              size="1.2rem"
              color={colorDesabled(isDetailsDisabled)}
            />
            <LinkText disabled={isDetailsDisabled}>View Details</LinkText>
          </LinkContainer>
          {loadingAdditionalInfo ? (
            <SendLinkLoader>
              <Loader skeleton color={colors.originalBlue} size="2.4rem" />
            </SendLinkLoader>
          ) : (
            <SendLinkWrapper onClick={() => sendLinkHandler()}>
              <>
                <Icon
                  icon="propertyLink"
                  clickable
                  size="1.2rem"
                  color={colorDesabled(!isActiveStatus)}
                />
                <LinkText disabled={!isActiveStatus}>Send Link</LinkText>
              </>
            </SendLinkWrapper>
          )}
        </LinkRow>
      </CardInfoWrapper>
      {additionalInfo.visits.length > 0 ? (
        <AddModal
          isShown={isShown}
          hide={toggle}
          onCancel={toggle}
          propertyId={propertyId}
          type={USER_TYPES.buyer}
          user="Buyer"
          getSelected={getBuyer}
        />
      ) : (
        <TimeSlotsModal
          isShown={isShown}
          hide={toggle}
          onCancel={toggle}
          propertyId={propertyId}
          sellerId={sellerId}
          contacts={additionalInfo.sellerProfile}
          onConfirm={() => {
            sendRequest('reminder')
          }}
        />
      )}
      {isSended && (
        <SendModal
          isShown={isSended}
          onConfirm={() => setSended(false)}
          type={request}
          propertyId={propertyId}
          propertyProfileId={profileId}
          buyerId={buyer.id}
          header={SEND_MODAL.header}
          message={request === 'reminder' ? SEND_MODAL.message.reminder : SEND_MODAL.message.link}
        />
      )}
    </CardWrapper>
  )
}

export default AgentPropertyCard
