import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'

export const AgencyContainer = styled(Col)<{ transparently: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) =>
    p.transparently ? p.theme.color.black_50 : p.theme.color.originalBlue};
  height: ${(p) => (p.isMobile ? '3rem' : p.theme.indent.XXXL)};
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
  max-width: 76.8rem;
`
export const Container = styled.div<{ transparently: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${(p) => (p.transparently ? 'none' : p.theme.color.originalBlue)};
  height: ${(p) => (p.isMobile ? '3rem' : p.theme.indent.XXXL)};
`
export const PhoneContainer = styled.div`
  display: flex;
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${(p) => p.theme.indent.XS};
`
