import { FIXED_INPUT_PHONE_NUMBERS } from '@constants/dictionary'
import { withoutCyrillicRegExp, onlyLatin } from '@constants/regExp'
import * as yup from 'yup'

export const requiredRule = (str: string): string => yup.string().trim().required(str)
export const notRequiredRule = (): string => yup.string().trim().notRequired()

const minSymbolTitle = 2
const maxSymbolTitle = 50
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', undefined]

export const schema = yup.object().shape({
  name: requiredRule().matches(onlyLatin).min(minSymbolTitle).max(maxSymbolTitle),
  phoneNumber: requiredRule('Value is required').min(
    FIXED_INPUT_PHONE_NUMBERS,
    'Not a valid number',
  ),
  location: notRequiredRule().matches(withoutCyrillicRegExp),
  image: yup
    .mixed()
    .test('fileType', 'Invalid File Format', (value) => SUPPORTED_FORMATS.includes(value.type)),
})
