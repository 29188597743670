import {
  collection,
  DocumentData,
  Query,
  getDocs,
  getFirestore,
  query,
  where,
  QueryConstraint,
  Timestamp,
} from 'firebase/firestore'
import { addHours } from 'date-fns'
import { getAgencyDataId } from '@utils/functions'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'
import { PROPERTY_TIMESLOTS_START_POINT } from '@constants/dictionary'

export const getPropertyTimeSlots = (propertyId: string): Query => {
  const db = getFirestore()

  const { isAgency, agentId, uid } = getAgencyDataId(false)

  const queryConstraints: Array<QueryConstraint> = [
    where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
    where(FS_Q_PARAMS.CANCELLED, '==', null),
    where(FS_Q_PARAMS.BUYER, '==', null),
  ]
  if (agentId) {
    queryConstraints.push(where(FS_Q_PARAMS.AGENTID, '==', agentId))
  }
  if (isAgency) {
    queryConstraints.push(where(FS_Q_PARAMS.AGENCY_ID, '==', uid))
  }

  return query(collection(db, FS_COL_NAMES.PROPERTY_VISITS), ...queryConstraints)
}

export const getPropertyBuyerTimeSlots = async (
  propertyId: string,
  fetchOnlyFutureVisits?: boolean,
): Promise<Array<{ id: string; visit: DocumentData }>> => {
  const db = getFirestore()
  const col = collection(db, FS_COL_NAMES.PROPERTY_VISITS)

  const removeVisitsTimePoint = addHours(new Date(), PROPERTY_TIMESLOTS_START_POINT)
  const removeVisitsTimestamp = Timestamp.fromDate(removeVisitsTimePoint)

  const queryConstraints: Array<QueryConstraint> = [
    where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
    where(FS_Q_PARAMS.CANCELLED, '==', null),
    where(FS_Q_PARAMS.BUYER, '==', null),
    where(FS_Q_PARAMS.IS_BOOKED, '==', false),
  ]

  if (fetchOnlyFutureVisits) {
    queryConstraints.push(where(FS_Q_PARAMS.FROM_DATE_TIME, '>', removeVisitsTimestamp))
    queryConstraints.push(where(FS_Q_PARAMS.IS_BOOKED, '==', false))
  }

  const q = query(col, ...queryConstraints)
  const visits = await getDocs(q)
  return visits.docs.map((visit) => ({
    id: visit.id,
    visit: visit.data(),
  }))
}

export const getPropertyBuyerTimeSlotsCol = (propertyId: string): Query => {
  const db = getFirestore()
  const col = collection(db, FS_COL_NAMES.PROPERTY_VISITS)
  return query(
    col,
    where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
    where(FS_Q_PARAMS.CANCELLED, '==', null),
    where(FS_Q_PARAMS.BUYER, '==', null),
    where(FS_Q_PARAMS.IS_BOOKED, '==', false),
  )
}
