import {
  AgentLogin,
  AgentHome,
  AgentResetPassword,
  AgentNewPassword,
  AgentAddPropertyPage,
  AgentPropertyInfoPage,
  Buyers,
  Sellers,
  AddUser,
  AgentSettings,
  AgentHelpPage,
  PrivacyPolicy,
  EditUser,
  EditProperty,
} from '@pages'
import { Route } from 'react-router-dom'
import { PrivateRoute } from '@helpers'
import { ROUTES } from '@constants/dictionary'

const PrivateAgentPages = [
  {
    id: 'AgentLogin',
    exact: true,
    component: AgentLogin,
    path: ROUTES.AGENT_LOGIN,
    route: Route,
  },
  {
    id: 'Analytics',
    exact: true,
    component: AgentHome,
    path: ROUTES.AGENT_ANALYTICS,
    route: PrivateRoute,
  },
  {
    id: 'Buyers',
    exact: true,
    component: AgentHome,
    path: ROUTES.AGENT_BUYERS,
    route: PrivateRoute,
  },
  {
    id: 'Sellers',
    exact: true,
    component: AgentHome,
    path: ROUTES.AGENT_SELLERS,
    route: PrivateRoute,
  },
  {
    id: 'Viewings',
    exact: true,
    component: AgentHome,
    path: ROUTES.AGENT_VIEWINGS,
    route: PrivateRoute,
  },
  {
    id: 'AllProperies',
    exact: true,
    component: AgentHome,
    path: ROUTES.AGENT_PROPERTIES,
    route: PrivateRoute,
  },
  {
    id: 'AgentAddPropertyPage',
    exact: true,
    component: AgentAddPropertyPage,
    path: ROUTES.AGENT_ADD_PROPERTY,
    route: PrivateRoute,
  },
  {
    id: 'AgentResetPassword',
    exact: true,
    component: AgentResetPassword,
    path: ROUTES.AGENT_RESET_PASSWORD,
    route: Route,
  },
  {
    id: 'AgentNewPassword',
    exact: true,
    component: AgentNewPassword,
    path: ROUTES.AGENT_NEW_PASSWORD,
    route: Route,
  },
  {
    id: 'AgentPropertyInfoPage',
    exact: true,
    component: AgentPropertyInfoPage,
    path: ROUTES.AGENT_PROPERTY_INFO,
    route: PrivateRoute,
  },
  {
    id: 'AgentPropertyVisitInfo',
    exact: true,
    component: AgentPropertyInfoPage,
    path: ROUTES.AGENT_PROPERTY_VISIT_INFO,
    route: PrivateRoute,
  },
  {
    id: 'AgentPropertyTimeSlots',
    exact: true,
    component: AgentPropertyInfoPage,
    path: ROUTES.AGENT_PROPERTY_TIME_SLOTS,
    route: PrivateRoute,
  },
  {
    id: 'VisitInfo',
    exact: true,
    component: Buyers,
    path: ROUTES.AGENT_BUYERS_VISIT_INFO,
    route: PrivateRoute,
  },
  {
    id: 'BuyerInfo',
    exact: true,
    component: Buyers,
    path: ROUTES.AGENT_BUYERS_BUYER_INFO,
    route: PrivateRoute,
  },
  {
    id: 'PropertyList',
    exact: true,
    component: Sellers,
    path: ROUTES.AGENT_SELLERS_PROPERTY_LIST,
    route: PrivateRoute,
  },
  {
    id: 'SellerInfo',
    exact: true,
    component: Sellers,
    path: ROUTES.AGENT_SELLERS_SELLER_INFO,
    route: PrivateRoute,
  },
  {
    id: 'AddUser',
    exact: true,
    component: AddUser,
    path: ROUTES.AGENT_ADD_USER,
    route: PrivateRoute,
  },
  {
    id: 'AgentSettings',
    exact: true,
    component: AgentSettings,
    path: ROUTES.AGENT_SETTINGS,
    route: PrivateRoute,
  },
  {
    id: 'Help',
    exact: true,
    component: AgentHelpPage,
    path: ROUTES.AGENT_HELP_PAGE,
    route: PrivateRoute,
  },
  {
    id: 'PrivacyPolicy',
    exact: true,
    component: PrivacyPolicy,
    path: ROUTES.PRIVACY_POLICY,
    route: Route,
  },
  {
    id: 'EditBuyer',
    exact: true,
    component: EditUser,
    path: ROUTES.AGENT_EDIT_BUYER,
    route: PrivateRoute,
  },
  {
    id: 'EditSeller',
    exact: true,
    component: EditUser,
    path: ROUTES.AGENT_EDIT_SELLER,
    route: PrivateRoute,
  },
  {
    id: 'EditProperty',
    exact: true,
    component: EditProperty,
    path: ROUTES.AGENT_EDIT_PROPERTY,
    route: PrivateRoute,
  },
  {
    id: 'EditProperty',
    exact: true,
    component: EditProperty,
    path: ROUTES.AGENT_EDIT_PROPERTY,
    route: PrivateRoute,
  },
]
export default PrivateAgentPages
