import { css } from 'styled-components'
import OpenSansRegular from '@assets/fonts/OpenSans-Regular.ttf'
import OpenSansSemiBold from '@assets/fonts/OpenSans-SemiBold.ttf'
import OpenSansBold from '@assets/fonts/OpenSans-Bold.ttf'
import QuicksandBold from '@assets/fonts/Quicksand-Bold.ttf'

const fontsCss = css`
  @font-face {
    font-family: 'OpenSansRegular';
    font-weight: 400;
    src: local('OpenSansRegular'), url(${OpenSansRegular}) format('truetype');
    font-style: normal;
    font-stretch: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'OpenSansSemiBold';
    font-weight: 600;
    src: local('OpenSansSemiBold'), url(${OpenSansSemiBold}) format('truetype');
    font-style: normal;
    font-stretch: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'OpenSansBold';
    font-weight: bold;
    src: local('OpenSansBold'), url(${OpenSansBold}) format('truetype');
    font-style: normal;
    font-stretch: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'QuicksandBold';
    font-weight: 800;
    src: local('QuicksandBold'), url(${QuicksandBold}) format('truetype');
    font-style: normal;
    font-stretch: normal;
    font-display: block;
  }
`

export default fontsCss
