import React from 'react'
import { Typography } from '@atoms'
import { AgentIntegrationPlate } from '@molecules'

import { IntegrationsContrainer } from './Integrations.style'

const Integrations: React.FC = () => {
  return (
    <IntegrationsContrainer>
      <Typography>You could manage your integrations</Typography>
      <AgentIntegrationPlate
        title="Google Calendar"
        iconName="integrations-google-calendar"
        connectHandler={(): void => {}}
      />
    </IntegrationsContrainer>
  )
}

export default Integrations
