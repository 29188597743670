import { FIXED_INPUT_PHONE_NUMBERS } from '@constants/dictionary'
import { withoutCyrillicRegExp } from '@constants/regExp'
import * as yup from 'yup'

export const requiredRule = (): string => yup.string().trim().required()

const minSymbolTitle = 2
const maxSymbolTitle = 50

export const schema = yup.object().shape({
  name: requiredRule().matches(withoutCyrillicRegExp).min(minSymbolTitle).max(maxSymbolTitle),
  phoneNumber: requiredRule().min(FIXED_INPUT_PHONE_NUMBERS),
})
