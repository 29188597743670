import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { refactoredData } from '@utils/functions'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Table } from '@organisms'
import { getDatesSorting } from '@utils/sorting'
import ViewProfileComponent from './ViewProfileComponent'
import { Cell, Container, LinkTo } from './UsersTabContent.style'
import { UserType } from '@constants/types'
import { ROUTES, USER_TYPES } from '@constants/dictionary'

const UsersTabContent = ({
  data,
  typeUser,
  user,
}: {
  data: Array<UserType>
  typeUser: string
  user: string
}): React.FC => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const { location, push } = useHistory()
  const refactorData = refactoredData(data)
  const clickOnButton = useCallback(() => {
    push({
      pathname: ROUTES.AGENT_ADD_USER,
      state: user,
    })
  }, [push, user])

  const viewProfile = useCallback(
    (id: string, profileId: string) => {
      push({
        pathname: `${location.pathname}/${id}-${profileId}/${
          typeUser === USER_TYPES.buyer ? 'buyer-info' : 'seller-info'
        }`,
      })
    },
    [push, location.pathname, typeUser],
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        percentWidth: 19,
        Cell: ({ row: { original } }: TableRowType<UserType>) => <Cell>{original.name}</Cell>,
      },
      {
        Header: 'Email',
        accessor: 'email',
        percentWidth: 20,
        Cell: ({ row: { original } }: TableRowType<UserType>) => (
          <LinkTo noUnderline isMobile href={`mailto:${original.email}?subject=ViewD`}>
            {original.email}
          </LinkTo>
        ),
      },
      {
        Header: 'Phone number',
        accessor: 'phoneNumber',
        percentWidth: 19,
        Cell: ({ row: { original } }: TableRowType<UserType>) => original.phoneNumber,
      },
      {
        Header: 'Date of contact',
        accessor: 'startDate',
        percentWidth: 19,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<UserType>) => original.startDate,
        sortType: getDatesSorting('startDate'),
      },
      {
        Header: 'Last activity',
        accessor: 'lastActivity',
        percentWidth: 13,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<UserType>) => original.lastActivity,
        sortType: getDatesSorting('lastActivity'),
      },
      {
        Header: '',
        accessor: 'id',
        percentWidth: 10,
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: TableRowType<UserType>) => (
          <ViewProfileComponent
            viewProfile={viewProfile}
            id={original.userId}
            profileId={original.id}
            agentId={original.createdBy}
          />
        ),
      },
    ],
    [viewProfile],
  )

  return (
    <Container isTabletPortrait={isTabletPortrait} isMobile={isMobile}>
      <Table
        typeUser={typeUser}
        columns={columns}
        data={refactorData}
        title="AgentUsersTitle"
        user={user}
        setAddUser={clickOnButton}
        defaultSorting={[
          {
            id: 'startDate',
            desc: true,
          },
        ]}
      />
    </Container>
  )
}

export default UsersTabContent
