import { getFunctions, httpsCallable } from 'firebase/functions'
import { UserType } from '@constants/types'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const updateUser = async (data: UserType): { result: boolean } => {
  const functions = getFunctions()
  const update = httpsCallable(functions, FUNC_NAMES.UPDATE_USER)
  try {
    await update(data)
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}
