import { Typography } from '@atoms'
import { Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const NotificationsWrapper = styled(Col)`
  padding: 0 ${(p) => p.theme.indent.XXXL} 11.2rem;
  background-color: ${({ theme }) => theme.color.white};
  overflow: auto;
  max-height: 100%;
`
export const NotificationsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(p) => p.theme.indent.MD};
`

export const Caption2 = styled(Typography.Caption2)`
  cursor: pointer;
`
