const COLORS = {
  white: '#FFFFFF',
  transparent: 'rgba(255, 255, 255, 0)',
  blue: '#3782A4',
  originalBlue: '#33A0C1',
  originalBlue_20: 'rgba(51, 160, 193, 0.2)',
  originalBlue_10: 'rgba(51, 160, 193, 0.1)',
  originalBlue_hover: '#218AAA',
  darkBlue: '#141E3D',
  darkBlue_90: 'rgba(20, 30, 61, 0.9)',
  darkBlue_60: 'rgba(20, 30, 61, 0.6)',
  lightGrey: '#E5E5E5',
  lightGrey_20: 'rgba(229, 229, 229, 0.2)',
  grey: '#B5B5B5',
  greyBlue: '#F3F7F8',
  darkGrey: '#9B9B9B',
  darkBlack: '#0A0A0A',
  black: '#000000',
  black_20: 'rgba(0, 0, 0, 0.2)',
  black_30: 'rgba(0, 0, 0, 0.3)',
  black_50: 'rgba(0, 0, 0, 0.5)',
  darkPurple: '#131C35',
  yellow: '#EF9B38',
  yellow_15: 'rgba(248, 181, 112, 0.15)',
  red: '#EF5050',
  red_15: 'rgba(223, 64, 64, 0.15)',
  lightRed: '#F4DDDE',
  green: ' #6FBA89',
  green_15: 'rgba(111, 186, 137, 0.15)',
  successGreen: '#69CB58',
  lightGreen: '#D8F8D2',
  whiteSmoke: '#f5f5f5',
  darkGrey_15: 'rgba(155, 155, 155, 0.15)',
  lightBlack: '#535353',
  lightBlack_15: 'rgba(83,83,83, 0.15)',
  darkYellow: '#DF6640',
  darkYellow_15: 'rgba(223,102,64, 0.15)',
  lightBlue: '#E0EEF3',
}

export default COLORS
