import { AgentVisitInfoCard, AgentPropertyInfoCard, AgentPropertyTimeSlotsCard } from '@templates'

export const tabsConfig = [
  {
    name: 'Property info',
    path: '/property-info',
    component: AgentPropertyInfoCard,
    exact: true,
    id: 'property-info',
  },
  {
    name: 'Visits info',
    path: '/visits-info',
    component: AgentVisitInfoCard,
    id: 'visits-info',
  },
  {
    name: 'Time slots',
    path: '/time-slots',
    component: AgentPropertyTimeSlotsCard,
    id: 'time-slots',
  },
]
