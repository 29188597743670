import React from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from 'styled-components'
import { useMobile } from '@utils/hooks'
import { TimeLineTypes } from '@constants/types'

const TimelineChart: React.FC<{ series: TimeLineTypes }> = ({ series }) => {
  const isMobile = useMobile()
  const colors = useTheme().color

  // https://apexcharts.com/docs
  const config = {
    options: {
      chart: {
        type: 'rangeBar',
        toolbar: { show: false },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%',
          rangeBarGroupRows: true,
        },
      },
      colors: [colors.yellow],
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            fontSize: isMobile ? '1.4rem' : '1.6rem',
            colors: [colors.darkGrey],
          },
          format: 'h tt',
          datetimeUTC: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          align: 'left',
          style: {
            fontSize: isMobile ? '1.4rem' : '1.6rem',
            colors: [colors.darkBlack],
          },
        },
        axisBorder: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
        x: {
          format: 'h tt',
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        borderColor: colors.lightGrey,
        strokeDashArray: 5,
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
        },
      },
    },
  }

  return <Chart {...config} type="rangeBar" series={series} height={isMobile ? '250' : '350'} />
}

export default TimelineChart
