import { VIEWING_STATUSES } from '@constants/dictionary'
import { ViewingStatusType } from '@constants/types'

export const getViewingStatus = (
  fromDateTime: Date,
  isConfirmed: boolean,
  hasCancelInfo: boolean,
): ViewingStatusType => {
  const currentTime = new Date()

  if (!isConfirmed && hasCancelInfo) {
    return VIEWING_STATUSES.CANCELED
  } else if (fromDateTime <= currentTime && isConfirmed) {
    return VIEWING_STATUSES.CONFIRMED
  } else if (fromDateTime > currentTime && !isConfirmed) {
    return VIEWING_STATUSES.CONFIRM_VIEW_DISABLED
  } else if (fromDateTime <= currentTime && !isConfirmed) {
    return VIEWING_STATUSES.CONFIRM_VIEW
  }

  return VIEWING_STATUSES.CONFIRM_VIEW_DISABLED
}
