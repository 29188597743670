import { GETADRESS } from '@constants/getAddressIO'

export const loadAddressOptions = async (value: string): Promise<object> => {
  const response = await fetch(`${GETADRESS.AUTOCOMPLETE}/${value}?api-key=${GETADRESS.API_KEY}`, {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.json())
  return response
}
