export const EDITING_STATUS = [
  { id: 'allStatuses', value: '', title: 'All Statuses' },
  {
    id: 'active',
    value: 'active',
    title: 'Active',
    text: 'Property is active now',
  },
  {
    id: 'underOffer',
    value: 'underOffer',
    title: 'Under offer',
    text: '	Property owner took the offer',
  },
  {
    id: 'soldStc',
    value: 'soldStc',
    title: 'Sold STC',
    text: 'Property selling underway',
  },
  {
    id: 'sold',
    value: 'sold',
    title: 'Sold',
    text: 'Property is sold',
  },
  {
    id: 'archived',
    value: 'archived',
    title: 'Archive',
    text: 'Property is temporary unavailable',
  },
  {
    id: 'offMarket',
    value: 'offMarket',
    title: 'Off market',
    text: 'Property is not in work',
  },
]
