/* eslint-disable no-nested-ternary */
import React from 'react'
import { Icon } from '@atoms'
import { useTheme } from 'styled-components'
import { Th } from './Table.style'

const TableHeadColumn = ({
  headers,
  visibleColumnsNumber,
  changeSort,
  typeUser,
}: {
  headers: Array
  visibleColumnsNumber: number
  changeSort: void
  typeUser: string
}): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      {headers.map((column, i) => (
        <Th
          {...column.getHeaderProps({
            width: column.width.toString(),
            colSpan: visibleColumnsNumber,
          })}
          key={i}
          onClick={() => {
            if (column.isSorted && !column.disableSortBy && typeUser === 'property') {
              changeSort(column)
            }
          }}
        >
          {column.render('Header')}
          <span {...column.getSortByToggleProps()}>
            {!column.disableSortBy &&
              (column.isSorted ? (
                column.isSortedDesc ? (
                  <Icon
                    icon="arrow-up-filled"
                    size="0.8rem"
                    color={theme.color.originalBlue}
                    clickable
                  />
                ) : (
                  <Icon icon="dropdown" size="0.8rem" color={theme.color.originalBlue} clickable />
                )
              ) : (
                <Icon icon="dropdown" size="0.8rem" color={theme.color.darkGrey} clickable />
              ))}
          </span>
        </Th>
      ))}
    </>
  )
}

export default TableHeadColumn
