import React from 'react'
import { useMobile } from '@utils/hooks'
import { Icon, Typography } from '@atoms'
import { AgencyContainer, IconContainer, PhoneContainer, Container } from './AgencyInfo.style'

import { AgencyInfoTypes } from '@constants/types/molecules'

const AgencyInfo: React.FC<AgencyInfoTypes> = ({
  nameAgency,
  phoneAgency,
  transparently = false,
}): JSX.Element => {
  const isMobile = useMobile()

  return (
    <Container transparently={transparently}>
      <AgencyContainer transparently={transparently} col={12} noGutter isMobile={isMobile}>
        <Typography.Body2 isMobile={isMobile} color="white">
          {nameAgency}
        </Typography.Body2>
        <PhoneContainer>
          <IconContainer>
            <Icon icon="phone" size={isMobile ? '1.2rem' : '1.5rem'} color="white" />
          </IconContainer>
          <Typography.Body2
            as="a"
            isMobile={isMobile}
            href={`tel:${phoneAgency}`}
            color="white"
            textDecoration="none"
          >
            {phoneAgency}
          </Typography.Body2>
        </PhoneContainer>
      </AgencyContainer>
    </Container>
  )
}

export default AgencyInfo
