import React, { useCallback, useState } from 'react'
import { useMobile } from '@utils/hooks'
import { DayPickRow } from '@molecules'
import { DayTypes, State } from '@constants/types'
import { combineSlotsIntoPeriods, switchDay } from '@utils/functions'
import { StyledRow, DayPickRowContainer } from './SellerSlots.style'
import TimePeriods from './TimePeriods'

const FirstWeek = ({ initialState }: State): JSX.Element => {
  const state = combineSlotsIntoPeriods(initialState.firstWeek)
  const [firstWeek, setFirstWeek] = useState(state)
  const isMobile = useMobile()

  const changeDay = useCallback(
    (payload) => {
      const changeWeek = switchDay(firstWeek, payload)
      setFirstWeek(changeWeek)
    },
    [firstWeek],
  )

  return (
    <>
      <DayPickRowContainer isMobile={isMobile}>
        <DayPickRow daysList={firstWeek} chooseDay={changeDay} />
      </DayPickRowContainer>
      <StyledRow isMobile={isMobile}>
        {firstWeek.map(
          (day: DayTypes, index: number) =>
            day.active && <TimePeriods day={day} key={`${index + 1}`} />,
        )}
      </StyledRow>
    </>
  )
}

export default FirstWeek
