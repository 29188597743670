import { UserType } from '@constants/types'
import { AgentAddUserFormType } from '@constants/types'
import { phoneCode } from '@constants/dictionary'
import { customFormatValue } from '@utils/functions'
import { withoutSpace } from '@constants/regExp'
import { USER_TYPES } from '@constants/dictionary'

const phoneFormat = (phone: string): string => {
  return phone ? phoneCode + customFormatValue(phone, withoutSpace, '') : ''
}
const clearDataField = (data: UserType): UserType => {
  const newData = data
  Object.keys(newData).forEach((e: string) => {
    if (!newData[e]) delete newData[e]
  })
  return newData
}

export const prepareData = (
  {
    fullName,
    email,
    phone,
    additionalPhone,
    additionalFullName,
    additionalEmail,
    note,
  }: AgentAddUserFormType,
  seller?: boolean,
): UserType => {
  return clearDataField({
    name: fullName,
    email: email,
    phoneNumber: phoneFormat(phone),
    userType: seller ? USER_TYPES.seller : USER_TYPES.buyer,
    note: note,
    additionalName: additionalFullName,
    additionalEmail: additionalEmail,
    additionalPhoneNumber: phoneFormat(additionalPhone),
  })
}

export const prepareDataForUpdate = (
  {
    fullName,
    email,
    phone,
    additionalPhone,
    additionalFullName,
    additionalEmail,
    note,
  }: AgentAddUserFormType,
  userId: string,
  userProfileId: string,
): UserType => {
  return clearDataField({
    userId,
    userProfileId,
    name: fullName,
    email: email,
    phoneNumber: phoneFormat(phone),
    note: note,
    additionalName: additionalFullName,
    additionalEmail: additionalEmail,
    additionalPhoneNumber: phoneFormat(additionalPhone),
  })
}
