import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const StyledImg = styled.img`
  width: 12rem;
`

export const Container = styled.div<{ isMobile: boolean; isMobileLandscape: boolean }>`
  ${({ theme, isMobile, isMobileLandscape }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    padding: theme.indent.XXXL,
    borderRadius: theme.borderRadius.MD,
    width: '41.7rem',
    height: '44.4rem',
    position: 'relative',
    ...(isMobile && {
      height: undefined,
    }),
    ...(isMobileLandscape && {
      height: undefined,
      overflowY: 'scroll',
    }),
  })};
`

export const IconWrapper = styled.div`
  position: absolute;
  top: ${(p) => p.theme.indent.XL};
  right: ${(p) => p.theme.indent.XL};
`

export const ConfirmMessage = styled.div`
  text-align: center;
  margin: ${(p) => p.theme.indent.LG} auto;
`

export const ContactsWrapper = styled.div<{ isMobile: boolean }>`
  margin-bottom: ${(p) => p.theme.indent.LG};
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${(p) => (p.isMobile || p.isTabletPortrait ? p.theme.indent.SM : p.theme.indent.MD)};
`

export const DetailsRow = styled(Row)<{ isMobile: boolean }>`
  display: grid;
  grid-template-areas: 'icon text';
  grid-template-columns: ${(p) => p.theme.indent.LG} auto;
  align-items: center;
  gap: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
`
export const Body3 = styled(Typography.Body3)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
