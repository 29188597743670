import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.indent.XXXL};
`

export const TitleBackground = styled.div<{
  path: URL
  isMobile: boolean
  isTabletPortrait: boolean
  isDesktop: boolean
}>`
  width: 100%;
  background-image: url(${({ path }) => path});
  background-size: cover;
  background-position: center;
  position: relative;
  height: ${({ isMobile, isTabletPortrait, isDesktop }) => {
    if (isDesktop) return '27rem'
    if (isTabletPortrait) return '22.2rem'
    if (isMobile) return '15.8rem'
  }};
`

export const Title = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.color.darkBlue_60};
  height: 100%;
`

export const BodyContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTabletPortrait: boolean
}>`
  display: flex;
  width: 100%;
  padding: ${({ isMobile, isDesktop, isTabletPortrait, theme }) => {
    if (isDesktop) return `0 5rem`
    if (isTabletPortrait) return `0 ${theme.indent.XXXL}`
    if (isMobile) return `0  ${theme.indent.LG}`
  }};
  ${({ isDesktop }) => !isDesktop && 'flex-wrap: wrap;'};
`
