import { Column } from 'react-table'
import { ONE_HUNDRED_PERCENT } from '@constants/dictionary'
import { tabs } from '@constants/dictionary'
import { TabsType } from '@constants/types'
import { parse } from 'date-fns'

export const mapColumns = (
  tableColumns: Column & { percentWidth?: number }[],
  tableWidth: number,
): Array => {
  return tableColumns.map((column) => {
    if (column.percentWidth) {
      const columnWidthProperty = (tableWidth / ONE_HUNDRED_PERCENT) * column.percentWidth
      return { ...column, width: columnWidthProperty }
    }
    return column
  })
}

function timeDifference(row: object, upcoming: boolean): boolean {
  const currentDate = new Date().getTime()
  const parsedDate = parse(row?.date, 'dd/MM/yyyy', new Date()).getTime()

  const difference = parsedDate - currentDate
  return upcoming ? difference >= 0 : difference < 0
}

export const filterTableData = (data: Array<object>, filter: typeof TabsType): [] => {
  switch (filter) {
    case tabs.upcomingVisits:
      return data.filter((row) => timeDifference(row, true))
      break
    case tabs.pastVisits:
      return data.filter((row) => timeDifference(row, false))
      break
    case tabs.cancelVisits:
      return data.filter((row) => row.status === 'Canceled')
      break
    default:
      return data
  }
}
