import React from 'react'

import {
  RadioButtonsContainer,
  RadioButtonItem,
  RadioBullet,
  RadioLabel,
} from './BulletRadioButton.style'

import { RadioButtonProps } from '@constants/types/molecules'

const BulletRadioButton: React.FC<RadioButtonProps> = ({
  onChange,
  items,
  selected,
  isHorizontal,
}) => {
  return (
    <RadioButtonsContainer isHorizontal={isHorizontal}>
      {items.map(({ label, value }) => (
        <RadioButtonItem onClick={() => onChange(value)} key={value}>
          <RadioBullet selected={value === selected} />
          <RadioLabel>{label}</RadioLabel>
        </RadioButtonItem>
      ))}
    </RadioButtonsContainer>
  )
}

export default BulletRadioButton
