import React from 'react'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { AgentAddUserCard } from '@organisms'
import { ROUTES } from '@constants/dictionary'

const EditUser = (): JSX.Element => {
  const location = useLocation()
  const { id }: { id: string } = useParams()
  const userType = location.pathname.split('/agent/')[1].split('/')[0]

  if (!location.state) {
    return (
      <Redirect
        to={
          userType === 'buyer'
            ? `${ROUTES.AGENT_BUYERS}/${id}/buyer-info`
            : `${ROUTES.AGENT_SELLERS}/${id}/seller-info`
        }
      />
    )
  }
  const userFile = location.state.userFile

  return <AgentAddUserCard editUser={userType} userFile={userFile} seller={userType === 'seller'} />
}

export default EditUser
