import { writeBatch, collection, doc, getFirestore } from 'firebase/firestore'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const setSellerSlots = async (
  arraySlots: Array<{ fromDateTime: string; toDateTime: string }>,
): { error: string } => {
  const db = getFirestore()
  const batch = writeBatch(db)

  const sellerId = localStorage.getItem(LS_CONTSTANTS.uid)
  const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)

  const arryaValidSlots = arraySlots.filter((item) => item.fromDateTime > new Date())

  arryaValidSlots.forEach((slot: { fromDateTime: string; toDateTime: string }) => {
    const generetedSlot = {
      ...slot,
      agent: null,
      buyer: null,
      cancelled: null,
      createdAt: new Date(),
      createdBy: sellerId,
      isConfirmed: false,
      propertyId,
      propertyProfile: null,
      isBooked: false,
    }
    const docRef = doc(collection(db, FS_COL_NAMES.PROPERTY_VISITS))
    batch.set(docRef, generetedSlot)
  })

  const propertyDocRef = doc(db, FS_COL_NAMES.PROPERTIES, propertyId)
  batch.update(propertyDocRef, { lastSlotsRenewingDate: new Date() })

  await batch.commit()
}
