import styled from 'styled-components'
import { Typography } from '@atoms'
import { Link } from 'react-router-dom'
import { Col, Row } from 'styled-bootstrap-grid'

export const ConfirmImage = styled.img`
  display: flex;
  margin: auto;
  height: ${(p) => (p.isMobile ? '18rem' : '25rem')};
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXL)};
  margin-bottom: 0;
`
export const ConfirmedImage = styled.img`
  display: flex;
  margin: auto;
  height: ${(p) => (p.isMobile ? '10rem' : '15rem')};
  margin-top: ${(p) => (p.isMobile ? '8rem' : '13rem')};
  margin-bottom: ${(p) => (p.isMobile ? p.theme.indent.XL : '4rem')};
`
export const ConfirmH2 = styled(Typography.H2)`
  padding: ${(p) =>
    p.isMobiles ? `${p.theme.indent.XL} 0 ${p.theme.indent.MD}` : `${p.theme.indent.XL} 0 `};
`
export const DayChooseH2 = styled(Typography.H2)`
  padding: ${(p) => `${p.theme.indent.LG} 0 ${p.theme.indent.SM}`};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 4.4rem);
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
`
export const DayChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LinkContainer = styled(Link)`
  text-decoration: none;
  justify-content: center;
  align-items: center;
`

export const StyledCol1 = styled(Col)`
  padding-right: ${(p) => p.theme.indent.XS};
`

export const StyledCol2 = styled(Col)`
  padding-left: ${(p) => p.theme.indent.XS};
`

export const ButtonRow = styled(Row)<{ isMobile: boolean; isDesktop: boolean }>`
  padding: ${(p) =>
    p.isMobile ? `${p.theme.indent.XXXL} 0 ${p.theme.indent.XXXL}` : `0 0  8rem 0 `};
  margin-top: ${({ isDesktop, theme }) => (isDesktop ? theme.indent.XXXL : 'auto')};
`

export const HR = styled.hr`
  border: 0;
  position: relative;
  left: -100%;
  z-index: 3;
  border-top: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  width: 300%;
`
