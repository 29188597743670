export const FUNC_NAMES = {
  GENERATE_CUSTOM_TOKEN: 'generateCustomToken',
  BOOK_A_VISIT: 'bookAVisit',
  CANCEL_PROPERTY_ALL_VISITS: 'cancelPropertyAllVisits',
  CANCEL_AVAILABLE_SLOTS: 'cancelAvailableSlots',
  CANCEL_A_VISIT: 'cancelAVisit',
  CONFIRM_A_VISIT: 'confirmAVisit',
  STORE_PROPERTY: 'storeProperty',
  DELETE_AGENT: 'deleteAgent',
  GET_SELLER_PROPERTIES_STATUSTICS_PER_AGENT: 'getSellerPropertiesStatisticsPerAgent',
  REMIND_SELLER_TO_ADD_SLOTS: 'remindSellerToAddSlots',
  SEND_PROPERTY_LINK: 'sendPropertyLink',
  STORE_AGENT: 'storeAgent',
  STORE_USER: 'storeUser',
  UPDATE_PROPERTY_PROFILE: 'updatePropertyProfile',
  UPDATE_USER: 'updateUser',
}
