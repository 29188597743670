import React, { useState } from 'react'
import { Loader, Typography } from '@atoms'
import { AgencyInfo, Carousel, TimelineChart } from '@molecules'
import { actualStatus, convertToPrice, getWeek } from '@utils/functions'
import { useMobile, useTabletPortrait, useDate, useMountEffect } from '@utils/hooks'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'
import { BOOKING_STATUSES_NAMES, LS_CONTSTANTS } from '@constants/dictionary'
import { getSellerSlots, getPropertyProfileCollection } from '@services/firebase'
import { calculatePeriodsData } from './SellerProperty.utils'
import {
  Container,
  ContainerInfo,
  BookingCardImage,
  Text,
  H2,
  Body2,
  ContainerAgency,
  ContainerH1,
} from './SellerProperty.style'

const SellerProperty: React.FC = () => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const [loaded, setLoaded] = useState(false)
  const [propertyProfiles, setPropertyProfiles] = useState([])
  const [curentProfileId, setCurrentProfileId] = useState(0)

  const getSellerPropertyProfiles = async (): undefined => {
    setLoaded(false)
    const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId) || ''
    const profiles = await getPropertyProfileCollection(propertyId)
    setPropertyProfiles(profiles)
    setLoaded(true)
  }

  useMountEffect(getSellerPropertyProfiles)

  // get seller visits periods by propertyProfile id
  const querySellerSlots = getSellerSlots()
  const [value, loading] = useCollectionOnce(querySellerSlots)
  const { getSellersWeek } = useDate()
  const sellerWeek = getSellersWeek()
  const currentWeek = getWeek(sellerWeek)
  const preparedData = calculatePeriodsData(
    value,
    sellerWeek,
    loading,
    propertyProfiles[curentProfileId]?.propertyProfileId,
  )

  const setActive = (activePage): undefined => {
    setCurrentProfileId(activePage)
  }

  const renderImage = (index): React.FC | null => {
    if (propertyProfiles[index]) {
      return (
        <ContainerAgency key={propertyProfiles[index].id + index}>
          <ContainerH1 isMobile={isMobile}>
            <Typography.H1 color="white">{BOOKING_STATUSES_NAMES[currentStatus]}</Typography.H1>
          </ContainerH1>
          <AgencyInfo
            transparently
            nameAgency={propertyProfiles[index]?.agentDetails?.name}
            phoneAgency={propertyProfiles[index]?.agentDetails?.phoneNumber}
          />
          <BookingCardImage path={propertyProfiles[index].imageUrl} />
        </ContainerAgency>
      )
    }
    return null
  }
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const currentStatus = actualStatus(userType, propertyProfiles[curentProfileId]?.status)
  return (
    <>
      <Loader loaded={loaded}>
        <Container>
          {propertyProfiles.length > 1 ? (
            <Carousel setActive={setActive}>
              {propertyProfiles.map((_, index) => renderImage(index))}
            </Carousel>
          ) : (
            renderImage(0)
          )}
          <ContainerInfo border={!isMobile && !isTabletPortrait}>
            <Typography.H2 isMobile={isMobile}>
              {propertyProfiles.length && propertyProfiles[curentProfileId].title}
            </Typography.H2>
            <Text isMobile={isMobile}>
              {propertyProfiles.length && propertyProfiles[curentProfileId].address}
            </Text>
            <Typography.Body2 isMobile={isMobile}>
              £ {convertToPrice(propertyProfiles.length && propertyProfiles[curentProfileId].price)}
            </Typography.Body2>
            <H2 isMobile={isMobile}>Bookings on this week</H2>
            <Body2 isMobile={isMobile}>{currentWeek}</Body2>
            <TimelineChart series={preparedData} />
          </ContainerInfo>
        </Container>
      </Loader>
    </>
  )
}

export default SellerProperty
