import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'
import { Link } from 'react-router-dom'

export const BackgroundContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: calc(100vh + 4.4rem);
  background-color: ${({ theme }) => theme.color.greyBlue};
`
export const TopContainer = styled(Col)<{ isMobile: boolean }>`
  margin: auto;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
  padding: ${({ theme, isMobile }) => (isMobile ? theme.indent.XL : theme.indent.XXXL)};
  position: relative;
  max-width: 76.8rem;
`
export const BottomContainer = styled(Col)<{ isMobile: boolean }>`
  margin: auto;
  max-width: 76.8rem;
  padding: ${({ theme, isMobile }) => (isMobile ? theme.indent.XL : theme.indent.XXXL)};
`

export const ShortInfo = styled(Typography.Body1)<{ isMobile: boolean }>`
  padding-top: ${({ theme }) => theme.indent.SM};
`

export const ConfirmTitle = styled(Typography.H2)<{ isMobile: boolean }>`
  padding-top: ${({ theme }) => theme.indent.SM};
`
export const InfoContainer = styled.div`
  display: flex;
`
export const IconContainer1 = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: ${(p) => p.theme.indent.LG};
  bottom: ${(p) => p.theme.indent.XL};
  padding-bottom: ${(p) => p.theme.indent.SM};
`
export const IconContainer2 = styled(Col)<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  padding-bottom: ${(p) => p.theme.indent.SM};
`
export const ButtonContainer = styled(Link)<{ isMobile: boolean }>`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.indent.XL};
  width: 100%;
`
