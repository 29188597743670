import { addUnit } from '@utils/styles'
import MAIN_COLORS from './colors'
import {
  BORDER_RADIUS,
  INDENTS,
  UNIT,
  FONT_FAMILIES,
  FONT_SIZES,
  LINE_HEIGHTS,
  LETTER_SPACINGS,
} from '../themeConstants'

const MAIN_THEME = {
  color: MAIN_COLORS,
  borderRadius: addUnit(BORDER_RADIUS),
  indent: addUnit(INDENTS),
  unit: UNIT,
  fontFamily: FONT_FAMILIES,
  fontSize: addUnit(FONT_SIZES),
  lineHeight: addUnit(LINE_HEIGHTS),
  letterSpacing: addUnit(LETTER_SPACINGS),
}

export default MAIN_THEME
