import React, { useMemo } from 'react'
import { useMobile } from '@utils/hooks'
import { Icon } from '@atoms'
import { useTheme } from 'styled-components'
import { IconContainer, TitleInfo, TitleEvent } from './IconAndLabel.style'

import { IconAndLabelProps } from '@constants/types/molecules'

const IconAndLabel: React.FC<IconAndLabelProps> = (props) => {
  const isMobile = useMobile()
  const theme = useTheme()

  const {
    onClick,
    title,
    color = theme.color.originalBlue,
    icon,
    textDecoration = 'none',
    link = false,
    textColor,
    clickable,
  } = props
  const Component = useMemo(() => (link ? TitleEvent : TitleInfo), [link])
  return (
    <IconContainer link={link} onClick={onClick || null}>
      <Icon
        icon={icon}
        size={isMobile ? '1.6rem' : '2.1rem'}
        color={color}
        clickable={link || clickable}
        key={color}
      />
      <Component
        clickable={clickable}
        color={textColor}
        isMobile={isMobile}
        textDecoration={textDecoration || 'none'}
      >
        {title}
      </Component>
    </IconContainer>
  )
}

export default IconAndLabel
