import React, { useEffect, useState } from 'react'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Icon } from '@atoms'
import {
  Container,
  ListContainer,
  ContentContainer,
  ListItem,
  MobileListItem,
  StyledIcon,
  ItemContainer,
  H3,
} from './Tabs.style'

type TabsTypes = {
  config: Array<{ title: string; component: JSX.Element; withHeader?: boolean }>
}

const Tabs: JSX.Element<TabsTypes> = ({ config = [] }): JSX.Element => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const [condition, setCondition] = useState<boolean>(false)
  const [active, setActive] = useState<number>(-1)
  const rotateDeg = 180

  const handleActive = (index: number): undefined => {
    return () => {
      setCondition(true)
      setActive(active === index ? -1 : index)
    }
  }

  useEffect(() => {
    // TODO: remove this if useMobile would be fixed, because it always returns false on first render
    if (!isMobile && !condition) {
      setActive(0)
    }
    if (isMobile && !condition) {
      setActive(-1)
    }
  }, [active, condition, isMobile])

  if (!!isMobile) {
    return config.map((item, index) => (
      <div key={item.title}>
        <ItemContainer>
          <StyledIcon>
            <Icon
              icon="arrow-bottom"
              rotate={active === index && rotateDeg}
              size="1.2rem"
              color="originalBlue"
              clickable
              onClick={handleActive(index)}
            />
          </StyledIcon>
          <MobileListItem onClick={handleActive(index)}>{item.title}</MobileListItem>
        </ItemContainer>
        {active === index && (
          <>
            {config[active]?.withHeader && <H3 customMobile={isMobile}>{config[active]?.title}</H3>}
            {config[active]?.component()}
          </>
        )}
      </div>
    ))
  } else
    return (
      <Container>
        <ListContainer isTabletPortrait={isTabletPortrait}>
          {config.map((item, index) => (
            <ListItem
              key={item.title}
              onClick={() => {
                setCondition(true)
                setActive(index)
              }}
              active={active === index}
            >
              {item.title}
            </ListItem>
          ))}
        </ListContainer>
        <ContentContainer>
          {config[active]?.withHeader && <H3 customMobile={isMobile}>{config[active]?.title}</H3>}
          {config[active]?.component()}
        </ContentContainer>
      </Container>
    )
  return null
}

export default Tabs
