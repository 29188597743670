import _ from 'lodash'
import { format } from 'date-fns'

export const mapNotifications = (firestoreList: { docs: Array } = { docs: [] }): Array => {
  const formatIt = (date: Date): string => format(date, 'y,L,d')
  const notificationsIds = []
  const source = firestoreList.docs.map((notification) => {
    // grab only not readed notifications id for writing batch 'markAllAsRead'
    if (notification.id && !notification?.data()?.isRead) {
      notificationsIds.push(notification.id)
    }
    if ({ ...notification.data }) {
      return {
        ...notification.data(),
        compareDate: formatIt(notification.data().createdAt.toDate()),
      }
    }
    return {
      ...notification,
      compareDate: formatIt(notification.createdAt),
    }
  })
  const list = _(source)
    .groupBy('compareDate')
    .map((items, date) => {
      return {
        startDate: new Date(date),
        notifications: _.orderBy(items, ['createdAt'], ['desc']),
      }
    })
    .value()
  return {
    list: _.orderBy(list, ['startDate'], ['desc']),
    idList: notificationsIds,
  }
}

export const hasUnread = (notifications = []): boolean => {
  let hasUnread = false
  let unreadCount = 0
  notifications.forEach((period) =>
    period.notifications.forEach((notification) => {
      // check if we have isREad and it's false
      if (typeof notification.isRead === 'boolean' && notification.isRead === false) {
        hasUnread = true
        unreadCount += 1
      }
    }),
  )
  return { newMessages: hasUnread, unreadCount }
}
