import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div`
  position: relative;
  padding-bottom: ${(p) => p.theme.indent.LG};
`

export const BookingCardImage = styled.div`
  background-image: url('${({ path }) => path}');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
`
export const ContainerInfo = styled.div`
  padding: ${({ theme }) => theme.indent.MD} ${({ theme }) => theme.indent.LG};
  ${(p) => (p.border ? `border: 0.1rem solid ${p.theme.color.lightGrey};` : '')}

  border-top-width: 0;
  height: 100%;
`
export const Text = styled(Typography.Body2)`
  padding: ${({ theme }) => theme.indent.XS} 0;
`

export const H2 = styled(Typography.H2)`
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.XL)};
`

export const Body2 = styled(Typography.Body2)`
  padding-bottom: ${({ theme }) => theme.indent.MD};
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.XS : p.theme.indent.SM)};
`
export const ContainerAgency = styled.div`
  position: relative;
  height: 21.1rem;
  width: 100%;
  z-index: 1;
`
export const ContainerH1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(p) => (p.isMobile ? '3rem' : p.theme.indent.XXXL)};
  left: 0;
  right: 0;
  height: 18rem;
  width: 100%;
  background-color: ${({ theme }) => theme.color.black_50};
`
