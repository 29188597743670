import { getFunctions, httpsCallable } from 'firebase/functions'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const deleteAgent = async (agentId?: string): Promise<{ result: boolean; e?: Error }> => {
  const functions = getFunctions()
  const deleteAgent = httpsCallable(functions, FUNC_NAMES.DELETE_AGENT)
  try {
    await deleteAgent({ agentId })
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}
