import React, { useState, Dispatch } from 'react'
import { UNDER_OFFER, ROUTES, LS_CONTSTANTS } from '@constants/dictionary'
import { cancelVisits } from '@services/firebase'
import { Loader } from '@atoms'
import Confirm from './Confirm'
import CompletedPage from '../CompletedPage'

const UnderOffer = (): JSX.Element => {
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleConfirmed = async (): Dispatch<string> | Dispatch<boolean> => {
    setLoader(true)
    const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)
    const canceled = await cancelVisits(propertyId)
    setLoader(false)
    if (canceled) {
      return setConfirmed(true)
    }
    return setError('Confirm Failed')
  }

  if (error) {
    return <div>{error}</div>
  }

  if (confirmed) {
    return (
      <>
        <CompletedPage
          route={ROUTES.SELLER_SLOTS}
          header={UNDER_OFFER.CONFIRMED.HEADER}
          confirmed
        />
      </>
    )
  }

  return (
    <>
      <Loader loaded={!loader}>
        <Confirm handleConfirmed={handleConfirmed} />
      </Loader>
    </>
  )
}

export default UnderOffer
