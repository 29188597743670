export enum PropertyStatuses {
  ACTIVE = 'active',
  UNDER_OFFER = 'underOffer',
  SOLD_STC = 'soldStc',
  SOLD = 'sold',
  OFF_MARKET = 'offMarket',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export const PROPERTY_STATUSES_CONFIG: Record<PropertyStatuses, { editable: boolean }> = {
  [PropertyStatuses.ACTIVE]: {
    editable: true,
  },
  [PropertyStatuses.UNDER_OFFER]: {
    editable: true,
  },
  [PropertyStatuses.SOLD_STC]: {
    editable: true,
  },
  [PropertyStatuses.SOLD]: {
    editable: true,
  },
  [PropertyStatuses.OFF_MARKET]: {
    editable: true,
  },
  [PropertyStatuses.ARCHIVED]: {
    editable: true,
  },
  [PropertyStatuses.DELETED]: {
    editable: false,
  },
}
