import { AgentType } from '@constants/types'
import { FormType } from '@constants/types/organisms'
import { phoneCode } from '@constants/dictionary'
import { customFormatValue } from '@utils/functions'
import { withoutSpace } from '@constants/regExp'
import { storeAgent, updateUserData } from '@services/firebase'
import React, { Dispatch } from 'react'

type FormUpdateType = {
  name: string
  phoneNumber: string
}
const phoneFormat = (phone: string): string => {
  return phone ? phoneCode + customFormatValue(phone, withoutSpace, '') : ''
}

const prepareData = ({ fullName, email, phone }: FormType): AgentType => {
  return {
    name: fullName,
    email: email,
    phoneNumber: phoneFormat(phone),
  }
}

const prepareUpdateData = ({ name, phoneNumber }: FormUpdateType): FormUpdateType => {
  return { name: name, phoneNumber: phoneFormat(phoneNumber) }
}

export const onSubmit = async (
  data: FormType,
  setLoader: Dispatch<React.SetStateAction<boolean>>,
  setBackendError: Dispatch<React.SetStateAction<boolean>>,
  onCancel: () => void,
  editAgent: (AgentType & { id: string }) | null,
): Promise<void> => {
  setLoader(true)
  if (!!editAgent) {
    try {
      const reqData = prepareUpdateData({ name: data.fullName, phoneNumber: data.phone })
      const req = await updateUserData(reqData, editAgent.id)
      if (!req) {
        setBackendError(true)
      } else {
        onCancel()
      }
    } catch (error) {
      setBackendError(true)
    } finally {
      setLoader(false)
    }
  } else {
    try {
      const reqData = prepareData(data)
      const req = await storeAgent(reqData)
      if (!req.result) {
        setBackendError(true)
      } else {
        onCancel()
      }
    } catch (error) {
      setBackendError(true)
    } finally {
      setLoader(false)
    }
  }
}
