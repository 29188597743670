import styled from 'styled-components'
import { Typography } from '@atoms'

const getWidth = (p) => {
  if (p.isMobile) {
    return 'width: 100%;'
  }
  if (p.isTabletPortrait) {
    return 'max-width: 43.5rem;'
  }
  if (p.isTabletLandscape) {
    return 'min-width: 43.5rem; max-width: 70rem;'
  }
  if (p.isDesktop) {
    return 'max-width: 80rem;'
  }
  return null
}

export const BodyContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTablet: boolean
}>`
  display: flex;
  width: 100%;
  min-height: ${({ isMobile }) => `calc(100vh - ${isMobile ? '16rem' : '20rem'});`}
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) {
      return `${theme.indent.LG} 5rem 0`
    }
    if (isTablet) {
      return `${theme.indent.LG} ${theme.indent.XXXL} 0`
    }
    if (isMobile) {
      return `${theme.indent.LG} ${theme.indent.LG} 0`
    }
  }};
`

export const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  ${({ isMobile }) => !isMobile && 'height: calc(100vh - 12.8rem) '};
  padding: ${({ theme }) => theme.indent.LG};
  overflow-y: auto;
`
export const TableContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTabletLandscape: boolean
  isTabletPortrait: boolean
}>`
  ${(p) => getWidth(p)}
`

export const LinkTo = styled(Typography.Link)<{ noUnderline: boolean; isMobile: boolean }>`
  padding-left: ${({ theme }) => theme.indent.SM};
  text-decoration: ${(p) => (p.noUnderline ? 'none' : 'underline')};
`
