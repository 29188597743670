import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { AgencyAddAgentCard, Table } from '@organisms'
import { Container, LinkTo, TableContainer } from './AgencyListOfAgents.style'
import {
  useDesktop,
  useMobile,
  useMountEffect,
  useTabletLandscape,
  useTabletPortrait,
} from '@utils/hooks'
import { Loader, Typography } from '@atoms'
import { TableRowType } from '@constants/types'
import { ColumnWidth } from '../../../../modals/AddModal/ColumnWidth'
import { getAgencyAgents } from '@services/firebase'
import {
  TABLE_ROW_MAX_SYMBOL_DESKTOP,
  TABLE_ROW_MAX_SYMBOL_TABLET,
  TABLE_ROW_MAX_SYMBOL_MOBILE,
  USER_TYPES,
  TABLE_ROW_EMAIL_MAX_SYMBOL_MOBILE,
  TABLE_ROW_EMAIL_MAX_SYMBOL_TABLET,
} from '@constants/dictionary'
import { AgentType } from '@constants/types'
import { headerMessage, sortAgents } from './AgencyListOfAgents.utils'

interface EditAgent extends AgentType {
  id: string
}

interface Agent extends EditAgent {
  edit: 'Edit'
}

const AgencyListOfAgents: React.FC = () => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const isTabletLandscape = useTabletLandscape()
  const isDesktop = useDesktop()
  const [agentsList, setAgentsList] = useState<Agent[] | []>([])
  const [isAddAgent, setIsAddAgent] = useState<boolean>(false)
  const [editAgent, setEditAgent] = useState<EditAgent | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)

  const maxSymbol = useCallback(
    (column: 'name' | 'email'): number => {
      if (column === 'name') {
        if (isMobile) {
          return TABLE_ROW_MAX_SYMBOL_MOBILE
        }
        if (isTabletPortrait) {
          return TABLE_ROW_MAX_SYMBOL_TABLET
        }
        return TABLE_ROW_MAX_SYMBOL_DESKTOP
      }
      if (column === 'email') {
        if (isMobile) {
          return TABLE_ROW_EMAIL_MAX_SYMBOL_MOBILE
        }
        if (isTabletPortrait) {
          return TABLE_ROW_EMAIL_MAX_SYMBOL_TABLET
        }
        return TABLE_ROW_MAX_SYMBOL_DESKTOP
      }
      return TABLE_ROW_MAX_SYMBOL_DESKTOP
    },
    [isMobile, isTabletPortrait],
  )

  const getAgents = useCallback(async (): Promise<void> => {
    setLoaded(false)
    const agents = await getAgencyAgents()
    const sortedAgents = sortAgents(agents, true)
    setAgentsList(
      sortedAgents.map((agent) => ({
        name: agent.agent.name,
        email: agent.agent.email,
        phoneNumber: agent.agent.phoneNumber,
        edit: 'Edit',
        id: agent.id,
      })),
    )

    setLoaded(true)
  }, [])
  useMountEffect(getAgents)

  useEffect(() => {
    if (!isAddAgent) {
      getAgents()
    }
  }, [getAgents, isAddAgent])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        percentWidth: ColumnWidth.width45,
        Cell: ({ row: { original } }: TableRowType<Agent>) =>
          _.truncate(original.name, { length: maxSymbol('name') }),
      },
      {
        Header: 'Email',
        accessor: 'email',
        percentWidth: ColumnWidth.width45,
        Cell: ({ row: { original } }: TableRowType<Agent>) =>
          _.truncate(original.email, { length: maxSymbol('email') }),
      },
      {
        Header: 'Phone number',
        accessor: isDesktop ? 'phoneNumber' : 'hiddenPhoneNumber',
        disableSortBy: true,
        percentWidth: ColumnWidth.width25,
        Cell: ({ row: { original } }: TableRowType<Agent>) => original.phoneNumber,
      },
      {
        Header: '',
        accessor: 'edit',
        percentWidth: ColumnWidth.width20,
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: ({ row: { original } }: TableRowType<Agent>) => (
          <LinkTo
            isMobile
            noUnderline={false}
            onClick={() => {
              setEditAgent({ ...original })
              setIsAddAgent(true)
            }}
          >
            {original.edit}
          </LinkTo>
        ),
      },
    ],
    [isDesktop, maxSymbol],
  )

  return (
    <Container isMobile={isMobile}>
      <Typography.H3>{headerMessage(!!editAgent, isAddAgent)}</Typography.H3>
      {isAddAgent ? (
        <AgencyAddAgentCard
          editAgent={editAgent}
          onCancel={() => {
            setEditAgent(null)
            setIsAddAgent(false)
          }}
        />
      ) : (
        <Loader loaded={loaded}>
          <TableContainer
            isDesktop={isDesktop}
            isTabletLandscape={isTabletLandscape}
            isTabletPortrait={isTabletPortrait}
            isMobile={isMobile}
          >
            <Table
              columns={columns}
              data={agentsList}
              title="AgencyListOfAgent"
              isTableModal
              onConfirm={() => {
                setEditAgent(null)
                setIsAddAgent(true)
              }}
              typeUser={USER_TYPES.agent}
              noItemHeight={isMobile ? '' : '40rem'}
              isListOfAgents
            />
          </TableContainer>
        </Loader>
      )}
    </Container>
  )
}

export default AgencyListOfAgents
