export const NOTIFICATION_TYPES = {
  new_visit: 'new_visit',
  new_review: 'new_review',
  visit_cancelled: 'visit_cancelled',
  visit_confirmed: 'visit_confirmed',
  reminder: 'reminder',
  visit: 'visit',
  feedback: 'feedback',
  // agent
  status_update: 'status_update',
  confirm_a_visit: 'confirm_a_visit',
  cancelled_days: 'cancelled_days',
}

export const NOTIFICATION_TYPES_ICONS = {
  new_visit: 'calendar',
  new_review: 'star',
  visit_cancelled: 'canceled',
  visit_confirmed: 'empty-home',
  reminder: 'warning',
  visit: 'calendar',
  feedback: 'star',
  // agent
  status_update: 'empty-home',
  confirm_a_visit: 'notification-calendar-checked',
  cancelled_days: 'user-cancelled',
}
