import { getFunctions, httpsCallable } from 'firebase/functions'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const confirmVisit = async (visitId: number | string): Promise<boolean> => {
  try {
    const functions = getFunctions()
    const bookVisit = httpsCallable(functions, FUNC_NAMES.CONFIRM_A_VISIT)
    await bookVisit({ visitId: String(visitId) })
    return true
  } catch (e) {
    return false
  }
}
