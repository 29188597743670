import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import fontsCss from './fonts'

export default createGlobalStyle`
  html {
    font-size: 10px;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-size: 1.6em;
    font-family: 'OpenSansRegular', sans-serif;
    font-weight: 400;
    line-height: 2.4rem;
  }

  div,
  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  p,
  span,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: "liga", "kern";
    -moz-font-feature-settings: "liga", "kern";
    font-feature-settings: "liga", "kern";
    -webkit-font-kerning: normal;
    -moz-font-kerning: normal;
    font-kerning: normal;
    font-weight: normal;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  img {
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: bicubic;
  }

  .rec-slider-container {
    margin: 0;
  }

  .apexcharts-canvas {
    margin-top: -24px;
  }

  .apexcharts-yaxis-label,
  .apexcharts-xaxis-label {
    font-family: 'OpenSansRegular', sans-serif !important;
  }

  ${normalize()}
  ${fontsCss}
`
