import React, { useEffect, useState } from 'react'
import { TitleTypes, TitleCases } from '@constants/types'

import AgentPropertyVisitInfoTitle from './AgentPropertyVisitInfoTitle'
import AgencyListOfAgent from './AgencyListOfAgentTitle'
import AgentUsersTitle from './AgentUsersTitle'
import AddModalTitle from './AddModalTitle'
import VisitInfoTitle from './VisitInfoTitle'

const Title = ({
  globalFilter,
  setGlobalFilter,
  title,
  onConfirm,
  onCancel,
  dropdownOptions = [],
  defaultValue,
  callback,
  user,
  setAddUser,
  setFilter,
  addButtonDisabled,
  selectedValue,
}: TitleTypes): JSX.Element => {
  const [selected, setSelected] = useState<string | number | undefined>(selectedValue)

  useEffect(() => {
    callback && callback(selected)
  }, [callback, selected])
  const TITLE_CASES: Record<TitleCases, JSX.Element> = {
    [TitleCases.AgentUsersTitle]: (
      <AgentUsersTitle
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        setAddUser={setAddUser}
        user={user}
      />
    ),
    [TitleCases.AddModal]: (
      <AddModalTitle
        dropdownOptions={dropdownOptions}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        user={user}
        addButtonDisabled={addButtonDisabled}
        onConfirm={onConfirm}
        onCancel={onCancel}
        defaultValue={defaultValue}
        callback={callback}
      />
    ),
    [TitleCases.VisitInfo]: (
      <VisitInfoTitle
        defaultValue={defaultValue}
        dropdownOptions={dropdownOptions}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        selectedValue={selectedValue}
        setSelected={setSelected}
      />
    ),
    [TitleCases.AgentPropertyVisitInfoTitle]: (
      <AgentPropertyVisitInfoTitle
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        setFilter={setFilter}
      />
    ),
    [TitleCases.AgencyListOfAgent]: (
      <AgencyListOfAgent
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        addAgent={onConfirm}
      />
    ),
  }

  return !!title ? TITLE_CASES[title] : <></>
}

export default Title
