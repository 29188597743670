import React, { useCallback } from 'react'
import { useDesktop, useMobile, useTabletLandscape, useTabletPortrait } from '@utils/hooks'
import { Typography, Icon, BookingStatus } from '@atoms'
import {
  BOOKING_STATUSES,
  PropertyStatuses,
  ROUTES,
  PROPERTY_STATUSES_CONFIG,
} from '@constants/dictionary'
import { Col, Row } from 'styled-bootstrap-grid'
import { useHistory } from 'react-router-dom'
import {
  CardWrapper,
  PropertyImage,
  StatusWrapper,
  DetailsWrapper,
  DetailsRow,
  ContactsWrapper,
  EditWrapper,
  H3,
  LinkTo,
} from './AgentPropertyDetailsCard.style'
import { AgentPropertyDetailsCardTypes } from '@constants/types/organisms'

const AgentPropertyDetailsCard: React.FC<AgentPropertyDetailsCardTypes> = (props): JSX.Element => {
  const {
    propertyProfileId,
    propertyId,
    sellerId,
    imageUrl = '',
    title = '',
    address = '',
    price = '',
    id = '',
    propertyStatus = PropertyStatuses.ACTIVE,
    contacts = {
      name: '',
      email: '',
      phoneNumber: '',
    },
    additionalContacts = {
      name: '',
      email: '',
      phoneNumber: '',
    },
  } = props
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const history = useHistory()
  const isTabletLandscape = useTabletLandscape()
  const isDesktop = useDesktop()

  const onEditClick = useCallback(() => {
    PROPERTY_STATUSES_CONFIG[propertyStatus].editable &&
      history.push({
        pathname: `${ROUTES.AGENT}/${propertyId}-${propertyProfileId}-${sellerId}${ROUTES.AGENT_EDIT_PROPERTY_SHORT}`,
      })
  }, [history, propertyId, propertyProfileId, sellerId, propertyStatus])

  return (
    <CardWrapper>
      <Row>
        <Col xl={6} lg={6} md={12} xs={12}>
          <StatusWrapper>
            <BookingStatus isPropertyCard status={propertyStatus} uppercase />
          </StatusWrapper>
          <PropertyImage path={imageUrl} />
        </Col>
        <Col xl={6} lg={6} md={12} xs={12}>
          <DetailsWrapper
            isTabletPortrait={isTabletPortrait}
            isTabletLandscape={isTabletLandscape}
            isMobile={isMobile}
            isDesktop={isDesktop}
          >
            <DetailsRow>
              <Typography.Body2>Details</Typography.Body2>
              <EditWrapper onClick={onEditClick} isMobile={isMobile}>
                <Icon
                  icon="edit"
                  onClick={onEditClick}
                  color={propertyStatus === BOOKING_STATUSES.DELETED ? 'grey' : 'originalBlue'}
                  clickable={propertyStatus !== BOOKING_STATUSES.DELETED}
                  size="2rem"
                />
              </EditWrapper>
            </DetailsRow>
            <DetailsRow isMobile={isMobile}>
              <Icon icon="house" color="darkGrey" size="1.6rem" />
              <H3>{title}</H3>
            </DetailsRow>
            <DetailsRow isMobile={isMobile}>
              <Icon icon="location" color="darkGrey" size="1.6rem" />
              <Typography.Body3>{address}</Typography.Body3>
            </DetailsRow>
            <DetailsRow isMobile={isMobile}>
              <Icon icon="pound" color="darkGrey" size="1.2rem" />
              <Typography.Body3>{price}</Typography.Body3>
            </DetailsRow>
            <DetailsRow isMobile={isMobile}>
              <Icon icon="id" color="darkGrey" size="1.6rem" />
              <Typography.Body3>{id}</Typography.Body3>
            </DetailsRow>
            <Row>
              <Col col={6} xs={12}>
                <ContactsWrapper>
                  <Typography.Body2>Seller Contacts</Typography.Body2>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="avatar" color="darkGrey" size="1.6rem" />
                    <H3>{contacts.name}</H3>
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="email" color="darkGrey" size="1.6rem" />
                    <LinkTo isMobile={isMobile} noUnderline href={`mailto:${contacts.email}`}>
                      {contacts.email}
                    </LinkTo>
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="phone" color="darkGrey" size="1.6rem" />
                    <Typography.Body3>{contacts.phoneNumber}</Typography.Body3>
                  </DetailsRow>
                </ContactsWrapper>
              </Col>
              <Col col={6} xs={12}>
                <ContactsWrapper isTabletPortrait={isTabletPortrait} isMobile={isMobile}>
                  <Typography.Body2>Additional contacts</Typography.Body2>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="avatar" color="darkGrey" size="1.6rem" />
                    {additionalContacts.name ? (
                      <H3>{additionalContacts.name}</H3>
                    ) : (
                      <Typography.Body3>-</Typography.Body3>
                    )}
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="email" color="darkGrey" size="1.6rem" />
                    {additionalContacts.email ? (
                      <LinkTo
                        isMobile={isMobile}
                        noUnderline
                        href={`mailto:${additionalContacts.email}`}
                      >
                        {additionalContacts.email}
                      </LinkTo>
                    ) : (
                      <Typography.Body3>-</Typography.Body3>
                    )}
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="phone" color="darkGrey" size="1.6rem" />
                    <Typography.Body3>
                      {additionalContacts.phoneNumber ? additionalContacts.phoneNumber : '-'}
                    </Typography.Body3>
                  </DetailsRow>
                </ContactsWrapper>
              </Col>
            </Row>
          </DetailsWrapper>
        </Col>
      </Row>
    </CardWrapper>
  )
}

export default AgentPropertyDetailsCard
