import React from 'react'
import { useMobile } from '@utils/hooks'
import { Typography } from '@atoms'
import { Button } from '@molecules'
import unableSMS from '@assets/images/unableSMS.svg'
import BaseModal from '../BaseModal'
import { Container, ButtonWrapper, ConfirmMessage, StyledImg } from './StatusModal.style'

import { StatusModalTypes } from '@constants/types/modals'

const StatusModal: React.FC<StatusModalTypes> = ({ onConfirm, isShown, hide }) => {
  const isMobile = useMobile()
  return (
    <BaseModal hide={hide} isShown={isShown}>
      <Container isMobile={isMobile}>
        <StyledImg src={unableSMS} alt="sms" />
        <ConfirmMessage>
          <Typography.H2>Property is unavailable</Typography.H2>
        </ConfirmMessage>
        <Typography.Body2>
          The booking link can be sent only to properties with status “Active”
        </Typography.Body2>
        <ButtonWrapper>
          <Button.Primary isMobile={isMobile} label="OK" onClick={onConfirm} />
        </ButtonWrapper>
      </Container>
    </BaseModal>
  )
}

export default StatusModal
