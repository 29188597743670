import React, { useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateUserData } from '@services/firebase'
import { FIXED_INPUT_PHONE_NUMBERS, phoneCode } from '@constants/dictionary'
import { InputForm } from '@helpers'
import { Button } from '@molecules'
import { useMobile } from '@utils/hooks'
import BaseModal from '../BaseModal'
import { schema } from './AgencyStartDataValidation'
import { Container, H2 } from './PrivacyPolicyModal.style'

import { CreateStartDataModalTypes } from '@constants/types/modals'

const CreateStartDataModal: React.FC<CreateStartDataModalTypes> = ({ isShown, id }) => {
  const isMobile = useMobile()
  const [loaded, setLoaded] = useState<boolean>(true)

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      phoneNumber: '',
    },
  })
  const { handleSubmit, watch, formState } = methods
  const { errors } = formState

  const name = watch('name')
  const phoneNumber = watch('phoneNumber')

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const submitResult = async (): Promise<undefined> => {
    setLoaded(false)
    const data = {
      name,
      phoneNumber: phoneCode + phoneNumber.replace(' ', ''),
    }
    await updateUserData(data, id)
    setLoaded(true)
  }

  return (
    <BaseModal isShown={isShown}>
      <FormProvider {...methods}>
        <Container isMobile={isMobile}>
          <H2 textCenter>Please enter agency data</H2>
          <InputForm
            name="name"
            disabled={!loaded}
            errorMessage="Value is required"
            inputLabel="Agency Name"
            error={hasError('name')}
            placeholder="Enter Name"
            maxLength={50}
            isName
          />
          <InputForm
            name="phoneNumber"
            disabled={!loaded}
            errorMessage="Not a valid number"
            inputLabel="Phone Number"
            error={hasError('phoneNumber')}
            maxLength={FIXED_INPUT_PHONE_NUMBERS}
            placeholder="Enter phone number"
            isPhone
          />
          <Button.Primary
            disabled={!loaded}
            label="Confirm"
            onClick={handleSubmit(submitResult)}
            loader={!loaded}
          />
        </Container>
      </FormProvider>
    </BaseModal>
  )
}

export default CreateStartDataModal
