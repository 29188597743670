import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const TopContainer = styled(Col)`
  padding-left: ${({ theme, isMobile }) => (isMobile ? theme.indent.LG : theme.indent.XXXL)};
  padding-right: ${({ theme, isMobile }) => (isMobile ? theme.indent.LG : theme.indent.XXXL)};
  padding-top: ${({ theme }) => theme.indent.XL};
  padding-bottom: ${({ theme }) => theme.indent.XS};
`

export const CheckboxContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.indent.LG};
`

export const TypographyContainer = styled(Typography.H2)`
  padding-bottom: ${({ theme }) => theme.indent.SM};
`
export const CommentArea = styled.textarea`
  border: 0.1rem solid ${({ theme }) => theme.color.grey};
  padding: ${({ theme }) => theme.indent.SM};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  width: 100%;
  height: 20rem;
  resize: none;
  margin-bottom: ${({ theme }) => theme.indent.SM};

  :focus {
    outline: 0;
    border-color: ${({ theme }) => theme.color.originalBlue};
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.grey};
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledCol = styled(Col)`
  padding: ${(p) => (p.isMobile ? `0 ${p.theme.indent.XS}` : `0 ${p.theme.indent.SM}`)};
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin: 0;
`
export const ButtonContainer = styled.div`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XXXS} ${p.theme.indent.XXXS} ${p.theme.indent.LG}`
      : `${p.theme.indent.XXXS} ${p.theme.indent.XXXS} ${p.theme.indent.XXL}`};
  margin: ${(p) => (p.isMobile ? `0 -${p.theme.indent.XS}` : `0 -${p.theme.indent.SM}`)};
`
export const BackgroundContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: calc(100vh - 4.4rem);
`
