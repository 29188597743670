import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '@atoms'

export const LabelContainer = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  padding: 0
    ${(p) => {
      if (p.isTabletPortrait) {
        return p.theme.indent.XXXL
      }
      if (p.isBuyerTab && p.isMobile) {
        return '2rem'
      }
      if (p.isMobile) {
        return '0.6rem'
      }

      return '5rem'
    }};

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-y: ${({ userTab }) => (userTab ? 'unset' : 'scroll')};
  }
`
export const Container = styled.div<{
  isMobile: boolean
  $isPropertyTab: boolean
  $active: boolean
}>`
  cursor: pointer;
  padding: ${(p) => `1rem ${p.theme.indent.MD} 0`};
  padding-bottom: ${(p) => p.theme.indent.XXXS};
  width: max-content;
  ${({ $active, theme, $isPropertyTab }) =>
    $isPropertyTab &&
    $active &&
    `z-index: 1;
  position: relative;
  background-color: ${theme.color.greyBlue};
  bottom: -0.1rem;
  border-top: 0.1rem solid ${theme.color.lightGrey};
  border-left: 0.1rem solid ${theme.color.lightGrey};
  border-right: 0.1rem solid ${theme.color.lightGrey};
  border-bottom: 0.1rem solid ${theme.color.greyBlue};
  border-radius: ${theme.borderRadius.LG} ${theme.borderRadius.LG} 0 0`};
`

export const Label = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  outline: none;
  padding-bottom: ${(p) => p.theme.indent.MD};

  ${(p) =>
    p.$isPropertyTab
      ? p.$active && ``
      : p.$active &&
        `border-bottom: 0.4rem solid ${p.theme.color.originalBlue};
  `};
`

export const LabelText = styled(Typography.Caption4)`
  color: ${({ theme, $active, $isPropertyTab }) =>
    $active && $isPropertyTab && theme.color.originalBlue};
  ${({ $isPropertyTab, theme }) =>
    $isPropertyTab && `font-family: ${theme.fontFamily.openSansBold}, sans-serif`}
`
export const Title = styled.div<{ isMobile: boolean }>`
  display: flex;
  margin-bottom: ${({ isMobile }) => (isMobile ? '1rem' : '1.4rem')};
  padding-left: 0.2rem;
`
export const H1Container = styled.div<{ isMobile: boolean }>`
  margin-left: 1.8rem;
`
export const H1 = styled(Typography.H1)`
  font-family: ${({ theme }) => theme.fontFamily.quicksandBold}, sans-serif;
  font-size: ${({ isMobile, theme }) => (isMobile ? theme.fontSize.LG : theme.fontSize.XL)};
  line-height: ${({ theme }) => theme.lineHeight.XL};
  color: ${({ theme }) => theme.color.originalBlue};
  margin-left: 1.8rem;
  font-weight: 700;
`
export const HeaderContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTablet: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.MD} 0 0`
    if (isTablet) return `${theme.indent.MD} 0 0`
    if (isMobile) return `${theme.indent.MD} 0 0`
    return `${theme.indent.MD} 5rem 0`
  }};
`
