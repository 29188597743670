export const filterProfiles = (propertiesProfiles: [], filteredData = []): Array =>
  propertiesProfiles.filter((profile) => {
    let found = false
    filteredData.forEach((d) => {
      if (d?.objectID === profile?.id) {
        found = true
      }
    })
    return found
  })
