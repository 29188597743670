import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  min-height: 5.2rem;
  width: 100%;
  padding-left: ${(p) => p.theme.indent.XXXL};
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};
  font-weight: normal;
  background-color: ${(p) => p.theme.color.white};
  align-items: center;

  border: ${(p) => `0.1rem solid ${p.theme.color.lightGrey}`};
`
export const PaginationInput = styled.input`
  width: ${(p) => p.theme.indent.XXXL};
  height: ${(p) => p.theme.indent.LG};
  border: ${(p) => `0.1rem solid ${p.theme.color.lightGrey}`};
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.borderRadius.XS};
  text-align: right;
  padding-right: ${(p) => p.theme.indent.XXS};
  margin-right: 0.5rem;
`
export const PaginationText = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color.darkGrey};
  margin: ${(p) => `${p.theme.indent.XS}`};
`
export const PaginationDropdown = styled.div`
  width: 5rem;
  height: ${(p) => p.theme.indent.LG};
  margin: ${(p) => `auto 0 auto ${p.theme.fontSize.MD}`};
`
