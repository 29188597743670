import { BuyerList, BuyerShortList } from '@templates'

export const tabsConfig = [
  {
    name: 'Visits',
    path: '/visits',
    component: BuyerList,
    exact: true,
  },
  {
    name: 'ShortList',
    path: '/shortlist',
    component: BuyerShortList,
  },
]
