export const TIME_SLOTS = [
  { id: 'timeslot1', startTime: '8:00', endTime: '9:00', disabled: true },
  { id: 'timeslot2', startTime: '10:00', endTime: '11:00' },
  { id: 'timeslot3', startTime: '11:00', endTime: '12:00' },
  { id: 'timeslot4', startTime: '12:00', endTime: '13:00' },
  { id: 'timeslot5', startTime: '13:00', endTime: '14:00', disabled: true },
  { id: 'timeslot6', startTime: '14:00', endTime: '15:00' },
  { id: 'timeslot7', startTime: '15:00', endTime: '16:00' },
  { id: 'timeslot8', startTime: '16:00', endTime: '17:00' },
  { id: 'timeslot9', startTime: '17:00', endTime: '18:00' },
  { id: 'timeslot10', startTime: '17:00', endTime: '18:00' },
]

export const EMPTY_TIME_SLOTS = []
