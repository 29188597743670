import React from 'react'
import { useMobile } from '@utils/hooks'
import { Icon } from '@atoms'
import { Container, ErrorMessage } from './ErrorWithIcon.style'

type ErrorWithIconProps = {
  text: string
  height?: string
}

const EmptyListText: React.FC<ErrorWithIconProps> = ({ height, text = '' }) => {
  const isMobile = useMobile()
  return (
    <Container isMobile={isMobile} height={height}>
      <Icon icon="Attention" size="2.6rem" />
      <ErrorMessage>{text}</ErrorMessage>
    </Container>
  )
}

export default EmptyListText
