import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Icon, Loader } from '@atoms'
import { LS_CONTSTANTS, ROUTES } from '@constants/dictionary'
import { mapNotifications, hasUnread } from '@utils/functions'
import { getUserNotifications, markAllAsRead, signOutAgent } from '@services/firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import { NotificationsModal } from '@modals'
import { useMobile, useTabletPortrait, useModal } from '@utils/hooks'
import MenuLink from './MenuLink'
import ModalTemplate from './ModalTemplate'
import ToggleMenu from './ToggleMenu'
import { NavigationFields } from './navigation.config'
import { ItemsID, NavigationProps } from '@constants/types'

import {
  HeaderContainer,
  HeaderControls,
  Menu,
  ProfileButtonContainer,
  ProfilePicture,
  ImgContainer,
} from './AgentHeader.style'
import { Link } from 'react-router-dom'

const AgentHeader: React.FC = () => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const history = useHistory()
  const { pathname: currentLocation } = useLocation()
  const { isShown: burgerMenuShown, toggle: toggleBurgerMenu } = useModal()
  const { isShown: notificationsShown, toggle: toggleNotifications } = useModal()
  const [menuShown, toggleMenu] = useState(false)

  const userId = localStorage.getItem(LS_CONTSTANTS.uid) || ''
  const profileImgUrl = localStorage.getItem(LS_CONTSTANTS.profileImgUrl)

  const notificationsQuery = getUserNotifications(userId)
  const [notificationsList, loading] = useCollection(notificationsQuery)
  const notifications = loading ? [] : mapNotifications(notificationsList)
  const { newMessages, unreadCount } = hasUnread(notifications.list)
  const onClickToRead = (): undefined => {
    markAllAsRead(notifications.idList)
  }

  const handleLink = ({ id, linkTo, mobile }): (() => void) | undefined => {
    switch (id) {
      case ItemsID.ProfileSettings:
        history.push(ROUTES.AGENT_SETTINGS)
        break
      case ItemsID.SignOut:
        return signOutAgent()
      case ItemsID.Notifications:
        return toggleNotifications()
      default:
        break
    }
    if (!!linkTo) {
      history.push(linkTo)
    }
    if (mobile) {
      return toggleBurgerMenu(false)
    }
  }

  const notificationsCloseHandler = useCallback(() => {
    toggleNotifications()
    isMobile && toggleBurgerMenu()
  }, [toggleNotifications, isMobile, toggleBurgerMenu])

  const renderLinks = (list, mobile): Array<JSX.Element> => {
    return list.map(({ title, linkTo, id, matchRoute = [] }: NavigationProps) => {
      if (id === ItemsID.Notifications && !notifications?.list?.length) {
        return null
      }
      return (
        <MenuLink
          active={matchRoute.includes(currentLocation)}
          onClick={() => handleLink({ id, linkTo, mobile })}
          key={`${id}${mobile}`}
          count={id === ItemsID.Notifications && unreadCount}
        >
          {title}
        </MenuLink>
      )
    })
  }

  const renderMenuIcon = (): JSX.Element => (
    <Icon
      onClick={toggleBurgerMenu}
      icon={burgerMenuShown ? 'close' : 'burger-menu-toggle'}
      color="white"
      size={burgerMenuShown ? '2.6rem' : '3.4rem'}
      clickable
    />
  )

  useEffect(() => {
    if (!isMobile && burgerMenuShown) {
      toggleBurgerMenu(false)
    }
  }, [burgerMenuShown, isMobile, toggleBurgerMenu])

  const rotateDeg = 180

  return (
    <HeaderContainer $loading={loading} isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
      {loading ? (
        <div>
          <Loader skeleton />
        </div>
      ) : (
        <>
          <Link to={ROUTES.AGENT}>
            <Icon icon="logo" size="11rem" color="white" />
          </Link>
          <HeaderControls isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
            <Menu>{renderLinks(NavigationFields)}</Menu>
            {!isMobile && (
              <ProfileButtonContainer
                tabIndex={0}
                onClick={() => toggleMenu(!menuShown)}
                onBlur={() => toggleMenu(false)}
              >
                {profileImgUrl ? (
                  <ProfilePicture src={profileImgUrl} alt="ProfilePicture" />
                ) : (
                  <ImgContainer>
                    <Icon icon="contact" size="1.5rem" color="lightGrey" />
                  </ImgContainer>
                )}
                <Icon
                  icon="arrow-bottom"
                  rotate={menuShown && rotateDeg}
                  size="1.1rem"
                  color="white"
                  clickable
                />
                {menuShown && <ToggleMenu toggleMenu={toggleMenu} />}
              </ProfileButtonContainer>
            )}
          </HeaderControls>
          {!burgerMenuShown && isMobile && renderMenuIcon()}

          {isMobile && (
            <ModalTemplate
              burgerMenuShown={burgerMenuShown}
              toggleBurgerMenu={toggleBurgerMenu}
              renderLinks={renderLinks}
              renderMenuIcon={renderMenuIcon}
            />
          )}
          <NotificationsModal
            title="Notifications"
            isShown={notificationsShown}
            onClose={notificationsCloseHandler}
            notificationsList={notifications.list}
            hasUnread={newMessages}
            onClickToRead={onClickToRead}
          />
        </>
      )}
    </HeaderContainer>
  )
}

export default AgentHeader
