import { getFunctions, httpsCallable } from 'firebase/functions'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const remindSellerToAddSlots = async (
  propertyId: string,
  propertyProfileId: string,
): Promise<boolean> => {
  try {
    const functions = getFunctions()
    const remindSeller = httpsCallable(functions, FUNC_NAMES.REMIND_SELLER_TO_ADD_SLOTS)
    await remindSeller({ propertyId, propertyProfileId })
    return true
  } catch (e) {
    return false
  }
}
