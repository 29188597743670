import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { Typography } from '@atoms'
import { useMobile } from '@utils/hooks'
import { LabelContainer, Label, StyledCol } from './Tabs.style'
import { TabsProps } from '@constants/types/organisms'

const Tabs: React.FC<TabsProps> = ({ config, baseRoute }): JSX.Element => {
  const { pathname } = useLocation()
  const isMobile = useMobile()
  const indexTab = config.findIndex((tab) => pathname === `${baseRoute}${tab.path}`) || 0
  return (
    <>
      <LabelContainer>
        <StyledCol col={12} noGutter>
          {config.map(({ name, path }) => {
            const active = pathname === `${baseRoute}${path}`
            return (
              <Label $active={active} to={`${baseRoute}${path}`} key={path}>
                <Typography.Caption1 isMobile={isMobile} $active={active}>
                  {name}
                </Typography.Caption1>
              </Label>
            )
          })}
        </StyledCol>
      </LabelContainer>
      <Switch>
        <Route path={`${baseRoute}/:tab`} component={config[indexTab].component} />
      </Switch>
    </>
  )
}

export default Tabs
