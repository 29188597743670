import React, { useMemo } from 'react'

import {
  Container,
  Logo,
  MenuWithCopyright,
  FooterMenu,
  FooterLink,
  Copyright,
} from './AgentFooter.style'
import { Icon, Typography } from '@atoms'
import { useMobile, useTabletPortrait } from '@utils/hooks'

import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '@constants/dictionary'

const AgentFooter: React.FC = () => {
  const FOOTER_LINKS: Array<{ title: string; linkTo: string }> = useMemo(
    () => [
      {
        title: 'Terms of use',
        linkTo: ROUTES.PRIVACY_POLICY,
      },
      {
        title: 'Privacy Policy',
        linkTo: ROUTES.PRIVACY_POLICY,
      },
      {
        title: 'Cookies Settings',
        linkTo: `${ROUTES.PRIVACY_POLICY}#cookies`,
      },
      {
        title: 'Help',
        linkTo: ROUTES.AGENT_HELP_PAGE,
      },
    ],
    [],
  )

  const PAGES_WITH_FOOTER_BORDER = useMemo(() => [ROUTES.AGENT_VIEWINGS], [])

  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()

  const { pathname } = useLocation()

  return (
    <Container
      showBorder={PAGES_WITH_FOOTER_BORDER.includes(pathname)}
      isMobile={isMobile}
      isTabletPortrait={isTabletPortrait}
    >
      <Logo isMobile={isMobile} as="span">
        <Link to={ROUTES.AGENT}>
          <Icon icon="empty-logo" color="originalBlue" size="3.4rem" />
        </Link>
      </Logo>
      <MenuWithCopyright isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
        <FooterMenu isMobile={isMobile}>
          {FOOTER_LINKS.map(({ title, linkTo }, index) => (
            <FooterLink key={`${linkTo}_${index}`} to={linkTo}>
              <Typography.Caption5 as="span">{title}</Typography.Caption5>
            </FooterLink>
          ))}
        </FooterMenu>
        <Copyright isMobile={isMobile}>&copy; Opinov8. 2021</Copyright>
      </MenuWithCopyright>
    </Container>
  )
}

export default AgentFooter
