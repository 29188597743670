export const FS_Q_PARAMS = {
  CREATED_BY: 'createdBy',
  PROPERTY_ID: 'propertyId',
  CANCELLED: 'cancelled',
  AGENCY_ID: 'agencyId',
  AGENT_ID: 'agentId',
  AGENTID: 'agent.id',
  USER_ID: 'userId',
  DELETED_AT: 'deletedAt',
  BUYERID: 'buyer.id',
  USER_TYPE: 'userType',
  BUYER: 'buyer',
  SELLER: 'seller',
  IS_BOOKED: 'isBooked',
  FROM_DATE_TIME: 'fromDateTime',
}
