import React from 'react'
import { useMobile } from '@utils/hooks'
import { Tabs } from './components'
import { USER_TYPES, LS_CONTSTANTS } from '@constants/dictionary'
import { Container, Header } from './AgentSettings.style'

import { AgentTabsList, AgencyTabsList } from './AgentSettings.constants'

const AgentSettings = (): JSX.Element => {
  const isMobile = useMobile()
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)

  return (
    <Container>
      <Header isMobile={isMobile}>Profile Settings</Header>
      <Tabs config={userType === USER_TYPES.agent ? AgentTabsList : AgencyTabsList} />
    </Container>
  )
}

export default AgentSettings
