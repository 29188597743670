import styled from 'styled-components'

export const TitleContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ isMobile }) =>
    isMobile &&
    `flex-direction: column;
    align-items: center;
  `};
`

export const ButtonContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '14.5rem')};
  height: 4rem;
`

export const SearchContainer = styled.div<{
  isMobile: boolean
  marginLeft: boolean
  isModal: boolean
}>`
  width: ${({ isMobile, isModal }) => (isMobile || isModal ? '100%' : '31.5rem')};
  margin-left: ${(p) => (p.marginLeft && !p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXS)};
`
