import React from 'react'
import { useTheme } from 'styled-components'
import { format } from 'date-fns'
import { Icon } from '@atoms'
import { NOTIFICATION_TYPES_ICONS, NOTIFICATION_TYPES, ROUTES } from '@constants/dictionary'
import { useTabletLandscape } from '@utils/hooks'
import {
  NotificationCardContainer,
  CardTime,
  CardDot,
  NotificationText,
  NotificationMessage,
  NotificationLink,
} from './NotificationCard.style'
import { parseStringToObject } from './NotificationsCard.utils'

import { NotificationCardTypes } from '@constants/types/molecules'

const NotificationCard: React.FC<NotificationCardTypes> = ({
  body = '',
  link = '',
  createdAt,
  isRead = true,
  type = '',
  onClick = () => {},
  onClose = () => {},
}): JSX.Element => {
  const isTabletLandscape = useTabletLandscape()
  const yellowColor = useTheme().color.yellow

  const timeFormat = (date): string => format(date, 'HH:mm')

  //parse body text to object with text & link if link is available
  const { firstPart, secondPart, thirdPart } = !!link
    ? parseStringToObject(body)
    : { firstPart: body }

  const renderLink = (): null | JSX.Element => {
    let linkText = null
    if (!!secondPart && !!link) {
      linkText = secondPart
    } else if (!!link) {
      linkText = 'Click here to see Details'
    }
    return linkText ? (
      <NotificationLink
        $isTabletLandscape={isTabletLandscape}
        to={link || ROUTES.SELLER_CONFIG_SLOTS}
        onClick={onClose}
      >
        {linkText}
      </NotificationLink>
    ) : null
  }

  // if type is undefined return nothing
  return typeof NOTIFICATION_TYPES[type] === 'string' ? (
    <NotificationCardContainer onClick={onClick}>
      {!isRead && <CardDot />}
      <Icon icon={NOTIFICATION_TYPES_ICONS[type] || ''} color={yellowColor} size="1.6rem" />
      <NotificationMessage>
        {!!firstPart && (
          <NotificationText $isTabletLandscape={isTabletLandscape} as="span">
            {firstPart}
          </NotificationText>
        )}
        {renderLink()}
        {!!thirdPart && (
          <NotificationText $isTabletLandscape={isTabletLandscape}>{thirdPart}</NotificationText>
        )}
      </NotificationMessage>
      {createdAt && (
        <CardTime>
          {createdAt.toDate ? timeFormat(createdAt.toDate()) : timeFormat(createdAt)}
        </CardTime>
      )}
    </NotificationCardContainer>
  ) : null
}

export default NotificationCard
