import React from 'react'
import { Typography } from '@atoms'
import { NotificationPreferencesContainer } from './NotificationPreferences.style'

const NotificationPreferences: React.FC = () => {
  return (
    <NotificationPreferencesContainer>
      <Typography.Caption2>Coming Soon...</Typography.Caption2>
    </NotificationPreferencesContainer>
  )
}

export default NotificationPreferences
