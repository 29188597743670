import { getFirestore, collection, query, where } from 'firebase/firestore'
import { getAgencyDataId } from '@utils/functions'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getAllBuyerVisits = (buyerId: string): { error: string } => {
  const db = getFirestore()

  const { isAgency, agentId, uid } = getAgencyDataId()

  return isAgency
    ? query(
        collection(db, FS_COL_NAMES.PROPERTY_VISITS),
        where(FS_Q_PARAMS.AGENCY_ID, '==', uid),
        !!agentId && where(FS_Q_PARAMS.AGENTID, '==', agentId),
        where(FS_Q_PARAMS.BUYERID, '==', buyerId),
      )
    : query(
        collection(db, FS_COL_NAMES.PROPERTY_VISITS),
        !!agentId && where(FS_Q_PARAMS.AGENTID, '==', agentId),
        where(FS_Q_PARAMS.BUYERID, '==', buyerId),
      )
}
