import React, { useState } from 'react'
import { useMobile } from '@utils/hooks'
import { Typography } from '@atoms'
import BookingDayTitle from '../BookingDayTitle'
import {
  Container,
  TitleContainer,
  DaysContainer,
  LinkContainer,
  BookingTitleContainer,
} from './DayPickRow.style'
import DayPick from './DayPick'

import { DayPickRowProps } from '@constants/types/molecules'

const DayPickRow: React.FC<DayPickRowProps> = ({
  daysList,
  currentWeek,
  chooseDay,
  selectedDay,
  onClick,
  onCheck,
  withCheckbox,
  isBookingCard,
}) => {
  const isMobile = useMobile()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const renderTitle = (): React.FC | null => {
    return currentWeek ? (
      <TitleContainer isMobile={isMobile} withCheckbox={withCheckbox}>
        {withCheckbox ? (
          <>
            <Typography.Body2 as="span" isMobile={isMobile}>
              {currentWeek}
            </Typography.Body2>
            <LinkContainer onClick={() => setAllChecked(true)}>Choose all</LinkContainer>
          </>
        ) : (
          <Typography.H3 as="span" isMobile={isMobile}>
            {currentWeek}
          </Typography.H3>
        )}
      </TitleContainer>
    ) : null
  }
  return (
    <>
      {isMobile ? null : renderTitle()}
      {isBookingCard ? (
        <BookingTitleContainer isMobile={isMobile}>
          <BookingDayTitle date={new Date()} />
        </BookingTitleContainer>
      ) : null}
      <Container isMobile={isMobile} withCheckbox={withCheckbox}>
        {isMobile && renderTitle()}
        <DaysContainer>
          {daysList.map((day) => (
            <DayPick
              key={day.dayNumber + day.dayName}
              {...day}
              currentWeek={currentWeek}
              chooseDay={chooseDay}
              selectedDay={selectedDay}
              onClick={onClick}
              onCheck={onCheck}
              withCheckbox={withCheckbox}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
            />
          ))}
        </DaysContainer>
      </Container>
    </>
  )
}

export default DayPickRow
