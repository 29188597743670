import React from 'react'
import { tabsConfig } from './AgentPropertyInfoPage.constants'
import { LS_CONTSTANTS, ROUTES } from '@constants/dictionary'
import { AgentTabs } from '@organisms'
import {
  HeaderContainer,
  Title,
  H1Container,
  Container,
  LinkContainer,
} from './AgentPropertyInfoPage.style'
import { useDesktop, useMobile, useTabletLandscape, useTabletPortrait } from '@utils/hooks'
import { Icon, Loader, Typography } from '@atoms'

const AgentPropertyInfoPage: React.FC = () => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTabletPortrait = useTabletPortrait()
  const isTabletLandscape = useTabletLandscape()
  const isTablet = isTabletPortrait || isTabletLandscape

  return (
    <Loader loaded={!!localStorage.getItem(LS_CONTSTANTS.propertyId)}>
      <Container>
        <HeaderContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
          <Title isMobile={isMobile}>
            <LinkContainer to={ROUTES.AGENT_PROPERTIES}>
              <Icon icon="move_back" clickable size="2rem" color="originalBlue" />
            </LinkContainer>
            <H1Container>
              <Typography.TitleH1 isMobile={isMobile}>Property Information</Typography.TitleH1>
            </H1Container>
          </Title>
        </HeaderContainer>
        <AgentTabs
          config={tabsConfig}
          baseRoute={ROUTES.AGENT_PROPERTIES}
          dynamicUrl
          isPropertyTab
        />
      </Container>
    </Loader>
  )
}

export default AgentPropertyInfoPage
