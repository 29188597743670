import { getFunctions, httpsCallable } from 'firebase/functions'
import { UserType } from '@constants/types'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const storeUser = async (data: UserType): { result: boolean } => {
  const functions = getFunctions()
  const createUser = httpsCallable(functions, FUNC_NAMES.STORE_USER)
  try {
    await createUser(data)
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}
