import styled from 'styled-components'

export const FooterContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXL)};
  display: flex;
  height: 4.4rem;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.color.darkBlue};
`
export const NotificationWrapper = styled.div`
  position: relative;
  cursor: pointer;
`
export const NotificationDot = styled.div<{ areUnread: boolean }>`
  display: ${(p) => (p.areUnread ? 'block' : 'none')};
  background-color: ${(p) => p.theme.color.originalBlue};
  width: ${(p) => p.theme.indent.SM};
  height: ${(p) => p.theme.indent.SM};
  border-radius: 50%;
  position: absolute;
  right: 0;
  z-index: 3;
  top: 0;
`
