import React, { useCallback, useState } from 'react'
import { Container, TitleInfo } from './RatingStars.style'
import { DEFAULT_NUMBER_STARS } from '@constants/dictionary'
import Star from './Star'
import { RatingStarsProps } from '@constants/types/molecules'

const RatingStars: React.FC<RatingStarsProps> = ({ label, value, onChange }) => {
  const [starIndex, setStarIndex] = useState(value || 0)
  const [hoveredStar, setHoveredStar] = useState(0)

  const clickStar = useCallback(
    (item: number): void => {
      setStarIndex(item + 1)
      onChange(item + 1)
    },
    [onChange],
  )
  const countStepsArray = Array.from(Array(DEFAULT_NUMBER_STARS).keys())

  return (
    <>
      <TitleInfo>{label}</TitleInfo>
      <Container>
        {countStepsArray.map((item) => (
          <Star
            key={item}
            clickStar={clickStar}
            item={item}
            starIndex={starIndex}
            hoveredStar={hoveredStar}
            setHoveredStar={setHoveredStar}
          />
        ))}
      </Container>
    </>
  )
}

export default RatingStars
