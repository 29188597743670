/**
 * @function parseStringToObject
 * @param string 'text <link> text'
 * @returns object {firstPart: string(text), secondPart: string(link), thirdPart: string(text)}
 */

export const parseStringToObject = (
  string = '',
): { firstPart: string; secondPart: string; thirdPart: string } => {
  const basicSplit = string.split('<') // split to array to define plain text and begining of link
  const firstPart = basicSplit[0]
  let secondPart = ['']
  let thirdPart = ''
  // if there was link writed like 'text <link> text'
  if (basicSplit[1]) {
    secondPart = basicSplit[1].split('>')
    if (secondPart[1]) {
      thirdPart = secondPart[1]
    }
  }
  return {
    firstPart: firstPart,
    secondPart: secondPart[0],
    thirdPart: thirdPart,
  }
}
