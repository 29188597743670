import { differenceInDays, parse } from 'date-fns'

export const datesSorting = (aDate: string, bDate: string): number => {
  const difference = differenceInDays(
    parse(aDate, 'dd/MM/yyyy', new Date()),
    parse(bDate, 'dd/MM/yyyy', new Date()),
  )
  if (difference > 0) {
    return 1
  }
  if (difference < 0) {
    return -1
  }
  return 0
}

export const statusSorting = (aStatus: string | number, bStatus: string | number): number => {
  const statusVariants = ['Confirmed', 'Wait for visit', 'Cancelled']
  if (Number.isInteger(aStatus)) {
    return -1
  }
  if (Number.isInteger(bStatus)) {
    return 1
  }
  if (statusVariants.indexOf(aStatus) <= statusVariants.indexOf(bStatus)) {
    return -1
  }
  return 1
}
