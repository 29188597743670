import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { PasswordInput } from '@molecules'

import { PasswordInputFormProps } from '@constants/types/helpers'

const PasswordInputForm: React.FC<PasswordInputFormProps> = ({
  name,
  placeholder,
  errorMessage,
  error,
  inputLabel,
  disabled,
  withOutIcon,
  maxLength,
  tabIndex,
  isNoSpace,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <PasswordInput
          {...field}
          error={error}
          withOutIcon={withOutIcon}
          disabled={disabled}
          inputLabel={inputLabel}
          onChange={field.onChange}
          errorMessage={errorMessage}
          placeholder={placeholder}
          maxLength={maxLength}
          tabIndex={tabIndex}
          isNoSpace={isNoSpace}
        />
      )}
    />
  )
}

export default PasswordInputForm
