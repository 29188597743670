import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import { Typography } from '@atoms'

export const Container = styled(Col)<{ isMobile: boolean }>`
  display: flex;
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.MD} ${p.theme.indent.LG}`
      : `${p.theme.indent.LG} ${p.theme.indent.XXXL}`};
`
export const ContainerNoSlots = styled(Col)<{ isMobile: boolean }>`
  display: flex;
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XXS} ${p.theme.indent.LG}`
      : `${p.theme.indent.MD} ${p.theme.indent.SM}`};
`

export const Divider = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
`
export const LinkContainer = styled(Link)`
  text-decoration: none;
  padding-left: ${({ theme }) => theme.indent.XS};
`
export const BackgroundContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
`
export const BottomContainer = styled.div<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XL} ${p.theme.indent.LG}`
      : `${p.theme.indent.LG} ${p.theme.indent.XXXL}`};
`
export const TextComponent = styled(Typography.Body2)<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XS} 0 ${p.theme.indent.SM}`
      : `${p.theme.indent.SM} 0 ${p.theme.indent.SM}`};
`
export const ButtonContainer = styled(Link)`
  text-decoration: none;
`
export const CheckBoxContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.indent.XL};
`

export const StyledRow = styled(Row)<{ isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? `` : `0 ${p.theme.indent.XXXL}`)};
  margin: ${(p) => (p.isMobile ? `` : `0 -${p.theme.indent.SM}`)};
`
export const StyledCol = styled(Col)<{ isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? `0` : `0 ${p.theme.indent.SM}`)};
`
export const DayPickRowContainer = styled.div<{ isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? '' : `${p.theme.indent.MD} ${p.theme.indent.XXL} 0 `)};
`
