import { getFirestore, query, collectionGroup, where } from '@firebase/firestore'
import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { FS_COL_GROUP_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getUsersProfilesByType = (type: string): { error: string } => {
  const db = getFirestore()
  const id = localStorage.getItem(LS_CONTSTANTS.uid)
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const selectedAgentId = localStorage.getItem(LS_CONTSTANTS.selectedAgentId)
  const prop = userType === USER_TYPES.agency ? FS_Q_PARAMS.AGENCY_ID : FS_Q_PARAMS.CREATED_BY
  if (userType === USER_TYPES.agency) {
    if (selectedAgentId?.length > 0 && selectedAgentId != id && type === 'buyer') {
      return query(
        collectionGroup(db, FS_COL_GROUP_NAMES.USER_PROFILES),
        where(prop, '==', id),
        where(FS_Q_PARAMS.CREATED_BY, '==', selectedAgentId),
        where(FS_Q_PARAMS.USER_TYPE, '==', type),
      )
    } else {
      return query(
        collectionGroup(db, FS_COL_GROUP_NAMES.USER_PROFILES),
        where(prop, '==', id),
        where(FS_Q_PARAMS.CREATED_BY, '==', id),
        where(FS_Q_PARAMS.USER_TYPE, '==', type),
      )
    }
  } else {
    return query(
      collectionGroup(db, FS_COL_GROUP_NAMES.USER_PROFILES),
      where(FS_Q_PARAMS.CREATED_BY, '==', id),
      where(FS_Q_PARAMS.USER_TYPE, '==', type),
    )
  }
}
