import React, { useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import { AgencyInfo, Button } from '@molecules'
import { Col } from 'styled-bootstrap-grid'
import { useTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMobile } from '@utils/hooks'
import { Loader, Icon, Typography } from '@atoms'
import { useDocument } from 'react-firebase-hooks/firestore'
import { ROUTES, LS_CONTSTANTS } from '@constants/dictionary'
import { getPropertVisit, getPropertyReviews } from '@services/firebase'
import {
  BackgroundContainer,
  TopContainer,
  BottomContainer,
  ShortInfo,
  ConfirmTitle,
  InfoContainer,
  IconContainer1,
  IconContainer2,
  ButtonContainer,
} from './BuyerSurveyStart.style'

const BuyerSurveyStart = (): JSX.Element => {
  const isMobile = useMobile()
  const theme = useTheme()
  const history = useHistory()
  const visitId = localStorage.getItem(LS_CONTSTANTS.visitId)
  const PropertyReview = getPropertyReviews(visitId)
  const [valueReview, loadingReview] = useDocument(PropertyReview)
  const isReview = loadingReview ? false : valueReview?.exists()

  useEffect(() => {
    if (isReview) {
      history.push({
        pathname: ROUTES.BUYER_SURVEY_COMPLETED,
      })
    }
  }, [isReview, history])

  const visit = getPropertVisit(visitId)
  const [value, loading] = useDocument(visit)
  const visitInfo = loading ? null : value?.data()

  if (!loading) {
    localStorage.setItem(LS_CONTSTANTS.agentId, visitInfo?.agent.id)
  }
  const Component = useMemo(() => (isMobile ? IconContainer1 : IconContainer2), [isMobile])
  return (
    <Loader loaded={!loading && !loadingReview && visitInfo}>
      <BackgroundContainer>
        {!loading && visitInfo ? (
          <AgencyInfo nameAgency={visitInfo.agent.name} phoneAgency={visitInfo.agent.phoneNumber} />
        ) : null}
        <TopContainer col={12} noGutter isMobile={isMobile}>
          <InfoContainer isMobile={isMobile}>
            <Col md={7}>
              <Typography.H1 isMobile={isMobile}>
                Hi{' '}
                <span role="img" aria-label="hello">
                  👋
                </span>
                , thanks for your time and property visit.
              </Typography.H1>
              {!loading && visitInfo ? (
                <>
                  <ShortInfo isMobile={isMobile}>{visitInfo.propertyProfile.title}</ShortInfo>
                  <ShortInfo isMobile={isMobile}>{visitInfo.propertyProfile.address}</ShortInfo>
                  <ShortInfo isMobile={isMobile}>
                    {format(visitInfo.fromDateTime.toDate(), 'LLLL d, y')}
                  </ShortInfo>
                  <ShortInfo isMobile={isMobile}>{`${format(
                    visitInfo.fromDateTime.toDate(),
                    'kk:mm',
                  )} - ${format(visitInfo.toDateTime.toDate(), 'kk:mm')}`}</ShortInfo>
                </>
              ) : null}
              <ConfirmTitle isMobile={isMobile}> Visit was confirmed.</ConfirmTitle>
            </Col>
            <Component md={5}>
              <Icon
                icon="empty-logo"
                color={theme.color.originalBlue}
                size={isMobile ? '8.7rem' : '23.9rem'}
              />
            </Component>
          </InfoContainer>
        </TopContainer>
        <BottomContainer col={12} noGutter isMobile={isMobile}>
          <Typography.H1 isMobile={isMobile}>
            Please, tell us more about your ViewD experience{' '}
            <span role="img" aria-label="home">
              🏠
            </span>
          </Typography.H1>
          <ShortInfo isMobile={isMobile}>
            This survey can help us to understand your ViewD experience and improve property for
            next visitors. Only five simple questions. To start survey click on “Start” button.
          </ShortInfo>
          <ButtonContainer
            to={{
              pathname: ROUTES.BUYER_SURVEY_ANSWER,
              state: visitInfo,
            }}
          >
            <Button.Primary width="100%" label="Start" />
          </ButtonContainer>
        </BottomContainer>
      </BackgroundContainer>
    </Loader>
  )
}

export default BuyerSurveyStart
