import React, { useCallback, useState } from 'react'
import { useMobile } from '@utils/hooks'
import { Button } from '@molecules'
import { StyledRow, StyledCol } from './RadioButton.style'

type RadioButtonProps = {
  onChange: (checked: boolean) => void
}

const RadioButton: React.FC<RadioButtonProps> = ({ onChange }) => {
  const isMobile = useMobile()
  const [checkedYes, setCheckedYes] = useState(0)
  const [checkedNo, setCheckedNo] = useState(0)

  const changeNo = useCallback((): void => {
    setCheckedNo(1)
    setCheckedYes(0)
    onChange(false)
  }, [onChange])

  const changeYes = useCallback((): void => {
    setCheckedYes(1)
    setCheckedNo(0)
    onChange(true)
  }, [onChange])

  return (
    <StyledRow>
      <StyledCol xs={6} sm={6} md={6} lg={6} isMobile={isMobile}>
        <Button.YesNo label="NO" checked={checkedNo === 1} onClick={changeNo} />
      </StyledCol>
      <StyledCol xs={6} sm={6} md={6} lg={6} isMobile={isMobile}>
        <Button.YesNo label="YES" checked={checkedYes === 1} onClick={changeYes} />
      </StyledCol>
    </StyledRow>
  )
}

export default RadioButton
