import React, { useCallback, useEffect, useState } from 'react'
import {
  useDesktop,
  useMobile,
  useMobileLandscape,
  useModal,
  useMountEffect,
  useTabletPortrait,
} from '@utils/hooks'
import {
  EMPTY_VIEWINGS_TEXT_AGENT,
  LS_CONTSTANTS,
  REQUEST_TIME_DELAY,
  VIEWING_TIME_FILTER,
  ViewingTime,
} from '@constants/dictionary'
import {
  cancelOrRescheduleVisit,
  confirmVisit,
  getAgentPrivateData,
  getAgentVisits,
  getSellerProfile,
} from '@services/firebase'
import { getAuth } from 'firebase/auth'
import algoliasearch from 'algoliasearch/lite'
import { Loader } from '@atoms'
import { Dropdown, SearchInput } from '@molecules'
import { AgentVisitCard } from '@organisms'
import { AgentViewingConfirm, ViewingInfoModal } from '@modals'
import {
  DropdownContainer,
  SearchContainer,
  StyledBody2,
  ViewingsContainer,
  ViewingsControls,
  ViewingsGrid,
} from './AgentViewings.style'

import {
  filterViewings,
  filterViewingsAlgolia,
  getCancelInitiator,
  getConflictedViewings,
  getViewingById,
  getViewingIndexByStringId,
  mapViewingsData,
  sortViewings,
} from './AgentViewings.utils'
import { InfoModalProps, ViewingCancelInitiators, ViewingStatusType } from '@constants/types'
import { useTheme } from 'styled-components'
import { getViewingStatus } from '@utils/functions'

const AgentViewings: React.FC = () => {
  const { currentUser } = getAuth()
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()
  const isTabletPortrait = useTabletPortrait()
  const isDesktop = useDesktop()
  const { color } = useTheme()

  const [filteredData, setFilteredData] = useState<Array>([])
  const [viewingsList, setViewingsList] = useState<Array>([])
  const [privateData, setUserPrivateData] = useState<Array>([])
  const [showedViewings, setShowedViewings] = useState<Array>([])

  const [searchValue, setSearchValue] = useState<string>('')
  const [viewingTime, setViewingTime] = useState<ViewingTime>(VIEWING_TIME_FILTER.TODAY.value)

  const [loaded, setLoaded] = useState<boolean>(false)

  // Details modal state
  const [infoModalData, setInfoModalData] = useState<InfoModalProps>()
  const { isShown: isShownInfoModal, toggle: toggleInfoModal } = useModal()
  const [loadingInfoModal, setLoadingInfoModal] = useState<boolean>(false)

  // Cancel modal
  const [cancelModalData, setCancelModalData] = useState<{ viewingStringId: string }>()
  const { isShown: isShownCancelModal, toggle: toggleCancelModal } = useModal()
  const [cancelingVisit, setCancelingVisit] = useState<boolean>(false)

  // Reschedule modal
  const [rescheduleModalData, setRescheduleModalData] = useState<{ viewingStringId: string }>()
  const { isShown: isShownRescheduleModal, toggle: toggleRescheduleModal } = useModal()
  const [reschedulingVisit, setReschedulingVisit] = useState<boolean>(false)

  // Pick modal
  const [conflictedViewingsPick, setConflictedViewingsPick] = useState()
  const { isShown: isShownPickModal, toggle: togglePickModal } = useModal()

  // Conflicted viewings list
  const [conflictedViewings, setConflictedViewings] = useState({})

  const agentPrivateData = useCallback(async (): Promise<void> => {
    const userPrivateData = await getAgentPrivateData(currentUser?.uid)
    setUserPrivateData(userPrivateData)
  }, [currentUser?.uid])

  const getViewings = useCallback(async (): Promise<void> => {
    setLoaded(false)
    await agentPrivateData()
    const viewings = await getAgentVisits()
    setViewingsList(viewings)

    setLoaded(true)
  }, [agentPrivateData])
  useMountEffect(getViewings)

  useEffect(() => {
    setShowedViewings(filterViewings(viewingsList, viewingTime))
  }, [viewingsList, viewingTime])

  useEffect(() => {
    const conflictedViewingsList = getConflictedViewings(showedViewings)
    const ignoredConflicts = localStorage.getItem(LS_CONTSTANTS.agentIgnoredConflictedViewings)

    if (ignoredConflicts) {
      for (const conflictTimeKey in conflictedViewingsList) {
        conflictedViewingsList[conflictTimeKey] = conflictedViewingsList[conflictTimeKey].filter(
          (viewingId) => !ignoredConflicts.includes(viewingId),
        )
      }
    }
    setConflictedViewings(conflictedViewingsList)
  }, [showedViewings])

  const searchAlgolia = useCallback(async (): Promise<void> => {
    setLoaded(false)
    let filtered: unknown[] = []
    if (privateData.algoliaApiKey && searchValue !== '') {
      const algolia = algoliasearch(process.env.ALGOLIA_APP_ID ?? '', privateData.algoliaApiKey)
      const { hits } = await algolia.initIndex('PVI').search(searchValue)
      filtered = hits.length ? hits : [{}]
    }
    setFilteredData(filtered)
    setLoaded(true)
  }, [privateData.algoliaApiKey, searchValue])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchAlgolia()
    }, REQUEST_TIME_DELAY)
    return () => clearTimeout(delayDebounceFn)
  }, [searchValue, searchAlgolia])

  const viewDetailsHandler = useCallback(
    async (
      id: string,
      status: { status: ViewingStatusType; cancelledBy: number },
    ): Promise<boolean> => {
      const targetViewing = getViewingById(viewingsList, id)
      const [profile] = await getSellerProfile(targetViewing?.viewing?.createdBy)

      setInfoModalData({
        title: targetViewing?.viewing?.propertyProfile?.title,
        address: targetViewing?.viewing?.propertyProfile?.address,
        price: targetViewing?.viewing?.propertyProfile?.price,
        visitId: targetViewing?.viewing?.id,
        fromDateTime: targetViewing?.viewing?.fromDateTime?.toDate(),
        toDateTime: targetViewing?.viewing?.toDateTime?.toDate(),
        buyerName: targetViewing?.viewing?.buyer?.name,
        buyerPhone: targetViewing?.viewing?.buyer?.phoneNumber,
        sellerName: profile?.name,
        sellerPhone: profile?.phoneNumber,
        status: {
          statusProps: status?.status,
          canceledBy: getCancelInitiator(targetViewing?.viewing?.cancelled?.cancellationReasonId),
        },
        rescheduleHandler: () => {
          setRescheduleModalData({
            viewingStringId: targetViewing?.id,
          })
          toggleInfoModal(false)
          toggleRescheduleModal(true)
        },
        cancelHandler: () => {
          setCancelModalData({
            viewingStringId: targetViewing?.id,
          })
          toggleInfoModal(false)
          toggleCancelModal(true)
        },
      })
      toggleInfoModal()
      return true
    },
    [viewingsList, toggleInfoModal, toggleCancelModal, toggleRescheduleModal],
  )

  const cancelPickHandler = useCallback(
    ({ fromDateTime, viewingIds }: { fromDateTime: Date; viewingIds: Array<number> }) => {
      const copyObject = { ...conflictedViewings }

      const timeKey = fromDateTime.getTime()

      if (Array.isArray(copyObject[timeKey])) {
        copyObject[timeKey] = []

        const previousDataString = localStorage.getItem(
          LS_CONTSTANTS.agentIgnoredConflictedViewings,
        )
        let conflictedViewingsArray: Array<number> = []
        if (!!previousDataString) {
          conflictedViewingsArray = JSON.parse(previousDataString)
        }

        const conflictedViewingsSet = new Set([...conflictedViewingsArray, ...viewingIds])
        localStorage.setItem(
          LS_CONTSTANTS.agentIgnoredConflictedViewings,
          JSON.stringify([...conflictedViewingsSet]),
        )
        setConflictedViewings(copyObject)
      }
      togglePickModal(false)
    },
    [conflictedViewings, togglePickModal],
  )

  const confirmPickHandler = useCallback(
    async ({ id }: { id: number }) => {
      setLoadingInfoModal(true)
      const viewingData = getViewingById(viewingsList, id)

      const viewingStatus = getViewingStatus(
        viewingData?.viewing?.fromDateTime,
        viewingData?.viewing?.isConfirmed,
        !!viewingData?.viewing?.cancelled,
      )

      await viewDetailsHandler(id, { status: viewingStatus, cancelledBy: null })
      togglePickModal(false)
      setLoadingInfoModal(false)
    },
    [viewingsList, togglePickModal, viewDetailsHandler],
  )

  const conflictPickHandler = useCallback(
    ({ fromDateTime }: { fromDateTime: Date }) => {
      const conflictedViewingIds = conflictedViewings[fromDateTime.getTime()]

      const conflictedViewingsByTime = conflictedViewingIds.map(
        (viewingId): { id: number; fromDateTime: Date; toDateTime: Date; title: string } => {
          const viewingData = getViewingById(viewingsList, viewingId)
          return {
            id: viewingData?.viewing?.id,
            fromDateTime: viewingData?.viewing?.fromDateTime?.toDate(),
            toDateTime: viewingData?.viewing?.toDateTime?.toDate(),
            title: viewingData?.viewing?.propertyProfile?.title,
          }
        },
      )

      setConflictedViewingsPick(conflictedViewingsByTime)
      togglePickModal(true)
    },
    [conflictedViewings, viewingsList, togglePickModal],
  )

  const confirmViewHandler = useCallback(
    async ({ id }: { id: string }): Promise<boolean> => {
      const copyArray = viewingsList.slice()
      const viewingIndex = copyArray.findIndex((element) => element.viewing.id === id)
      if (viewingIndex >= 0) {
        const confirmed = await confirmVisit(copyArray[viewingIndex].id)
        if (confirmed) {
          copyArray[viewingIndex].viewing.isConfirmed = true
          setViewingsList(copyArray)
          return true
        }
      }
      return false
    },
    [viewingsList],
  )

  const cancelViewingHandler = useCallback(
    async ({
      viewingStringId,
      message,
      cancelInitiator,
      isReschedule = false,
    }: {
      viewingStringId: string
      message: string
      cancelInitiator: ViewingCancelInitiators
      isReschedule: boolean
    }): Promise<void> => {
      isReschedule ? setReschedulingVisit(true) : setCancelingVisit(true)
      const result = await cancelOrRescheduleVisit({
        viewingStringId,
        cancellationDescription: message,
        cancelInitiator,
        isReschedule: isReschedule,
      })

      if (result) {
        const copyArray = viewingsList.slice()
        const viewingIndex = getViewingIndexByStringId(copyArray, viewingStringId)
        if (viewingIndex >= 0) {
          copyArray[viewingIndex].viewing.cancelled = {
            cancellationReasonId: cancelInitiator,
            cancellationReasonMessage: !!message ? message : null,
            cancelledBy: localStorage.getItem(LS_CONTSTANTS.agentId),
          }
          setViewingsList(copyArray)
          isReschedule ? setReschedulingVisit(false) : setCancelingVisit(false)
          isReschedule ? toggleRescheduleModal(false) : toggleCancelModal(false)
        }
      }
    },
    [viewingsList, toggleCancelModal, toggleRescheduleModal],
  )

  const renderSearch = (): JSX.Element => (
    <SearchContainer isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
      <SearchInput
        key="viewingsSearch"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search viewing"
        noMargin
      />
    </SearchContainer>
  )

  const renderDropdown = (): JSX.Element => (
    <DropdownContainer isMobile={isMobile}>
      <Dropdown
        selectedValue={VIEWING_TIME_FILTER.TODAY.title}
        testid="AgentViewingTime"
        handleValue={(optionValue) => setViewingTime(optionValue)}
        renderThin
        backgroundColor={color.white}
      >
        {Object.values(VIEWING_TIME_FILTER).map((item) => (
          <Dropdown.Option key={item.id} value={item.value} title={item.title} renderThin>
            {item.title}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </DropdownContainer>
  )
  const renderViewings = (): JSX.Element => {
    let filteredValue = showedViewings
    if (!!filteredData.length) {
      filteredValue = filterViewingsAlgolia(viewingsList, filteredData)
    }

    filteredValue = sortViewings(mapViewingsData(filteredValue, conflictedViewings))
    return loaded && !!filteredValue.length ? (
      <ViewingsGrid isDesktop={isDesktop} isMobile={isMobile}>
        {filteredValue.map((props, index) => (
          <AgentVisitCard
            {...props}
            conflictHandler={conflictPickHandler}
            confirmHandler={confirmViewHandler}
            viewDetailsCb={viewDetailsHandler}
            key={index}
          />
        ))}
      </ViewingsGrid>
    ) : (
      <StyledBody2>
        {!!searchValue
          ? EMPTY_VIEWINGS_TEXT_AGENT.AGENT_NO_RESULTS
          : EMPTY_VIEWINGS_TEXT_AGENT.AGENT_NO_VIEWINGS}
      </StyledBody2>
    )
  }

  return (
    <>
      <AgentViewingConfirm.Pick
        isMobile={isMobile}
        isMobileLandscape={isMobileLandscape}
        isConfirming={loadingInfoModal}
        onConfirm={confirmPickHandler}
        onCancel={cancelPickHandler}
        isShown={isShownPickModal}
        onClose={togglePickModal}
        conflictedViewings={conflictedViewingsPick}
      />
      <AgentViewingConfirm.Cancel
        isMobile={isMobile}
        isMobileLandscape={isMobileLandscape}
        isShown={isShownCancelModal}
        onClose={toggleCancelModal}
        onConfirm={cancelViewingHandler}
        isCanceling={cancelingVisit}
        {...cancelModalData}
      />
      <AgentViewingConfirm.Reschedule
        isMobile={isMobile}
        isMobileLandscape={isMobileLandscape}
        onClose={toggleRescheduleModal}
        isShown={isShownRescheduleModal}
        isRescheduling={reschedulingVisit}
        onConfirm={cancelViewingHandler}
        {...rescheduleModalData}
      />
      <ViewingInfoModal
        {...infoModalData}
        isShown={isShownInfoModal}
        closeHandler={toggleInfoModal}
      />
      <ViewingsContainer isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
        <ViewingsControls isMobile={isMobile}>
          {renderDropdown()}
          {renderSearch()}
        </ViewingsControls>
        <Loader loaded={loaded}>{renderViewings()}</Loader>
      </ViewingsContainer>
    </>
  )
}

export default AgentViewings
