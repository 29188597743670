import React from 'react'
import BaseModal from '../BaseModal'
import { formatPhoneNumber, convertToPrice } from '@utils/functions'
import { InfoModalProps } from '@constants/types'
import {
  ViewingInfoContainer,
  Main,
  Secondary,
  PropertyInfo,
  BuyerInfo,
  SellerInfo,
  InfoContainer,
  InfoIcon,
  ViewingTitle,
  InfoTable,
  Controls,
  CloseButton,
  StatusBlock,
  StatusView,
  Body2,
} from './ViewingInfoModal.style'
import { formatDate, formatTime, cutString } from './ViewingInfoModal.utils'
import { VIEWING_ADDRESS_MAX_SYMBOLS, VIEWING_STATUSES } from '@constants/dictionary'

import { useMobile, useMobileLandscape, useTabletPortrait } from '@utils/hooks'
import { Icon, Typography } from '@atoms'
import { Button } from '@molecules'

const ViewingInfoModal: React.FC<InfoModalProps> = ({
  isShown = false,
  closeHandler,
  title = '',
  address = '',
  price = 0,
  buyerName = '',
  buyerPhone = '',
  sellerName = '',
  sellerPhone = '',
  fromDateTime = new Date(),
  toDateTime = new Date(),
  visitId = '',
  status,
  rescheduleHandler,
  cancelHandler,
}) => {
  const isTabletPortrait = useTabletPortrait()
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()

  const renderStatus = (): JSX.Element => {
    if (
      status?.statusProps.statusKey === VIEWING_STATUSES.CONFIRM_VIEW.statusKey ||
      status?.statusProps.statusKey === VIEWING_STATUSES.CONFIRM_VIEW_DISABLED.statusKey
    ) {
      return (
        <Controls>
          <Button.Primary height="4rem" label="Reschedule" onClick={rescheduleHandler} />
          <Button.Secondary height="4rem" label="Cancel viewing" onClick={cancelHandler} />
        </Controls>
      )
    }

    return (
      <StatusBlock>
        <Typography.Caption5>Status</Typography.Caption5>
        <StatusView>
          <Icon
            icon={
              status?.statusProps.statusKey === VIEWING_STATUSES.CONFIRMED.statusKey
                ? 'notification-checked'
                : 'notification-declined'
            }
            color="darkGrey"
          />
          {status?.statusProps.statusKey === VIEWING_STATUSES.CONFIRMED.statusKey
            ? 'Viewing was confirmed'
            : `Viewing was canceled by ${status?.canceledBy}`}
        </StatusView>
      </StatusBlock>
    )
  }

  const modalClickHandler: React.MouseEventHandler = (event) => event.stopPropagation()

  return (
    <BaseModal isShown={isShown} hide={closeHandler}>
      <ViewingInfoContainer
        isMobileLandscape={isMobileLandscape}
        isMobile={isMobile}
        isTabletPortrait={isTabletPortrait}
      >
        <Main isMobile={isMobile} isTabletPortrait={isTabletPortrait} onClick={modalClickHandler}>
          <PropertyInfo>
            <Typography.Caption5>Property Info</Typography.Caption5>
            <InfoTable isMobile={isMobile}>
              <InfoIcon>
                <Icon icon="house" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>
                <ViewingTitle>{title}</ViewingTitle>
              </InfoContainer>
              <InfoIcon>
                <Icon icon="viewing-location" size="1.6rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{cutString(address, VIEWING_ADDRESS_MAX_SYMBOLS)}</InfoContainer>
              <InfoIcon>
                <Icon icon="pound" size="1.5rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{convertToPrice(price)}</InfoContainer>
            </InfoTable>
          </PropertyInfo>
          <BuyerInfo>
            <Typography.Caption5>Buyer</Typography.Caption5>
            <InfoTable isMobile={isMobile}>
              <InfoIcon>
                <Icon icon="avatar" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>
                <Body2>{buyerName}</Body2>
              </InfoContainer>
              <InfoIcon>
                <Icon icon="phone" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{formatPhoneNumber(buyerPhone)}</InfoContainer>
            </InfoTable>
          </BuyerInfo>
          <SellerInfo>
            <Typography.Caption5>Seller</Typography.Caption5>
            <InfoTable isMobile={isMobile}>
              <InfoIcon>
                <Icon icon="avatar" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>
                <Body2>{sellerName}</Body2>
              </InfoContainer>
              <InfoIcon>
                <Icon icon="phone" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{formatPhoneNumber(sellerPhone)}</InfoContainer>
            </InfoTable>
          </SellerInfo>
        </Main>
        <Secondary isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
          <div>
            <CloseButton>
              <Icon
                icon="close"
                size="1.75rem"
                color="originalBlue"
                clickable
                onClick={closeHandler}
              />
            </CloseButton>
            <Typography.Caption5>Viewing Event</Typography.Caption5>
            <InfoTable isMobile={isMobile}>
              <InfoIcon>
                <Icon icon="calendar" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{formatDate(fromDateTime)}</InfoContainer>
              <InfoIcon>
                <Icon icon="time" size="2rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{formatTime(fromDateTime, toDateTime)}</InfoContainer>
              <InfoIcon>
                <Icon icon="id" size="1.7rem" color="darkGrey" />
              </InfoIcon>
              <InfoContainer>{visitId}</InfoContainer>
            </InfoTable>
          </div>
          {renderStatus()}
        </Secondary>
      </ViewingInfoContainer>
    </BaseModal>
  )
}

export default ViewingInfoModal
