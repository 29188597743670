import { DayTypes } from '@constants/types'
import { combineSlotsIntoPeriodsToNextWeek } from './combineSlotsIntoPeriods'

export const getSlotsFromPastWeek = (
  sellerDaysListNextWeek: Array<DayTypes>,
  pastWeek: Array<DayTypes>,
): Array<DayTypes> => {
  const week = combineSlotsIntoPeriodsToNextWeek(pastWeek)

  return sellerDaysListNextWeek.map((day: DayTypes, i: number) => {
    return { ...day, timePeriods: week[i].slots }
  })
}
