/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { Typography } from '@atoms'

export const SelectedOption = styled(Typography.Body3)<{
  center: boolean
  paginationDropdown: boolean
}>`
  display: flex;
  align-items: center;
  ${({ center }) => center && 'justify-content: center;'};

  transition: color 0.2s ease-out;
  color: ${({ theme }) => theme.color.darkBlack};
  cursor: pointer;
  width: 100%;
  font-weight: ${(p) => {
    if (p.paginationDropdown) return 400
    if (p.formDropdown) return 'normal'
    return 600
  }};
  padding-left: ${(p) =>
    p.center ? '2.5rem' : p.paginationDropdown ? p.theme.indent.SM : p.theme.indent.MD};
  ${(p) => p.paginationDropdown && `margin-right: ${p.theme.indent.XXS};`}
`

export const Option = styled(SelectedOption)<{ center: boolean; renderThin: boolean }>`
  ${({ center }) =>
    center &&
    `  display: flex;
      justify-content: center;
      padding-left: 0;
  `};

  ${({ renderThin }) => renderThin && `font-weight: 400;`};

  background-color: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.indent.XXXL};

  &:hover {
    background-color: ${({ theme }) => theme.color.originalBlue_20};
  }
`

export const OptionSelect = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`
export const IconContainer = styled.div<{ isDropped: boolean }>`
  margin-right: ${({ theme }) => theme.indent.MD};
  height: ${({ theme }) => theme.indent.XL};
  display: flex;
  align-content: center;
  ${({ isDropped }) => isDropped && 'transform: rotateX(180deg);'};
`

export const OptionContainer = styled.div<{
  center: boolean
  paginationDropdown: boolean
  id: number
  modalDropDown: boolean
  backgroundColor?: string
}>`
  ${({ center }) => center && 'justify-content: center;'};

  box-sizing: content-box;
  display: flex;
  width: 100%;
  border: 0.1rem solid ${(p) => (p.isDropped ? p.theme.color.originalBlue : p.theme.color.darkGrey)};
  border-radius: ${(p) =>
    p.isDropped === false
      ? p.theme.borderRadius.SM
      : p.drop === 'up'
      ? `0 0 ${p.theme.borderRadius.SM} ${p.theme.borderRadius.SM}`
      : `${p.theme.borderRadius.SM} ${p.theme.borderRadius.SM} 0 0`};

  ${(p) => p.paginationDropdown && `border-radius:  ${p.theme.borderRadius.XS}`};
  ${(p) =>
    (p.paginationDropdown || (p.formDropdown && !p.isDropped)) &&
    `border-color: ${p.theme.color.lightGrey}`};

  height: ${(p) => (p.paginationDropdown ? '1.6rem' : '4.4rem')};
  position: relative;
  outline: none;
  z-index: ${(p) => (p.isDropped ? `${998 + p.id}` : `${998 - p.id}`)};

  ${(p) => p.modalDropDown && `height: 4rem; border: 0.1rem solid ${p.theme.color.lightGrey}`};
  ${(p) => !!p.backgroundColor && `background-color: ${p.backgroundColor}`};
`

export const OptionContainerThin = styled(OptionContainer)`
  border: 0.1rem solid
    ${(p) => (p.isDropped ? p.theme.color.originalBlue : p.theme.color.lightGrey)};
  height: ${(p) => (p.paginationDropdown ? p.theme.indent.MD : '3.8rem')};
`

export const OptionsArea = styled.div<{ tableDropdown: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  position: absolute;
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  ${(p) => p.tableDropdown && 'z-index: 1;'};
  max-height: ${(p) => (p.isDropped ? '12.8rem' : '0')};
  border-radius: ${({ drop, theme }) =>
    drop === 'up'
      ? `${theme.borderRadius.SM} ${theme.borderRadius.SM}  0 0`
      : `0 0 ${theme.borderRadius.SM}  ${theme.borderRadius.SM} `};
  border: 0.1rem solid ${(p) => (p.isDropped ? p.theme.color.originalBlue : 'none')};

  ${(p) => {
    if (p.renderThin) {
      return p.drop === 'up'
        ? `bottom: 3.8rem; left:-0.1rem;`
        : `top : 3.8rem; left:-0.1rem; z-index :1`
    }
    return p.drop === 'up' ? `bottom: 4rem; left:-0.1rem;` : `top : 4rem; left:-0.1rem; z-index :1`
  }}

  ::-webkit-scrollbar {
    width: ${({ theme }) => theme.indent.SM};
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.grey};
    border: ${({ theme }) => theme.indent.XXS} solid transparent;
    border-radius: 1rem;
    background-clip: content-box;
    min-height: 3.4rem;
  }
`

export const Body3 = styled(Typography.Body3)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.2rem;
  padding: 0 ${(p) => p.theme.indent.MD};
`
