import styled from 'styled-components'
import { Typography } from '@atoms'

export const VisitCardContainer = styled.div`
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  width: 100%;
  background-color: ${(p) => p.theme.color.whiteSmoke};
`

export const VisitAgencyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${(p) => p.theme.indent.SM};
`

export const VisitNumber = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  width: 5.6rem;
`
export const Notification = styled(Typography.Body2)`
  color: ${(p) => p.theme.color.originalBlue};
`

export const VisitTime = styled.div`
  padding: 0 ${(p) => p.theme.indent.SM};
`

export const VisitDuration = styled.div`
  display: flex;
  justify-content: right;
  column-gap: ${(p) => (p.isMobile ? p.theme.indent.XL : p.theme.lineHeight.XXL)};
  text-align: right;
  padding-right: ${(p) => p.theme.indent.SM};
`

export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.85rem;
`

export const VisitInfo = styled.div`
  display: grid;
  grid-template-columns: 5.6rem 1fr 1fr;
  width: 100%;
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  height: 4.8rem;
  align-items: center;
`
