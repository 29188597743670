import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const StyledRow = styled(Row)`
  padding-top: ${(p) => p.theme.indent.SM};
`

export const Body2 = styled(Typography.Body2)`
  padding-top: ${(p) => (p.padding ? p.theme.indent.SM : 0)};
  padding-left: ${(p) => (p.gutter ? p.theme.indent.XS : 0)};
`

export const LinkText = styled(Typography.Body3)`
  padding-right: ${(p) => p.theme.indent.XXS};
  color: ${(p) => (p.disabled ? p.theme.color.darkGrey : p.theme.color.originalBlue)};
`

export const LinkContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right: 0;
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: ${(p) => p.theme.indent.SM};
`
