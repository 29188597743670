/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { onAuthStateChanged, getAuth } from 'firebase/auth'
import { LS_CONTSTANTS } from '@constants/dictionary'

const useFirebaseAuth = (): Array<boolean> => {
  const [logedIn, setLogedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const auth = getAuth()
    const unlisten = onAuthStateChanged(auth, async (user) => {
      setLoaded(false)
      if (user && !logedIn) {
        const { uid } = user
        setLogedIn(!!uid)
      }
      if (!user && logedIn) {
        setLogedIn(false)
        localStorage.setItem(LS_CONTSTANTS.uid, '')
      }
      setLoaded(true)
    })
    return () => {
      unlisten()
    }
  }, [])

  return [logedIn, loaded]
}

export default useFirebaseAuth
