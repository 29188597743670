import React, { useEffect, useState } from 'react'
import {
  CancelReasonChoose,
  CloseButton,
  Container,
  ControlButtons,
  Header,
  Main,
  Question,
  QuestionBlock,
  Suggestion,
} from './AgentViewingConfirm.style'
import { getDateString } from './AgentViewingConfirm.utils'

import { MODAL_MESSAGES, AGENT_VIEWING_CONFIRM_RADIO } from '@constants/dictionary'

import {
  ViewingCancelInitiators,
  AgentViewingConfirmProps,
  CancelModalProps,
  ModalProps,
  PickModalProps,
  RescheduleModalProps,
} from '@constants/types'

import BaseModal from '../BaseModal'
import { CommentArea, Icon, Typography } from '@atoms'
import { BulletRadioButton, Button } from '@molecules'
import { useTheme } from 'styled-components'

const AgentViewingConfirm: ModalProps & React.FC<AgentViewingConfirmProps> = ({
  children,
  isShown,
  headerIcon,
  question,
  suggestion,
  onClose,
  isMobile,
  isMobileLandscape,
}) => {
  const { color } = useTheme()
  const modalClickHandler: React.MouseEventHandler = (event) => event.stopPropagation()

  return (
    <BaseModal isShown={isShown} hide={onClose}>
      <Container
        isMobileLandscape={isMobileLandscape}
        isMobile={isMobile}
        onClick={modalClickHandler}
      >
        <CloseButton>
          <Icon icon="close" size="1.75rem" color="originalBlue" clickable onClick={onClose} />
        </CloseButton>
        <Header>
          <Icon icon={headerIcon} size="5.6rem" />
          <QuestionBlock isMobile={isMobile}>
            <Question textAlign="center">{question}</Question>
            <Suggestion color={color.darkGrey} textAlign="center">
              {suggestion}
            </Suggestion>
          </QuestionBlock>
        </Header>
        <Main>{children}</Main>
      </Container>
    </BaseModal>
  )
}

const Cancel: React.FC<CancelModalProps> = ({
  isCanceling,
  viewingStringId,
  isShown,
  onClose,
  onConfirm,
  isMobile,
  isMobileLandscape,
}) => {
  const [cancellationReason, setCancellationReason] = useState<number>(
    ViewingCancelInitiators.BUYER,
  )
  const [commentValue, setCommentValue] = useState<string>('')

  useEffect(() => {
    // Reset form default values when hide
    if (!isShown) {
      setCommentValue('')
      setCancellationReason(ViewingCancelInitiators.BUYER)
    }
  }, [isShown])

  const {
    agentViewingConfirm: {
      cancel: { question, suggestion, commentAreaPlaceholder },
    },
  } = MODAL_MESSAGES

  return (
    <AgentViewingConfirm
      isShown={isShown}
      onClose={onClose}
      headerIcon="viewing-dialog-cancel"
      question={question}
      suggestion={isMobile ? suggestion.mobile : suggestion.other}
      isMobile={isMobile}
      isMobileLandscape={isMobileLandscape}
    >
      <CommentArea
        maxLength={100}
        isConfirmReason
        placeholder={commentAreaPlaceholder}
        onChange={(comment: string) => setCommentValue(comment)}
        value={commentValue}
      />
      <CancelReasonChoose>
        <p>Who requested canceling?</p>
        <BulletRadioButton
          onChange={setCancellationReason}
          selected={cancellationReason}
          isHorizontal
          items={AGENT_VIEWING_CONFIRM_RADIO}
        />
      </CancelReasonChoose>
      <ControlButtons>
        <Button.Primary
          label="Confirm"
          loader={isCanceling}
          disabled={isCanceling}
          width={isMobile ? '100%' : '37.8rem'}
          height="4rem"
          onClick={() => {
            onConfirm({
              viewingStringId: viewingStringId,
              message: commentValue,
              cancelInitiator: cancellationReason,
              isReschedule: false,
            })
          }}
        />
      </ControlButtons>
    </AgentViewingConfirm>
  )
}

const Reschedule: React.FC<RescheduleModalProps> = ({
  isRescheduling,
  viewingStringId,
  onConfirm,
  isShown,
  onClose,
  isMobile,
  isMobileLandscape,
}) => {
  const [commentValue, setCommentValue] = useState<string>('')
  const [reschedulingReason, setReschedulingReason] = useState<number>(
    ViewingCancelInitiators.BUYER,
  )

  useEffect(() => {
    // Reset form default values when hide
    if (!isShown) {
      setCommentValue('')
      setReschedulingReason(ViewingCancelInitiators.BUYER)
    }
  }, [isShown])

  const {
    agentViewingConfirm: {
      reschedule: { question, suggestion, commentAreaPlaceholder },
    },
  } = MODAL_MESSAGES

  return (
    <AgentViewingConfirm
      isShown={isShown}
      onClose={onClose}
      headerIcon="viewing-dialog-reschedule"
      question={isMobile ? question.mobile : question.other}
      suggestion={isMobile ? suggestion.mobile : suggestion.other}
      isMobile={isMobile}
      isMobileLandscape={isMobileLandscape}
    >
      <CommentArea
        maxLength={100}
        isConfirmReason
        placeholder={commentAreaPlaceholder}
        onChange={(comment) => setCommentValue(comment)}
        value={commentValue}
      />
      <CancelReasonChoose>
        <p>Who requested rescheduling?</p>
        <BulletRadioButton
          onChange={setReschedulingReason}
          selected={reschedulingReason}
          isHorizontal
          items={AGENT_VIEWING_CONFIRM_RADIO}
        />
      </CancelReasonChoose>
      <ControlButtons>
        <Button.Primary
          label="Confirm"
          loader={isRescheduling}
          disabled={isRescheduling}
          width={isMobile ? '30.3rem' : '37.8rem'}
          height="4rem"
          onClick={() =>
            onConfirm({
              viewingStringId: viewingStringId,
              message: commentValue,
              cancelInitiator: reschedulingReason,
              isReschedule: true,
            })
          }
        />
      </ControlButtons>
    </AgentViewingConfirm>
  )
}

const Pick: React.FC<PickModalProps> = ({
  isConfirming,
  onClose,
  onCancel,
  onConfirm,
  conflictedViewings = [],
  isShown,
  isMobile,
  isMobileLandscape,
}) => {
  const sortedViewings = conflictedViewings.sort((a, b) => a?.id - b?.id)
  const [selectedVisit, setSelectedVisit] = useState<number>()

  useEffect(() => {
    setSelectedVisit(sortedViewings[0]?.id)
  }, [sortedViewings])

  const {
    agentViewingConfirm: {
      pick: { question, suggestion },
    },
  } = MODAL_MESSAGES

  return (
    <AgentViewingConfirm
      onClose={onClose}
      isShown={isShown}
      headerIcon="viewing-dialog-time-issue"
      question={isMobile ? question.mobile : question.other}
      suggestion={suggestion}
      isMobile={isMobile}
      isMobileLandscape={isMobileLandscape}
    >
      <>
        <BulletRadioButton
          selected={selectedVisit}
          onChange={setSelectedVisit}
          items={sortedViewings.map((viewing) => ({
            label: (
              <>
                <Typography.CheckboxText1>
                  {getDateString({
                    id: viewing?.id,
                    fromDateTime: viewing?.fromDateTime,
                    toDateTime: viewing?.toDateTime,
                  })}
                </Typography.CheckboxText1>
                <Typography.CheckboxText2>{viewing?.title}</Typography.CheckboxText2>
              </>
            ),
            value: viewing?.id,
          }))}
        />
        <ControlButtons>
          <Button.Primary
            loader={isConfirming}
            disabled={isConfirming}
            label="Continue"
            width={isMobile ? '30.3rem' : '37.8rem'}
            height="4rem"
            onClick={() => onConfirm({ id: selectedVisit })}
          />
          <Button.Secondary
            label="No changes needed"
            width={isMobile ? '30.3rem' : '37.8rem'}
            height="4rem"
            onClick={() =>
              onCancel({
                viewingIds: sortedViewings.map((viewing) => viewing.id),
                fromDateTime: sortedViewings[0].fromDateTime,
              })
            }
          />
        </ControlButtons>
      </>
    </AgentViewingConfirm>
  )
}

AgentViewingConfirm.Pick = Pick
AgentViewingConfirm.Cancel = Cancel
AgentViewingConfirm.Reschedule = Reschedule

export default AgentViewingConfirm
