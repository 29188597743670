import { collection, query, where, getFirestore, Query } from 'firebase/firestore'
import { startOfDay, endOfWeek, isSunday, previousSunday } from 'date-fns'
import { SEVEN_DAY, LS_CONTSTANTS } from '@constants/dictionary'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getSellerSlots = (): Query => {
  const getSaturdayNextWeek = (): Date => {
    const today = new Date()
    const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + SEVEN_DAY)
    return endOfWeek(nextWeek)
  }
  const saturdayNextWeek = getSaturdayNextWeek()

  const sundayCurrentWeek = isSunday(new Date())
    ? startOfDay(new Date())
    : startOfDay(previousSunday(new Date()))

  const sellerId = localStorage.getItem(LS_CONTSTANTS.uid)
  const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)
  const db = getFirestore()
  const col = collection(db, FS_COL_NAMES.PROPERTY_VISITS)
  return query(
    col,
    where(FS_Q_PARAMS.CREATED_BY, '==', sellerId),
    where(FS_Q_PARAMS.CANCELLED, '==', null),
    where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
    where(FS_Q_PARAMS.FROM_DATE_TIME, '>', new Date(sundayCurrentWeek)),
    where(FS_Q_PARAMS.FROM_DATE_TIME, '<', new Date(saturdayNextWeek)),
  )
}
