import React, { Dispatch, useEffect, useState } from 'react'
import { Loader, Typography } from '@atoms'
import { format } from 'date-fns'
import { AgencyInfo, IconAndLabel } from '@molecules'
import { Col } from 'styled-bootstrap-grid'
import { useTheme } from 'styled-components'
import { useMobile } from '@utils/hooks'
import { getFirestore, doc } from 'firebase/firestore'
import { useDocument } from 'react-firebase-hooks/firestore'
import { useLocation } from 'react-router'
import confirmation from '@assets/imagesMock/Confirmation.png'
import { addVisitToCalendar, bookAVisit } from '@services/firebase'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'
import {
  ImageContainer,
  RowContainer,
  DetailsContainer,
  Container,
  PageContainer,
  ConfirmImage,
} from './BuyerConfirm.style'

const BuyerConfirm = (): JSX.Element => {
  const isMobile = useMobile()
  const theme = useTheme()
  const [loaded, setLoaded] = useState<boolean>(false)
  const location = useLocation()
  const { agentId, visitId } = location.state
  const handleLoaded = (): Dispatch<boolean> => setLoaded(true)

  useEffect(() => {
    bookAVisit(agentId, visitId).then(() => {
      return handleLoaded()
    })
  }, [agentId, visitId])
  const db = getFirestore()
  const visitProfile = doc(db, FS_COL_NAMES.PROPERTY_VISITS, visitId)
  const [visit, loadingVisit] = useDocument(visitProfile)
  const visitDetails = loadingVisit ? {} : visit.data()
  const fromTime = loadingVisit ? '' : format(visitDetails.fromDateTime.toDate(), 'HH:mm')
  const toTime = loadingVisit ? '' : format(visitDetails.toDateTime.toDate(), 'HH:mm')
  const day = loadingVisit ? '' : format(visitDetails.fromDateTime.toDate(), 'MMMM d, Y')
  const propertyDetails = !loaded ? {} : visitDetails.propertyProfile
  const agentDetails = !loaded ? {} : visitDetails.agent

  const onClick = (): (() => void) => addVisitToCalendar(visitId)

  return (
    <Loader loaded={loaded && !loadingVisit}>
      <PageContainer>
        <AgencyInfo nameAgency={agentDetails.name} phoneAgency={agentDetails.phoneNumber} />
        <ImageContainer isMobile={isMobile}>
          <Col>
            <RowContainer>
              <ConfirmImage src={confirmation} alt="confirmation" />
            </RowContainer>
            <RowContainer>
              <Typography.H1>Booking confirmed!</Typography.H1>
            </RowContainer>
          </Col>
        </ImageContainer>
        <Container>
          <DetailsContainer isMobile={isMobile}>
            <Typography.H2>Viewing of {propertyDetails.title} will take place:</Typography.H2>
            <IconAndLabel icon="calendar" color={theme.color.yellow} title={day} />
            <IconAndLabel
              icon="time"
              color={theme.color.yellow}
              title={`${fromTime} - ${toTime}`}
            />
            <IconAndLabel
              icon="location"
              color={theme.color.yellow}
              title={propertyDetails.address}
            />
            <IconAndLabel
              link
              icon="calendar"
              color={theme.color.originalBlue}
              title="Add reminder to the calendar"
              onClick={onClick}
              textDecoration="underline"
            />
          </DetailsContainer>
        </Container>
      </PageContainer>
    </Loader>
  )
}

export default BuyerConfirm
