export const ONE_THOUSAND = 1000
export const ONE_HUNDRED_PERCENT = 100
export const ONE_WEEK_IN_MILISECOND = 604800000
export const START_DEFAULT_PERIOD = 8
export const FINISH_DEFAULT_PERIOD = 20
export const TIME_TO_SEND_SMS = 19
export const THIRTY_MINUTES = 30
export const DEFAULT_NUMBER_STARS = 5
export const SIX_DAY = 6
export const SEVEN_DAY = 7
export const EVEN_NUMBER = 2
export const PRE_LAST_INDEX = 2
export const NUMBER_TWENTY = 20
export const MIN_NAME_SYMBOLS = 2
export const MIN_SYMBOLS = 8
export const MAX_SYMBOLS = 30
export const TWELVE_HOURS = 12
export const REQUEST_TIME_DELAY = 1000
export const VIEWING_ADDRESS_MAX_SYMBOLS = 50
export const VIEWING_USER_MAX_SYMBOLS = 30
export const FIXED_INPUT_PHONE_NUMBERS = 11
export const FIRST_THREE = 3
export const MIN_HIGHLIGHTED_VIEWING_CONFLICTS = 2
export const CANCELATION_BY_AGENT = 3
export const CANCELATION_BY_SELLER = 2
export const CANCELATION_BY_BUYER = 1
export const TABLE_ROW_MAX_SYMBOL_DESKTOP = 24
export const TABLE_ROW_MAX_SYMBOL_TABLET = 15
export const TABLE_ROW_MAX_SYMBOL_MOBILE = 13
export const TABLE_ROW_EMAIL_MAX_SYMBOL_MOBILE = 15
export const TABLE_ROW_EMAIL_MAX_SYMBOL_TABLET = 18
export const PROPERTY_TIMESLOTS_START_POINT = 2
export const SELLER_TIMESLOTS_START_ON_MIDDLE_OF_DAY = 2
