import { FIXED_INPUT_PHONE_NUMBERS, MIN_NAME_SYMBOLS } from '@constants/dictionary'
import { onlyLatin, withoutCyrillicRegExp } from '@constants/regExp'
import * as yup from 'yup'

export const requiredRule = (): unknown => yup.string().trim().required('Value is required')

export const schema = yup.object().shape({
  fullName: requiredRule()
    .matches(withoutCyrillicRegExp, 'Not a valid name')
    .min(MIN_NAME_SYMBOLS, 'Not a valid name')
    .matches(onlyLatin, 'Not a valid name'),
  email: requiredRule().email('Not a valid email').matches(withoutCyrillicRegExp),
  phone: requiredRule().min(FIXED_INPUT_PHONE_NUMBERS, 'Not a valid number'),
})
