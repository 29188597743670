import styled from 'styled-components'
import { Typography } from '@atoms'

export const IconContainer = styled.div`
  cursor: pointer;
  width: ${(p) => (p.isMobile ? '4rem' : '6rem')};
  height: ${(p) => (p.isMobile ? '4rem' : '6rem')};
  border-radius: 50%;
  background: ${(p) => p.theme.color.greyBlue};
  box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, active }) => active && `border: 0.3rem solid ${theme.color.originalBlue}`};

  margin-right: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXL)};

  :hover {
    ${({ theme }) => `border: 0.3rem solid ${theme.color.originalBlue}`};
  }
`
export const TitleInfo = styled(Typography.H1)`
  line-height: ${(p) => p.theme.lineHeight.LG};
`

export const Container = styled.div`
  display: flex;
  padding-top: ${(p) => p.theme.indent.MD};
  padding-bottom: ${(p) => p.theme.indent.XXL};
`
export const Image = styled.img`
  height: ${(p) => (p.isMobile ? '2rem' : '3rem')};
`
