import {
  BuyerHome,
  BuyerBooking,
  BuyerConfirm,
  BuyerSurveyStart,
  BuyerSurveyAnswer,
  SurveyCompleted,
} from '@pages'
import { ROUTES } from '@constants/dictionary'

const BuyerRoutes = [
  {
    id: 'BuyerConfirm',
    exact: true,
    component: BuyerConfirm,
    path: ROUTES.BUYER_CONFIRM,
  },
  {
    id: 'BuyerSurvey',
    exact: true,
    component: BuyerSurveyStart,
    path: ROUTES.BUYER_SURVEY,
  },
  {
    id: 'BuyerSurveyAnswer',
    exact: true,
    component: BuyerSurveyAnswer,
    path: ROUTES.BUYER_SURVEY_ANSWER,
  },
  {
    id: 'BuyerVisitsRoute',
    exact: true,
    component: BuyerHome,
    path: ROUTES.BUYER_VISITS,
  },
  {
    id: 'BuyerShortListRoute',
    exact: true,
    component: BuyerHome,
    path: ROUTES.BUYER_SHORTLIST,
  },
  {
    id: 'BuyerBooking',
    exact: true,
    component: BuyerBooking,
    path: ROUTES.BUYER_BOOKING,
  },
  {
    id: 'SurveyCompleted',
    exact: true,
    component: SurveyCompleted,
    path: ROUTES.BUYER_SURVEY_COMPLETED,
  },
]
export default BuyerRoutes
