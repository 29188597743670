import { collection, writeBatch, doc, query, where, getFirestore } from 'firebase/firestore'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getUserNotifications = (id: string): Query => {
  const db = getFirestore()
  const col = collection(db, FS_COL_NAMES.NOTIFICATIONS)
  return query(col, where(FS_Q_PARAMS.USER_ID, '==', id))
}

export const markAllAsRead = async (idList: string): Query => {
  const db = getFirestore()
  const batch = writeBatch(db)
  idList.forEach((id) => {
    const notRef = doc(db, FS_COL_NAMES.NOTIFICATIONS, id)
    batch.update(notRef, { isRead: true })
  })
  await batch.commit()
}
