import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'

export const ImageContainer = styled(Col)<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: end;
  height: ${({ isMobile }) => (isMobile ? '24.1rem' : '21.7rem')};
  background-color: ${({ theme }) => theme.color.greyBlue};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
`
export const RowContainer = styled(Row)`
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.indent.LG};
`
export const DetailsContainer = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile, theme }) =>
    isMobile ? `${theme.indent.MD} ${theme.indent.LG}` : `${theme.indent.LG} ${theme.indent.XXXS}`};
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
`
export const PageContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
`
export const ConfirmImage = styled.img`
  height: 10rem;
  width: 10rem;
`
