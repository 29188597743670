import React from 'react'
import { MobileFields } from './navigation.config'
import { BaseModal } from '@modals'
import { CloseMenuContainer, BurgerMenuContainer } from './AgentHeader.style'

type ModalTemplateTypes = {
  burgerMenuShown: boolean
  toggleBurgerMenu: () => void
  renderLinks: React.FC
  renderMenuIcon: React.FC
}

const ModalTemplate: React.FC<ModalTemplateTypes> = ({
  burgerMenuShown,
  toggleBurgerMenu,
  renderLinks,
  renderMenuIcon,
}) => {
  return (
    <BaseModal isShown={burgerMenuShown} hide={toggleBurgerMenu} isNotification marginNone>
      <BurgerMenuContainer onClick={(e) => e.stopPropagation()}>
        <CloseMenuContainer>{renderMenuIcon()}</CloseMenuContainer>
        {renderLinks(MobileFields, true)}
      </BurgerMenuContainer>
    </BaseModal>
  )
}

export default ModalTemplate
