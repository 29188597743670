import React from 'react'
import { useMobile } from '@utils/hooks'
import { Typography, Icon } from '@atoms'
import NotificationsList from '@organisms/NotificationsList'
import BaseModal from '../BaseModal'
import { NotificationContainer, NotificationHeader } from './NotificationsModal.style'

import { NotificationsModalTypes } from '@constants/types/modals'

const NotificationsModal: React.FC<NotificationsModalTypes> = ({
  title,
  onClose,
  isShown,
  hasUnread,
  notificationsList,
  onClickToRead,
}) => {
  const isMobile = useMobile()

  return (
    <BaseModal isNotification hide={onClose} isShown={isShown}>
      <NotificationContainer
        isMobile={isMobile}
        isOpen={isShown}
        onClick={(e) => e.stopPropagation()}
      >
        <NotificationHeader>
          <Typography.H2 isMobile={isMobile}>{title}</Typography.H2>
          <Icon
            icon="close"
            clickable
            size={isMobile ? '2rem' : '2.2rem'}
            color="black"
            onClick={onClose}
          />
        </NotificationHeader>
        <NotificationsList
          notificationsList={notificationsList}
          hasUnread={hasUnread}
          onClickToRead={onClickToRead}
          onClose={onClose}
        />
      </NotificationContainer>
    </BaseModal>
  )
}

export default NotificationsModal
