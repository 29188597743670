import React, { useEffect, useState } from 'react'
import {
  BodyContainer,
  ButtonContainer,
  CalendarContainer,
  Container,
  CustomCalendar,
  Header,
  TimeSlotsContainer,
  Title,
} from './AgentPropertyTimeSlotsCard.style'
import { useDate, useDesktop, useMobile, useTabletPortrait, useMountEffect } from '@utils/hooks'
import { Typography, Loader } from '@atoms'
import { initialStateForShowSlots, mapTimeSlots } from '@utils/functions'
import { TimeSlotsList } from '@organisms'
import { Button } from '@molecules'
import { useCollection } from 'react-firebase-hooks/firestore'
import { format } from 'date-fns'
import { filterTimeSlots, tileDisabled } from './AgentPropertyTimeSlotsCard.utils'
import {
  LS_CONTSTANTS,
  SEND_MODAL,
  SIX_DAY,
  AGENT_PROPERTY_TIME_SLOTS_CARD,
} from '@constants/dictionary'
import { getPropertyBuyerTimeSlotsCol, getPropertyProfile } from '@services/firebase'
import { SendModal } from '@modals'
import { useParams } from 'react-router-dom'

const AgentPropertyTimeSlotsCard: React.FC = () => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTablet = useTabletPortrait()
  const yearSize = 5
  const today = new Date()
  const { todayDate, buyersWeek } = useDate()
  const [chosenDay, setChosenDay] = useState(today)
  const [isSent, setIsSent] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [propertyProfile, setPropertyProfile] = useState(undefined)

  const { id: propertyProfileId }: { id: string } = useParams()
  const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)
  const [timeSlotVisits, loadingVisits] = useCollection(getPropertyBuyerTimeSlotsCol(propertyId))
  const timeSlotDetails = loadingVisits ? [] : mapTimeSlots(timeSlotVisits)
  const [timeSlots, setTimeSlots] = useState()
  const noData = timeSlotDetails.length === 0

  const loadData = async (): Promise<undefined> => {
    const propertyDetails = await getPropertyProfile(propertyId)
    setPropertyProfile(propertyDetails)
  }
  useMountEffect(loadData)

  useEffect(() => {
    if (!loadingVisits) {
      setTimeSlots(filterTimeSlots(timeSlotDetails, today))
    }
    setChosenDay(today)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingVisits])
  const cardState = initialStateForShowSlots(
    timeSlotDetails,
    format(chosenDay, 'd LLLL y'),
    timeSlots,
  )

  const handleSlotsChange = (day: string): void => {
    setTimeSlots(
      timeSlotDetails.filter((item) => format(item.dayOfWeek, 'd LLLL y') === day)[0]?.timeSlots,
    )
  }

  const handleDayChange = (date: Date): void => {
    setChosenDay(date)
    handleSlotsChange(format(date, 'd LLLL y'))
  }

  return (
    <BodyContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
      <Loader loaded={!!propertyProfile}>
        <Container isMobile={isMobile} isDesktop={isDesktop}>
          <CalendarContainer isMobile={isMobile}>
            <Title>Calendar</Title>
            <Header>
              Today:<Typography.Body2>{todayDate}</Typography.Body2>
            </Header>
            <CustomCalendar
              onChange={handleDayChange}
              value={chosenDay}
              calendarType="US"
              view="month"
              minDetail="month"
              navigationLabel={({ label }: { label: string }) => `${label.slice(0, -yearSize)}`}
              minDate={buyersWeek(SIX_DAY)[0]}
              maxDate={buyersWeek(SIX_DAY)[buyersWeek(SIX_DAY).length - 1]}
              disabled={noData}
              tileDisabled={({ date, view }) => {
                return tileDisabled({ date, view, timeSlotDetails, week: buyersWeek(SIX_DAY) })
              }}
            />
          </CalendarContainer>
          <TimeSlotsContainer isDesktop={isDesktop} isMobile={isMobile}>
            <Title>TimeSlots</Title>
            {!noData && cardState.timeSlots ? (
              <TimeSlotsList timeSlotsList={cardState.timeSlots} onlyShow noHover />
            ) : (
              <>
                <Typography.Body2 disabled>
                  {propertyProfile?.status !== 'active'
                    ? AGENT_PROPERTY_TIME_SLOTS_CARD.noActive
                    : AGENT_PROPERTY_TIME_SLOTS_CARD.noSlots}
                </Typography.Body2>
                <ButtonContainer isDesktop={isDesktop}>
                  <Button.Primary
                    label="Request Slots"
                    width={'16.9rem'}
                    disabled={
                      isSent
                        ? isSent
                        : (!noData && !cardState.timeSlots) || propertyProfile?.status !== 'active'
                    }
                    onClick={() => {
                      setIsSent(true)
                      setIsModal(true)
                    }}
                  />
                </ButtonContainer>
              </>
            )}
          </TimeSlotsContainer>
        </Container>
      </Loader>
      {isModal && (
        <SendModal
          isShown={isModal}
          onConfirm={() => setIsModal(false)}
          type={'reminder'}
          propertyId={propertyId}
          propertyProfileId={propertyProfileId}
          buyerId={''}
          header={SEND_MODAL.header}
          message={SEND_MODAL.message.reminder}
        />
      )}
    </BodyContainer>
  )
}

export default AgentPropertyTimeSlotsCard
