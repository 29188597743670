import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const getReviewById = async (visitId: string): Promise<T> | undefined => {
  const db = getFirestore()

  const propertyReviewsRef = doc(db, FS_COL_NAMES.PROPERTY_REVIEWS, visitId)
  const reviewDoc = await getDoc(propertyReviewsRef)
  return reviewDoc.data()
}
