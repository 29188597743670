import styled from 'styled-components'
import { Typography } from '@atoms'

export const BookingCardImage = styled.div`
  background-image: url(${({ path }) => path});
  background-size: cover;
  background-position: center;
  height: 21.1rem;
  position: relative;
`
export const ContainerInfo = styled.div`
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ theme }) => theme.indent.MD} ${({ theme }) => theme.indent.LG};
`
export const Text = styled(Typography.Body2)`
  padding: ${({ theme }) => theme.indent.XS} 0;
`
export const ContainerAgency = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`
export const BottomContainer = styled.div`
  padding: ${({ theme, isMobile }) =>
    isMobile ? `${theme.indent.SM} ${theme.indent.LG}` : `${theme.indent.MD} ${theme.indent.XXXL}`};
`
