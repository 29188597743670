/* eslint-disable no-console */

const minStars = 0
const maxStars = 5

export const getSecureCount = (count: number): number => {
  if (typeof count !== 'number') {
    console.error(`count ${count} is not number`)
    return minStars
  }
  if (count < minStars) {
    return minStars
  }
  if (count > maxStars) {
    return maxStars
  }
  return count
}
