import React, { Dispatch, useEffect, useMemo, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'
import { Loader, Icon, Typography } from '@atoms'
import { getUserProfiles } from '@utils/functions'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getUsersProfilesByType } from '@services/firebase'
import Table from '../../organisms/Table'
import {
  useMobile,
  useTabletPortrait,
  useDesktop,
  useTabletLandscape,
  useMobileLandscape,
} from '@utils/hooks'
import { defaultDropDown } from './AddModal.utils'
import { LS_CONTSTANTS, ROUTES, USER_TYPES } from '@constants/dictionary'
import BaseModal from '../BaseModal'
import { ColumnWidth } from './ColumnWidth'
import { Cell, Container, IconWrapper, LinkTo } from './AddModal.style'

import { AddModalModalTypes } from '@constants/types/modals'

const AddModal: React.FC<AddModalModalTypes> = ({
  hide,
  isShown,
  // onConfirm,
  onCancel,
  type,
  getSelected,
  user,
}) => {
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()
  const isTabletPortrait = useTabletPortrait()
  const isTabletLandscape = useTabletLandscape()
  const isDesktop = useDesktop()
  const color = useTheme().color
  const [sortByDropDown, setSortBy] = useState()
  const [result, loadingProfiles] = useCollection(getUsersProfilesByType(type))
  const data = loadingProfiles && !result ? [] : getUserProfiles(result)
  const [selectedOption, setSelectedOption] = useState<string>('')
  const getState = (selected: string): Dispatch<string> => setSelectedOption(selected)
  const { push } = useHistory()
  const clickAdd = useCallback(() => {
    push({
      pathname: ROUTES.AGENT_ADD_USER,
      state: user,
    })
  }, [push, user])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        percentWidth: isMobile ? ColumnWidth.width45 : ColumnWidth.width25,
        disableGlobalFilter: false,
        Cell: ({ row: { original } }) => <Cell>{original.name}</Cell>,
      },
      {
        Header: 'Email',
        accessor: isMobile ? 'hiddenEmail' : 'email',
        percentWidth: ColumnWidth.width25,
        disableGlobalFilter: false,
        Cell: ({ row: { original } }) => (
          <LinkTo noUnderline isMobile href={`mailto:${original.email}?subject=ViewD`}>
            {original.email}
          </LinkTo>
        ),
      },
      {
        Header: 'Phone number',
        accessor: 'phoneNumber',
        percentWidth: isMobile ? ColumnWidth.width35 : ColumnWidth.width25,
        Cell: ({ row: { original } }) => original.phoneNumber,
      },
      {
        Header: '',
        accessor: 'addToCard',
        disableSortBy: true,
        percentWidth: isMobile ? ColumnWidth.width20 : ColumnWidth.width25,
        disableSortBy: true,
        Cell: ({ row: { original } }) =>
          isMobile ? (
            <IconWrapper
              onClick={() => {
                getSelected(original.name, original.userId)
                onCancel()
              }}
            >
              <Icon
                icon={type === 'seller' ? 'contact-plus' : 'send_icon'}
                onClick={() => {
                  getSelected(original.name, original.userId)
                  onCancel()
                }}
                color={color.originalBlue}
              />
            </IconWrapper>
          ) : (
            <LinkTo
              onClick={() => {
                getSelected(original.name, original.userId), onCancel()
              }}
            >
              <Typography.Body3 color="originalBlue">
                {type === 'seller' ? 'Add to property' : 'Send link'}
              </Typography.Body3>
            </LinkTo>
          ),
      },
      {
        Header: 'createdAt',
        accessor: 'createdAt',
        defaultCanSort: true,
        sortType: 'datetime',
        isSortedDesc: false,
        percentWidth: 25,
        Cell: ({ row: { original } }) => original.createdAt,
      },
    ],
    [color.originalBlue, isMobile, onCancel, getSelected, type],
  )
  useEffect(() => {
    if (selectedOption === 'Recently added') {
      return setSortBy([{ id: 'createdAt', desc: true }])
    } else {
      return setSortBy(undefined)
    }
  }, [setSortBy, selectedOption])

  const modalClickHandler: React.MouseEventHandler = (event) => event.stopPropagation()

  return (
    <BaseModal marginNone hide={hide} isShown={isShown}>
      <Loader loaded={!loadingProfiles}>
        <Container
          isDesktop={isDesktop}
          isTabletLandscape={isTabletLandscape}
          isTabletPortrait={isTabletPortrait}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          onClick={modalClickHandler}
        >
          <Table
            sortByDropDown={sortByDropDown}
            callback={getState}
            isTableModal
            columns={columns}
            data={data}
            user={user}
            typeUser={type}
            title="AddModal"
            onCancel={onCancel}
            onConfirm={clickAdd}
            selectedValue={selectedOption}
            defaultValue={`All ${defaultDropDown(type)}`}
            dropdownOptions={[
              {
                id: `All ${defaultDropDown(type)}`,
                value: `All ${defaultDropDown(type)}`,
                title: `All ${defaultDropDown(type)}`,
              },
              { id: 'Recently added', value: 'Recently added', title: 'Recently added' },
            ]}
            addButtonDisabled={
              localStorage.getItem(LS_CONTSTANTS.userType) === USER_TYPES.agency &&
              !!localStorage.getItem(LS_CONTSTANTS.selectedAgentId) &&
              localStorage.getItem(LS_CONTSTANTS.selectedAgentId) !==
                localStorage.getItem(LS_CONTSTANTS.uid)
            }
          />
        </Container>
      </Loader>
    </BaseModal>
  )
}

export default AddModal
