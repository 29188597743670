import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Icon } from '@atoms'
import { Input } from '@molecules'

import { SearchFormProps } from '@constants/types/helpers'

const SearchForm: React.FC<SearchFormProps> = ({
  name,
  placeholder,
  errorMessage,
  inputLabel,
  error,
  disabled,
  type,
  maxLength,
  onSelected,
  isLeftIcon = true,
  isEditProperty,
  tabIndex,
}) => {
  const { control } = useFormContext()
  const disableSearch = (): boolean => {
    if (isEditProperty) {
      return false
    } else {
      return true
    }
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Input
          {...field}
          isLeftIcon={isLeftIcon}
          isSearchAddress={disableSearch()}
          icon={isLeftIcon && <Icon color="darkGrey" icon="search" size="1.6rem" />}
          error={error}
          inputLabel={inputLabel}
          onChange={field.onChange}
          errorMessage={errorMessage}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          maxLength={maxLength}
          onSelected={onSelected}
          tabIndex={tabIndex}
        />
      )}
    />
  )
}
export default SearchForm
