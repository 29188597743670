const EMPTY_LIST_TEXT = {
  BUYER_VISITS: 'You don’t have any scheduled visits yet.',
  BUYER_SHORT_LIST:
    'You don’t have any property in your shortlist. Fill survey after the visit and select the checkbox "Add to shortlist".',
  BUYER_PROPERTY_TIME_SLOTS:
    'No time slots available, for any questions contact your agent or the agency.',
}

export const EMPTY_LIST_TEXT_AGENT = {
  AGENT_NO_PROPERTIES: `Sorry, but you don't have any added property. Click on "Add Property" button to add a new property.`,
  AGENT_NO_RESULTS: 'No entries have been found',
}

export const EMPTY_VIEWINGS_TEXT_AGENT = {
  AGENT_NO_VIEWINGS: 'You have no scheduled viewings',
  AGENT_NO_RESULTS: 'No visits have been found',
}

export default EMPTY_LIST_TEXT
