import { USER_TYPES } from '@constants/dictionary'

export const defaultDropDown = (type: string): string => {
  if (type === USER_TYPES.seller) {
    return 'Sellers'
  }
  if (type === USER_TYPES.buyer) {
    return 'Buyers'
  }
  return ''
}
