export const allOptions = [
  { id: 'allVisits', value: 'allVisits', title: 'All visits' },
  { id: 'upcomingVisits', value: 'upcomingVisits', title: 'Upcoming visits' },
  { id: 'pastVisits', value: 'pastVisits', title: 'Past visits' },
  { id: 'cancelledVisits', value: 'cancelledVisits', title: 'Cancelled visits' },
  { id: 'shortList', value: 'shortList', title: 'Short list' },
]
export const defaultModalBody = {
  cancellationDescription: '',
  cancelledBy: '',
  comment: '',
  interestedInProperty: true,
  location: 0,
  name: '',
  overallCondition: 0,
  price: 0,
  rating: 0,
  title: '',
  valueForMoney: 0,
  visitsQuantity: 0,
}
export const MESSAGE_TYPE = {
  REMINDER: 'reminder',
  LINK: 'link',
}

export const defaultData = {
  name: '',
  id: '',
  propertyId: '',
  sellerId: '',
  propertyProfileId: '',
}

export const defaultContacts = {
  name: '',
  phoneNumber: '',
  email: '',
}
