import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const CardWrapper = styled.div`
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  padding: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XL)};
  background-color: white;
  height: fit-content;
  position: relative;
`
export const CardWrapperStatistic = styled.div`
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  padding: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XL)};
  background-color: white;
  height: fit-content;
  margin-top: ${(p) => p.theme.indent.MD};
`

export const DetailsRow = styled(Row)<{ isMobile: boolean }>`
  display: grid;
  grid-template-areas: 'icon text';
  grid-template-columns: ${(p) => p.theme.indent.LG} auto;
  align-items: center;
  gap: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
`
export const DetailsRowStatistic = styled(Row)<{ isMobile: boolean }>`
  display: grid;
  grid-template-areas: 'icon text';
  grid-template-columns: ${(p) => p.theme.indent.LG} auto;
  align-items: center;
  gap: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
  padding-right: 2.7rem;
`
export const DetailsRowCalendar = styled.div`
  ${(p) =>
    (p.isMobile || p.isTabletPortrait) && p.statistic
      ? `
        margin-top: ${p.theme.indent.MD};
        border-top: 0.1rem solid ${p.theme.color.lightGrey};
        padding-top: ${p.theme.indent.MD};
        width: 100%;
      `
      : 0};
`
export const DetailsSecondRow = styled.div`
  ${(p) =>
    p.isMobile
      ? `
      margin-top: ${p.theme.indent.MD};
      border-top: 0.1rem solid ${p.theme.color.lightGrey};
      padding-top: ${p.theme.indent.MD};
      width: 100%;
      `
      : 0};
  display: flex;
`
export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.isMobile || p.isTabletPortrait ? p.theme.indent.SM : p.theme.indent.MD)};
`

export const EditWrapper = styled.div`
  position: absolute;
  right: 2.7rem;
  top: 2.7rem;
`
export const ColStyled = styled(Col)`
  padding-left: ${(p) => (p.isMobile || p.isTabletPortrait ? 0 : '4rem')};
  padding-right: ${(p) => (p.isMobile || p.isTabletPortrait ? 0 : '7rem')};

  ${({ theme, isMobile, isTabletPortrait, statistic }) => {
    if ((isMobile || isTabletPortrait) && statistic)
      return `
      border-top: 0.1rem solid ${theme.color.lightGrey};
      margin-top: ${theme.indent.XL};
      padding-top: ${theme.indent.XL};
      `
    return `border-left: 0.1rem solid ${theme.color.lightGrey}`
  }}
`
export const NotesRow = styled.div`
  padding-bottom: ${(p) => p.theme.indent.MD};
`
export const AdditionalBlock = styled.div`
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.LG : 0)};
`
export const Caption2 = styled(Typography.Caption2)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const Link = styled(Typography.Link)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const Body3 = styled(Typography.Body3)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
