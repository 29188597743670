import { getFirestore, query, collection, doc, where } from '@firebase/firestore'
import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { getDocs } from 'firebase/firestore'
import { FS_COL_GROUP_NAMES, FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getSellerProfile = async (sellerId: string): Promise<Array<unknown>> => {
  const id = localStorage.getItem(LS_CONTSTANTS.uid)
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const db = getFirestore()
  const user = doc(db, FS_COL_NAMES.USERS, sellerId)
  const colUser = collection(user, FS_COL_GROUP_NAMES.USER_PROFILES)

  const prop = userType === USER_TYPES.agency ? FS_Q_PARAMS.AGENCY_ID : FS_Q_PARAMS.CREATED_BY

  const q = query(
    colUser,
    where(prop, '==', id),
    where(FS_Q_PARAMS.USER_TYPE, '==', FS_Q_PARAMS.SELLER),
  )

  const querySnapshot = await getDocs(q)
  return querySnapshot?.docs?.map((profile) => profile?.data())
}
