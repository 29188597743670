import React, { useState } from 'react'
import { mapShortLists } from '@utils/functions'
import { useModal, useMobile, useTabletLandscape, useTabletPortrait } from '@utils/hooks'
import { Loader } from '@atoms'
import { BookingDayTitle, EmptyListText } from '@molecules'
import { BookingInfoCard } from '@organisms'
import { useCollection } from 'react-firebase-hooks/firestore'
import { collection, getFirestore, doc, deleteDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { MODAL_MESSAGES, EMPTY_LIST_TEXT } from '@constants/dictionary'
import { ConfirmModal } from '@modals'
import { Container } from './BuyerShortList.style'
import { FS_COL_NAMES, FS_COL_GROUP_NAMES } from '@constants/dictionary/firebase'

const BuyerShortList = (): JSX.Element => {
  const isMobile = useMobile()
  const isTabletLandscape = useTabletLandscape()
  const isTabletPortrait = useTabletPortrait()
  const auth = getAuth()
  const db = getFirestore()
  const col = collection(db, FS_COL_NAMES.USERS)
  const user = doc(col, auth.currentUser.uid)
  const colshortlist = collection(user, FS_COL_GROUP_NAMES.SHORT_LISTED_PROPERTIES)
  const [value, loading] = useCollection(colshortlist)

  const [deletedPrtoprty, setDeleteProperty] = useState('')
  const list = loading ? [] : mapShortLists(value)
  const { isShown, toggle } = useModal()

  const deleteCard = async (): Promise => {
    await deleteDoc(doc(colshortlist, deletedPrtoprty))
  }
  const clickOnTrash = (property: string): undefined => {
    toggle()
    setDeleteProperty(property)
  }
  return (
    <Container
      isMobile={isMobile}
      isTabletLandscape={isTabletLandscape}
      isTabletPortrait={isTabletPortrait}
    >
      <Loader loaded={!loading}>
        <ConfirmModal
          isShown={isShown}
          hide={toggle}
          onCancel={() => toggle}
          onConfirm={deleteCard}
          message={MODAL_MESSAGES.confirmModal}
        />
        {list.length ? (
          list.map(({ startDate, visits, index }) => (
            <div key={`${index}vist`}>
              <BookingDayTitle date={startDate} />
              {visits.map((meeting) => (
                <BookingInfoCard
                  {...meeting}
                  agentId={meeting.agent.id}
                  propertyId={meeting.propertyId}
                  key={meeting.compareDate}
                  isConfirmed
                  onClick={clickOnTrash}
                />
              ))}
            </div>
          ))
        ) : (
          <EmptyListText text={EMPTY_LIST_TEXT.BUYER_SHORT_LIST} />
        )}
      </Loader>
    </Container>
  )
}

export default BuyerShortList
