import styled from 'styled-components'

export const ContainerList = styled.div<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.MD} ${p.theme.indent.LG} ${p.theme.indent.XXXS}`
      : `${p.theme.indent.XL} ${p.theme.indent.XXXS} ${p.theme.indent.XXXS}`};
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(p) => p.theme.indent.SM};
`
