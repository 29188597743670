import React from 'react'
import { Icon, Typography } from '@atoms'
import { useMobile } from '@utils/hooks'
import {
  VisitCardContainer,
  VisitAgencyInfo,
  VisitNumber,
  VisitTime,
  VisitDuration,
  PhoneContainer,
  VisitInfo,
  Notification,
} from './VisitInfoCard.style'
import { VisitInfoCardTypes } from '@constants/types/molecules'

const VisitInfoCard: React.FC<VisitInfoCardTypes> = ({
  agencyName = '',
  agencyPhone = '',
  visitNumber = '',
  visitTime = '',
  visitDuration = '',
  visitNotification,
  disabled,
  onClick,
}): JSX.Element => {
  const isMobile = useMobile()
  return (
    <VisitCardContainer isMobile={isMobile} onClick={onClick}>
      <VisitAgencyInfo>
        <Typography.Body2 isMobile={isMobile} disabled={disabled}>
          {agencyName}
        </Typography.Body2>
        <PhoneContainer>
          <Icon icon="phone" clickable size="1.5rem" color="grey" />
          <Typography.Body2 isMobile={isMobile} disabled={disabled}>
            {agencyPhone}
          </Typography.Body2>
        </PhoneContainer>
      </VisitAgencyInfo>
      <VisitInfo>
        <VisitNumber>
          <Typography.H3 disabled={disabled}>{visitNumber}</Typography.H3>
        </VisitNumber>
        <VisitTime>
          <Typography.Body2 isMobile={isMobile} disabled={disabled}>
            {visitTime}
          </Typography.Body2>
        </VisitTime>
        <VisitDuration isMobile={isMobile}>
          {visitNotification ? (
            <Notification isMobile={isMobile}>{visitNotification}</Notification>
          ) : null}
          <Typography.Body2 isMobile={isMobile} disabled={disabled}>
            {visitDuration}
          </Typography.Body2>
        </VisitDuration>
      </VisitInfo>
    </VisitCardContainer>
  )
}

export default VisitInfoCard
