import React from 'react'
import { IconWithTitle, Integration } from './AgentIntegrationPlate.style'
import { Icon, Typography } from '@atoms'
import Button from '../Button'
import { useMobile } from '@utils/hooks'

import { IntegrationProps } from '@constants/types/molecules'

const AgentIntegrationPlate: React.FC<IntegrationProps> = ({ iconName, title, connectHandler }) => {
  const isMobile = useMobile()

  return (
    <Integration>
      <IconWithTitle>
        <Icon icon={iconName} size="3.2rem" />
        <Typography>{title}</Typography>
      </IconWithTitle>
      <Button.Primary
        label="Connect"
        width={isMobile ? '10.4rem' : '12.8rem'}
        height="4rem"
        onClick={connectHandler}
      />
    </Integration>
  )
}

export default AgentIntegrationPlate
