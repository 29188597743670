import styled from 'styled-components'
import { Typography } from '@atoms'

export const SearchContainer = styled.div<{
  isMobile: boolean
}>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '26rem')};
  padding-bottom: ${({ theme }) => theme.indent.XS};
`

export const Header = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ isMobile, theme }) =>
    isMobile
      ? `${theme.indent.SM} ${theme.indent.LG}`
      : `${theme.indent.XS} ${theme.indent.XXXL} 0`};
  ${({ isMobile }) =>
    isMobile &&
    `flex-direction: column;
    align-items: center;
  `};
  border-top: ${({ theme }) => `0.1rem solid ${theme.color.lightGrey}`};
  border-left: ${({ theme }) => `0.1rem solid ${theme.color.lightGrey}`};
  border-right: ${({ theme }) => `0.1rem solid ${theme.color.lightGrey}`};
  border-radius: ${({ theme }) => `${theme.borderRadius.LG} ${theme.borderRadius.LG} 0 0;`};
`

export const DropdownContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${({ isTablet }) => (isTablet ? '16rem' : '100%')};
  ${({ isMobile, theme }) => isMobile && `padding-bottom: ${theme.indent.SM}`};
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const Button = styled.div<{ active: boolean }>`
  padding: ${({ theme }) => `1rem calc(${theme.indent.MD} - 0.1rem)`};
  background-color: ${({ theme }) => theme.color.white};
  ${(p) => p.active && `border-bottom: 0.4rem solid ${p.theme.color.originalBlue};`};
  cursor: pointer;
`

export const ButtonText = styled(Typography.Caption4)``
