import { ERROR_PASS } from '@constants/dictionary'

export const checkOobCode = async (resultSuccess: {
  error: string
  loaded: boolean
}): { error: string; loaded: boolean } => {
  if (!resultSuccess.result && resultSuccess.e.code === ERROR_PASS.INVALID_CODE) {
    return { error: ERROR_PASS.INVALID_CODE, loaded: true }
  }

  if (!resultSuccess.result && resultSuccess.e.code === ERROR_PASS.EXPIRED_CODE) {
    return { error: ERROR_PASS.EXPIRED_CODE, loaded: true }
  }

  if (resultSuccess.result) {
    return { error: '', loaded: true }
  }

  return { error: '', loaded: true }
}
