import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'

const getColor = (p) => {
  if (p.isDragAccept) {
    return p.theme.color.lightGrey
  }
  if (p.isDragActive) {
    return p.theme.color.originalBlue
  }
  if (p.fileRejections.map((file) => file.errors).length) {
    return p.theme.color.red
  }
  return p.theme.color.lightGrey
}

export const DropZoneContainer = styled.div<{
  isTabletPortrait: boolean
  isMobile: boolean
  isDragActive: boolean
}>`
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.borderRadius.XS};
  min-height: 19.2rem;
  width: 100%;
  margin-top: ${(p) => (p.isMobile || p.isTabletPortrait ? p.theme.indent.MD : p.theme.indent.XS)};
  border-style: dashed;
  border-color: ${(p) => getColor(p)};
  outline: none;
  transition: border 0.24s ease-in-out;
  background-color: ${(p) => p.isDragActive && p.theme.color.lightBlue};
`

export const BaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
`
export const DropZoneImg = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`
export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
`
export const StyledRow = styled(Row)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LoadedImg = styled.img`
  height: 19.2rem;
  width: 100%;
`

export const DragActiveWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.indent.LG} ${(p) => p.theme.indent.XXXS};
`

export const ImageWrapper = styled.div`
  position: relative;
`

export const CloseWrapper = styled.div`
  border-radius: 50%;
  background-color: ${(p) => p.theme.color.white};
  position: absolute;
  width: ${(p) => p.theme.indent.XL};
  height: ${(p) => p.theme.indent.XL};
  right: ${(p) => p.theme.indent.SM};
  top: ${(p) => p.theme.indent.SM};
  display: flex;
  align-items: center;
  justify-content: center;
`
