import styled from 'styled-components'

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: ${(p) => (p.isMobile ? 2.4 : 1.6)}rem;
  /* align inside */
  height: ${(p) => (p.isMobile ? 2.4 : 1.6)}rem;
  margin-left: ${(p) => (p.isMobile ? p.theme.indent.XXS : 0)};
  background-color: ${(p) => p.theme.color.red};
  border-radius: ${(p) => p.theme.borderRadius.XXXL};
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.SM : p.theme.fontSize.XXXS)};
  color: ${(p) => p.theme.color.white};
`

export const Wrapper = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
