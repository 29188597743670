import { getFunctions, httpsCallable } from 'firebase/functions'
import { PropertyType } from '@constants/types'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const createProperty = async (
  data: PropertyType,
): Promise<{ result: boolean; e?: unknown }> => {
  const functions = getFunctions()
  const storeProperty = httpsCallable(functions, FUNC_NAMES.STORE_PROPERTY)
  try {
    await storeProperty(data)
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}
