import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { usePagination, useSortBy, useTable, useGlobalFilter } from 'react-table'
import { useResizeDetector } from 'react-resize-detector'
import { useMobile } from '@utils/hooks'
import {
  Container,
  TableBody,
  TableContainer,
  TableHead,
  Td,
  Tr,
  CellWrapper,
  NoItem,
  WithPaginationContainer,
} from './Table.style'
import Pagination from './Pagination'
import { TableTypes, TabsType } from '@constants/types'
import Title from './Title'
import TableHeadColumn from './TableHeadColumn'
import { mapColumns, filterTableData } from './Table.utils'
import { Loader } from '@atoms'

const Table = ({
  columns,
  data = [],
  title,
  onCancel,
  onConfirm,
  defaultValue,
  dropdownOptions,
  isTableModal,
  selectedValue,
  callback,
  sortByDropDown,
  typeUser,
  user,
  setAddUser,
  bigRow,
  isTableVisit,
  changeSort,
  loaded = true,
  changePage,
  noItemHeight = '',
  addButtonDisabled = false,
  defaultSorting = [],
  isListOfAgents,
}: TableTypes): JSX.Element => {
  const isMobile = useMobile()
  const { width, ref } = useResizeDetector()
  const memoData = useMemo(() => data, [data])
  const memoColumns = useMemo(() => mapColumns(columns, width), [columns, width])
  const [filter, setFilter] = useState<TabsType>()
  const [filteredData, setFilteredData] = useState(memoData)

  const isHorizontalCenteredCell = useCallback(
    (rowLength: number, currentIndex: number): boolean =>
      !!isListOfAgents && !isMobile && rowLength - 1 === currentIndex,
    [isListOfAgents, isMobile],
  )

  useEffect(() => {
    setFilteredData(memoData)
  }, [memoData])

  useEffect(() => {
    if (filter) {
      setFilteredData(filterTableData(memoData, filter))
    }
  }, [filter, memoData])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    setGlobalFilter,
    setSortBy,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = useTable(
    {
      columns: memoColumns,
      data: filteredData,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ['hiddenEmail', 'createdAt', 'hiddenPhoneNumber'],
        sortBy: defaultSorting,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  useEffect(() => {
    if (sortByDropDown) {
      return setSortBy(sortByDropDown)
    }
    return setSortBy(sortBy)
  }, [setSortBy, sortByDropDown, sortBy])

  return (
    <Container isMobile={isMobile} isTableModal={isTableModal}>
      <Title
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        title={title}
        onCancel={onCancel}
        onConfirm={onConfirm}
        defaultValue={defaultValue}
        dropdownOptions={dropdownOptions}
        callback={callback}
        selectedValue={selectedValue}
        user={user}
        setAddUser={setAddUser}
        setFilter={setFilter}
        addButtonDisabled={addButtonDisabled}
      />
      <WithPaginationContainer>
        <TableContainer
          isTableModal={isTableModal}
          isTableVisit={isTableVisit}
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map(({ getHeaderGroupProps, headers }, i) => (
              <Tr
                {...getHeaderGroupProps()}
                ref={ref}
                key={i}
                isTableModal={isTableModal}
                typeUser={typeUser}
                bigRow={bigRow}
              >
                {headers && (
                  <TableHeadColumn
                    changeSort={changeSort}
                    headers={headers}
                    visibleColumnsNumber={visibleColumns.length}
                    typeUser={typeUser}
                  />
                )}
              </Tr>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            <Loader isTable loaded={loaded}>
              {page.length > 0 ? (
                page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <Tr
                      {...row.getRowProps()}
                      key={i}
                      isTableModal={isTableModal}
                      typeUser={typeUser}
                      bigRow={bigRow}
                    >
                      {row.cells.map((cell, ii) => {
                        return (
                          <Td
                            {...cell.getCellProps({ width: cell.column.width.toString() })}
                            key={ii}
                          >
                            <CellWrapper
                              isHorizontalCentered={isHorizontalCenteredCell(row.cells.length, ii)}
                            >
                              {cell.render('Cell')}
                            </CellWrapper>
                          </Td>
                        )
                      })}
                    </Tr>
                  )
                })
              ) : (
                <NoItem noItemHeight={noItemHeight}>
                  <td>
                    No {typeUser === 'property' ? 'properties' : `${typeUser}s`} have been found
                  </td>
                </NoItem>
              )}
            </Loader>
          </TableBody>
        </TableContainer>
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          gotoPage={gotoPage}
          pageOptions={pageOptions}
          setPageSize={setPageSize}
          state={{ pageIndex, pageSize }}
          changePage={changePage}
        />
      </WithPaginationContainer>
    </Container>
  )
}

export default Table
