import styled from 'styled-components'
import { Typography } from '@atoms'

export const CardTitle = styled.div`
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadius.SM};
  background-color: ${(p) => p.theme.color.greyBlue};
  margin-bottom: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(p) => `${p.theme.indent.XXS} ${p.theme.indent.SM}`};
`

export const PhoneLink = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`

export const CardContainer = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.indent.SM};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  background-color: ${(p) => p.theme.color.white};
`

export const Body2 = styled(Typography.Body2)`
  padding-top: ${(p) => (p.padding ? p.theme.indent.SM : 0)};
  padding-left: ${(p) => (p.gutter ? p.theme.indent.XS : 0)};
`
