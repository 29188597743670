import { SellerList, SellerProperty, SellerVisits } from '@organisms'
import { SellerSlots } from '@templates'

export const sellerTabsConfig = [
  {
    name: 'Property',
    path: '/property',
    component: SellerProperty,
    exact: true,
  },
  {
    name: 'Slots',
    path: '/slots',
    component: SellerSlots,
  },
  {
    name: 'Visits',
    path: '/visits',
    component: SellerVisits,
  },
  {
    name: 'Statistics',
    path: '/statistics',
    component: SellerList /* to add seller components */,
  },
]
