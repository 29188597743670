import styled from 'styled-components'
import { Typography } from '@atoms'

export const TimeSlotButtonContainer = styled.div`
  border: 0.1rem solid ${(p) => p.theme.color.grey};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  padding: ${({ theme }) => theme.indent.XS};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex-grow: 1;
`
export const TimeSlotLabel = styled(Typography.H3)`
  color: ${(p) => p.theme.color.darkBlack};
`
