import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input } from '@molecules'

import { InputFormProps } from '@constants/types/helpers'

const InputForm: React.FC<InputFormProps> = ({
  name,
  placeholder,
  errorMessage,
  inputLabel,
  error,
  disabled,
  type,
  isPassword,
  maxLength,
  isNoSpace,
  isPhone,
  isPrice,
  tabIndex,
  isName,
  isPropertyTitle,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Input
          {...field}
          error={error}
          inputLabel={inputLabel}
          onChange={field.onChange}
          errorMessage={errorMessage}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          isPassword={isPassword}
          maxLength={maxLength}
          isNoSpace={isNoSpace}
          isPhone={isPhone}
          isPrice={isPrice}
          tabIndex={tabIndex}
          isName={isName}
          isPropertyTitle={isPropertyTitle}
        />
      )}
    />
  )
}
export default InputForm
