import { Typography } from '@atoms'
import { Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'

interface HeaderControlsProps {
  isMobile: boolean
  isTabletPortrait: boolean
}

export const HeaderContainer = styled.div<{ isMobile: boolean; isTabletPortrait: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.$loading ? 'center' : 'space-between')};
  align-items: center;
  background: ${({ theme }) => theme.color.darkBlue};
  width: 100%;
  height: 6.4rem;
  padding: 0
    ${(p) => {
      if (p.isMobile) {
        return p.theme.indent.LG
      }
      if (p.isTabletPortrait) {
        return p.theme.indent.XXXL
      }
      return '5rem'
    }};
`

export const HeaderControls = styled.div<HeaderControlsProps>`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
  margin-right: ${(p) => p.theme.indent.XS};
`

export const Menu = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.indent.XXXL};
  margin-right: ${({ theme }) => theme.indent.XL};
`

export const ProfileButtonContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`

export const ProfilePicture = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: ${(p) => p.theme.indent.XS};
`

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-right: ${(p) => p.theme.indent.XS};
  height: 4rem;
  width: 4rem;
  border: 0.2rem solid ${(p) => p.theme.color.lightGrey};
`

export const ToggleMenuContainer = styled.div`
  position: absolute;
  top: calc(100% + 1.2rem);
  right: 0;
  width: 24.5rem;
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  box-shadow: 0 0.4rem 1rem ${(p) => p.theme.color.black_20};
  z-index: 2;

  ::before {
    content: '';
    border-style: solid;
    border-width: 0 0.8rem 1rem 0.8rem;
    border-color: transparent transparent ${(p) => p.theme.color.white} transparent;
    display: inline-block;
    position: absolute;
    top: -1rem;
    right: 3.1rem;
  }
`

export const StyledRow = styled(Row)`
  padding: ${(p) => `${p.theme.indent.MD} ${p.theme.indent.LG}`};
`

export const Body2 = styled(Typography.Body2)`
  padding-left: ${(p) => p.theme.indent.MD};
`

export const HR = styled(Row)`
  border-top: 0.1rem solid ${(p) => p.theme.color.lightGrey};
`

export const LinkContainer = styled.div.attrs({ activeClassName: 'active' })<{ isMobile: boolean }>`
  ${({ isMobile }) => (isMobile ? 'position: relative' : '')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.indent.XS};
  border-bottom: ${({ theme, isMobile }) => (isMobile ? `0.1rem solid ${theme.color.white}` : '')};
  width: ${({ isMobile }) => (isMobile ? 'calc(100vw - 4rem)' : '')};
  height: ${({ isMobile }) => (isMobile ? '8.8rem' : '')};
  cursor: pointer;
  &:last-child {
    border-bottom: 0;
  }
`

export const BurgerMenuContainer = styled.div`
  display: flex;
  padding-top: 8rem;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  background-color: ${({ theme }) => theme.color.darkBlue_90};
`

export const CloseMenuContainer = styled.div<{ isMobile: boolean; marginNone: boolean }>`
  position: absolute;
  top: 2rem;
  right: 3rem;
`
