import '@utils/setup'
import React, { StrictMode } from 'react'
import { hot, setConfig } from 'react-hot-loader'
import { ThemeProvider } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { GlobalStyle, gridConfig } from '@styles'
import MAIN_THEME from '@theme/mainTheme'
import RootPages from '@routes'

// https://github.com/gaearon/react-hot-loader#react--dom
// Config - https://github.com/gaearon/react-hot-loader/blob/master/index.d.ts
setConfig({
  showReactDomPatchNotification: false,
  ErrorOverlay: () => null,
})

const App = (): React.Component<Record<string, unknown>, undefined> => (
  <StrictMode>
    <ThemeProvider theme={MAIN_THEME}>
      <GridThemeProvider gridTheme={gridConfig}>
        <GlobalStyle />
        <RootPages />
      </GridThemeProvider>
    </ThemeProvider>
  </StrictMode>
)

declare let module: Record<string, unknown>

export default hot(module)(App)
