import React, { Dispatch, useState } from 'react'
import { useMobile } from '@utils/hooks'
import { Icon, Typography } from '@atoms'
import { useTheme } from 'styled-components'
import { CollapseContainer, CollapseLabel, ContainerLabel, Container } from './Collapse.styles'

import { CollapseProps } from '@constants/types/molecules'

const Collapse: React.FC<CollapseProps> = ({
  label = '',
  labelCollapse = `Collapse `,
  labelExpand = `Expand `,
  collapsed = false,
  iconNameCollapsed = 'arrow-up',
  iconNameExpand = 'arrow-down',
  children,
}) => {
  const isMobile = useMobile()
  const color = useTheme().color.originalBlue
  const [isCollapsed, setCollapsed] = useState<boolean>(collapsed)

  const handleClick = (): Dispatch<boolean> => setCollapsed(!isCollapsed)

  return (
    <CollapseContainer isMobile={isMobile}>
      <ContainerLabel isMobile={isMobile}>
        <Typography.Caption2 isMobile={isMobile}> {label}</Typography.Caption2>
        <Container onClick={handleClick}>
          <CollapseLabel isMobile={isMobile}>
            {isCollapsed ? labelExpand : labelCollapse}
          </CollapseLabel>
          <Icon
            icon={isCollapsed ? iconNameCollapsed : iconNameExpand}
            color={color}
            size="1.4rem"
          />
        </Container>
      </ContainerLabel>

      {!isCollapsed && children}
    </CollapseContainer>
  )
}

export default Collapse
