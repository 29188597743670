import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '@atoms'

export const Container = styled.div`
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
  margin-bottom: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
`
export const NoSlotsImage = styled.img`
  display: flex;
  margin: auto;
  height: ${(p) => (p.isMobile ? '18rem' : '25rem')};
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.XL : '6rem')};
  margin-bottom: 0;
`
export const TextWrapper = styled.div`
  margin-top: ${(p) => p.theme.indent.SM};
  margin-bottom: ${(p) => p.theme.indent.XL};
`
export const LinkContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const H1 = styled(Typography.H1)`
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.XL : '5rem')};
  padding-bottom: ${(p) => (p.bottomPadding ? p.theme.indent.XS : '0')};
`
