import { FIXED_INPUT_PHONE_NUMBERS, MIN_NAME_SYMBOLS } from '@constants/dictionary'
import { onlyLatin, withoutCyrillicRegExp } from '@constants/regExp'
import * as yup from 'yup'

export const requiredRule = (): string => yup.string().trim().required()
export const notRequiredRule = (): string => yup.string().trim().notRequired()

export const schema = yup.object().shape(
  {
    email: requiredRule().email().matches(withoutCyrillicRegExp),
    additionalEmail: notRequiredRule().email().matches(withoutCyrillicRegExp),
    fullName: requiredRule()
      .matches(withoutCyrillicRegExp)
      .min(MIN_NAME_SYMBOLS)
      .matches(onlyLatin),
    additionalFullName: notRequiredRule()
      .matches(withoutCyrillicRegExp)
      .when('additionalFullName', {
        is: (value: string) => value?.length,
        then: (rule: string) => rule.min(MIN_NAME_SYMBOLS).matches(onlyLatin),
      }),
    phone: requiredRule().min(FIXED_INPUT_PHONE_NUMBERS),
    additionalPhone: notRequiredRule().when('additionalPhone', {
      is: (value: string) => value?.length,
      then: (rule: string) => rule.min(FIXED_INPUT_PHONE_NUMBERS),
    }),
  },
  [
    ['additionalFullName', 'additionalFullName'],
    ['additionalPhone', 'additionalPhone'],
  ],
)
