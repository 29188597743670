import React from 'react'
import { Button } from '@molecules'
import { useMobile } from '@utils/hooks'
import confirmation from '@assets/imagesMock/Confirmation.png'
import {
  RowContainer,
  Container,
  ButtonContainer,
  BackgroundContainer,
  TopContainer,
  ConfirmedImage,
  H1,
  CompletedImg,
} from './CompletedPage.style'

type CompletedPageProps = {
  route: string
  header: string
  confirmed?: boolean
}

const CompletedPage: React.FC<CompletedPageProps> = ({ route, header, confirmed }): JSX.Element => {
  const isMobile = useMobile()

  return (
    <BackgroundContainer confirmed={confirmed}>
      <TopContainer xs={12} sm={12} md={8} lg={7} xl={7} xxl={6} noGutter isMobile={isMobile}>
        <Container>
          <RowContainer>
            {confirmed ? (
              <ConfirmedImage isMobile={isMobile} src={confirmation} />
            ) : (
              <CompletedImg src={confirmation} alt="confirmation" />
            )}
          </RowContainer>
          <RowContainer>
            <H1>{header}</H1>
          </RowContainer>
        </Container>
        <ButtonContainer
          to={{
            pathname: route,
          }}
        >
          <Button.Primary label="OK" />
        </ButtonContainer>
      </TopContainer>
    </BackgroundContainer>
  )
}

export default CompletedPage
