import {
  confirmPasswordReset,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  updatePassword,
} from 'firebase/auth'

const auth = getAuth()
export const forgotPassword = async (email: string): { result: boolean } => {
  try {
    await sendPasswordResetEmail(auth, email)
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}

export const verifyResetCode = async (oobCode: string): { result: boolean } => {
  try {
    await verifyPasswordResetCode(auth, oobCode)
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}

export const confirmPassword = async (
  oobCode: string,
  newPassword: string,
): { result: boolean } => {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword)
    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}

export const verifyOldPassword = async (oldPassword: string): Promise<{ result: boolean }> => {
  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(user?.email, oldPassword)
  return await reauthenticateWithCredential(user, credential)
    .then(() => {
      return { result: true }
    })
    .catch((error) => {
      throw error
    })
}

export const setNewPassword = async (newPassword: string): Promise<{ result: boolean }> => {
  const user = auth.currentUser

  return await updatePassword(user, newPassword)
    .then(() => {
      return { result: true }
    })
    .catch((error) => {
      throw error
    })
}
