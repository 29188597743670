import React from 'react'
import { useMobile } from '@utils/hooks'
import { URGENT_MATTERS } from '@constants/dictionary'
import ConfirmImg from '@assets/images/UMConfirmImg.svg'
import { CommentArea } from '@atoms'
import { ConfirmImage, ConfirmH2 } from './UrgentMatters.styled'

const Confirm: React.FC = ({
  onChange,
  value,
}: {
  onChange: () => void
  value: string
}): JSX.Element => {
  const isMobile = useMobile()
  return (
    <>
      <ConfirmImage isMobile={isMobile} src={ConfirmImg} />
      <ConfirmH2 isMobile={isMobile}>{URGENT_MATTERS.CONFIRM.HEADER}</ConfirmH2>
      <CommentArea
        name="comment"
        placeholder={URGENT_MATTERS.CONFIRM.TEXTAREA_PLACEHOLDER}
        onChange={onChange}
        value={value}
      />
    </>
  )
}

export default Confirm
