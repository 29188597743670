import styled from 'styled-components'
import { Typography } from '@atoms'
import { Link } from 'react-router-dom'

export const CancelVisitImage = styled.img`
  display: flex;
  margin: auto;
  height: ${(p) => (p.isMobile ? '18rem' : '25rem')};
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXL)};
  padding: ${(p) => p.isMobile && `0 ${p.theme.indent.XL}`};
  margin-bottom: 0;
`

export const H2 = styled(Typography.H2)`
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.XL : p.theme.indent.XXXL)};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 4.4rem);
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
`

export const LinkContainer = styled(Link)`
  text-decoration: none;
  justify-content: center;
  align-items: center;
`

export const StyledRow = styled.div`
  padding-bottom: ${(p) => p.theme.indent.SM};
`
