import React from 'react'
import { useMobile } from '@utils/hooks'
import { format } from 'date-fns'
import { Typography } from '@atoms'
import PeriodButtons from '../PeriodButtons'
import { ContainerList, TitleContainer } from './TimePeriod.style'
import { TimePeriodProps } from '@constants/types/molecules'

const TimePeriod: React.FC<TimePeriodProps> = ({ slot, id }) => {
  const isMobile = useMobile()
  const period = `${format(slot.fromDateTime, 'H:mm')} - ${format(slot.toDateTime, 'H:mm')}`
  return (
    <ContainerList isMobile={isMobile}>
      <TitleContainer>
        <Typography.Body2 isMobile={isMobile}>Time period #{id + 1}</Typography.Body2>
      </TitleContainer>
      <PeriodButtons selectTime={period} />
    </ContainerList>
  )
}

export default TimePeriod
