import styled from 'styled-components'

export const Container = styled.div<{ isMobile: boolean; isDesktop: boolean }>`
  padding-top: ${({ theme }) => theme.indent.LG};
`

export const FormContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => !isMobile && 'max-width: 36rem '};
`

export const ButtonRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  column-gap: ${({ theme }) => theme.indent.MD};
  ${({ isMobile }) => !isMobile && 'max-width: 30rem'};
`

export const ErrorContainer = styled.div`
  padding-top: ${({ theme }) => theme.indent.MD};
  width: 27rem;
`

export const DeleteContainer = styled.div`
  display: inline-flex;
  margin-top: ${({ theme }) => theme.indent.XXXL};
  p:first-of-type {
    padding-left: ${(p) => p.theme.indent.XS};
  }
  cursor: pointer;
`
