import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMountEffect } from '@utils/hooks'
import { getPropertyProfile, getSellerProfile } from '@services/firebase'
import { Loader } from '@atoms'
import AgentAddPropertyPage from '../AgentAddPropertyPage'

const EditProperty = (): JSX.Element => {
  const { id }: { id: string } = useParams()
  const [propertyId, propertyProfileId, sellerId] = id.split('-')

  const [propertyProfile, setPropertyProfile] = useState(undefined)
  const [sellerProfile, setSellerProfile] = useState(undefined)
  const status = sellerProfile ? propertyProfile.status : ''
  const loadData = async (): Promise<undefined> => {
    const propertyDetails = await getPropertyProfile(propertyId)
    const sellerProfile = await getSellerProfile(sellerId)
    Promise.all([propertyDetails, sellerProfile]).then(() => {
      setPropertyProfile(propertyDetails)
      setSellerProfile(sellerProfile)
    })
  }
  useMountEffect(loadData)

  return (
    <Loader loaded={!!propertyProfile && !!sellerProfile}>
      <AgentAddPropertyPage
        isEditProperty={true}
        propertyId={propertyId}
        propertyProfileId={propertyProfileId}
        propertyProfile={propertyProfile}
        sellerProfile={sellerProfile}
        status={status}
      />
    </Loader>
  )
}

export default EditProperty
