import styled from 'styled-components'

export const CheckboxLabel = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`
export const CheckboxContainer = styled.div`
  border: ${({ theme, checked, fill }) => {
    if (checked && fill) return 'none'
    if (checked && !fill) return `0.1rem solid ${theme.color.grey};`
    return `0.1rem solid ${theme.color.grey};`
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  cursor: pointer;
  width: ${(p) => (p.size ? `${p.size}rem` : '2.4rem')};
  height: ${(p) => (p.size ? `${p.size}rem` : '2.4rem')};
  ${(props) => props.disabled && 'pointer-events: none;'}

  background-color: ${({ theme, disabled, fill, checked }) => {
    if (disabled) return `${theme.color.lightGrey}`
    if (checked && fill) return ` ${theme.color.originalBlue}`
    return `transparent`
  }};
`
