import React from 'react'
import { LinkContainer } from './AgentHeader.style'
import { useMobile } from '@utils/hooks'
import { Typography, Counter } from '@atoms'

type MenuLinkProps = {
  active?: boolean
  count?: number
  onClick?: () => void
  children?: string | JSX.Element
}

const MenuLink: React.FC<MenuLinkProps> = ({ count, children, active, onClick }) => {
  const isMobile = useMobile()

  return (
    <LinkContainer isMobile={isMobile} onClick={onClick}>
      {isMobile ? (
        <Typography.Caption3 color="white">{children}</Typography.Caption3>
      ) : (
        <Typography.Body2 color={active ? 'originalBlue' : 'white'}>{children}</Typography.Body2>
      )}
      {!!count && <Counter count={count} />}
    </LinkContainer>
  )
}

export default MenuLink
