const INDENTS = {
  XXXS: 0,
  XXS: 0.4,
  XS: 0.8,
  SM: 1.2,
  MD: 1.6,
  LG: 2,
  XL: 2.4,
  XXL: 2.8,
  XXXL: 3.2,
}

export default INDENTS
