import { getFunctions, httpsCallable } from 'firebase/functions'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const sendPropertyLink = async (
  propertyId: string,
  buyerId: string,
  propertyProfileId: string,
): Promise<boolean> => {
  try {
    const functions = getFunctions()
    const sendLink = httpsCallable(functions, FUNC_NAMES.SEND_PROPERTY_LINK)
    await sendLink({ propertyId, buyerId, propertyProfileId })
    return true
  } catch (e) {
    return false
  }
}
