import React, { useState, useCallback, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useFirebaseAuth, useMobile, useTabletPortrait } from '@utils/hooks'
import { confirmPassword } from '@services/firebase'
import confirmation from '@assets/imagesMock/Confirmation.png'
import {
  ROUTES,
  ERROR_PASS,
  LOGIN,
  MIN_SYMBOLS,
  LS_CONTSTANTS,
  USER_TYPES,
} from '@constants/dictionary'
import { Loader, Icon } from '@atoms'
import { BackToLoginHeader } from '@organisms'
import { Button } from '@molecules'
import bgDesctop from '@assets/images/bg-land-desctop.png'
import bgTablet from '@assets/images/bg-land-tablet.png'
import bgMobile from '@assets/images/bg-land-mobile.png'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordInputForm } from '@helpers'
import {
  Container,
  StyledCol,
  FormContainer,
  H2,
  FieldsContainer,
  BottomContainer,
  Form,
  TextInfo,
  ImageBlock,
} from './AgentNewPassword.style'
import { schema } from './AgentNewPasswordValidation'
import AgentInspiredLinkPage from '../AgentInspiredLinkPage'

const AgentNewPassword = (): JSX.Element => {
  const isMobile = useMobile()
  const [loader, setLoader] = useState(false)
  const [successfully, setSuccessfully] = useState(false)

  const isTabletPortrait = useTabletPortrait()
  const [logedIn, loaded] = useFirebaseAuth()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = methods

  const newPass = watch('newPassword')
  const confirmPass = watch('confirmPassword')

  const path = (): string => {
    if (isTabletPortrait) {
      return bgTablet
    }
    if (isMobile) {
      return bgMobile
    }
    return bgDesctop
  }
  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const getErrorMessage = useCallback(
    (field) => {
      if (errors) {
        if (errors[field]) {
          return errors[field].message
        }
      }
      return ''
    },
    [errors],
  )
  const oobCode = localStorage.getItem(LS_CONTSTANTS.oobCode)
  const status = localStorage.getItem(LS_CONTSTANTS.err_new_pass)

  const onSubmit = useCallback(async (): Promise<undefined> => {
    if (newPass !== confirmPass) {
      setError('confirmPassword', { message: 'Not match' })
    } else {
      setLoader(true)

      const resultSuccess = await confirmPassword(oobCode, newPass)
      setLoader(false)
      if (resultSuccess) {
        setSuccessfully(true)
      }
    }
  }, [confirmPass, newPass, oobCode, setError])

  useEffect(() => {
    const listener = (event): (() => void) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(onSubmit)()
      }
    }

    document.addEventListener('keyup', listener)
    return () => {
      document.removeEventListener('keyup', listener)
    }
  }, [handleSubmit, onSubmit])

  if (status === ERROR_PASS.EXPIRED_CODE) {
    return <AgentInspiredLinkPage />
  }

  const isDisable = newPass.length >= MIN_SYMBOLS && confirmPass.length >= MIN_SYMBOLS
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)

  return (
    <Loader loaded={loaded}>
      {logedIn && (userType === USER_TYPES.agent || userType === USER_TYPES.agency) ? (
        <Redirect to={ROUTES.AGENT} />
      ) : (
        <Container path={path}>
          <StyledCol md={12} lg={6} xl={6} xxl={6}>
            <FormContainer isMobile={isMobile}>
              <Icon icon="logo" clickable size="20rem" color="white" />
              <FieldsContainer isMobile={isMobile}>
                <BackToLoginHeader />
                {status === ERROR_PASS.INVALID_CODE || successfully ? (
                  <>
                    <H2 isMobile={isMobile}>{LOGIN.SUCCESSFUL_CHANGE_PASSWORD}</H2>
                    <ImageBlock>
                      <img src={confirmation} alt="confirmation" width="29%" />
                    </ImageBlock>
                    <BottomContainer isMobile={isMobile} />
                  </>
                ) : (
                  <>
                    <H2 isMobile={isMobile}>Create new password</H2>
                    <TextInfo>{LOGIN.PASSWORDS_RULE}</TextInfo>
                    <FormProvider {...methods}>
                      <Form autoComplete="off">
                        <PasswordInputForm
                          disabled={loader}
                          placeholder="Enter new password "
                          inputLabel="Password"
                          errorMessage={getErrorMessage('newPassword')}
                          name="newPassword"
                          error={hasError('newPassword')}
                          isNoSpace
                        />
                        <PasswordInputForm
                          disabled={loader}
                          placeholder="Repeat your password"
                          inputLabel="Confirm Password"
                          errorMessage={getErrorMessage('confirmPassword')}
                          name="confirmPassword"
                          error={hasError('confirmPassword')}
                          withOutIcon
                          isNoSpace
                        />
                      </Form>
                    </FormProvider>
                    <Button.Primary
                      loader={loader}
                      label="Change password"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isDisable}
                    />
                    <BottomContainer isMobile={isMobile} />
                  </>
                )}
              </FieldsContainer>
            </FormContainer>
          </StyledCol>
        </Container>
      )}
    </Loader>
  )
}

export default AgentNewPassword
