export const SWITCH_DAY = {
  TAP_ON_DAY: 'tapOnDay',
  TO_PREVIOUS_DAY: 'toPreviousDay',
  TO_NEXT_DAY: 'toNextDay',
}

export const TIME_PERIOD = {
  ADD_FROM_TIME: 'addFromTime',
  MINUS_FROM_TIME: 'minusFromTime',
  ADD_UNTIL_TIME: 'addUntilTime',
  MINUS_UNTIL_TIME: 'minusUntilTime',
  SET_FROM_TIME: 'setFromTime',
  SET_UNTIL_TIME: 'setUntilTime',
}
