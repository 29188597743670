import React, { Dispatch, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDesktop, useMobile } from '@utils/hooks'
import { URGENT_MATTERS, ROUTES } from '@constants/dictionary'
import { cancelDayVisits } from '@services/firebase'
import { Button } from '@molecules'
import { Loader } from '@atoms'
import { Container, ButtonRow, StyledCol1, StyledCol2 } from './UrgentMatters.styled'
import { calculateOnCheck, calculateOnAll } from './UrgentMatters.utils'
import Confirm from './Confirm'
import DayChoose from './DayChoose'
import CompletedPage from '../CompletedPage'

const UrgentMatters = (): JSX.Element => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const history = useHistory()
  const [step, setStep] = useState<number>(0)
  const [checkedList, setCheckedList] = useState<Array<string>>([])
  const [text, setText] = useState<string>('')
  const [loading, setLoading] = useState<string>(false)
  const onClickBack = (): undefined => history.push(ROUTES.SELLER_CANCEL_VISIT)
  const addStep = (): Dispatch<number> => {
    setStep(step + 1)
  }
  const minusStep = (): Dispatch<number> => {
    setStep(step - 1)
  }

  const onCheck = (value, allChecked): Dispatch<Array<string>> => {
    let list = []
    if (allChecked) {
      list = calculateOnAll(value, checkedList)
    } else {
      list = calculateOnCheck(value, checkedList)
    }
    // [...value] to triger rerender
    return setCheckedList([...list])
  }

  const onChange = (value): Dispatch<string> => {
    setText(value)
  }

  const onConfirm = async (): Promise => {
    setLoading(true)
    await cancelDayVisits(checkedList, text)
    setLoading(false)
  }

  const PAGE_ONE = 1
  const PAGE_TWO = 2
  const pageSwitch = (page: number): React.FC => {
    switch (page) {
      case PAGE_ONE:
        return <Confirm onChange={onChange} value={text} />
      case PAGE_TWO:
        return (
          <Loader loaded={!loading}>
            <CompletedPage
              route={ROUTES.SELLER_SLOTS}
              header={URGENT_MATTERS.CONFIRMED.HEADER}
              confirmed
            />
          </Loader>
        )
      default:
        return <DayChoose onCheck={onCheck} />
    }
  }

  return (
    <Container isMobile={isMobile}>
      {pageSwitch(step)}
      {step !== PAGE_TWO && (
        <ButtonRow isMobile={isMobile} isDesktop={isDesktop}>
          <StyledCol1 col={6}>
            <Button.Secondary
              label="Back"
              onClick={() => (step === 0 ? onClickBack() : minusStep())}
            />
          </StyledCol1>
          <StyledCol2 col={6}>
            <Button.Primary
              label={step === 1 ? 'Confirm' : 'Next'}
              onClick={() => {
                if (step === 1) {
                  onConfirm()
                }
                addStep()
              }}
              disabled={!checkedList.length}
            />
          </StyledCol2>
        </ButtonRow>
      )}
    </Container>
  )
}

export default UrgentMatters
