/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState, useCallback } from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { parse } from 'qs'
import { authByToken } from '@services/firebase'
import { useFirebaseAuth } from '@utils/hooks'
import { Loader } from '@atoms'
import { UserLayout } from '@templates'
import { ROUTES, USER_TYPES, LS_CONTSTANTS } from '@constants/dictionary'
import { getRedirectLinkSeller, getRedirectLinkBuyer, setAdditionalProps } from '@utils/functions'
import { PrivacyPolicyModal } from '@modals'
import SellerRoutes from './SellerPages.routes'
import BuyerRoutes from './BuyerPages.routes'

const SellerPages = (): JSX.Element => {
  const [loginLoaded, setLoginLoaded] = useState<boolean>(false)
  const [policy, setPolicy] = useState<boolean>(false)
  const [routes, setRoutes] = useState<array>([])
  const [redirectLink, setRedirectLink] = useState<string>('')
  const [logedIn, loaded] = useFirebaseAuth()
  const { push } = useHistory()
  // get query params
  const location = useLocation()
  const {
    token = '',
    agentId = '',
    propertyId = '',
    visitId = '',
  } = parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const userId = localStorage.getItem(LS_CONTSTANTS.uid)

  const setRedirectValues = (): undefined => {
    const userType = localStorage.getItem(LS_CONTSTANTS.userType)
    switch (userType) {
      case USER_TYPES.buyer:
        localStorage.setItem(LS_CONTSTANTS.redirectLink, ROUTES.BUYER)
        setRedirectLink(getRedirectLinkBuyer(location.pathname))
        setRoutes(BuyerRoutes)
        break
      case USER_TYPES.seller:
        localStorage.setItem(LS_CONTSTANTS.redirectLink, ROUTES.SELLER)
        setRedirectLink(getRedirectLinkSeller(location.pathname))
        setRoutes(SellerRoutes)
        break
      default:
        localStorage.setItem(LS_CONTSTANTS.redirectLink, '/')
        push(ROUTES.ERROR)
        break
    }
  }

  const login = useCallback(async () => {
    await authByToken(token)
    if (!!localStorage.getItem(LS_CONTSTANTS.error)) {
      setRedirectLink(ROUTES.ERROR)
    } else {
      setRedirectValues()
      setAdditionalProps({ agentId, propertyId, visitId })
    }
    setLoginLoaded(true)
  }, [])

  const handleSetPolicy = useCallback((isPolicy) => setPolicy(isPolicy))

  useEffect(() => {
    if (loaded && !logedIn && !loginLoaded) {
      login()
    }
    if (loaded && logedIn) {
      if (!!token) {
        return login()
      }
      setRedirectValues()
      setLoginLoaded(true)
    }
  }, [loaded, logedIn, login])

  return (
    <>
      <UserLayout>
        <Loader loaded={loaded && loginLoaded}>
          {logedIn && !!redirectLink && loginLoaded ? (
            <Switch>
              {routes.map(({ id, ...props }) => (
                <Route key={id} {...props} />
              ))}
              <Redirect to={redirectLink} />
            </Switch>
          ) : (
            <Redirect to={ROUTES.ERROR} />
          )}
        </Loader>
      </UserLayout>
      {!!userId && <PrivacyPolicyModal policy={policy} setIsPolicy={handleSetPolicy} />}
    </>
  )
}

export default SellerPages
