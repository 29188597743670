import React, { useCallback, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import {
  getAgentPropertyVisits,
  getPropertyBuyerTimeSlots,
  getPropertyProfileById,
  getReviewById,
  getSellerProfile,
} from '@services/firebase'
import { getPropertyVisits } from '@utils/functions'
import { useDesktop, useMobile, useTabletPortrait, useModal } from '@utils/hooks'
import { getDatesSorting, getStatusSorting } from '@utils/sorting'
import {
  ContactsType,
  DataVisitType,
  ModalBodyTypes,
  TableRowType,
  VsitInfoCardType,
} from '@constants/types'
import { Table } from '@organisms'
import { Icon, Loader, SimpleRatingStars } from '@atoms'
import InfoModalTemplates from './InfoModalTemplates'
import {
  EmailPhone,
  IconContainer,
  Container,
  InfoContainer,
  Cell,
  LinkTo,
} from './AgentVisitInfoCard.style'
import { BOOKING_STATUSES, LS_CONTSTANTS, SEND_MODAL } from '@constants/dictionary'
import {
  defaultModalBody,
  defaultContacts,
  defaultData,
  MESSAGE_TYPE,
} from '../VisitInfo/VisitInfo.constants'
import { SendModal, StatusModal, TimeSlotsModal } from '@modals'
import SendLink from '../VisitInfo/SendLink'

const AgentVisitInfoCard: React.FC = () => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTablet = useTabletPortrait()

  const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)
  const visitsCol = propertyId && getAgentPropertyVisits(propertyId)
  const [visitsData, loadingVisitsData] = useCollection(visitsCol)
  const visits = loadingVisitsData ? [] : getPropertyVisits(visitsData)
  const { isShown, toggle } = useModal()
  const [infoType, setInfoType] = useState<string>('')
  const [loaded, setLoaded] = useState(true)
  const [loadedModal, setLoadedModal] = useState(true)

  const [modalBody, setModalBody] = useState<ModalBodyTypes>(defaultModalBody)
  const toggleInfoModal = useCallback(
    async (type: string, visitId: string): undefined => {
      if (type === 'Rated') {
        setLoadedModal(false)
        setInfoType(type)
        toggle()
        const reviewDoc = await getReviewById(visitId)
        visits.forEach((visit) => {
          if (visit.visitId === visitId) {
            setModalBody({
              ...visit.modalBody,
              location: reviewDoc?.detailedRatings.location,
              overallCondition: reviewDoc?.detailedRatings.overallCondition,
              price: reviewDoc?.detailedRatings.price,
              valueForMoney: reviewDoc?.detailedRatings.valueForMoney,
              comment: reviewDoc?.comment,
              interestedInProperty: reviewDoc?.interestedInProperty,
              rating: reviewDoc?.rating,
            })
          }
        })
        setLoadedModal(true)
      } else {
        visits.forEach((visit) => {
          if (visit.visitId === visitId) {
            setModalBody(visit.modalBody)
          }
        })
        setInfoType(type)
        toggle()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle],
  )

  const renderIcon = (onClick): JSX.Element => (
    <IconContainer>
      <Icon
        icon={'propertydetails'}
        color="originalBlue"
        size="1.6rem"
        onClick={onClick}
        clickable
      />
    </IconContainer>
  )
  //logic for send reminder
  const [status, setStatus] = useState('')
  const [timeSlots, setTimeSlots] = useState([])
  const [request, setRequest] = useState('')
  const { isShown: isShownModals, toggle: toggleModals } = useModal()
  const [data, setData] = useState<DataVisitType>(defaultData)
  const [contacts, setContacts] = useState<ContactsType>(defaultContacts)

  const onClickResendLink = useCallback(
    async (propertyId, propertyProfileId, buyerName, buyerId) => {
      setLoaded(false)
      const DocPropertyProfile = await getPropertyProfileById(propertyId, propertyProfileId)
      setStatus(DocPropertyProfile.status)
      if (DocPropertyProfile.status === BOOKING_STATUSES.ACTIVE) {
        setData({
          name: buyerName,
          id: buyerId,
          propertyId: propertyId,
          sellerId: DocPropertyProfile.sellerId,
          propertyProfileId: propertyProfileId,
        })
        const visits = await getPropertyBuyerTimeSlots(propertyId)
        if (!!visits.length) {
          setTimeSlots(visits)
          sendRequest(MESSAGE_TYPE.LINK)
        } else {
          const sellerProfile = await getSellerProfile(DocPropertyProfile.sellerId)
          if (!!sellerProfile.length) {
            setContacts({
              name: sellerProfile[0].name,
              phoneNumber: sellerProfile[0].phoneNumber,
              email: sellerProfile[0].email,
            })
          }
        }
        setLoaded(true)
        toggleModals()
      } else {
        setLoaded(true)
        toggleModals()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleModals],
  )

  const sendRequest = (type: string): (() => void) => {
    setRequest(type)
    return toggleModals()
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        percentWidth: 8,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => original.id,
      },
      {
        Header: 'Date',
        accessor: 'date',
        percentWidth: 10,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => original.date,
        sortType: getDatesSorting('date'),
      },
      {
        Header: 'Time',
        accessor: 'time',
        percentWidth: 10,
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => original.time,
      },
      {
        Header: 'Name',
        accessor: 'name',
        percentWidth: 14,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => (
          <Cell>{original.name}</Cell>
        ),
      },
      {
        Header: 'Email/Mobile',
        accessor: 'contact',
        disableSortBy: true,
        percentWidth: 18,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => {
          const contacts = original.contact.split('+')
          const [email, phone] = contacts
          return (
            <EmailPhone>
              <LinkTo isMobile noUnderline href={`mailto:${email}`}>
                {email}
              </LinkTo>
              <div>{`+${phone}`}</div>
            </EmailPhone>
          )
        },
      },
      {
        Header: 'Status/Rating ',
        accessor: 'status',
        percentWidth: 15,
        disableGlobalFilter: true,
        sortType: getStatusSorting('status'),
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => {
          if (Number.isInteger(original.status)) {
            return (
              <InfoContainer>
                <SimpleRatingStars size="1.5rem" count={original.status} />
                {renderIcon(() => toggleInfoModal('Rated', original.visitId))}
              </InfoContainer>
            )
          }

          switch (original.status) {
            case 'Confirmed':
              return (
                <InfoContainer>
                  {original.status}
                  {renderIcon(() => toggleInfoModal('Confirmed', original.visitId))}
                </InfoContainer>
              )
            case 'Canceled':
              return (
                <InfoContainer>
                  {original.status}
                  {renderIcon(() => toggleInfoModal('Cancelled', original.visitId))}
                </InfoContainer>
              )
            default:
              return original.status
          }
        },
      },
      {
        Header: 'Short list',
        accessor: 'shortList',
        sortInverted: true,
        percentWidth: 10,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) =>
          original.shortList ? 'YES' : 'NO',
      },
      {
        Header: '',
        accessor: 'link',
        percentWidth: 15,
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<VsitInfoCardType>) => (
          <SendLink
            buyerName={original?.name}
            buyerId={original?.buyerId}
            propertyId={original?.propertyId}
            propertyProfileId={original?.propertyProfileId}
            onClickResendLink={onClickResendLink}
          />
        ),
      },
    ],
    [toggleInfoModal, onClickResendLink],
  )
  return (
    <Loader loaded={loaded}>
      <Container isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
        {isShown && (
          <InfoModalTemplates
            modalBody={modalBody}
            infoType={infoType}
            isShown={isShown}
            toggle={toggle}
            loadedModal={loadedModal}
          />
        )}
        <Table
          columns={columns}
          data={visits}
          title="AgentPropertyVisitInfoTitle"
          bigRow
          typeUser="visit"
          loaded={!loadingVisitsData}
        />
        {status !== BOOKING_STATUSES.ACTIVE && (
          <StatusModal
            onConfirm={() => toggleModals()}
            isShown={status !== BOOKING_STATUSES.ACTIVE && isShownModals}
          />
        )}
        {!timeSlots.length && status === BOOKING_STATUSES.ACTIVE && (
          <TimeSlotsModal
            isShown={isShownModals}
            onCancel={toggleModals}
            propertyId={data.propertyId}
            sellerId={data.sellerId}
            contacts={contacts}
            onConfirm={() => sendRequest(MESSAGE_TYPE.REMINDER)}
          />
        )}
        {!!request && status === BOOKING_STATUSES.ACTIVE && (
          <SendModal
            isShown={!!request}
            onConfirm={() => setRequest('')}
            type={request}
            propertyId={data.propertyId}
            buyerId={data.id}
            propertyProfileId={data.propertyProfileId}
            header={SEND_MODAL.header}
            message={
              request === MESSAGE_TYPE.REMINDER
                ? SEND_MODAL.message.reminder
                : SEND_MODAL.message.link
            }
          />
        )}
      </Container>
    </Loader>
  )
}

export default AgentVisitInfoCard
