import styled from 'styled-components'

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: ${({ isMobile }) => `calc(100vh - ${isMobile ? '16rem' : '20rem'});`}
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.LG} 5rem 0`
    if (isTablet) return `${theme.indent.LG} ${theme.indent.XXXL} 0`
    if (isMobile) return `${theme.indent.LG} ${theme.indent.LG} 0`
  }};
`

export const Container = styled.div`
  width: 100%;
`
