import { getAuth } from 'firebase/auth'

const BASE_URL = 'https://us-central1-viewd-prod.cloudfunctions.net'

export const addVisitToCalendar = async (visitId: string): { error: string } => {
  const auth = getAuth()
  const token = auth.currentUser.accessToken
  try {
    const res = await fetch(`${BASE_URL}/addVisitToCalendar`, {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type':
          'application/json' /* Omar asked tp add "Content-Type": "application/json"*/,
      },
      method: 'post',
      body: JSON.stringify({
        visitId,
      }),
    })
    if (!res.ok) {
      const reason = await res.json()
      throw reason.error.message
    }
    const blob = await res.blob()
    const file = new Blob([blob], { type: 'text/calendar' })
    const url = window.URL.createObjectURL(file)
    return window.open(url)
  } catch (e) {
    return e
  }
}
