import { isToday, isTomorrow, isYesterday, format } from 'date-fns'

export const dateToDayName = (date: Date, defaultFormated: boolean | undefined): string => {
  if (isToday(date)) {
    return 'Today'
  }
  if (isTomorrow(date)) {
    return 'Tomorrow'
  }
  if (isYesterday(date)) {
    return 'Yesterday'
  }
  if (defaultFormated) {
    return format(date, 'd LLLL y')
  }
  return ''
}
