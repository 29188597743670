import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.LG)};
`

export const VisitsContainer = styled.div`
  padding: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.LG)}
    ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)} 0;
`

export const MessageContainer = styled.div<{ isMobile: boolean }>`
  padding-top: ${(p) => (p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXL)};
  display: flex;
  justify-content: center;
`
