const LINE_HEIGHTS = {
  XXXS: 1.4,
  XXS: 1.6,
  XS: 1.8,
  SM: 2,
  MD: 2.4,
  LG: 3,
  XL: 4,
  XXL: 6.4,
}

export default LINE_HEIGHTS
