import React, { useCallback } from 'react'
import { useMobile } from '@utils/hooks'
import star from '@assets/images/star.png'
import { IconContainer, Image } from './RatingStars.style'

type StarProps = {
  item: number
  starIndex: number
  clickStar: (item: number) => void
  hoveredStar: number
  setHoveredStar: (item: number) => void
}

const Star: React.FC<StarProps> = ({ starIndex, clickStar, item, setHoveredStar, hoveredStar }) => {
  const isMobile = useMobile()
  const clickOnStar = useCallback(() => {
    clickStar(item)
  }, [clickStar, item])
  return (
    <IconContainer
      isMobile={isMobile}
      active={item < (starIndex || hoveredStar)}
      onClick={clickOnStar}
      onMouseEnter={() => setHoveredStar(item)}
      onMouseLeave={() => setHoveredStar(starIndex)}
    >
      <Image data-testid="star" src={star} alt="star" isMobile={isMobile} />
    </IconContainer>
  )
}

export default Star
