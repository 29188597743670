import React from 'react'
import { Redirect } from 'react-router-dom'
import { useFirebaseAuth, useMobile, useTabletPortrait } from '@utils/hooks'
import { ROUTES, LOGIN } from '@constants/dictionary'
import { Loader, Icon } from '@atoms'
import { BackToLoginHeader } from '@organisms'
import LinkExpired from '@assets/images/LinkExpired.svg'
import bgDesctop from '@assets/images/bg-land-desctop.png'
import bgTablet from '@assets/images/bg-land-tablet.png'
import bgMobile from '@assets/images/bg-land-mobile.png'
import {
  Container,
  StyledCol,
  FormContainer,
  H2,
  FieldsContainer,
  ImageBlock,
  StyledImg,
} from './AgentInspiredLinkPage.style'

const AgentInspiredLinkPage = (): JSX.Element => {
  const isMobile = useMobile()

  const isTabletPortrait = useTabletPortrait()
  const [logedIn, loaded] = useFirebaseAuth()

  const path = (): string => {
    if (isTabletPortrait) {
      return bgTablet
    }
    if (isMobile) {
      return bgMobile
    }
    return bgDesctop
  }

  return (
    <Loader loaded={loaded}>
      {logedIn ? (
        <Redirect to={ROUTES.AGENT} />
      ) : (
        <Container path={path}>
          <StyledCol md={12} lg={6} xl={6} xxl={6}>
            <FormContainer isMobile={isMobile}>
              <Icon icon="logo" clickable size="20rem" color="white" />
              <FieldsContainer isMobile={isMobile}>
                <BackToLoginHeader />
                <H2 isMobile={isMobile}>{LOGIN.EXPIRED_LINK}</H2>
                <ImageBlock>
                  <StyledImg src={LinkExpired} alt="LinkExpired" />
                </ImageBlock>
              </FieldsContainer>
            </FormContainer>
          </StyledCol>
        </Container>
      )}
    </Loader>
  )
}

export default AgentInspiredLinkPage
