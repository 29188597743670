import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const Container = styled(Row)`
  padding: ${(p) => p.theme.indent.MD}
    ${(p) => {
      if (p.isTabletPortrait) {
        return p.theme.indent.XXXL
      }
      if (p.isMobile) {
        return p.theme.indent.LG
      }
      return '5rem'
    }}
    ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.MD)};
  flex-direction: row;
  align-items: center;
`
export const LinkTo = styled(Typography.Link)<{ noUnderline: boolean; isMobile: boolean }>`
  text-decoration: ${(p) => (p.noUnderline ? 'none' : 'underline')};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Cell = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
