import styled from 'styled-components'

export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ isMobile }) =>
    isMobile &&
    `flex-direction: column;
    align-items: center;
  `};
  padding: ${({ isMobile, theme }) =>
    isMobile ? `${theme.indent.SM} ${theme.indent.LG}` : `${theme.indent.XS} ${theme.indent.MD}`};
  border-top: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  border-left: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  border-right: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  border-top-right-radius: ${(p) => p.theme.indent.XS} ${(p) => p.theme.indent.XS};
  border-top-left-radius: ${(p) => p.theme.indent.XS} ${(p) => p.theme.indent.XS};
`

export const DropdownContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '16rem')};
  height: 4rem;
  margin-bottom: ${(p) => p.isMobile && p.theme.indent.XS};
`

// TODO: Move to Typography
export const Label = styled.div`
  text-decoration: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  outline: none;
  padding-bottom: ${(p) => p.theme.indent.SM};

  ${(p) => p.$active && `border-bottom: 0.4rem solid ${p.theme.color.originalBlue};`}
`

export const SearchContainer = styled.div<{
  isMobile: boolean
  marginLeft: boolean
  isModal: boolean
}>`
  width: ${({ isMobile, isModal }) => (isMobile || isModal ? '100%' : '31.5rem')};
  margin-left: ${(p) => (p.marginLeft && !p.isMobile ? p.theme.indent.MD : p.theme.indent.XXXS)};
`

export const TabButton = styled.div`
  cursor: pointer;
  padding: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.MD)};
  padding-bottom: ${(p) => p.theme.indent.XXXS};
  padding-top: ${(p) => p.theme.indent.XS};
  width: max-content;
`

export const TabContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  height: 4rem;
`
