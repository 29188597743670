import styled from 'styled-components'

export const InfoCard = styled.div<{
  isTabletPortrait: boolean
  isDesktop: boolean
  isMobile: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.LG};
  padding-bottom: ${({ isDesktop, theme }) => (isDesktop ? '4rem' : `${theme.indent.XXXL}`)};
  ${({ isTabletPortrait, theme }) =>
    isTabletPortrait &&
    `flex: 0 1 calc(50% - 2rem);
      &:first-of-type {
  margin-right: ${theme.indent.XXXL}};
  `};
  ${({ isDesktop, theme }) =>
    isDesktop &&
    `&:nth-of-type(2n) {margin-right: ${theme.indent.XXXL}; margin-left: ${theme.indent.XXXL}};`};
  margin-top: ${({ isMobile, isDesktop, isTabletPortrait }) => {
    if (isDesktop) return '8rem'
    if (isTabletPortrait) return '5rem'
    if (isMobile) return '4.6rem'
  }};
`

export const IconContainer = styled.div<{ isDesktop: boolean }>`
  position: relative;
  top: ${({ isDesktop }) => (isDesktop ? '-4rem' : '-3rem')};
  margin-bottom: ${({ isDesktop }) => (isDesktop ? '-4rem' : '-3rem')};
`

export const TextContainer = styled.div<{ isDesktop: boolean; clickable: boolean }>`
  padding-top: ${({ theme, isDesktop }) => (isDesktop ? theme.indent.XXXL : theme.indent.MD)};
`
