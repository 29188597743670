import React from 'react'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { BOOKING_STATUSES, BOOKING_STATUSES_NAMES } from '@constants/dictionary'
import { Container, Text } from './BookingStatus.style'
import { BookingStatusTypes } from '@constants/types/atoms'

const BookingStatus: React.FC<BookingStatusTypes> = ({
  status = BOOKING_STATUSES.UNDEFINED,
  radius,
  shadow = false,
  isPropertyCard = false,
  isListView = false,
  uppercase = false,
}) => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  return (
    <Container
      isPropertyCard={isPropertyCard}
      isListView={isListView}
      status={status}
      radius={radius}
      shadow={shadow}
      isTabletPortrait={isTabletPortrait}
    >
      <Text
        isPropertyCard={isPropertyCard}
        uppercase={uppercase}
        status={status}
        isMobile={isMobile}
      >
        {BOOKING_STATUSES_NAMES[status]}
      </Text>
    </Container>
  )
}

export default BookingStatus
