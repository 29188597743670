import React from 'react'
import { UserHeader } from '@organisms'
import { Container, StyledCol } from './UserLayout.style'

interface UserLayoutTypes {
  children?: JSX.Element[]
}

const UserLayout: React.FC<UserLayoutTypes> = ({ children }) => {
  return (
    <Container>
      <UserHeader />
      <StyledCol col={12} noGutter>
        {children}
      </StyledCol>
    </Container>
  )
}

export default UserLayout
