/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { Typography } from '@atoms'
import { Col, Row } from 'styled-bootstrap-grid'

export const ProgressContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 100%;
  width: ${({ countSteps }) => `${4 * countSteps}rem`};

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: ${({ theme }) => theme.indent.XXS};
    background-color: ${({ theme }) => theme.color.lightGrey};
    z-index: 1;
    transition: 0.4s ease;
  }
`
export const ProgressLine = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: ${({ width }) => width}%;
  height: 0.4rem;
  background-color: ${({ theme }) => theme.color.originalBlue};
  z-index: 1;
  transition: 0.4s ease;
`

export const CircleStep = styled(Typography.H2)`
  color: ${({ theme, full, active }) =>
    full ? theme.color.white : active ? theme.color.originalBlue : theme.color.grey};
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ theme, full }) => (full ? theme.color.originalBlue : theme.color.white)};
  border: ${(p) => p.theme.borderRadius.XS} solid
    ${({ theme, active }) => (active ? theme.color.originalBlue : theme.color.lightGrey)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
  z-index: 2;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const TopContainer = styled(Col)`
  display: flex;
  justify-content: center;
  min-height: ${({ isMobile }) => (isMobile ? '7.9rem' : '10rem')};
  background-color: ${({ theme }) => theme.color.greyBlue};
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
`
export const BottomContainer = styled(Col)`
  margin-bottom: ${({ theme }) => theme.indent.XXXL};
`

export const StyledCol = styled(Col)`
  padding: ${(p) => (p.isMobile ? `0 ${p.theme.indent.XS}` : `0 ${p.theme.indent.SM}`)};
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin: 0;
`
export const Container = styled.div`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.SM} ${p.theme.indent.LG}`
      : `${p.theme.indent.SM} ${p.theme.indent.XXXL}`};
  margin: ${(p) => (p.isMobile ? `0 -${p.theme.indent.XS}` : `0 -${p.theme.indent.SM}`)};
`
