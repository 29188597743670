import React, { useCallback, useState, useMemo } from 'react'
import { Button } from '@molecules'
import { useMobile } from '@utils/hooks'
import { ONE_HUNDRED_PERCENT, EVEN_NUMBER } from '@constants/dictionary'
import {
  ProgressContainer,
  ProgressLine,
  CircleStep,
  ButtonContainer,
  TopContainer,
  BottomContainer,
  StyledRow,
  StyledCol,
  Container,
} from './StepProgressBar.style'

type StepProgressBarTypes = {
  children?: JSX.Element
  countSteps: number
  onClickComplete?: () => void
  minusStep?: () => void
  addStep?: () => void
  onClickBack?: () => void
  disabledFirstStep?: boolean
  disabledSecondStep?: boolean
}

const StepProgressBar: React.FC<StepProgressBarTypes> = ({
  children,
  countSteps = 3,
  onClickComplete,
  onClickBack,
  minusStep,
  addStep,
  disabledFirstStep,
  disabledSecondStep,
}): JSX.Element => {
  const [currentActive, setCurrentActive] = useState(0)
  const handleBack = useCallback(() => {
    if (currentActive > 0) {
      setCurrentActive(currentActive - 1)
      minusStep()
    } else {
      onClickBack()
    }
  }, [currentActive, minusStep, onClickBack])

  const handleNext = useCallback(() => {
    if (currentActive + 1 < countSteps) {
      setCurrentActive(currentActive + 1)
      addStep()
    }
  }, [currentActive, addStep, countSteps])

  const isActive = useCallback(
    (index: number) => {
      return index === currentActive || index < currentActive
    },
    [currentActive],
  )
  const isFull = useCallback(
    (index: number) => {
      return currentActive > index
    },
    [currentActive],
  )
  const handleComplete = useCallback(() => {
    onClickComplete()
  }, [onClickComplete])

  const countStepsArray = Array.from(Array(countSteps).keys())
  const width = (currentActive / (countStepsArray.length - 1)) * ONE_HUNDRED_PERCENT
  const isLastStep = useMemo(() => {
    return currentActive === countSteps - 1
  }, [currentActive, countSteps])
  const isMobile = useMobile()

  return (
    <>
      <TopContainer col={12}>
        <ProgressContainer countSteps={EVEN_NUMBER * countSteps - 1}>
          <ProgressLine width={width || '100%'} />
          {countStepsArray.map(
            (item: number, index: number): JSX.Element => (
              <CircleStep key={item} active={isActive(index)} full={isFull(index)}>
                {item + 1}
              </CircleStep>
            ),
          )}
        </ProgressContainer>
      </TopContainer>
      {children}
      <ButtonContainer>
        <BottomContainer lg={7} xs={12} md={10} noGutter>
          <Container isMobile={isMobile}>
            <StyledRow>
              <StyledCol xs={6} sm={6} md={6} lg={6} isMobile={isMobile}>
                <Button.Secondary label="Back" onClick={handleBack} />
              </StyledCol>
              {isLastStep ? (
                <StyledCol xs={6} sm={6} md={6} lg={6} isMobile={isMobile}>
                  <Button.Primary
                    label="Complete"
                    onClick={handleComplete}
                    disabled={!disabledSecondStep}
                  />
                </StyledCol>
              ) : (
                <StyledCol xs={6} sm={6} md={6} lg={6} isMobile={isMobile}>
                  <Button.Primary label="Next" onClick={handleNext} disabled={!disabledFirstStep} />
                </StyledCol>
              )}
            </StyledRow>
          </Container>
        </BottomContainer>
      </ButtonContainer>
    </>
  )
}

export default StepProgressBar
