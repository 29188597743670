/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { parse } from 'qs'
import { Switch, Redirect, useLocation, useHistory } from 'react-router-dom'
import { verifyResetCode } from '@services/firebase'
import { checkOobCode, getRedirectLinkAgent } from '@utils/functions'
import { useFirebaseAuth } from '@utils/hooks'
import { AgentLayout } from '@templates'
import { Loader } from '@atoms'
import { LS_CONTSTANTS, URLS_WITHOUT_HEADER, URLS_WITHOUT_FOOTER } from '@constants/dictionary'
import AgentPagesRoutes from './AgentPages.routes'

const AgentPages = (): JSX.Element => {
  const [status, setStatus] = useState({ error: '', loaded: false })
  const [, loaded] = useFirebaseAuth()
  const location = useLocation()
  const history = useHistory()

  const { oobCode = '' } = parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const checkCode = async (): Promise<undefined> => {
    if (oobCode) {
      localStorage.setItem(LS_CONTSTANTS.oobCode, oobCode)
      const resultSuccess = await verifyResetCode(oobCode)
      const state = await checkOobCode(resultSuccess)

      setStatus(state)
    } else {
      setStatus({ error: '', loaded: true })
    }
  }

  if (status.error && status.loaded) {
    localStorage.setItem(LS_CONTSTANTS.err_new_pass, status.error)
  }
  const redirectLink = getRedirectLinkAgent(location.pathname)

  useEffect(() => {
    if (location.pathname.endsWith('/')) {
      history.push({
        pathname: location.pathname.slice(0, -1),
      })
    }
    checkCode()
    return () => {
      localStorage.removeItem(LS_CONTSTANTS.oobCode)
      localStorage.removeItem(LS_CONTSTANTS.err_new_pass)
    }
  }, [])

  return (
    <AgentLayout
      shownHeader={!URLS_WITHOUT_HEADER.includes(location.pathname)}
      shownFooter={!URLS_WITHOUT_FOOTER.includes(location.pathname)}
    >
      <Loader loaded={status.loaded && loaded}>
        <Switch>
          {AgentPagesRoutes.map(({ route: Route, id, ...props }) => (
            <Route key={id} {...props} />
          ))}
          <Redirect to={redirectLink} />
        </Switch>
      </Loader>
    </AgentLayout>
  )
}

export default AgentPages
