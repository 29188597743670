import styled from 'styled-components'

export const NotificationContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  position: absolute;
  right: ${(p) => (p.isOpen ? '0' : '-50.8rem')};
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  top: 0;
  width: ${(p) => (p.isMobile ? '100%' : '50.8rem')};
  transition: width 0.5s ease-in-out, display 0.5s ease-in-out, right 0.5s ease-in-out;
  box-shadow: 0 0.4rem 1rem ${(p) => p.theme.color.black_20};
  height: 100%;
`

export const NotificationHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
  padding: ${(p) => p.theme.indent.XL} ${(p) => p.theme.indent.XXL};
`
