import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Image = styled.img`
  height: ${(p) => {
    if (p.size) {
      return p.size
    }
    return p.isMobile ? '1.6rem' : '2rem'
  }};
  padding-right: ${(p) => p.theme.indent.XXS};
`

export const IconContainer = styled.div`
  padding-right: ${(p) => p.theme.indent.XXS};
`
