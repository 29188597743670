import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { GlobalFilterType } from '@constants/types'
import { SearchInput } from '@molecules'

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  placeholder,
  noMargin,
}: GlobalFilterType): JSX.Element => {
  const [value, setValue] = useState(globalFilter)
  const TWO_HUNDRED_MILISECONDS = 200
  const onChange = useAsyncDebounce(($value) => {
    setGlobalFilter($value || undefined)
  }, TWO_HUNDRED_MILISECONDS)
  return (
    <SearchInput
      key="tableSearch"
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={placeholder}
      noMargin={noMargin}
    />
  )
}

export default GlobalFilter
