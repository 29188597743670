import styled from 'styled-components'

export const Integration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.indent.LG};
  padding: ${({ theme }) =>
    `${theme.indent.XS} ${theme.indent.SM} ${theme.indent.XS} ${theme.indent.MD}`};
  border: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  max-width: 52.8rem;
  height: 5.6rem;

  &:nth-child(1) {
    margin-top: 0;
  }
`

export const IconWithTitle = styled.div`
  display: flex;
  align-items: inherit;
  gap: ${({ theme }) => theme.indent.MD};
`
