import { SellerHome, SellerConfigSlots, SellerCancelVisit, UnderOffer, UrgentMatters } from '@pages'
import { ROUTES } from '@constants/dictionary'

const SellerRoutes = [
  {
    id: 'SellerPropertyRoute',
    exact: true,
    component: SellerHome,
    path: ROUTES.SELLER_PROPERTY,
  },
  {
    id: 'SellerSlotsRoute',
    exact: true,
    component: SellerHome,
    path: ROUTES.SELLER_SLOTS,
  },
  {
    id: 'SellerVisitsRoute',
    exact: true,
    component: SellerHome,
    path: ROUTES.SELLER_VISITS,
  },
  {
    id: 'SellerStatisticsRoute',
    exact: true,
    component: SellerHome,
    path: ROUTES.SELLER_STATISTICS,
  },
  {
    id: 'SellerSlotsConfig',
    exact: true,
    component: SellerConfigSlots,
    path: ROUTES.SELLER_CONFIG_SLOTS,
  },
  {
    id: 'SellerCancelVisit',
    exact: true,
    component: SellerCancelVisit,
    path: ROUTES.SELLER_CANCEL_VISIT,
  },
  {
    id: 'SellerUnderOffer',
    exact: true,
    component: UnderOffer,
    path: ROUTES.SELLER_UNDER_OFFER,
  },
  {
    id: 'SellerUrgentMatters',
    exact: true,
    component: UrgentMatters,
    path: ROUTES.SELLER_URGENT_MATTERS,
  },
]
export default SellerRoutes
