import React from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { ROUTES } from '@constants/dictionary'
import { ErrorPage, Home, PrivacyPolicy } from '@pages'
import ShortLinkProcessor from './ShortLinkProcessor'
import UserPages from './UserPages'
import AgentPages from './AgentPages'

const RootPages = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route path={ROUTES.PRIVACY_POLICY} exact={true} component={PrivacyPolicy} />
        <Route path={ROUTES.SHORTED_LINK} exact={true} component={ShortLinkProcessor} />
        <Route path={ROUTES.BUYER} exact={false} component={UserPages} />
        <Route path={ROUTES.SELLER} exact={false} component={UserPages} />
        <Route path={ROUTES.AGENT} exact={false} component={AgentPages} />
        <Route path={ROUTES.ERROR} exact component={ErrorPage} />
        <Route path="/" exact={false} component={Home} />
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default RootPages
