import React, { useCallback } from 'react'
import { useMobile, useDesktop } from '@utils/hooks'
import { useTheme } from 'styled-components'
import { Icon, Typography } from '@atoms'
import { ContainerList, TitleContainer } from './SelectTimePeriod.style'
import PeriodByButton from '../PeriodByButton'
import PeriodByDropdown from '../PeriodByDropdown'
import { isDisabledMinusFromTime, isDisabledAddUntilTime, isDisabledTime } from '@utils/functions'
import { SelectTimePeriodProps } from '@constants/types/organisms'

const SelectTimePeriod: React.FC<SelectTimePeriodProps> = ({
  allHours,
  slot,
  id,
  removeTimePeriod,
  addFromTime,
  minusFromTime,
  addUntilTime,
  minusUtilTime,
  setUntilTime,
  setFromTime,
  timePeriodBefore,
  timePeriodNext,
}) => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const theme = useTheme()

  const remove = useCallback(() => {
    removeTimePeriod(id)
  }, [id, removeTimePeriod])

  const disabledminusFromTime = isDisabledMinusFromTime(timePeriodBefore, slot, allHours)
  const disabledaddUntilTime = isDisabledAddUntilTime(timePeriodNext, slot, allHours)
  const disabledTime = isDisabledTime(slot)

  const allHourUntil = allHours
    .filter((hour) => hour > slot.fromDateTime)
    .filter((hour) => (timePeriodNext ? hour < timePeriodNext.fromDateTime : true))

  const allHourFrom = allHours
    .filter((hour) => hour < slot.toDateTime)
    .filter((hour) => (timePeriodBefore ? hour > timePeriodBefore.toDateTime : true))

  return (
    <ContainerList isMobile={isMobile}>
      <TitleContainer>
        <Typography.Body2 isMobile={isMobile}>Select time period #{id + 1}</Typography.Body2>
        <Icon
          icon="close"
          testid="close"
          clickable
          size="1.5rem"
          color={theme.color.darkBlack}
          onClick={remove}
        />
      </TitleContainer>
      {isDesktop ? (
        <PeriodByDropdown
          slot={slot}
          id={id}
          setUntilTime={setUntilTime}
          setFromTime={setFromTime}
          allHourFrom={allHourFrom}
          allHourUntil={allHourUntil}
        />
      ) : (
        <PeriodByButton
          slot={slot}
          id={id}
          minusUtilTime={minusUtilTime}
          addUntilTime={addUntilTime}
          minusFromTime={minusFromTime}
          addFromTime={addFromTime}
          allHours={allHours}
          disabledminusFromTime={disabledminusFromTime}
          disabledaddFromTime={disabledTime}
          disabledminusUtilTime={disabledTime}
          disabledaddUntilTime={disabledaddUntilTime}
        />
      )}
    </ContainerList>
  )
}

export default SelectTimePeriod
