import React from 'react'
import { Loader } from '@atoms'
import { getUserProfiles } from '@utils/functions'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getUsersProfilesByType } from '@services/firebase'
import UsersTabContent from '../UsersTabContent'

const SellersTabContent = (): React.FC => {
  const [result, loadingProfiles] = useCollection(getUsersProfilesByType('seller'))
  const data = loadingProfiles && !result ? [] : getUserProfiles(result)

  return (
    <Loader loaded={!loadingProfiles}>
      <UsersTabContent typeUser="seller" data={data} user="Seller" />
    </Loader>
  )
}

export default SellersTabContent
