import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.isTable && 'height: 40vh;'};
  margin-top: ${(p) => (p.noMargin || p.isTable ? 0 : '35vh')};
`

export const StyledLoader = styled.div`
  width: ${(p) => (p.isMobile ? '8rem' : '14rem')};
  height: ${(p) => (p.isMobile ? '8rem' : '14rem')};
  border-radius: 50%;
  background: ${(p) => p.theme.color.lightGrey};
  background: linear-gradient(
    to right,
    ${(p) => p.theme.color.lightGrey} 10%,
    ${(p) => p.theme.color.transparent} 42%
  );
  position: relative;
  animation: ${rotate} 1.4s infinite linear;
  transform: translateZ(0);

  &::before {
    width: 50%;
    height: 50%;
    background: ${(p) => p.theme.color.lightGrey};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &::after {
    background: ${(p) => (!!p.bgColor ? p.theme.color[p.bgColor] : p.theme.color.white)};
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export const StyledSkeletonLoader = styled.div<{ color?: string }>`
  width: ${(p) => p.size || '2.4rem'};
  height: ${(p) => p.size || '2.4rem'};
  border-radius: 50%;
  background: transparent;
  position: relative;
  animation: ${rotate} 1s infinite linear;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 0.3rem;
    background: linear-gradient(
      to right,
      ${(p) => (!!p.color ? p.color : p.theme.color.white)} 40%,
      ${(p) => p.theme.color.transparent} 60%
    );
    -webkit-mask: linear-gradient(${(p) => p.theme.color.white} 0 0) content-box,
      linear-gradient(${(p) => p.theme.color.white} 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`
