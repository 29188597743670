const gridConfig = {
  gridColumns: 12, // default 12
  breakpoints: {
    // defaults below
    xxl: 1600,
    xl: 1366,
    lg: 1024,
    md: 769,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0, // default 15
  },
  col: {
    padding: 0, // default 15
  },
  container: {
    padding: 0, // default 15
    maxWidth: {
      // defaults below
      xxl: 1600,
      xl: 1366,
      lg: 1024,
      md: 769,
      sm: 576,
      xs: 575,
    },
  },
}

export default gridConfig
