import React from 'react'
import { useMobile } from '@utils/hooks'
import { TimeSlotButtonContainer, TimeSlotLabel } from './PeriodButtons.style'

type PeriodButtonsProps = {
  selectTime: string
}

const PeriodButtons: React.FC<PeriodButtonsProps> = ({ selectTime }) => {
  const isMobile = useMobile()

  return (
    <TimeSlotButtonContainer isMobile={isMobile}>
      <TimeSlotLabel isMobile={isMobile}>{selectTime}</TimeSlotLabel>
    </TimeSlotButtonContainer>
  )
}

export default PeriodButtons
