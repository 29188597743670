import { getFunctions, httpsCallable } from 'firebase/functions'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { ViewingCancelInitiators } from '@constants/types'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const cancelVisits = async (propertyId: string): { error: string } => {
  try {
    const functions = getFunctions()
    const bookVisit = httpsCallable(functions, FUNC_NAMES.CANCEL_PROPERTY_ALL_VISITS)
    await bookVisit({ propertyId })
    return true
  } catch (e) {
    return false
  }
}

export const cancelDayVisits = async (
  dates: Array<string>,
  cancellationDescription: string,
): { error: string } => {
  const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)
  try {
    const functions = getFunctions()
    const bookVisit = httpsCallable(functions, FUNC_NAMES.CANCEL_AVAILABLE_SLOTS)
    await Promise.all(
      dates.map((date) =>
        bookVisit({
          propertyId,
          date,
          cancellationDescription: !!cancellationDescription ? cancellationDescription : 'Empty',
        }),
      ),
    )
    return true
  } catch (e) {
    return false
  }
}

export const cancelOrRescheduleVisit = async ({
  viewingStringId,
  cancellationDescription,
  cancelInitiator,
  isReschedule = false,
}: {
  viewingStringId: string
  cancellationDescription: string
  cancelInitiator: ViewingCancelInitiators
  isReschedule?: boolean
}): Promise<boolean> => {
  try {
    const functions = getFunctions()
    const cancelReschedule = httpsCallable(functions, FUNC_NAMES.CANCEL_A_VISIT)
    const cancellationReason = cancellationDescription.trim()
    await cancelReschedule({
      visitId: viewingStringId,
      cancellationDescription: cancellationReason.length > 0 ? cancellationReason : 'Empty',
      cancellationReasonId: cancelInitiator,
      reschedule: isReschedule,
    })
    return true
  } catch (err) {
    return false
  }
}
