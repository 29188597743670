import { getMonth, setMonth, format } from 'date-fns'
import { uniq } from 'lodash'

export const mapDateToString = (dates: Array<Date>): string => {
  // grab uniq months
  const unicMonths = uniq(dates.map((date) => getMonth(date)))
  // prepare objects
  const months = unicMonths.map((month) => ({ month, days: [] }))
  // grab days
  dates.forEach((date) => {
    months.forEach((monthData) => {
      // if same month and no same days, push in format 01, 02 etc
      if (monthData.month === getMonth(date) && !monthData.days.includes(format(date, 'dd'))) {
        monthData.days.push(format(date, 'dd'))
      }
    })
  })

  const formatedMonths = months.map(
    (monthDate) =>
      // to format dd, dd, dd mon.
      `${monthDate.days.join()} ${format(setMonth(new Date(), monthDate.month), 'MMM.')} `,
  )

  return formatedMonths.join()
}
