import { collection, getFirestore, query, where } from 'firebase/firestore'
import { getAgencyDataId } from '@utils/functions'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getAgentPropertyVisits = (propertyId: string): { error: string } => {
  const db = getFirestore()

  const { isAgency, agentId, uid } = getAgencyDataId()

  const visitCol = collection(db, FS_COL_NAMES.PROPERTY_VISITS)
  return isAgency
    ? query(
        visitCol,
        where(FS_Q_PARAMS.AGENCY_ID, '==', uid),
        !!agentId && where(FS_Q_PARAMS.AGENTID, '==', agentId),
        where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
      )
    : query(
        visitCol,
        !!agentId && where(FS_Q_PARAMS.AGENTID, '==', agentId),
        where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
      )
}
