import { collection, getFirestore, doc, getDoc, DocumentData } from 'firebase/firestore'
import { FS_COL_NAMES, FS_COL_GROUP_NAMES } from '@constants/dictionary/firebase'

export const getAgentPrivateData = async (id = ''): Promise<DocumentData | undefined> => {
  const db = getFirestore()
  const user = doc(db, FS_COL_NAMES.USERS, id)
  const col = collection(user, FS_COL_GROUP_NAMES.PRIVATE_DATA)
  const userPrivateDataRef = doc(col, id)
  const userPrivateData = await getDoc(userPrivateDataRef)
  return userPrivateData?.data()
}
