import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'

export const getAgencyDataId = (
  isUser: boolean,
): { isAgency: boolean; agentId: string; uid: string } => {
  const isAgency = localStorage.getItem(LS_CONTSTANTS.userType) === USER_TYPES.agency

  const agentId = (): string => {
    if (isUser) {
      return localStorage.getItem(LS_CONTSTANTS.agentId) || ''
    }
    return localStorage.getItem(isAgency ? LS_CONTSTANTS.selectedAgentId : LS_CONTSTANTS.uid) || ''
  }

  return {
    isAgency,
    agentId: agentId(),
    uid: localStorage.getItem(LS_CONTSTANTS.uid) || '',
  }
}
