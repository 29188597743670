import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ERROR_MESSAGE_TEXT_PROPERTY, USER_TYPES, LS_CONTSTANTS } from '@constants/dictionary'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const generateCustomToken = async (token: string): { error: string; authToken: string } => {
  const functions = getFunctions()
  try {
    const addMessage = httpsCallable(functions, FUNC_NAMES.GENERATE_CUSTOM_TOKEN)
    const res = await addMessage({ exchangeToken: token })
    return { error: '', authToken: res.data.authToken }
  } catch (error) {
    return { error: ERROR_MESSAGE_TEXT_PROPERTY.INCORRECT_TOKEN_ERROR }
  }
}

export const authByToken = async (token: string): { error: string } => {
  if (!token.length) {
    localStorage.setItem(LS_CONTSTANTS.error, ERROR_MESSAGE_TEXT_PROPERTY.INCORRECT_TOKEN_ERROR)
    return { error: ERROR_MESSAGE_TEXT_PROPERTY.INCORRECT_TOKEN_ERROR }
  }
  const result = await generateCustomToken(token)
  if (result.error.length) {
    localStorage.setItem(LS_CONTSTANTS.error, result.error)
    return { error: result.error }
  }
  const auth = getAuth()
  try {
    const userCredentials = await signInWithCustomToken(auth, result.authToken)
    localStorage.setItem(LS_CONTSTANTS.error, '')
    localStorage.setItem(LS_CONTSTANTS.uid, userCredentials.user.uid)
    // set userType
    if (auth.currentUser) {
      const userResult = await auth.currentUser.getIdTokenResult()
      if (!!USER_TYPES[userResult.claims.userType]) {
        localStorage.setItem(LS_CONTSTANTS.userType, userResult.claims.userType)
      }
    }
    return { error: '' }
  } catch (error) {
    localStorage.setItem(LS_CONTSTANTS.error, ERROR_MESSAGE_TEXT_PROPERTY.INCORRECT_TOKEN_ERROR)
    return { error: ERROR_MESSAGE_TEXT_PROPERTY.INCORRECT_TOKEN_ERROR }
  }
}
