import React, { useEffect, useState } from 'react'
import { useMobile, useTabletLandscape, useTabletPortrait } from '@utils/hooks'
import { mapToVisits } from '@utils/functions'
import { Loader } from '@atoms'
import { EMPTY_LIST_TEXT } from '@constants/dictionary'
import { BookingDayTitle, EmptyListText } from '@molecules'
import { BookingInfoCard } from '@organisms'
import { getBuyerVisits } from '@services/firebase'
import { Container } from './BuyerList.style'

const BuyerList = (): JSX.Element => {
  const isMobile = useMobile()
  const isTabletLandscape = useTabletLandscape()
  const isTabletPortrait = useTabletPortrait()

  const [loadingVisitsList, setLoadingVisitsList] = useState(false)
  const [visitsList, setVisitsList] = useState<[]>([])

  useEffect(() => {
    ;(async function () {
      setLoadingVisitsList(true)
      const buyerVisitsQuery = await getBuyerVisits()
      const visits = mapToVisits(buyerVisitsQuery)
      setVisitsList(visits)
      setLoadingVisitsList(false)
    })()
  }, [])

  return (
    <Container
      isMobile={isMobile}
      isTabletLandscape={isTabletLandscape}
      isTabletPortrait={isTabletPortrait}
    >
      <Loader loaded={!loadingVisitsList}>
        {visitsList.length ? (
          visitsList.map(({ startDate, visits }) => (
            <div key={startDate}>
              <BookingDayTitle date={startDate} />
              {visits.map((meeting) => (
                <BookingInfoCard
                  {...meeting}
                  agentId={meeting.agent.id}
                  propertyId={meeting.propertyId}
                  visits
                  key={meeting.fromDateTime}
                />
              ))}
            </div>
          ))
        ) : (
          <EmptyListText text={EMPTY_LIST_TEXT.BUYER_VISITS} />
        )}
      </Loader>
    </Container>
  )
}

export default BuyerList
