import { collection, getFirestore, query, where, DocumentData, getDocs } from 'firebase/firestore'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getAgencyAgents = async (): Promise<Array<{ agent: DocumentData; id: string }>> => {
  const agencyId = localStorage.getItem(LS_CONTSTANTS.uid)
  const db = getFirestore()
  const q = query(
    collection(db, FS_COL_NAMES.USERS),
    where(FS_Q_PARAMS.AGENCY_ID, '==', agencyId),
    where(FS_Q_PARAMS.DELETED_AT, '==', null),
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((agent) => ({
    agent: agent.data(),
    id: agent.id,
  }))
}
