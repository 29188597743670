import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CommentArea } from '@atoms'

import { TextAreaFormProps } from '@constants/types/helpers'

const TextAreaForm: React.FC<TextAreaFormProps> = ({
  name,
  placeholder,
  isNotes,
  maxLength,
  tabIndex,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <CommentArea
          {...field}
          onChange={(value) => field.onChange(value)}
          placeholder={placeholder}
          maxLength={maxLength}
          isNotes={isNotes}
          value={field.value}
          tabIndex={tabIndex}
        />
      )}
    />
  )
}
export default TextAreaForm
