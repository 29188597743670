import React from 'react'
import { RatingStars } from '@molecules'
import { Controller, useFormContext } from 'react-hook-form'

import { RatingFormProps } from '@constants/types/helpers'

const RatingForm: React.FC<RatingFormProps> = ({ name, label }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RatingStars label={label} value={value} onChange={onChange} />
      )}
    />
  )
}
export default RatingForm
