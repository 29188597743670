import React, { useCallback, useEffect, useState } from 'react'
import { Typography, Icon } from '@atoms'
import { useMobile } from '@utils/hooks'
import { CheckboxContainer, CheckboxLabel } from './Checkbox.style'

import { EmptyCheckboxProps, CheckboxProps } from '@constants/types/molecules'

const iconPadding = 0.2

const EmptyCheckbox: React.FC<EmptyCheckboxProps> = ({
  toggleCheckBox,
  checked,
  disabled,
  fill,
  size,
}) => (
  <CheckboxContainer
    checked={checked}
    disabled={disabled}
    fill={fill}
    onClick={toggleCheckBox}
    size={size}
  >
    {checked ? (
      <Icon
        icon="check"
        clickable
        color={fill && 'white'}
        size={size ? `${size - iconPadding}rem` : '2rem'}
      />
    ) : null}
  </CheckboxContainer>
)

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  value = false,
  disabled,
  fill,
  check = false,
  size,
}) => {
  const [checked, setChecked] = useState(value)
  const toggleCheckBox = useCallback(() => {
    onChange(!checked, true)
    setChecked(!checked)
  }, [onChange, checked])
  const isMobile = useMobile()

  useEffect(() => {
    if (!checked && check && !disabled) {
      onChange(true)
      setChecked(true)
    }
  }, [check, checked, disabled, onChange])

  return (
    <>
      {label ? (
        <CheckboxLabel>
          {EmptyCheckbox({ toggleCheckBox, checked, disabled, fill })}
          <Typography.CheckboxText isMobile={isMobile} disabled={disabled} onClick={toggleCheckBox}>
            {label}
          </Typography.CheckboxText>
        </CheckboxLabel>
      ) : (
        EmptyCheckbox({ toggleCheckBox, checked, disabled, fill, size })
      )}
    </>
  )
}

export default Checkbox
