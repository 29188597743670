import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'

export const BackgroundContainer = styled.div`
  padding: 1rem;
  max-width: 122.6rem;
  margin: 0 auto;
`
export const Container = styled.div<{ isMobile: boolean; isTabletPortrait: boolean }>`
  width: 100%;
  ${({ theme, isMobile, isTabletPortrait }) => ({
    ...(isTabletPortrait && { padding: `0 ${theme.indent.LG} 0 ${theme.indent.LG}` }),
    ...(isMobile && { padding: `0 ${theme.indent.XS} 0 ${theme.indent.XS}` }),
  })};
`

export const WrapperRow = styled(Row)<{ isMobile: boolean }>`
  padding-bottom: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.MD)};
`

export const ButtonsWithError = styled.div<{ isMobile: boolean }>`
  display: flex;

  ${({ isMobile }) => {
    if (isMobile) {
      return {
        flexDirection: 'column-reverse',
      }
    } else {
      return { justifyContent: 'flex-end' }
    }
  }}
  column-gap: 1rem;
`

export const TitleWrapper = styled.div``

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  column-gap: ${({ theme }) => theme.indent.MD};
`

export const HeaderRow = styled.div<{
  isMobile: boolean
  isTabletPortrait: boolean
  hasError: boolean
}>`
  display: flex;
  margin-bottom: ${({ theme }) => theme.indent.MD};
  ${({ isTabletPortrait, isMobile, hasError }) => {
    if (isMobile) {
      return {
        flexDirection: 'column',
      }
    } else if (isTabletPortrait) {
      return {
        flexDirection: hasError ? 'column' : 'row',
        justifyContent: 'space-between',
        marginBottom: 0,
      }
    } else {
      return {
        height: '4rem',
        justifyContent: 'space-between',
      }
    }
  }};
`
