import styled from 'styled-components'
import { Typography } from '@atoms'

export const ViewingInfoContainer = styled.div<{
  isMobileLandscape: boolean
  isTabletPortrait: boolean
  isMobile: boolean
}>`
  display: ${({ isMobile, isTabletPortrait }) => (isMobile || isTabletPortrait ? 'block' : 'grid')};
  position: relative;
  ${({ isTabletPortrait, isMobile }) =>
    isMobile || isTabletPortrait
      ? 'grid-template-rows: minmax(25rem, auto) minmax(20rem, auto)'
      : 'grid-template-columns: 50.8rem auto'};
  ${({ isMobile }) => isMobile && 'grid-template-columns: 30rem'};
  padding: ${({ theme }) => `${theme.indent.LG} ${theme.indent.MD}`};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0.4rem 1rem ${({ theme }) => theme.color.black_20};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  ${({ isMobile, isTabletPortrait, isMobileLandscape }) => ({
    width: '84.3rem',
    minHeight: '27.8rem',
    ...(isTabletPortrait && {
      width: '52.6rem',
      minHeight: '49.7rem',
    }),
    ...(isMobile && {
      width: '33.5rem',
      minHeight: '58.3rem',
    }),
    ...(isMobileLandscape && {
      overflowY: 'scroll',
      minHeight: 'max-content',
    }),
  })}
`

export const Main = styled.div<{
  isTabletPortrait: boolean
  isMobile: boolean
}>`
  display: grid;
  grid-template-areas: ${({ isMobile }) =>
    isMobile
      ? `'property'
          'buyer'
          'seller'`
      : `'property property'
         'buyer    seller'`};
  ${({ isMobile }) => !isMobile && 'grid-template-columns: 25.5rem 23.7rem'};
  grid-template-rows: ${({ isMobile }) => (isMobile ? 'minmax(12rem, auto)' : '13.9rem')} 9rem;
  ${({ theme, isMobile }) => isMobile && `grid-row-gap: ${theme.indent.MD}`};
  width: 100%;
  ${({ theme, isTabletPortrait, isMobile }) =>
    isTabletPortrait || isMobile
      ? `border-bottom: 0.1rem solid ${theme.color.lightGrey}`
      : `border-right: 0.1rem solid ${theme.color.lightGrey}`};
  ${({ theme, isTabletPortrait, isMobile }) => ({
    padding: `0 ${theme.indent.MD} 0 0`,
    ...(isTabletPortrait && { padding: `0 0 ${theme.indent.LG} 0` }),
    ...(isMobile && { padding: `0 0 ${theme.indent.MD} 0` }),
  })}
`

export const Secondary = styled.div<{ isMobile: boolean; isTabletPortrait: boolean }>`
  width: 100%;
  ${({ theme, isTabletPortrait, isMobile }) => ({
    padding: `0 0 0 ${theme.indent.MD}`,
    ...(isTabletPortrait && { padding: `${theme.indent.LG} 0 0 0` }),
    ...(isMobile && { padding: `${theme.indent.MD} 0 0 0` }),
  })}
`

export const PropertyInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 12.2rem;
  grid-area: property;
`

export const InfoTable = styled.div<{ isMobile: boolean }>`
  display: grid;
  ${({ isMobile }) => isMobile && 'width: 30rem'};
  grid-template-columns: 2.4rem auto;
  grid-row-gap: ${({ theme }) => theme.indent.XS};
  margin-top: ${({ theme }) => theme.indent.XS};
`

export const ViewingTitle = styled(Typography.H3)<{ hasCancelInfo: boolean }>`
  ${({ theme }) => theme.color.darkBlack}
`

export const BuyerInfo = styled.div`
  min-height: 8.8rem;
  grid-area: buyer;
`

export const SellerInfo = styled.div`
  min-height: 8.8rem;
  grid-area: seller;
`

export const InfoIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2.4rem;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.indent.XS};
  margin-left: ${({ theme }) => theme.indent.SM};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.indent.MD};
  margin-top: ${({ theme }) => theme.indent.LG};
`

export const StatusBlock = styled.div`
  margin-top: ${({ theme }) => theme.indent.MD};
`

export const StatusView = styled.div`
  margin-top: ${({ theme }) => theme.indent.XS};
  display: flex;
  gap: ${({ theme }) => theme.indent.SM};
`

export const CloseButton = styled.div`
  position: absolute;
  right: 2.4rem;
  top: 1.8rem;
`
export const Body2 = styled(Typography.Body2)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
