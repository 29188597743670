import styled from 'styled-components'

export const CommentArea = styled.textarea<{ isNotes: boolean; isConfirmReason: boolean }>`
  border: 0.1rem solid
    ${({ theme, isNotes }) => (isNotes ? theme.color.lightGrey : theme.color.grey)};
  padding: ${({ theme }) => theme.indent.SM};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  width: 100%;
  ${({ isNotes, isConfirmReason }) => ({
    height: '20rem',
    ...(isNotes && { height: '20.8rem' }),
    ...(isConfirmReason && { height: '12rem' }),
  })};
  resize: ${({ isNotes }) => (isNotes ? 'both' : 'none')};
  ${({ isNotes }) => (isNotes ? '' : `margin-bottom: ${({ theme }) => theme.indent.SM}`)};

  :focus {
    outline: 0;
    border-color: ${({ theme }) => theme.color.originalBlue};

    ::placeholder {
      color: transparent;
    }
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.grey};
  }
`
