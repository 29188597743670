import React, { useCallback } from 'react'
import { format } from 'date-fns'
import { useMobile } from '@utils/hooks'
import Checkbox from '@molecules/Checkbox'
import { DayLabelContainer, DayLabelNumber, DayLabel } from './DayPickRow.style'

type DayPickProps = {
  dayName: string
  dayNumber: string
  currentWeek: string
  date: Date
  day: Date
  active: boolean
  today: boolean
  disabled: boolean
  chooseDay?: (dayNumber: string) => void
  selectedDay?: string
  onClick: (date: Date) => void
  withCheckbox?: boolean
  allChecked?: boolean
  setAllChecked: () => void
  onCheck: () => void
}

const DayPick: React.FC<DayPickProps> = ({
  dayName,
  dayNumber,
  active,
  disabled,
  today,
  chooseDay,
  selectedDay,
  date,
  onClick,
  withCheckbox,
  allChecked = false,
  setAllChecked = () => {},
  onCheck = () => {},
  day,
}) => {
  const isMobile = useMobile()
  const onClickDay = useCallback(() => {
    if (chooseDay) {
      chooseDay(dayNumber)
    } else {
      onClick(date)
    }
  }, [chooseDay, dayNumber, onClick, date])

  const labelActive = (): boolean => {
    if (!withCheckbox) {
      if (selectedDay) {
        return selectedDay === format(date, 'd LLLL y')
      }
      return active
    }
    return false
  }

  return (
    <DayLabelContainer
      active={labelActive()}
      isMobile={isMobile}
      key={dayNumber}
      onClick={disabled || withCheckbox ? () => {} : onClickDay}
      withCheckbox={withCheckbox}
    >
      <DayLabel isMobile={isMobile} active={labelActive()} today={today} disabled={disabled}>
        {dayName}
      </DayLabel>
      <DayLabelNumber isMobile={isMobile} active={labelActive()} disabled={disabled} today={today}>
        {dayNumber}
      </DayLabelNumber>
      {withCheckbox && (
        <Checkbox
          disabled={disabled}
          onChange={(v, toggle) => {
            if (toggle) {
              setAllChecked(false)
            }
            // 2021-05-07
            onCheck(format(day, 'y-MM-dd'), toggle ? false : allChecked)
          }}
          check={allChecked}
        />
      )}
    </DayLabelContainer>
  )
}

export default DayPick
