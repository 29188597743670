const firebaseConfig = {
  apiKey: process.env.APP_FIREBASE_API_KEY,
  authDomain: 'viewd-prod.firebaseapp.com',
  projectId: process.env.APP_FIREBASE_API_PROJECT_ID,
  storageBucket: 'viewd-prod.appspot.com',
  messagingSenderId: process.env.APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.APP_FIREBASE_APP_ID,
}

export default firebaseConfig
