import React, { useState } from 'react'
import { useDesktop, useMobile, useTabletPortrait } from '@utils/hooks'
import GlobalFilter from '../GlobalFilter'
import {
  SearchContainer,
  Header,
  DropdownContainer,
  ButtonRow,
  Button,
  ButtonText,
} from './AgentPropertyVisitInfoTitle.style'
import { Dropdown } from '@molecules'
import { TabsType } from '@constants/types'
import { tabs } from '@constants/dictionary'

type Props = {
  globalFilter: string
  setGlobalFilter: (filterValue: string) => void
  setFilter: (tab: string) => void
}

const AgentPropertyVisitInfoTitle: React.FC<Props> = (props): JSX.Element => {
  const { globalFilter, setGlobalFilter, setFilter } = props
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTablet = useTabletPortrait()
  const createOption = (tab: string): object => {
    return {
      id: tab,
      value: tab,
      title: tab,
    }
  }
  const dropdownOptions = [
    createOption(tabs.allVisits),
    createOption(tabs.upcomingVisits),
    createOption(tabs.pastVisits),
    createOption(tabs.cancelVisits),
  ]

  const [chosenTab, setChosenTab] = useState<string>(tabs.allVisits)
  const changeTabHandler = (tab: string): void => {
    setChosenTab(tab)
    setFilter(tab)
  }
  return (
    <Header isMobile={isMobile}>
      {isDesktop ? (
        <ButtonRow>
          {tabs &&
            Object.keys(tabs).map((tab): JSX.Element => {
              return (
                <Button
                  key={tabs[tab as keyof TabsType]}
                  active={chosenTab === tabs[tab as keyof TabsType]}
                >
                  <ButtonText
                    $active={chosenTab === tabs[tab as keyof TabsType]}
                    onClick={() => changeTabHandler(tabs[tab as keyof TabsType])}
                  >
                    {tabs[tab as keyof TabsType]}
                  </ButtonText>
                </Button>
              )
            })}
        </ButtonRow>
      ) : (
        <DropdownContainer isTablet={isTablet} isMobile={isMobile}>
          <Dropdown
            modalDropDown
            handleValue={(optionValue: string) => {
              changeTabHandler(optionValue)
            }}
            selectedValue={chosenTab}
            value={chosenTab}
            renderThin
            tableDropdown
          >
            {dropdownOptions.map((item) => (
              <Dropdown.Option key={item.id} value={item.value} title={item.title}>
                {item.title}
              </Dropdown.Option>
            ))}
          </Dropdown>
        </DropdownContainer>
      )}
      <SearchContainer isMobile={isMobile}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder="Search visit"
        />
      </SearchContainer>
    </Header>
  )
}

export default AgentPropertyVisitInfoTitle
