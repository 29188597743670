import React from 'react'
import { useMobile } from '@utils/hooks'
import BaseModal from '../BaseModal'
import { CloseButton, Container, Header, H2, ButtonContainer } from './AgencyDeleteAgent.style'
import { Icon } from '@atoms'
import { MODAL_MESSAGES } from '@constants/dictionary'
import { Button } from '@molecules'

import { AgencyDeleteAgentTypes } from '@constants/types/modals'

const AgencyDeleteAgent: React.FC<AgencyDeleteAgentTypes> = ({
  isDeleting,
  isShown,
  onCancel,
  onConfirm,
}) => {
  const isMobile = useMobile()

  return (
    <BaseModal isShown={isShown}>
      <Container isMobile={isMobile}>
        <CloseButton>
          <Icon icon="close" size="1.75rem" color="originalBlue" clickable onClick={onCancel} />
        </CloseButton>
        <Header isMobile={isMobile}>
          <Icon icon="delete-user" size="4rem" />
          <H2 $isMobile={isMobile}>{MODAL_MESSAGES.agencyDeleteAgentModal}</H2>
        </Header>
        <ButtonContainer>
          <Button.Primary
            label="Confirm"
            onClick={onConfirm}
            loader={isDeleting}
            disabled={isDeleting}
          />
        </ButtonContainer>
        <ButtonContainer>
          <Button.Secondary label="Cancel" onClick={onCancel} />
        </ButtonContainer>
      </Container>
    </BaseModal>
  )
}

export default AgencyDeleteAgent
