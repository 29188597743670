import {
  collection,
  getFirestore,
  getDocs,
  query,
  where,
  DocumentData,
  Timestamp,
} from 'firebase/firestore'
import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { getMidnightTime } from '@utils/functions'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getAgentVisits = async (): Promise<Array<{ viewing: DocumentData; id: string }>> => {
  const id = localStorage.getItem(LS_CONTSTANTS.uid)
  const db = getFirestore()
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const prop = userType === USER_TYPES.agency ? FS_Q_PARAMS.AGENCY_ID : FS_Q_PARAMS.AGENTID

  const todayMidnight = getMidnightTime(new Date())
  const todayMidnightTimestamp = Timestamp.fromDate(todayMidnight)

  const q = query(
    collection(db, FS_COL_NAMES.PROPERTY_VISITS),
    where(prop, '==', id),
    where(FS_Q_PARAMS.FROM_DATE_TIME, '>=', todayMidnightTimestamp),
  )
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((viewing) => ({
    viewing: viewing.data(),
    id: viewing.id,
  }))
}
