import React, { useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { format } from 'date-fns'
import { useTheme } from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import { useMobile } from '@utils/hooks'
import { Typography, Icon } from '@atoms'
import { BookingSelectionRow } from '@molecules'
import { CardTitle, TitleContainer, CardContainer, PhoneLink, Body2 } from './BookingInfoCard.style'
import { BookingInfoCardTypes } from '@constants/types/organisms'

const BookingInfoCard: React.FC<BookingInfoCardTypes> = (props): JSX.Element => {
  const {
    agent = { name: '', phoneNumber: '', id: '' },
    propertyProfile = { address: '', title: '' },
    propertyId,
    fromDateTime = new Date(),
    toDateTime = new Date(),
    visits = false,
    rating,
    status,
    onClick,
    id,
  } = props
  const isMobile = useMobile()
  const disabled = toDateTime.toDate ? toDateTime.toDate() <= new Date() : true
  const colorGrey = useTheme().color.darkGrey
  const fromTime = fromDateTime.toDate ? fromDateTime.toDate() : fromDateTime
  const toTime = toDateTime.toDate ? toDateTime.toDate() : toDateTime

  const removeProperty = useCallback(() => {
    onClick(id)
  }, [onClick, id])

  return (
    <CardTitle isMobile={isMobile}>
      <TitleContainer>
        <Body2 as="span" disabled={disabled} isMobile={isMobile}>
          {agent.name}
        </Body2>
        <PhoneLink isMobile={isMobile} href={`tel:${agent.phoneNumber}`}>
          <Icon icon="phone" clickable size="1.4rem" color={colorGrey} />
          <Body2 disabled={disabled} isMobile={isMobile} gutter>
            {agent.phoneNumber}
          </Body2>
        </PhoneLink>
      </TitleContainer>
      <CardContainer isMobile={isMobile}>
        <Row justifyContent="between">
          <Typography.H3 as="span" disabled={disabled} isMobile={isMobile}>
            {propertyProfile.title}
          </Typography.H3>
          {!visits && (
            <Icon icon="trash" clickable size="1.2rem" color={colorGrey} onClick={removeProperty} />
          )}
        </Row>
        <Body2 disabled={disabled} isMobile={isMobile} padding>
          {propertyProfile.address}
        </Body2>
        {visits ? (
          <Body2 disabled={disabled} isMobile={isMobile}>
            {`${format(fromTime, 'kk:mm')} - ${format(toTime, 'kk:mm')}`}
          </Body2>
        ) : (
          <BookingSelectionRow
            bookingDisabled={disabled}
            rating={rating}
            status={status || propertyProfile.status}
            agentId={agent.id}
            propertyId={propertyId}
            {...propertyProfile}
          />
        )}
      </CardContainer>
    </CardTitle>
  )
}

export default withRouter(BookingInfoCard)
