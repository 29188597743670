export const ROUTES = {
  // BUYER
  BUYER: '/buyer',
  BUYER_BOOKING: '/buyer/booking',
  BUYER_CONFIRM: '/buyer/confirm',
  BUYER_SURVEY: '/buyer/survey',
  BUYER_SURVEY_ANSWER: '/buyer/survey-answer',
  BUYER_VISITS: '/buyer/visits',
  BUYER_SHORTLIST: '/buyer/shortlist',
  BUYER_SURVEY_COMPLETED: '/buyer/survey-completed',
  // SELLER
  SELLER: '/seller',
  SELLER_ID: '/seller/:id',
  SELLER_PROPERTY: '/seller/property',
  SELLER_SLOTS: '/seller/slots',
  SELLER_VISITS: '/seller/visits',
  SELLER_STATISTICS: '/seller/statistics',
  SELLER_CONFIG_SLOTS: '/seller/config-slots',
  SELLER_NOTIFICATIONS: '/seller/notifications',
  SELLER_CANCEL_VISIT: '/seller/cancel-visit',
  SELLER_UNDER_OFFER: '/seller/cancel-visit/under-offer',
  SELLER_URGENT_MATTERS: '/seller/cancel-visit/urgent-matters',
  SELLER_CANCEL_SLOTS: '/seller/cancel-slots',
  // OTHER
  ERROR: '/error',
  SHORTED_LINK: '/l/:id',
  PRIVACY_POLICY: '/privacy-policy',
  // AGENT
  AGENT: '/agent',
  AGENT_LOGIN: '/agent/login',
  AGENT_HELP_PAGE: '/agent/help',
  AGENT_ADD_PROPERTY: '/agent/add-property-page',
  AGENT_RESET_PASSWORD: '/agent/reset-password',
  AGENT_NEW_PASSWORD: '/agent/new-password',
  AGENT_PROPERTIES: '/agent/properties',
  AGENT_VIEWINGS: '/agent/viewings',
  AGENT_BUYERS: '/agent/buyers',
  AGENT_SELLERS: '/agent/sellers',
  AGENT_ANALYTICS: '/agent/analytics',
  AGENT_ADD_USER: '/agent/add-user',
  AGENT_BUYERS_BUYER_INFO: '/agent/buyers/:id/buyer-info',
  AGENT_BUYERS_VISIT_INFO: '/agent/buyers/:id/visits-info',
  AGENT_SELLERS_SELLER_INFO: '/agent/sellers/:id/seller-info',
  AGENT_SELLERS_PROPERTY_LIST: '/agent/sellers/:id/property-list',
  AGENT_PROPERTY_INFO: '/agent/properties/:id/property-info',
  AGENT_PROPERTY_VISIT_INFO: '/agent/properties/:id/visits-info',
  AGENT_PROPERTY_TIME_SLOTS: '/agent/properties/:id/time-slots',
  AGENT_SETTINGS: '/agent/settings',
  AGENT_PRIVACY_POLICY: '/agent/privacy-policy',
  AGENT_EDIT_BUYER: '/agent/buyer/:id/edit-user',
  AGENT_EDIT_SELLER: '/agent/seller/:id/edit-user',
  AGENT_EDIT_PROPERTY: '/agent/:id/edit-property',
  AGENT_EDIT_PROPERTY_SHORT: '/edit-property/',
}

export const URLS_WITHOUT_HEADER = [
  ROUTES.AGENT_LOGIN,
  ROUTES.AGENT_NEW_PASSWORD,
  ROUTES.AGENT_RESET_PASSWORD,
]

export const URLS_WITHOUT_FOOTER = [
  ROUTES.AGENT_LOGIN,
  ROUTES.AGENT_NEW_PASSWORD,
  ROUTES.AGENT_RESET_PASSWORD,
]
