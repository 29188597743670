export const UNDER_OFFER = {
  CONFIRM: {
    HEADER: 'Are you sure?',
    MESSAGES: 'All scheduled visits and available days for for booking will be canceled.',
  },
  CONFIRMED: {
    HEADER: `All days for visits and
bookings have been canceled`,
  },
}
