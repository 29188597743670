import { startOfDay, compareAsc, format, isToday, isSunday, startOfToday } from 'date-fns'
import { State, SellerSlot } from '@constants/types'

const prepareDayArray = (week: Array<Date>): Array<{ day: Date; slots: Array }> => {
  return week.map((item: Date) => {
    return {
      day: item,
      slots: [],
    }
  })
}

const compareCondition = (item, day): Date | number =>
  compareAsc(new Date(startOfDay(item.fromDateTime.toDate())), new Date(startOfDay(day.day))) === 0

const mapFinalData = (week, first): Array =>
  week.map((item) => ({
    dayName: format(item.day, 'ccc'),
    dayNumber: format(item.day, 'dd'),
    today: isToday(item.day),
    active: first ? isToday(item.day) : isSunday(item.day),
    disabled: startOfToday() > item.day,
    ...item,
  }))

// map weeks for cancel by seller
const mapFilterBookedData = (week, first): { week: Array } => {
  // for displaying booked slots
  const bookedSlots = []
  week.forEach((day) =>
    day.slots.forEach((slot) => {
      if (slot.buyer !== null && slot.agent !== null) {
        bookedSlots.push(day.day)
      }
    }),
  )

  return {
    week: week.map((item) => ({
      dayName: format(item.day, 'ccc'),
      dayNumber: format(item.day, 'dd'),
      today: isToday(item.day),
      active: first ? isToday(item.day) : isSunday(item.day),
      disabled:
        // if before today
        startOfToday() > item.day ||
        // if no slots
        !item.slots.length ||
        // if there any booked slots
        bookedSlots.includes(item.day),
      ...item,
    })),
    bookedSlots,
  }
}

export const sortedInToTwoWeeks = ({
  sellerSlots,
  sellerWeek,
  sellerNextWeek,
  filterBooked,
}: {
  sellerSlots: Array<SellerSlot>
  sellersWeek: Array<Date>
  sellersNextWeek: Array<Date>
  filterBooked: boolean
}): State => {
  const firstWeek = prepareDayArray(sellerWeek)
  const secondWeek = prepareDayArray(sellerNextWeek)

  sellerSlots.forEach((item) => {
    firstWeek.forEach((day, index) => {
      if (compareCondition(item, day)) {
        firstWeek[index].slots.push(item)
      }
    })
    secondWeek.forEach((day, index) => {
      if (compareCondition(item, day)) {
        secondWeek[index].slots.push(item)
      }
    })
  })

  if (filterBooked) {
    return {
      firstWeek: mapFilterBookedData(firstWeek, true),
      secondWeek: mapFilterBookedData(secondWeek),
    }
  }

  return {
    firstWeek: mapFinalData(firstWeek, true),
    secondWeek: mapFinalData(secondWeek),
  }
}

export const sortedInToWeek = (allSlots: Array<SellerSlot>, week: Array<Date>): State => {
  const sortedWeek = prepareDayArray(week)

  allSlots.forEach((item) => {
    sortedWeek.forEach((day, index) => {
      if (compareCondition(item, day)) {
        sortedWeek[index].slots.push(item)
      }
    })
  })
  return mapFinalData(sortedWeek, true)
}
