import { BOOKING_STATUSES } from '@constants/dictionary'
export const checkSeller = (name: string, isEdit: boolean | undefined): boolean =>
  name === '' && !isEdit

export const checkTitle = (array: Array<T>, status: string): string =>
  array.filter((item) => item.value === status)[0].title

export const filterEmptyValue = (array: Array<T>): Array<T> =>
  array.filter((item) => item.value != '')

export const getStatusDescription = (array: Array<T>, status: string): string =>
  array.filter((item) => item.value === (status || BOOKING_STATUSES.ACTIVE))[0].text
