import React, { useReducer } from 'react'
import { useMobile, useDate, useCreateSlots } from '@utils/hooks'
import { useLocation, Redirect, useHistory } from 'react-router-dom'
import {
  getWeek,
  indexToDay,
  indexActiveDay,
  isButtonDisabled,
  getSlotsFromPastWeek,
  isDisabledNewPeriod,
  getTimePeriodBefore,
  getTimePeriodNext,
} from '@utils/functions'
import { sellerSlotsReducer } from '@utils/reducers'
import { ROUTES, SIX_DAY } from '@constants/dictionary'
import { useTheme } from 'styled-components'
import { ActionsSellerSlotsReducer } from '@constants/actions'
import { DayTypes, Action, TimePeriodsTypes } from '@constants/types'
import { DayPickRow, Button, IconAndLabel } from '@molecules'
import { SelectTimePeriod } from '@organisms'
import { setSellerSlots } from '@services/firebase'
import {
  StyledCol1,
  StyledCol2,
  ButtonContainer,
  Container,
  BottomContainer,
  BottomTitle,
  DayPickRowContainer,
  H1,
} from './SellerConfigSlots.styled'

const SellerConfigSlots = (): JSX.Element => {
  const isMobile = useMobile()
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const { sellerDaysList, getSellersWeek, sellerDaysListNextWeek, getSellersNextWeek } = useDate()

  const sellersWeek = getSellersWeek()
  const sellerNextWeek = getSellersNextWeek()

  const sellerNextWeekDaysList =
    location.state && location.state.usePastWeek
      ? getSlotsFromPastWeek(sellerDaysListNextWeek, location.state.pastWeek)
      : sellerDaysListNextWeek

  const currentWeek =
    location.state && location.state.emptyFirstWeek ? getWeek(sellersWeek) : getWeek(sellerNextWeek)

  const initialState =
    location.state && location.state.emptyFirstWeek ? sellerDaysList : sellerNextWeekDaysList

  const [state, dispatch] = useReducer<React.Reducer<Array<DayTypes>, Action>>(
    sellerSlotsReducer,
    initialState,
  )
  const activeDay = indexActiveDay(state)
  const tooDay = indexToDay(state)

  const chooseDay = (chooseday: string): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.chooseDay,
      payload: chooseday,
    })
  }

  const toPreviousDay = (): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.toPreviousDay,
      payload: activeDay,
    })
  }

  const toNextDay = (): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.toNextDay,
      payload: activeDay,
    })
  }

  const addNewTimePeriod = (): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.addNewTimePeriod,
      payload: 0,
    })
  }

  const removeTimePeriod = (id: number): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.removeTimePeriod,
      payload: id,
    })
  }

  const addFromTime = (id: number): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.addFromTime,
      payload: id,
    })
  }

  const minusFromTime = (id: number): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.minusFromTime,
      payload: id,
    })
  }

  const addUntilTime = (id: number): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.addUntilTime,
      payload: id,
    })
  }

  const minusUtilTime = (id: number): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.minusUtilTime,
      payload: id,
    })
  }
  const setFromTime = (id: number, setDate: Date): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.setFromTime,
      payload: { id, setDate },
    })
  }

  const setUntilTime = (id: number, setDate: Date): undefined => {
    dispatch({
      type: ActionsSellerSlotsReducer.setUntilTime,
      payload: { id, setDate },
    })
  }
  const SaveDate = (): undefined => {
    const arraySlots = useCreateSlots(state)
    setSellerSlots(arraySlots)
    history.push(ROUTES.SELLER_SLOTS)
  }
  const disabledButton = !isButtonDisabled(state)

  const disabled = isDisabledNewPeriod(state)

  return (
    <>
      {!location.state ? (
        <Redirect to={ROUTES.SELLER_SLOTS} />
      ) : (
        <>
          <H1 isMobile={isMobile}>Property time slots for booking</H1>
          <DayPickRowContainer isMobile={isMobile}>
            <DayPickRow currentWeek={currentWeek} daysList={state} chooseDay={chooseDay} />
          </DayPickRowContainer>
          {state.map((day: DayTypes) =>
            day.active
              ? day.timePeriods.map((slot: TimePeriodsTypes, index: number) => (
                  <SelectTimePeriod
                    addFromTime={addFromTime}
                    minusFromTime={minusFromTime}
                    removeTimePeriod={removeTimePeriod}
                    minusUtilTime={minusUtilTime}
                    addUntilTime={addUntilTime}
                    setUntilTime={setUntilTime}
                    setFromTime={setFromTime}
                    allHours={day.allHours}
                    timePeriodBefore={getTimePeriodBefore(day, index)}
                    timePeriodNext={getTimePeriodNext(day, index)}
                    slot={slot}
                    id={index}
                    key={`${day.dayNumber + index}`}
                  />
                ))
              : null,
          )}
          <Container isMobile={isMobile} onClick={!disabled ? addNewTimePeriod : null}>
            <IconAndLabel
              textColor={disabled ? theme.color.darkGrey : theme.color.originalBlue}
              title="Add new time period"
              icon="add"
              clickable={!disabled}
              color={disabled ? 'darkGrey' : 'originalBlue'}
            />
          </Container>
          <ButtonContainer isMobile={isMobile}>
            <StyledCol1 xs={6} sm={6}>
              <Button.Primary
                disabled={activeDay < tooDay + 1}
                onClick={toPreviousDay}
                width="100%"
                label="< Previous day"
              />
            </StyledCol1>
            <StyledCol2 xs={6} sm={6}>
              <Button.Primary
                onClick={toNextDay}
                disabled={activeDay === SIX_DAY}
                width="100%"
                label="Next day >"
              />
            </StyledCol2>
          </ButtonContainer>
          <BottomContainer isMobile={isMobile}>
            <BottomTitle>Click on &#39;Save&#39; button to finish scheduling</BottomTitle>
            <Button.Secondary
              disabled={disabledButton}
              width="100%"
              label="Save"
              onClick={SaveDate}
            />
          </BottomContainer>
        </>
      )}
    </>
  )
}

export default SellerConfigSlots
