import { Typography } from '@atoms'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledImage = styled.img`
  width: ${(p) => (p.isMobile ? '20rem' : '28rem')};
  height: ${(p) => (p.isMobile ? '20rem' : '28rem')};
  margin-top: 15%;
  margin-bottom: ${(p) => p.theme.indent.XL};
`

export const Body1 = styled(Typography.Body1)`
  text-align: center;
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.XS : p.theme.indent.LG)};
  padding-bottom: ${(p) => p.theme.indent.LG};
`
