import styled from 'styled-components'
import { VIEWING_STATUSES } from '@constants/dictionary'
import { Typography } from '@atoms'

export const StatusContainer = styled.div<{
  backgroundColor: string
  status: string
  isLoading: boolean
}>`
  display: flex;
  ${({ isLoading }) => (isLoading ? 'justify-content: center' : '')};
  align-items: center;
  min-width: 12rem;
  height: 2.4rem;
  border-radius: ${({ theme }) => theme.borderRadius.XXL};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: ${({ status }) =>
    status === VIEWING_STATUSES.CONFIRM_VIEW.statusKey ? 'pointer' : 'default'};
`

export const StatusIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.indent.SM};
`

export const StatusCaption = styled(Typography.Caption2)<{ status: string }>`
  font-family: ${({ theme }) => theme.fontFamily.openSansRegular};
  font-weight: 400;
  padding-left: ${({ status, theme }) =>
    status === VIEWING_STATUSES.CANCELED.statusKey ? theme.indent.XS : theme.indent.XXS};
  color: ${({ textColor }) => textColor};
  cursor: inherit;
  &:hover {
    color: ${({ status, theme }) =>
      status === VIEWING_STATUSES.CONFIRM_VIEW.statusKey ? theme.color.originalBlue_hover : ''};
  }
`
