import { COLORS } from '../themeConstants'

const MAIN_COLORS = {
  primary: COLORS.white,
  secondary: COLORS.blue,
  white: COLORS.white,
  transparent: COLORS.transparent,
  blue: COLORS.blue,
  originalBlue: COLORS.originalBlue,
  originalBlue_20: COLORS.originalBlue_20,
  originalBlue_10: COLORS.originalBlue_10,
  originalBlue_hover: COLORS.originalBlue_hover,
  darkBlue: COLORS.darkBlue,
  darkBlue_90: COLORS.darkBlue_90,
  darkBlue_60: COLORS.darkBlue_60,
  lightGrey: COLORS.lightGrey,
  lightGrey_20: COLORS.lightGrey_20,
  grey: COLORS.grey,
  greyBlue: COLORS.greyBlue,
  darkGrey: COLORS.darkGrey,
  darkBlack: COLORS.darkBlack,
  black: COLORS.black,
  black_20: COLORS.black_20,
  black_50: COLORS.black_50,
  darkPurple: COLORS.darkPurple,
  yellow: COLORS.yellow,
  yellow_15: COLORS.yellow_15,
  red: COLORS.red,
  lightRed: COLORS.lightRed,
  red_15: COLORS.red_15,
  green: COLORS.green,
  green_15: COLORS.green_15,
  successGreen: COLORS.successGreen,
  lightGreen: COLORS.lightGreen,
  whiteSmoke: COLORS.whiteSmoke,
  darkGrey_15: COLORS.darkGrey_15,
  black_30: COLORS.black_30,
  lightBlack: COLORS.lightBlack,
  lightBlack_15: COLORS.lightBlack_15,
  darkYellow: COLORS.darkYellow,
  darkYellow_15: COLORS.darkYellow_15,
  lightBlue: COLORS.lightBlue,
}

export default MAIN_COLORS
