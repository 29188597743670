export const CANCEL_VISIT = {
  HEADER: 'Choose your option:',
  UNDER_OFFER: 'Property under offer',
  UNDER_OFFER_MESSAGE: 'All days for visits and days for booking will be canceled.',
  URGENT_MATTERS: 'Urgent matters',
  URGENT_MATTERS_MESSAGE: `You can select the day(s) during the week when you don't have \n the opportunity to show the property.`,
  YOU_HAVE_BOOKED_SLOTS_1: 'You can’t cancel ',
  YOU_HAVE_BOOKED_SLOTS_2:
    ' because you have got bookings on these days, \n if you can’t show your property contact your agent.',
}
