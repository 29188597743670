import React from 'react'
import { useLocation } from 'react-router-dom'
import { AgentAddUserCard } from '@organisms'

const AddUser = (): JSX.Element => {
  const location = useLocation()
  return <AgentAddUserCard seller={location.state === 'Seller'} />
}

export default AddUser
