import { addMinutes, compareAsc, startOfHour, addHours } from 'date-fns'
import { TimePeriodsTypes, DayTypes } from '@constants/types'
import { THIRTY_MINUTES, PRE_LAST_INDEX } from '@constants/dictionary'

export const isDisabledMinusFromTime = (
  timePeriodBefore: TimePeriodsTypes | null,
  slot: TimePeriodsTypes,
  allHours: Array<Date>,
): boolean => {
  const checkPeriodBefore = (): boolean => {
    if (timePeriodBefore) {
      return (
        compareAsc(addMinutes(timePeriodBefore.toDateTime, THIRTY_MINUTES), slot.fromDateTime) === 0
      )
    }
    return false
  }

  if (compareAsc(slot.fromDateTime, allHours[0]) === 0 || checkPeriodBefore()) {
    return true
  }
  return false
}

export const isDisabledAddUntilTime = (
  timePeriodNext: TimePeriodsTypes | null,
  slot: TimePeriodsTypes,
  allHours: Array<Date>,
): boolean => {
  const checkPeriodNext = (): boolean => {
    if (timePeriodNext) {
      return (
        compareAsc(addMinutes(timePeriodNext.fromDateTime, -THIRTY_MINUTES), slot.toDateTime) === 0
      )
    }
    return false
  }

  if (timePeriodNext) {
    if (
      compareAsc(slot.toDateTime, allHours[allHours.length - 1]) === 0 ||
      compareAsc(slot.toDateTime, allHours[allHours.length - PRE_LAST_INDEX]) === 0 ||
      checkPeriodNext()
    ) {
      return true
    }
    return false
  } else {
    if (compareAsc(slot.toDateTime, allHours[allHours.length - 1]) === 0 || checkPeriodNext()) {
      return true
    }
  }
  return false
}

export const isDisabledTime = (slot: TimePeriodsTypes): boolean => {
  if (compareAsc(addMinutes(slot.fromDateTime, THIRTY_MINUTES), slot.toDateTime) === 0) {
    return true
  }
  return false
}

export const isDisabledNewPeriod = (state: Array<DayTypes>): boolean => {
  const isActive = (day: DayTypes): DayTypes | undefined => {
    if (day.active) return day
  }
  const active = state.find(isActive)
  const timePeriods = active.timePeriods
  if (timePeriods.length > 0) {
    const lastPeriod = timePeriods[timePeriods.length - 1]
    const lastHourInDay = active.allHours[active.allHours.length - 1]
    const PrelastHourInDay = active.allHours[active.allHours.length - PRE_LAST_INDEX]
    if (
      compareAsc(lastPeriod.toDateTime, lastHourInDay) === 0 ||
      compareAsc(lastPeriod.toDateTime, PrelastHourInDay) === 0
    ) {
      return true
    }
    return false
  } else {
    if (
      compareAsc(
        startOfHour(addHours(new Date(), 1)),
        active.allHours[active.allHours.length - 1],
      ) >= 0
    ) {
      return true
    }
  }
  return false
}
