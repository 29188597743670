import { getFirestore, doc, collection, getDocs, query, where, getDoc } from 'firebase/firestore'
import { getAgencyDataId } from '@utils/functions'
import { getUserById } from './getUserById'
import { FS_COL_NAMES, FS_COL_GROUP_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getPropertyById = (propertyId: string): { error: string } => {
  const db = getFirestore()
  return doc(db, FS_COL_NAMES.PROPERTIES, propertyId)
}

export const getPropertyProfile = async (propertyId: string, isUser: string): { error: string } => {
  try {
    const { isAgency, agentId, uid } = getAgencyDataId(isUser)

    const q = isAgency
      ? query(
          collection(getPropertyById(propertyId), FS_COL_GROUP_NAMES.PROPERTY_PROFILES),
          where(FS_Q_PARAMS.AGENCY_ID, '==', uid),
          !!agentId && where(FS_Q_PARAMS.AGENT_ID, '==', agentId),
        )
      : query(
          collection(getPropertyById(propertyId), FS_COL_GROUP_NAMES.PROPERTY_PROFILES),
          !!agentId && where(FS_Q_PARAMS.AGENT_ID, '==', agentId),
        )

    const propertyProfiles = await getDocs(q)
    const docData = propertyProfiles?.docs[0]
    return {
      ...docData.data(),
      propertyProfileId: docData?.id,
    }
  } catch (error) {
    error
  }
}

export const getPropertyProfileById = async (
  propertyId: string,
  propertyProfileId: string,
): { error: string } => {
  const refDoc = doc(
    getPropertyById(propertyId),
    FS_COL_GROUP_NAMES.PROPERTY_PROFILES,
    propertyProfileId,
  )
  const docShapshot = await getDoc(refDoc)
  if (docShapshot.exists()) {
    return docShapshot?.data()
  }
  return {
    sellerId: '',
  }
}

export const getPropertyProfileCollection = async (propertyId: string): { error: string } => {
  const propertyProfiles = await getDocs(
    collection(getPropertyById(propertyId), FS_COL_GROUP_NAMES.PROPERTY_PROFILES),
  )
  const profiles = await Promise.all(
    propertyProfiles.docs.map(async (propertyProfile) => {
      // request all agent details for profiles
      const agentDetails = await getUserById(propertyProfile.data()?.agentId)
      return {
        propertyProfileId: propertyProfile.id,
        ...propertyProfile.data(),
        agentDetails,
      }
    }),
  )
  return profiles
}
