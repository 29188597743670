import React from 'react'
import { useMobile } from '@utils/hooks'

import { StyledCol1, StyledCol2, ButtonContainer } from './TwoButtonRow.styled'
import { TwoButtonRowProps } from '@constants/types/molecules'

const TwoButtonRow: React.FC<TwoButtonRowProps> = (props) => {
  const isMobile = useMobile()

  const { firstButton, secondButton } = props
  return (
    <ButtonContainer isMobile={isMobile}>
      <StyledCol1 col={6}>{firstButton}</StyledCol1>
      <StyledCol2 col={6}>{secondButton}</StyledCol2>
    </ButtonContainer>
  )
}

export default TwoButtonRow
