import {
  getFirestore,
  collection,
  query,
  where,
  Timestamp,
  QuerySnapshot,
  getDocs,
} from 'firebase/firestore'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'
import { getMidnightTime } from '@utils/functions'

export const getBuyerVisits = async (): Promise<QuerySnapshot> => {
  const db = getFirestore()
  const col = collection(db, FS_COL_NAMES.PROPERTY_VISITS)

  const todayMidnight = getMidnightTime(new Date())
  const todayMidnightTimestamp = Timestamp.fromDate(todayMidnight)

  const visitsQuery = query(
    col,
    where(FS_Q_PARAMS.BUYERID, '==', localStorage.getItem(LS_CONTSTANTS.uid)),
    where(FS_Q_PARAMS.CANCELLED, '==', null),
    where(FS_Q_PARAMS.FROM_DATE_TIME, '>=', todayMidnightTimestamp),
  )
  const querySnapshot = await getDocs(visitsQuery)

  return querySnapshot
}
