import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { SellerAddedLabel } from '@molecules'

import { UserAddedLabelFormProps } from '@constants/types/helpers'

const UserAddedLabelForm: React.FC<UserAddedLabelFormProps> = ({
  name,
  error,
  sellerName,
  value,
  onClick,
  disabled,
  isEditProperty,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <SellerAddedLabel
          {...field}
          sellerName={sellerName}
          error={error}
          value={value}
          onClick={onClick}
          disabled={disabled}
          isEditProperty={isEditProperty}
        />
      )}
    />
  )
}
export default UserAddedLabelForm
