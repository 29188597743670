import React from 'react'
import { Icon, Loader } from '@atoms'
import { Link, useLocation } from 'react-router-dom'
import { useMobile, useFirebaseAuth, useModal } from '@utils/hooks'
import { ROUTES, LS_CONTSTANTS } from '@constants/dictionary'
import { mapNotifications, hasUnread } from '@utils/functions'
import { getUserNotifications, markAllAsRead } from '@services/firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import { NotificationsModal } from '@modals'
import { FooterContainer, NotificationDot, NotificationWrapper } from './UserHeader.style'

const UserHeader = (): JSX.Element => {
  const isMobile = useMobile()
  const { isShown, toggle } = useModal()
  const redirectLink = localStorage.getItem(LS_CONTSTANTS.redirectLink) || '/'
  const { pathname } = useLocation()

  const [logedIn] = useFirebaseAuth()

  const userId = localStorage.getItem(LS_CONTSTANTS.uid) || ''
  const notificatiionsQuery = getUserNotifications(userId)
  const [notificationsList, loading] = useCollection(notificatiionsQuery)
  const notifications = loading ? [] : mapNotifications(notificationsList)
  const { newMessages } = hasUnread(notifications.list)
  const onClickToRead = (): undefined => {
    markAllAsRead(notifications.idList)
  }

  const renderNotifications = (): React.FC | null => {
    if (loading) {
      return <Loader skeleton loaded={!loading} />
    } else if (!notifications.list.length) {
      return null
    } else {
      return (
        <NotificationWrapper onClick={toggle}>
          <Icon icon="notification" clickable size="2.4rem" color="white" />
          <NotificationDot areUnread={newMessages} />
        </NotificationWrapper>
      )
    }
  }

  return (
    <FooterContainer isMobile={isMobile}>
      <div>{/* <Icon icon="arrow-back" clickable size="2.4rem" color="white" /> */}</div>
      <Link to={logedIn ? redirectLink : '/'}>
        <Icon icon="logo" clickable size="7.25rem" color="white" />
      </Link>
      <div>
        {pathname !== ROUTES.ERROR && logedIn && (
          <>
            {renderNotifications()}
            <NotificationsModal
              title="Notifications"
              isShown={isShown}
              onClose={toggle}
              notificationsList={notifications.list}
              hasUnread={newMessages}
              onClickToRead={onClickToRead}
            />
          </>
        )}
      </div>
    </FooterContainer>
  )
}

export default UserHeader
