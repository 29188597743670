/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'styled-bootstrap-grid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMountEffect } from '@utils/hooks'
import { customFormatValue } from '@utils/functions'
import { phoneForm } from '@constants/regExp'
import { getUserById, updateUserData, getImgUrl } from '@services/firebase'
import {
  LS_CONTSTANTS,
  FIXED_INPUT_PHONE_NUMBERS,
  USER_TYPES,
  phoneCode,
} from '@constants/dictionary'
import { InputForm, DropZoneForm } from '@helpers'
import { Input, Dropdown, Button } from '@molecules'
import { Loader, Typography } from '@atoms'
import { schema } from './AgencyValidation'
import {
  Container,
  StyledCol,
  DropdownLabel,
  DropdownContainer,
  ButtonContainer,
} from './AccountPreferences.style'

const FIELDS_ARRAY = ['name', 'email', 'phoneNumber', 'location']
const CUT_PHONE_CODE_LENGTH = 3

const AccountPreferences: JSX.Element<AccountPreferencesTypes> = (): JSX.Element => {
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const userId = localStorage.getItem(LS_CONTSTANTS.uid)

  const [loaded, setLoaded] = useState<boolean>(false)
  const [userData, setUserData] = useState({})

  const loadUserData = async (): Promise<undefined> => {
    const userData = await getUserById(userId)
    setUserData(userData)
    // set default values by async way
    FIELDS_ARRAY.forEach((field) => {
      if (field === 'phoneNumber') {
        return setValue(
          field,
          customFormatValue(userData[field]?.substring(CUT_PHONE_CODE_LENGTH), phoneForm, '$1 $2'),
        )
      }
      return setValue(field, userData[field])
    })
    setLoaded(true)
  }
  useMountEffect(loadUserData)

  const [errorDropZone, setErrorDropZone] = useState<string>('')

  const defaultValues = {
    name: '',
    email: '',
    phoneNumber: '',
    location: '',
    imageUrl: '',
  }
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const { handleSubmit, watch, formState, setValue, reset } = methods
  const { errors, isDirty } = formState

  const name = watch('name')
  const phoneNumber = watch('phoneNumber')
  const location = watch('location')
  const image = watch('image')

  const errorUrl = errors.image ? errors.image.message : ''
  const getDropZoneError = (message: string): Dispatch<string> => setErrorDropZone(message)

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const submitResult = async (): Promise<undefined> => {
    setLoaded(false)
    const imageUrl = await getImgUrl(image, 'images/agency/')
    const data = {
      name,
      phoneNumber: phoneCode + phoneNumber.replace(' ', ''),
      country: 'United Kingdom',
      location,
      ...(imageUrl ? { profilePictureImageUrl: imageUrl } : {}),
    }
    await updateUserData(data, userId)
    reset(defaultValues)
    await loadUserData()
    setLoaded(true)
  }

  const renderEmailField = (): JSX.Element => (
    <Row>
      <Input inputLabel="Contact Email" disabled value={userData?.email} />
    </Row>
  )

  const renderAgentFields = (): JSX.Element => (
    <StyledCol>
      <Row>
        <Input inputLabel="Full Name" disabled value={userData?.name} />
      </Row>
      {renderEmailField()}
      <Row>
        <Input inputLabel="Phone Number" disabled value={userData?.phoneNumber} />
      </Row>
    </StyledCol>
  )

  return (
    <Container>
      <Loader loaded={loaded} noMargin>
        {userType === USER_TYPES.agent ? (
          renderAgentFields()
        ) : (
          <>
            <FormProvider {...methods}>
              <Row>
                <StyledCol>
                  <Row>
                    <InputForm
                      name="name"
                      disabled={!loaded}
                      placeholder="Enter agency name"
                      errorMessage="Value is required"
                      inputLabel="Agency Name"
                      error={hasError('name')}
                      maxLength={50}
                      tabIndex={1}
                      isName
                    />
                  </Row>
                  {renderEmailField()}
                  <Row>
                    <InputForm
                      name="phoneNumber"
                      disabled={!loaded}
                      placeholder="Enter phone number"
                      errorMessage={errors?.phoneNumber?.message || 'Not a valid number'}
                      inputLabel="Phone Number"
                      error={hasError('phoneNumber')}
                      maxLength={FIXED_INPUT_PHONE_NUMBERS}
                      isPhone
                      tabIndex={3}
                    />
                  </Row>
                  <DropdownContainer>
                    <DropdownLabel>Country/Region</DropdownLabel>
                    <Dropdown selectedValue="United Kingdom" testid="AgencyCountry" formDropdown>
                      <Dropdown.Option key="uk" value="uk" title="United Kingdom" renderThin>
                        United Kingdom
                      </Dropdown.Option>
                    </Dropdown>
                  </DropdownContainer>
                  <Row>
                    <InputForm
                      name="location"
                      disabled={!loaded}
                      placeholder="Enter agency location"
                      errorMessage="Value is required"
                      inputLabel="Location"
                      error={hasError('location')}
                      tabIndex={3}
                    />
                  </Row>
                </StyledCol>
                <DropZoneForm
                  name="image"
                  disabled={!loaded}
                  errorMessage=""
                  error={hasError('image')}
                  getError={getDropZoneError}
                  imageUrl={userData?.profilePictureImageUrl}
                  profile
                >
                  {errorUrl && <Typography.Body3 color="red">{errorUrl}</Typography.Body3>}
                  {errorDropZone && (
                    <Typography.Body3 color="red">{errorDropZone}</Typography.Body3>
                  )}
                </DropZoneForm>
              </Row>
              <ButtonContainer>
                <Button.Primary
                  width="18rem"
                  disabled={!loaded || !isDirty || !!errorUrl || !!errorDropZone}
                  label="Save Changes"
                  onClick={handleSubmit(submitResult)}
                />
              </ButtonContainer>
            </FormProvider>
          </>
        )}
      </Loader>
    </Container>
  )
}

export default AccountPreferences
