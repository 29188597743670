import React, { useEffect, useState, useCallback } from 'react'
import BaseModal from '../BaseModal'
import { Loader } from '@atoms'
import { remindSellerToAddSlots, sendPropertyLink } from '@services/firebase'
import SMSModal from '@assets/images/SMSModal.svg'

import SuccessModal from '../SuccessModal'

import { SendModalTypes } from '@constants/types/modals'

const SendModal: React.FC<SendModalTypes> = ({
  header,
  message,
  onConfirm,
  isShown,
  hide,
  type,
  propertyId,
  buyerId,
  propertyProfileId,
}) => {
  const [loader, setLoader] = useState(false)

  const sendReminder = useCallback(async () => {
    await remindSellerToAddSlots(propertyId, propertyProfileId)
    return setLoader(true)
  }, [propertyId, propertyProfileId])

  const sendLink = useCallback(async () => {
    await sendPropertyLink(propertyId, buyerId, propertyProfileId)
    return setLoader(true)
  }, [propertyId, buyerId, propertyProfileId])

  useEffect(() => {
    switch (type) {
      case 'reminder':
        return sendReminder()
      case 'link':
        return sendLink()
      default:
        return null
    }
  }, [type, sendReminder, sendLink])
  return (
    <BaseModal isShown={isShown} hide={hide}>
      <Loader bgColor="transparent" loaded={loader}>
        <SuccessModal
          image={{ src: SMSModal, alt: message }}
          header={header}
          message={message}
          onConfirm={onConfirm}
          isShown
        />
      </Loader>
    </BaseModal>
  )
}

export default SendModal
