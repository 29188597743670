import { getFunctions, httpsCallable } from 'firebase/functions'
import { FUNC_NAMES } from '@constants/dictionary/firebase'

export const getSellerPropertiesStatistics = async ({
  page,
  sellerId,
  sellerProfileId,
  sortBy,
  sortOrder,
}: {
  page: number
  sellerId: string
  sellerProfileId: string
  sortBy: string
  sortOrder: string
}): { error: string } => {
  const functions = getFunctions()
  const getStatistics = httpsCallable(
    functions,
    FUNC_NAMES.GET_SELLER_PROPERTIES_STATUSTICS_PER_AGENT,
  )
  const res = await getStatistics({ page, sellerId, sellerProfileId, sortBy, sortOrder })
  return res
}
