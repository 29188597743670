import _ from 'lodash'
import { format, differenceInMinutes } from 'date-fns'
import { THIRTY_MINUTES } from '@constants/dictionary'

const createVisitTime = (fromDateTime, toDateTime): string => {
  return `${format(fromDateTime.toDate(), 'HH:mm')} - ${format(toDateTime.toDate(), 'HH:mm')}`
}

const createVisitDuration = (toDateTime, fromDateTime): string => {
  return `${differenceInMinutes(toDateTime.toDate(), fromDateTime.toDate())} min`
}

const createVisitNotification = (fromDateTime, minutesBeforeVisit: number): string => {
  return differenceInMinutes(fromDateTime.toDate(), new Date()) < minutesBeforeVisit &&
    differenceInMinutes(fromDateTime.toDate(), new Date()) > 0
    ? `${differenceInMinutes(fromDateTime.toDate(), new Date())} minutes to visit`
    : ''
}

const createNumberOfVisit = (arrayVisits, visit): number => {
  const number = arrayVisits.indexOf(visit) + 1
  return number
}

export const mapSellerVisits = (firestoreList: { docs: Array }): Array => {
  const source = firestoreList.docs.map((visit) => ({
    ...visit.data(),
    compareDate: visit.data().fromDateTime.toDate().setHours(0, 0, 0),
  }))
  return _(source)
    .groupBy('compareDate')
    .map((items, date) => {
      return {
        day: new Date(+date),
        date,
        visits: items
          .filter((item) => item.buyer != null && item.agent != null && item.cancelled == null)
          .map((item) => {
            return {
              agencyName: item.agent.name,
              agencyPhone: item.agent.phoneNumber,
              visitTime: createVisitTime(item.fromDateTime, item.toDateTime),
              visitDuration: createVisitDuration(item.toDateTime, item.fromDateTime),
              visitNotification: createVisitNotification(item.fromDateTime, THIRTY_MINUTES),
              disabled: item.fromDateTime.toDate() < new Date(),
              id: item.id,
              fromDateTime: item.fromDateTime,
            }
          }),
      }
    })
    .filter((item) => item.visits.length > 0)
    .map((item) => {
      return {
        ...item,
        visits: item.visits
          .sort(
            (current, previous) => current.fromDateTime.toDate() - previous.fromDateTime.toDate(),
          )
          .map((visit) => {
            return { ...visit, visitNumber: createNumberOfVisit(item.visits, visit) }
          }),
      }
    })
    .sort((current, previous) => current.day - previous.day)
    .value()
}
