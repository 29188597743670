import React from 'react'
import { AgentHeader, AgentFooter } from '@organisms'

import { PageContainer } from './AgentLayout.style'

interface AgentLayoutProps {
  shownHeader: boolean
  shownFooter: boolean
}

const AgentLayout: React.FC<AgentLayoutProps> = ({ shownFooter, shownHeader, children }) => {
  return (
    <>
      {shownHeader && <AgentHeader />}
      <PageContainer>{children}</PageContainer>
      {shownFooter && <AgentFooter />}
    </>
  )
}

export default AgentLayout
