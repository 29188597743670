const maxSize = 10485760

export const customValidation = (file: {
  size: number
}): { code: string; message: string } | null => {
  const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png']
  if (file.size > maxSize) {
    return {
      code: 'size-is-too-large',
      message: 'File Exceeds Maximum Allowed Value',
    }
  }
  if (!(file && acceptedImageTypes.includes(file['type']))) {
    return {
      code: 'not-valid-format',
      message: 'Invalid File Format',
    }
  }

  return null
}

export const createImgPreview = (file: File): Record<string, unknown> => {
  return Object.assign(file, {
    preview: URL.createObjectURL(file),
  })
}

export const revokePreview = (file: File): Record<string, unknown> => {
  return URL.revokeObjectURL(file.preview)
}
