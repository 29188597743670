export const DAYS_LIST = [
  {
    dayName: 'Sun',
    dayNumber: '13',
    date: new Date(),
    active: false,
    disabled: true,
    today: false,
  },
  {
    dayName: 'Mon',
    dayNumber: '14',
    date: new Date(),
    active: false,
    disabled: true,
    today: false,
  },
  {
    dayName: 'Tue',
    dayNumber: '15',
    date: new Date(),
    active: false,
    disabled: false,
    today: true,
  },
  {
    dayName: 'Wed',
    dayNumber: '16',
    date: new Date(),
    active: true,
    disabled: false,
    today: false,
  },
  {
    dayName: 'Thu',
    dayNumber: '17',
    date: new Date(),
    active: false,
    disabled: false,
    today: false,
  },
  {
    dayName: 'Fri',
    dayNumber: '18',
    date: new Date(),
    active: false,
    disabled: false,
    today: false,
  },
  {
    dayName: 'Sat',
    dayNumber: '19',
    date: new Date(),
    active: false,
    disabled: false,
    today: false,
  },
]

export const TIME_SLOTS_LIST = [
  {
    fromTime: '8:00',
    toTime: '8:30',
    active: false,
    disabled: true,
  },
  {
    fromTime: '8:30',
    toTime: '9:00',
    active: false,
    disabled: false,
  },
  {
    fromTime: '9:00',
    toTime: '9:30',
    active: true,
    disabled: false,
  },
  {
    fromTime: '9:30',
    toTime: '10:00',
    active: false,
    disabled: false,
  },
  {
    fromTime: '10:30',
    toTime: '11:00',
    active: false,
    disabled: false,
  },
  {
    fromTime: '11:00',
    toTime: '11:30',
    active: false,
    disabled: false,
  },
]
