import React, { useCallback, useEffect, useState } from 'react'
import { useDesktop, useMobile, useTabletPortrait } from '@utils/hooks'
import { convertToPrice } from '@utils/functions'
import { BookingStatus, SimpleRatingStars } from '@atoms'
import { Table } from '@organisms'
import { getSellerPropertiesStatistics } from '@services/firebase'
import { useHistory, useParams } from 'react-router-dom'
import {
  BodyContainer,
  Container,
  StatusContainer,
  RatingContainer,
  ViewContainer,
  Cell,
} from './PropertyList.style'
import { TableRowType } from '@organisms/Table/types'
import ViewInfoLink from './ViewInfoLink'
import { ROUTES } from '@constants/dictionary'

const PropertyList = (): JSX.Element => {
  const { id }: { id: string } = useParams()
  const sellerId = id.split('-')[0]
  const sellerProfileId = id.split('-')[1]

  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTablet = useTabletPortrait()
  const { push } = useHistory()
  const [loaded, setLoaded] = useState(false)
  const [state, setstate] = useState({ data: [], totalCount: 0 })
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ sortBy: 'id', orderBy: 'desc' })
  const getStatisric = async ({
    page,
    sellerId,
    sellerProfileId,
    sortBy,
    sortOrder,
  }: {
    page: number
    sellerId: string
    sellerProfileId: string
    sortBy: string
    sortOrder: string
  }): Promise<T> => {
    setLoaded(false)
    const response = await getSellerPropertiesStatistics({
      page,
      sellerId,
      sellerProfileId,
      sortBy,
      sortOrder,
    })
    if (response) {
      setstate(response.data)
      setLoaded(true)
    }
  }

  useEffect(() => {
    getStatisric({
      page: currentPage,
      sellerId,
      sellerProfileId: sellerProfileId,
      sortBy: sorting.sortBy,
      sortOrder: sorting.orderBy,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sellerId, sellerProfileId])

  const changeSort = useCallback((sort) => {
    setSorting({ sortBy: sort.id, orderBy: Boolean(sort.isSortedDesc) ? 'desc' : 'asc' })
  }, [])

  const changePage = useCallback(
    (step) => {
      setCurrentPage(currentPage + step)
    },
    [currentPage],
  )

  const viewProfile = useCallback(
    (id: string) => {
      push({
        pathname: `${ROUTES.AGENT_PROPERTIES}/${id}/property-info`,
      })
    },
    [push],
  )
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        percentWidth: 8,
        defaultCanSort: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => original.id,
      },
      {
        Header: 'Property Title',
        accessor: 'title',
        percentWidth: 28,
        disableSortBy: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => <Cell>{original.title}</Cell>,
      },
      {
        Header: 'Price',
        accessor: 'price',
        percentWidth: 12,
        Cell: ({ row: { original } }: TableRowType<Property>) => (
          <>£ {convertToPrice(original.price)}</>
        ),
      },
      {
        Header: 'Status ',
        accessor: 'statusOrder',
        percentWidth: 11,
        Cell: ({ row: { original } }: TableRowType<Property>) => (
          <StatusContainer>
            <BookingStatus status={original.status} uppercase isPropertyCard radius="0.8rem" />
          </StatusContainer>
        ),
      },
      {
        Header: 'Rating',
        accessor: 'averageRating',
        percentWidth: 11,
        Cell: ({ row: { original } }: TableRowType<Property>) => (
          <>
            {original.averageRating && (
              <RatingContainer>
                <SimpleRatingStars count={1} size="1.5rem" />
                {original.averageRating}
              </RatingContainer>
            )}
          </>
        ),
      },
      {
        Header: 'Viewings',
        accessor: 'viewingsCount',
        percentWidth: 11,
        Cell: ({ row: { original } }: TableRowType<Property>) =>
          original.viewingsCount ? <ViewContainer>{original.viewingsCount}</ViewContainer> : '',
      },
      {
        Header: 'Short List',
        accessor: 'shortListedCount',
        percentWidth: 11,
        Cell: ({ row: { original } }: TableRowType<Property>) =>
          original.shortListedCount ? (
            <ViewContainer>{original.shortListedCount}</ViewContainer>
          ) : (
            ''
          ),
      },
      {
        Header: '',
        accessor: 'propertyProfileId',
        percentWidth: 8,
        disableSortBy: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => (
          <ViewInfoLink propertyProfileId={original.propertyProfileId} viewProfile={viewProfile} />
        ),
      },
    ],
    [viewProfile],
  )

  return (
    <BodyContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
      <Container>
        <Table
          columns={columns}
          isTableVisit
          typeUser="property"
          data={state.data}
          changeSort={changeSort}
          loaded={loaded}
          changePage={changePage}
        />
      </Container>
    </BodyContainer>
  )
}

export default PropertyList
