import React from 'react'
import { useMobile } from '@utils/hooks'
import { TimeSlotButton } from '@molecules'
import { Container, Title, StyledCol, StyledRow } from './TimeSlotsList.style'
import { TimeSlotsListProps } from '@constants/types/organisms'

const TimeSlotsList: React.FC<TimeSlotsListProps> = ({
  timeSlotsList = [],
  onClick,
  onlyShow,
  noHover,
}) => {
  const isMobile = useMobile()

  return (
    <>
      {!onlyShow && <Title isMobile={isMobile}>Select a time</Title>}
      <Container isMobile={isMobile}>
        <StyledRow>
          {timeSlotsList.map(({ fromTime, toTime, active, disabled, id }) => (
            <StyledCol xs={6} sm={3} key={`${fromTime}-${toTime}`} isMobile={isMobile}>
              <TimeSlotButton
                id={id}
                startTime={fromTime}
                endTime={toTime}
                disabled={disabled}
                active={active}
                onClick={disabled ? () => {} : onClick}
                noHover={noHover}
              />
            </StyledCol>
          ))}
        </StyledRow>
      </Container>
    </>
  )
}

export default TimeSlotsList
