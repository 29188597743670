/*eslint-disable no-magic-numbers*/
export type ViewingStatusType = { statusKey: string; value: string }

type StatusType = {
  statusProps: ViewingStatusType
  canceledBy?: string
}

export type InfoModalProps = {
  isShown?: boolean
  closeHandler?(): void
  title: string
  address: string
  price: number
  buyerName: string
  buyerPhone: string
  sellerName: string
  sellerPhone: string
  fromDateTime: Date
  toDateTime: Date
  visitId: string
  status: StatusType

  cancelHandler: () => void
  rescheduleHandler: () => void
}

export enum ViewingCancelInitiators {
  BUYER = 1,
  SELLER = 2,
  AGENT = 3,
  AGENT_FROM_ACTIVE = 4,
  SELLER_PROP_UNDER_OFFER = 5,
}
