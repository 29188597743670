/* eslint-disable complexity */
import React, { useCallback, useState, forwardRef } from 'react'
import { Icon } from '@atoms'
import Input from '../Input'

import { PasswordInputTypes } from '@constants/types/molecules'

// eslint-disable-next-line react/display-name
const PasswordInput = forwardRef((props: PasswordInputTypes, ref) => {
  const {
    value,
    name,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    testID,
    error,
    errorMessage,
    disabled,
    inputLabel = '',
    withOutIcon,
    maxLength,
    tabIndex,
    isNoSpace,
  } = props

  const [showPassword, setShowPassword] = useState(true)

  const handlePasswordIconClick = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  return (
    <Input
      ref={ref}
      type={showPassword ? 'password' : 'text'}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      testID={testID}
      inputLabel={inputLabel}
      error={error}
      disabled={disabled}
      errorMessage={errorMessage}
      icon={
        !withOutIcon ? (
          <Icon
            color="grey"
            size="2.4rem"
            icon={showPassword ? 'eye-closed' : 'eye-opened'}
            onClick={handlePasswordIconClick}
            clickable
          />
        ) : null
      }
      isPassword={showPassword}
      maxLength={maxLength}
      tabIndex={tabIndex || -1}
      isNoSpace={isNoSpace}
    />
  )
})

export default PasswordInput
