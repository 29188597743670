import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Col } from 'styled-bootstrap-grid'

export const LabelContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(p) => p.theme.color.greyBlue};
`

export const Label = styled(Link)`
  text-decoration: none;
  display: flex;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  outline: none;
  padding: ${(p) => p.theme.indent.MD};
  background-color: ${(p) => (p.$active ? p.theme.color.originalBlue_10 : 'none')};
  border-bottom: 0.2rem solid;
  border-bottom-color: ${(p) => (p.$active ? p.theme.color.originalBlue : p.theme.color.greyBlue)};
`

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  max-width: 76.8rem;
`
