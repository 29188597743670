import styled from 'styled-components'
import { Typography } from '@atoms'
import { Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'

export const CardWrapper = styled(Col)<{ isListView: boolean }>`
  width: 100%;
  height: ${(p) => (p.isListView ? '9rem' : '34.7rem')};
  background-color: ${(p) => p.theme.color.greyBlue};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  position: relative;

  ${(p) => (p.isListView ? 'display: flex; flex-direction: row;' : '')};
`

export const LoaderWrapper = styled(Row)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const StatusLabel = styled.div<{ isListView: boolean }>`
  position: absolute;
  left: ${(p) => (p.isListView ? '0' : '0.8rem')};
  top: ${(p) => (p.isListView ? '0' : '0.8rem')};
  width: ${(p) => (p.isTabletPortrait && p.isListView ? '100%' : '12rem')};
`

export const CardImg = styled.div<{ isListView: boolean }>`
  position: relative;
  width: ${(p) => (p.isListView ? '' : '100%')};
  min-width: ${(p) => (p.isTabletPortrait ? '11.6rem' : '16rem')};
  height: ${(p) => (p.isListView ? '9rem' : '14.7rem')};
  background-image: url('${({ path }) => path}');
  background-size: cover;
  background-position: center;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.MD};
  border-top-right-radius: ${(p) => (p.isListView ? '0' : p.theme.borderRadius.MD)};
  border-bottom-left-radius: ${(p) => (p.isListView ? p.theme.borderRadius.MD : '')};
`

export const CardInfoWrapper = styled.div<{ isListView: boolean }>`
  grid-area: info;
  display: grid;
  grid-template-areas: ${(p) =>
    p.isListView
      ? "'title price link' 'address price link'"
      : "'title title' 'address address' 'price price' 'link link'"};
  row-gap: ${(p) => (p.isListView ? 'none' : `${p.theme.indent.XS}`)};
  padding: ${(p) =>
    p.isListView
      ? `${p.theme.indent.LG} ${p.theme.indent.SM} ${p.theme.indent.LG}  ${p.theme.indent.SM}`
      : `${p.theme.indent.XS}  ${p.theme.indent.SM}`};
  width: 100%;
  align-items: center;
  ${(p) => p.isListView && 'grid-template-columns: 50% minmax(auto, 25%) auto; column-gap: 1.5rem;'}
`

export const Title = styled(Typography.H3)`
  grid-area: title;
  ${(p) => !p.isListView && 'min-height: 4.8rem;'}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const Address = styled(Typography.Body2)<{ isListView: boolean }>`
  grid-area: address;
  white-space: pre-wrap;
  border-top: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(p) => p.isListView && 'border-top: none;'}
`

export const PriceRow = styled(Row)<{ isListView: boolean }>`
  height: 100%;
  grid-area: price;
  justify-content: ${(p) => (p.isListView ? 'space-between' : 'space-between')};
  align-items: center;
  margin-top: ${(p) => (p.isListView ? '' : `${p.theme.indent.XS}`)};
  border-bottom: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  ${(p) => p.isListView && 'border-bottom: none;'}
`
export const LinkRow = styled(Row)<{ isListView: boolean }>`
  height: 100%;
  grid-area: link;
  justify-content: ${(p) => (p.isListView ? 'center' : 'space-between')};
  margin-top: ${(p) => (p.isListView ? '' : `${p.theme.indent.XS}`)};
  align-items: flex-start;
  ${(p) => p.isListView && 'flex-direction: column; justify-self: end;'}
`
export const LinkContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  text-decoration: none;
`
export const SendLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  text-decoration: none;
`
export const LinkText = styled(Typography.Body3)`
  padding-left: ${(p) => p.theme.indent.XXS};
  color: ${(p) => (p.disabled ? p.theme.color.darkGrey : p.theme.color.originalBlue)};
`

export const SendLinkLoader = styled.div`
  width: 4.5rem;
`
