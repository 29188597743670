import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { getFullLink } from '@services/firebase'
import { Loader } from '@atoms'
import { ROUTES, ERROR_MESSAGE_TEXT_PROPERTY, LS_CONTSTANTS } from '@constants/dictionary'
import { useMountEffect } from '@utils/hooks'

const ShortLinkProcessor = (): JSX.Element => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [link, setLink] = useState<boolean>(false)
  const { id }: { id: string } = useParams()

  const fullLink = async (): undefined => {
    if (!!id) {
      const link = await getFullLink(id)
      if (!!link) {
        setLink(link)
        return setLoaded(true)
      }
    }
    localStorage.setItem(LS_CONTSTANTS.error, ERROR_MESSAGE_TEXT_PROPERTY.INCORRECT_TOKEN_ERROR)
    setLoaded(true)
  }

  useMountEffect(fullLink)

  return (
    <Loader loaded={loaded}>
      {loaded && !!link ? <Redirect to={link} /> : <Redirect to={ROUTES.ERROR} />}
    </Loader>
  )
}

export default ShortLinkProcessor
