import { getFirestore, query, collectionGroup, where, getDocs } from '@firebase/firestore'
import { USER_TYPES, LS_CONTSTANTS } from '@constants/dictionary'
import { FS_Q_PARAMS, FS_COL_GROUP_NAMES } from '@constants/dictionary/firebase'
import { DocumentData } from 'firebase/firestore'

export const getAgentPropertiesProfiles = async (): Promise<
  Array<{
    id: string
    propertyProfile: DocumentData
  }>
> => {
  const db = getFirestore()
  const id = localStorage.getItem(LS_CONTSTANTS.uid)
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const prop = userType === USER_TYPES.agency ? FS_Q_PARAMS.AGENCY_ID : FS_Q_PARAMS.AGENT_ID
  const q = query(collectionGroup(db, FS_COL_GROUP_NAMES.PROPERTY_PROFILES), where(prop, '==', id))
  const propertyProfiles = await getDocs(q)

  return propertyProfiles?.docs
    ?.map((propertyProfile) => ({
      id: propertyProfile.id,
      propertyProfile: propertyProfile?.data(),
    }))
    .sort((firstProperty, secondProperty) => {
      return (
        secondProperty.propertyProfile?.createdAt?.toDate() -
        firstProperty.propertyProfile?.createdAt?.toDate()
      )
    })
}
