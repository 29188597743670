export const LOGIN = {
  QUESTION: `Don't remember the password?`,
  ERROR: 'Email or password is incorrect',
  EXPIRED_LINK: 'Sorry but link was expired',
  PASSWORDS_RULE:
    'Password must contain at least 8 characters, 1 upper and lower case letter, 1 digit',
  SUCCESSFUL_CHANGE_PASSWORD: 'Password has been successfully changed for your profile',
  RESET_PASSWORD_MESSAGE:
    'We have emailed you reset password link If you have not received an email within 10 minutes please contact Viewd support on',
  RESET_PASSWORD_INSTRUCTION:
    'Enter the email associated with your account and we will send an email with instructions to reset your password.',
}
