import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Checkbox from '../../molecules/Checkbox'

import { CheckBoxFormProps } from '@constants/types/helpers'

const CheckBoxForm: React.FC<CheckBoxFormProps> = ({ name, label, disabled, fill }) => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <Checkbox disabled={disabled} onChange={onChange} label={label} value={value} fill={fill} />
      )}
    />
  )
}
export default CheckBoxForm
