import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const FilterContainer = styled(Row)`
  padding: 0
    ${(p) => {
      if (p.isTabletPortrait) {
        return p.theme.indent.XXXL
      }
      if (p.isMobile) {
        return p.theme.indent.LG
      }
      return '5rem'
    }}
    ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.MD)};
  margin: ${(p) => (p.margin ? `0 -${p.theme.indent.XS}` : 0)};
  flex-direction: row;
  align-items: center;
`

export const PropertyContainer = styled(Col)`
  padding: 0 ${(p) => p.theme.indent.XS} ${(p) => p.theme.indent.MD};
  min-width: 24rem;
  width: ${(p) => {
    if (p.isTabletPortrait) {
      return p.isListView ? '100%' : '50%'
    }
    if (p.isMobile || p.isListView) {
      return '100%'
    }
    return '20%'
  }};
`
export const IconContainer = styled.div`
  padding-right: ${(p) => (p.padding && !p.isMobile ? p.theme.indent.LG : 0)};
  padding-left: ${(p) => (p.isMobile ? p.theme.indent.LG : 0)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${(p) => p.theme.indent.MD};
`

export const DropdownContainer = styled.div`
  min-width: 14.6rem;
  margin: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.MD)} 0
    ${(p) => p.theme.indent.MD};
  padding-top: ${(p) => p.theme.indent.MD};
  flex: 1;
  z-index: 1;
`
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SearchContainer = styled.div`
  min-width: ${(p) => {
    if (!p.isTabletPortrait && !p.isMobile) {
      return '31rem'
    }
    if (p.isMobile) {
      return ''
    }
    return '16rem'
  }};
  margin-right: ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.MD)};
  margin-left: ${(p) => (p.isMobile ? p.theme.indent.LG : 0)};
  padding-top: ${(p) => p.theme.indent.MD};
`

export const ButtonContainer = styled.div`
  min-width: ${(p) => (p.isMobile ? '100%' : '16.7rem')};
  padding: ${(p) => p.theme.indent.MD} ${(p) => (p.isMobile ? p.theme.indent.LG : 0)}
    ${(p) => (p.isMobile ? p.theme.indent.LG : 0)};
  ${(p) => p.isMobile && 'flex: 1'};
`

export const StyledBody2 = styled(Typography.Body2)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 ${(p) => p.theme.indent.LG};
  color: ${(p) => p.theme.color.darkGrey};
  height: calc(100vh - 36rem);
`
