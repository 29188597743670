import React from 'react'
import styled from 'styled-components'
import { Icon } from '@atoms'
import { SearchInputProps } from '@constants/types/molecules'

const SearchContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 1.1rem;
    left: 1rem;
  }

  margin-bottom: ${(p) => (p.noMargin ? 0 : p.theme.indent.MD)};
`

const Input = styled.input`
  width: 100%;
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 4rem;
  border-radius: ${(p) => p.theme.borderRadius.SM};
  border: ${(p) => `0.1rem solid ${p.theme.color.lightGrey}`};
  letter-spacing: normal;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};

  &:focus {
    outline: none;
  }
`

const SearchInput = ({ value, onChange, placeholder, noMargin }: SearchInputProps): JSX.Element => (
  <SearchContainer noMargin={noMargin}>
    <Icon color="darkGrey" icon="search" size="1.6rem" />
    <Input value={value} onChange={onChange} placeholder={placeholder} />
  </SearchContainer>
)

export default SearchInput
