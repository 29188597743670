import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div`
  display: flex;
`

export const ListContainer = styled.div`
  max-width: 29.9rem;
  width: 100%;
  min-width: 27.9rem;
  padding: ${(p) => p.theme.indent.LG} 0 0
    ${(p) => (p.isTabletPortrait ? p.theme.indent.XXXL : '5rem')};
  border-right: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  background-color: ${(p) => p.theme.color.greyBlue};
`

export const ListItem = styled(Typography.Body2)`
  width: 100%;
  cursor: pointer;
  padding: ${(p) => `${p.theme.indent.SM} 0 ${p.theme.indent.SM} ${p.theme.indent.MD}`};
  background-color: ${(p) => (p.active ? p.theme.color.lightGrey : p.theme.color.greyBlue)};
  border-left: 0.4rem solid
    ${(p) => (p.active ? p.theme.color.originalBlue : p.theme.color.greyBlue)};
`

export const ItemContainer = styled.div`
  position: relative;
`

export const MobileListItem = styled(Typography.Body2)`
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: ${(p) => `${p.theme.indent.SM} ${p.theme.indent.LG}`};
  background-color: ${(p) => p.theme.color.greyBlue};
  border-bottom: 0.1rem solid ${(p) => p.theme.color.lightGrey};
`

export const StyledIcon = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: ${(p) => p.theme.indent.XL};
  z-index: 1;
`

export const ContentContainer = styled.div`
  flex: 1;
`

export const H3 = styled(Typography.H3)`
  padding: ${(p) => (p.customMobile ? p.theme.indent.MD : p.theme.indent.LG)}
    ${(p) => `${p.theme.indent.LG} ${p.theme.indent.MD}`};
`
