import React from 'react'
import { Redirect } from 'react-router-dom'

import { useFirebaseAuth } from '@utils/hooks'
import { ROUTES } from '@constants/dictionary'
import { Loader } from '@atoms'

const Home = (): JSX.Element => {
  const [loggedIn, loaded] = useFirebaseAuth()

  return (
    <Loader loaded={loaded}>
      <Redirect to={!loggedIn ? ROUTES.AGENT_LOGIN : ROUTES.AGENT} />
    </Loader>
  )
}

export default Home
