import styled from 'styled-components'
import { Typography } from '@atoms'

export const ButtonContainer = styled.div<{ height?: string }>`
  display: flex;
  width: ${(p) => (p.width ? p.width : '100%')};
  ${({ height }) => !!height && `height: ${height}`};
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.isMobile ? p.theme.indent.XS : '1rem')}
    ${(p) => (p.isMobile ? p.theme.indent.XXS : p.theme.indent.MD)};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  outline: none;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
    props.secondary ? `0.1rem solid ${props.theme.color.originalBlue}` : 'none'};
  position: relative;
  ${(props) =>
    props.disabled &&
    `
  pointer-events: none;
  ${props.secondary && `border: 0.1rem solid ${props.theme.color.darkGrey}`}; `}
`
export const ButtonText = styled(Typography.Body2)`
  font-weight: ${(p) => (p.renderMobile ? 600 : 700)};
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.XS : p.theme.fontSize.SM)};
  color: ${(props) => (props.secondary ? props.theme.color.originalBlue : props.theme.color.white)};
  ${(props) => props.disabled && props.secondary && `color: ${props.theme.color.darkGrey}; `}
`
export const Cicle = styled.div`
  position: absolute;
  width: ${({ theme, isMobile }) => (isMobile ? '1.8rem' : theme.indent.XL)};
  height: ${({ theme, isMobile }) => (isMobile ? '1.8rem' : theme.indent.XL)};
  border-radius: 50%;
  border: ${({ theme, checked }) =>
    checked ? `0.5rem solid ${theme.color.originalBlue}` : `0.1rem solid ${theme.color.darkGrey}`};
  top: 1rem;
  left: ${({ theme }) => theme.indent.SM};
`
export const ButtonTextYesNo = styled(Typography.Caption1)`
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.SM : p.theme.lineHeight.MD)};
`
export const ButtonContainerRed = styled(ButtonContainer)`
  background-color: ${(p) => p.theme.color.coralRed};
  column-gap: ${(p) => p.theme.indent.XS};
`
export const IconContainer = styled(ButtonContainer)`
  padding: ${(p) => p.theme.indent.XS};
  gap: 1rem;
  justify-content: flex-start;
`
export const ButtonRedText = styled(Typography.Body2)`
  color: ${(p) => p.theme.color.red};
`
