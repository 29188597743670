import styled from 'styled-components'
import IcomoonReact from 'icomoon-react'

export const StyledIcomoonReact = styled(IcomoonReact)`
  cursor: ${(p) => (p.$clickable ? 'pointer' : 'auto')};
  color: ${(p) => p.theme.color[p.color]};
  fill: ${(p) => p.theme.color[p.color]};
  width: ${(p) => p.size};
  height: auto;
  pointer-events: ${(p) => (p.$clickable ? 'auto' : 'none')};
  ${(p) => !!p.$rotate && `transform: rotate(${p.$rotate}deg);`}
`
