/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDesktop, useMobile, useTabletPortrait } from '@utils/hooks'
import { Icon } from '@atoms'
import { storeUser, updateUser } from '@services/firebase'
import { Button, ErrorWithIcon } from '@molecules'
import {
  ButtonContainerFirst,
  ButtonContainerSecond,
  ButtonRow,
  ButtonsContainer,
  CardBody,
  Column,
  ColumnTitle,
  Container,
  H1,
  H2,
  Title,
  NotesHeader,
  ErrorContainer,
} from './AgentAddUserCard.style'
import { InputForm, TextAreaForm } from '@helpers'
import {
  VALIDATION_ERRORS_FORM_ADD,
  ROUTES,
  FIXED_INPUT_PHONE_NUMBERS,
  FIRST_THREE,
} from '@constants/dictionary'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from './AgentAddUserValidation'
import { useTheme } from 'styled-components'
import { prepareData, prepareDataForUpdate } from './AgentAddUserCard.utils'
import { useParams } from 'react-router-dom'
import { phoneForm } from '@constants/regExp'
import { customFormatValue } from '@utils/functions'
import { AddUserCardProps, AgentAddUserFormType } from '@constants/types'

const AgentAddUserCard: React.FC<AddUserCardProps> = (props) => {
  const { seller, editUser, userFile } = props
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const userId = id?.split('-')[0]

  const isTabletPortrait = useTabletPortrait()
  const theme = useTheme()
  const [backendError, setBackendError] = useState(false)
  const [loader, setLoader] = useState(false)

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: '',
      email: '',
      phone: '',
      additionalFullName: '',
      additionalEmail: '',
      additionalPhone: '',
      note: '',
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const onSubmit = async (data: AgentAddUserFormType): void => {
    setLoader(true)
    if (!!editUser) {
      try {
        const reqData = prepareDataForUpdate(data, userId, userFile.id)
        const req = await updateUser(reqData)
        if (!!req.result) {
          onCancelClick()
        } else {
          setBackendError(true)
        }
      } catch (error) {
        setBackendError(true)
      } finally {
        setLoader(false)
      }
    } else {
      try {
        const reqData = prepareData(data, seller)
        const req = await storeUser(reqData)
        if (!!req.result) {
          onCancelClick()
        } else {
          setBackendError(true)
        }
      } catch (error) {
        setBackendError(true)
      } finally {
        setLoader(false)
      }
    }
  }
  useEffect(() => {
    if (!!editUser) {
      setValue('fullName', userFile.profile.name)
      setValue('email', userFile.profile.email)
      setValue(
        'phone',
        customFormatValue(userFile.profile.phoneNumber.slice(FIRST_THREE), phoneForm, '$1 $2'),
      )
      setValue('additionalFullName', userFile.profile.additionalName)
      setValue('additionalEmail', userFile.profile.additionalEmail)
      setValue(
        'additionalPhone',
        userFile.profile.additionalPhoneNumber &&
          customFormatValue(
            userFile.profile.additionalPhoneNumber.slice(FIRST_THREE),
            phoneForm,
            '$1 $2',
          ),
      )
      setValue('note', userFile.profile.note)
    }
  }, [userFile, editUser, setValue])

  const errorText = seller
    ? VALIDATION_ERRORS_FORM_ADD.SELLER_EXISTS
    : VALIDATION_ERRORS_FORM_ADD.BUYER_EXISTS

  const onCancelClick = useCallback(() => {
    if (!!editUser) {
      history.push({
        pathname: `${ROUTES.AGENT}/${editUser}s/${id}/${editUser}-info`,
      })
    } else {
      history.push(seller ? ROUTES.AGENT_SELLERS : ROUTES.AGENT_BUYERS)
    }
  }, [history, editUser, seller, id])

  return (
    <Container isMobile={isMobile} isDesktop={isDesktop}>
      <Title isMobile={isMobile} backendError={backendError} isTabletPortrait={isTabletPortrait}>
        {editUser ? (
          <H1>Edit {editUser}</H1>
        ) : (
          <H1 isMobile={isMobile}>{seller ? 'Add new seller' : 'Add new buyer'}</H1>
        )}
        <ButtonRow isMobile={isMobile}>
          {backendError && (
            <ErrorContainer isMobile={isMobile}>
              <ErrorWithIcon text={errorText} />
            </ErrorContainer>
          )}
          <ButtonsContainer>
            <ButtonContainerFirst isMobile={isMobile}>
              <Button.Primary
                onClick={handleSubmit(onSubmit)}
                isMobile={isMobile}
                label={editUser ? 'Save' : seller ? 'Add Seller' : 'Add Buyer'}
                width="100%"
                disabled={loader}
                loader={loader}
              />
            </ButtonContainerFirst>
            <ButtonContainerSecond isMobile={isMobile}>
              <Button.Secondary
                isMobile={isMobile}
                label="Cancel"
                width="100%"
                onClick={onCancelClick}
              />
            </ButtonContainerSecond>
          </ButtonsContainer>
        </ButtonRow>
      </Title>
      <CardBody isDesktop={isDesktop}>
        <FormProvider {...methods}>
          <Column isDesktop={isDesktop} isTabletPortrait={isTabletPortrait}>
            <ColumnTitle>
              <Icon color={theme.color.darkBlack} icon="contact" size="1.6rem" />
              <H2>{seller ? 'Seller Contacts' : 'Buyer Contacts'}</H2>
            </ColumnTitle>
            <InputForm
              name="fullName"
              disabled={loader}
              errorMessage="Value is required"
              placeholder="Fill name"
              inputLabel="Full name"
              error={hasError('fullName')}
              maxLength={50}
              tabIndex={1}
              isName
            />
            <InputForm
              name="email"
              disabled={loader}
              errorMessage="Not a valid email"
              placeholder="Fill email"
              inputLabel="Email"
              error={hasError('email')}
              isNoSpace
              tabIndex={2}
            />
            <InputForm
              name="phone"
              disabled={loader}
              errorMessage="Not a valid number"
              placeholder="Fill phone number"
              inputLabel="Phone"
              error={hasError('phone')}
              maxLength={FIXED_INPUT_PHONE_NUMBERS}
              isPhone
              tabIndex={3}
            />
          </Column>
          <Column isDesktop={isDesktop} isTabletPortrait={isTabletPortrait}>
            <ColumnTitle>
              <Icon color={theme.color.darkBlack} icon="contact-plus" size="2.2rem" />
              <H2>Additional contacts</H2>
            </ColumnTitle>
            <InputForm
              disabled={loader}
              name="additionalFullName"
              errorMessage="Value is required"
              placeholder="Fill name"
              inputLabel="Full name"
              error={hasError('additionalFullName')}
              maxLength={50}
              tabIndex={4}
              isName
            />
            <InputForm
              disabled={loader}
              name="additionalEmail"
              errorMessage="Not a valid email"
              placeholder="Fill email"
              inputLabel="Email #2"
              error={hasError('additionalEmail')}
              isNoSpace
              tabIndex={5}
            />
            <InputForm
              disabled={loader}
              name="additionalPhone"
              errorMessage="Not a valid number"
              placeholder="Fill phone number"
              inputLabel="Phone #2"
              error={hasError('additionalPhone')}
              maxLength={FIXED_INPUT_PHONE_NUMBERS}
              isPhone
              tabIndex={6}
            />
          </Column>
          <Column isDesktop={isDesktop} isTabletPortrait={isTabletPortrait}>
            <ColumnTitle>
              <Icon color={theme.color.darkBlack} icon="notes" size="1.8rem" />
              <H2>Notes</H2>
            </ColumnTitle>
            <NotesHeader>You could leave some notes</NotesHeader>
            <TextAreaForm
              name="note"
              placeholder="Leave your notes"
              isNotes
              maxLength={150}
              tabIndex={7}
            />
          </Column>
        </FormProvider>
      </CardBody>
    </Container>
  )
}

export default AgentAddUserCard
