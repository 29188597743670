import React from 'react'
import { Typography } from '@atoms'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Button, ErrorWithIcon } from '@molecules'
import { Row } from 'styled-bootstrap-grid'
import {
  ButtonsWithError,
  Container,
  HeaderRow,
  ButtonsWrapper,
  TitleWrapper,
} from './AgentAddPropertyPage.style'

type AddPropertyWrapperTypes = {
  header: string
  children: JSX.Element
  onClickAdd?: () => void
  backendError: boolean
  applyingProperty?: boolean
  onClickCancel?: () => void
  isEditProperty?: boolean
}

const AddPropertyWrapper: React.FC<AddPropertyWrapperTypes> = ({
  header,
  children,
  backendError,
  onClickAdd,
  onClickCancel,
  applyingProperty,
  isEditProperty,
}): JSX.Element => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  return (
    <Container isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
      <HeaderRow isTabletPortrait={isTabletPortrait} isMobile={isMobile} hasError={backendError}>
        <TitleWrapper>
          <Typography.Caption3 isTitle active isMobile={isMobile}>
            {header}
          </Typography.Caption3>
        </TitleWrapper>
        <ButtonsWithError isMobile={isMobile}>
          {backendError && <ErrorWithIcon height="4rem" text="This property is already added" />}
          <ButtonsWrapper>
            <Button.Primary
              width={!isMobile ? '16.7rem' : undefined}
              height="4rem"
              disabled={applyingProperty}
              loader={applyingProperty}
              label={isEditProperty ? 'Save' : 'Add Property'}
              onClick={onClickAdd}
            />
            <Button.Secondary
              width={!isMobile ? '11.6rem' : undefined}
              height="4rem"
              label="Cancel"
              onClick={onClickCancel}
            />
          </ButtonsWrapper>
        </ButtonsWithError>
      </HeaderRow>
      <Row>{children}</Row>
    </Container>
  )
}

export default AddPropertyWrapper
