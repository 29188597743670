import React, { MutableRefObject, useMemo, useRef, useLayoutEffect } from 'react'
import { Container, StyledCol, MainTitle, Body2, H2 } from './PrivacyPolicy.style'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Typography } from '@atoms'
import { useLocation } from 'react-router-dom'

const PrivacyPolicy = (): JSX.Element => {
  const isMobile = useMobile()
  const isTablet = useTabletPortrait()
  const documentStartRef = useRef(null)
  const cookiesRef = useRef(null)

  const { hash } = useLocation()

  const scrollRefs: Record<string, MutableRefObject<HTMLSpanElement | null>> = useMemo(
    () => ({ ['#cookies']: cookiesRef }),
    [],
  )

  useLayoutEffect(() => {
    const scrollToElement = scrollRefs[hash] ?? documentStartRef
    scrollToElement?.current?.scrollIntoView()
  }, [hash, scrollRefs])

  return (
    <Container>
      <span ref={documentStartRef} />
      <StyledCol isMobile={isMobile} isTablet={isTablet} col={12} noGutter>
        <MainTitle>Privacy Policy</MainTitle>
        <Body2>
          This privacy policy applies between you, the User of this Website and ViewD Limited, the
          owner and provider of this Website. ViewD Limited takes the privacy of your information
          very seriously. This privacy policy applies to our use of any and all Data collected by us
          or provided by you in relation to your use of the Website.
        </Body2>
        <H2>Please read this privacy policy carefully.</H2>
        <H2>Definitions and interpretation</H2>
        <Body2>In this privacy policy, the following definitions are used:</Body2>
        <H2>Data</H2>
        <Body2>
          collectively all information that you submit to ViewD Limited via the Website. This
          definition incorporates, where applicable, the definitions provided in the Data Protection
          Laws;
        </Body2>
        <span id="cookies" ref={cookiesRef} />
        <H2>Cookies</H2>
        <Body2>
          a small text file placed on your computer by this Website when you visit certain parts of
          the Website and/or when you use certain features of the Website. Details of the cookies
          used by this Website are set out in the clause below (Cookies);
        </Body2>
        <H2>Data Protection Laws</H2>
        <Body2>
          any applicable law relating to the processing of personal Data, including, but not limited
          to, the Directive 96/46/EC (Data Protection Directive) or the GDPR, and any national
          implementing laws, regulations and secondary legislation, for as long as the GDPR is
          effective in the UK;
        </Body2>
        <H2>GDPR</H2>
        <Body2>the General Data Protection Regulation (EU) 2016/679;</Body2>
        <H2>ViewD Limited, we or us</H2>
        <Body2>
          ViewD Limited, a company incorporated in England and Wales with registered number 13527097
          whose registered office is at 130 Old Street, London, England, EC1V 9BD;
        </Body2>
        <H2>UK and EU Cookie Law</H2>
        <Body2>
          the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by
          the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011;
        </Body2>
        <H2>User or you</H2>
        <Body2>
          any third party that accesses the Website and is not either (i) employed by ViewD Limited
          and acting in the course of their employment or (ii) engaged as a consultant or otherwise
          providing services to ViewD Limited and accessing the Website in connection with the
          provision of such services;
        </Body2>
        <H2>Website</H2>
        <Body2>
          the website that you are currently using, www.viewd.io, and any sub-domains of this site
          unless expressly excluded by their own terms and conditions.
        </Body2>
        <Body2>
          In this privacy policy, unless the context requires a different interpretation:
        </Body2>
        <Body2> - the singular includes the plural and vice versa;</Body2>
        <Body2>
          - references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses,
          schedules or appendices of this privacy policy;
        </Body2>
        <Body2>
          - a reference to a person includes firms, companies, government entities, trusts and
          partnerships;
        </Body2>
        <Body2>{`"including" is understood to mean "including without limitation";`}</Body2>
        <Body2>
          - reference to any statutory provision includes any modification or amendment of it;
        </Body2>
        <Body2>the headings and subheadings do not form part of this privacy policy.</Body2>
        <H2>Scope of this privacy policy</H2>
        <Body2>
          This privacy policy applies only to the actions of ViewD Limited and Users with respect to
          this Website. It does not extend to any websites that can be accessed from this Website
          including, but not limited to, any links we may provide to social media websites.
        </Body2>
        <Body2>
          {`For purposes of the applicable Data Protection Laws, ViewD Limited is the "data
          controller". This means that ViewD Limited determines the purposes for which, and the
          manner in which, your Data is processed.`}
        </Body2>
        <H2>Data collected</H2>
        <Body2>We may collect the following Data, which includes personal Data, from you:</Body2>
        <Body2>- name;</Body2>
        <Body2>- date of birth;</Body2>
        <Body2>- gender;</Body2>
        <Body2>- job title;</Body2>
        <Body2>- profession;</Body2>
        <Body2>- contact Information such as email addresses and telephone numbers;</Body2>
        <Body2>- demographic information such as postcode, preferences and interests;</Body2>
        <Body2>- financial information such as credit / debit card numbers;</Body2>
        <Body2>- Personal data surrounding the service;</Body2>
        <Body2>in each case, in accordance with this privacy policy.</Body2>
        <H2>How we collect Data</H2>
        <Body2>We collect Data in the following ways:</Body2>
        <Body2>- data is given to us by you;</Body2>
        <Body2>- data is received from other sources;</Body2>
        <Body2>- data is collected automatically.</Body2>
        <H2>Data that is given to us by you</H2>
        <Body2>ViewD Limited will collect your Data in a number of ways, for example:</Body2>
        <Body2>
          - when you contact us through the Website, by telephone, post, e-mail or through any other
          means;
        </Body2>
        <Body2>
          - when you register with us and set up an account to receive our products/services;
        </Body2>
        <Body2>
          - when you complete surveys that we use for research purposes (although you are not
          obliged to respond to them);
        </Body2>
        <Body2>- when you enter a competition or promotion through a social media channel;</Body2>
        <Body2>- when you make payments to us, through this Website or otherwise;</Body2>
        <Body2>- when you elect to receive marketing communications from us;</Body2>
        <Body2>- when you use our services;</Body2>
        <Body2>in each case, in accordance with this privacy policy.</Body2>
        <H2>Data that is received from third parties</H2>
        <Body2>ViewD Limited will receive Data about you from the following third parties:</Body2>
        <Body2>- Algolia</Body2>
        <Body2>- GetAddress</Body2>
        <Body2>- BigQuery</Body2>
        <Body2>- SendGrid</Body2>
        <Body2>- Twilio</Body2>
        <Body2>- Firestore</Body2>
        <Body2>- Firebase Authentication</Body2>
        <Body2>- Google cloud platform storage</Body2>
        <Body2>- Google cloud platform functions</Body2>
        <H2>Data that is collected automatically</H2>
        <Body2>
          To the extent that you access the Website, we will collect your Data automatically, for
          example:
        </Body2>
        <Body2>
          - We automatically collect some information about your visit to the Website. This
          information helps us to make improvements to Website content and navigation and includes
          your IP address, the date, times and frequency with which you access the Website and the
          way you use and interact with its content.
        </Body2>
        <Body2>
          {`- We will collect your Data automatically via cookies, in line with the cookie settings on
          your browser. For more information about cookies, and how we use them on the Website, see
          the section below, headed "Cookies".`}
        </Body2>
        <H2>Security of our systems</H2>
        <Body2>
          Opinov8 infrastructure is protected by the numerous systems preventing getting access to
          any data which is stored in any of the services, the following measures applied to
          infrastructure:
        </Body2>
        <Body2>
          - Office based protection by Cisco Meraki system with firewall and strict access via
          RADIUS
        </Body2>
        <Body2>- Domain controller with security policies applied</Body2>
        <Body2>- Azure AD with security policies</Body2>
        <Body2>- SSO via Gsuite and Azure AD</Body2>
        <Body2>- VPN to access critical services</Body2>
        <Body2>- Password rotation of 6 month</Body2>
        <Body2>- AD Groups and Roles access</Body2>
        <Body2>- MFA on all the systems</Body2>
        <Body2>- Disk encryption to prevent theft data leaks</Body2>
        <Body2>- No anonymous access allowed</Body2>
        <Body2>- 6 month audits of project specific data and accesses</Body2>
        <Body2>- All team members passed GDPR and Cyber security courses</Body2>
        <H2>Our use of Data</H2>
        <Body2>
          Any or all of the above Data may be required by us from time to time in order to provide
          you with the best possible service and experience when using our Website. Specifically,
          Data may be used by us for the following reasons:
        </Body2>
        <Body2>- internal record keeping;</Body2>
        <Body2>- improvement of our products/services;</Body2>
        <Body2>
          - transmission by email of marketing materials that may be of interest to you;
        </Body2>
        <Body2>
          - contact for market research purposes which may be done using email, telephone, fax or
          mail. Such information may be used to customise or update the Website;
        </Body2>
        <Body2>in each case, in accordance with this privacy policy.</Body2>
        <Body2>
          {`We may use your Data for the above purposes if we deem it necessary to do so for our
          legitimate interests. If you are not satisfied with this, you have the right to object in
          certain circumstances (see the section headed "Your rights" below). For the delivery of
          direct marketing to you via e-mail, we'll need your consent, whether via an opt-in or
          soft-opt-in:`}
        </Body2>
        <Body2>
          {`- soft opt-in consent is a specific type of consent which applies when you have previously
          engaged with us (for example, you contact us to ask us for more details about a particular
          product/service, and we are marketing similar products/services). Under "soft opt-in"
          consent, we will take your consent as given unless you opt-out.`}
        </Body2>
        <Body2>
          {`- for other types of e-marketing, we are required to obtain your explicit consent; that
          is, you need to take positive and affirmative action when consenting by, for example,
          checking a tick box that we'll provide.`}
        </Body2>
        <Body2>
          {`- if you are not satisfied about our approach to marketing, you have the right to withdraw
          consent at any time. To find out how to withdraw your consent, see the section headed
          "Your rights" below.`}
        </Body2>
        <Body2>
          When you register with us and set up an account to receive our services, the legal basis
          for this processing is the performance of a contract between you and us and/or taking
          steps, at your request, to enter into such a contract.
        </Body2>
        <H2>Who we share Data with</H2>
        <Body2>
          We may share your Data with the following groups of people for the following reasons:
        </Body2>
        <Body2>
          - our employees, agents and/or professional advisors - our employees may need this data to
          ensure the service runs smoothly;
        </Body2>
        <Body2>
          - third party service providers who provide services to us which require the processing of
          personal data - to allow third party service providers;{' '}
        </Body2>
        <Body2>
          - third party payment providers who process payments made over the Website - to enable
          third party payment providers to process user payments and refunds;
        </Body2>
        <Body2>in each case, in accordance with this privacy policy.</Body2>
        <H2>Keeping Data secure</H2>
        <Body2>All data stored on Opinov8 services is protected by:</Body2>
        <Body2>- LDAP or Openid Connect access</Body2>
        <Body2>- MFA</Body2>
        <Body2>- Permissions only to those who actually working with the data</Body2>
        <Body2>- Password rotation for every account</Body2>
        <Body2>- Roles based access depending on contribution level</Body2>
        <Body2>- Role based Restrictions for documentation</Body2>
        <Body2>- 6 month security audits</Body2>
        <H2>Data retention</H2>
        <Body2>
          Unless a longer retention period is required or permitted by law, we will only hold your
          Data on our systems for the period necessary to fulfil the purposes outlined in this
          privacy policy or until you request that the Data be deleted.
        </Body2>
        <Body2>
          Even if we delete your Data, it may persist on backup or archival media for legal, tax or
          regulatory purposes.
        </Body2>
        <H2>Your rights</H2>
        <Body2>You have the following rights in relation to your Data:</Body2>
        <Body2>
          - <Typography.Caption2 as="span">Right to access</Typography.Caption2>
          {`- the right to request (i) copies of the information we hold about you
          at any time, or (ii) that we modify, update or delete such information. If we provide you
          with access to the information we hold about you, we will not charge you for this, unless
          your request is "manifestly unfounded or excessive." Where we are legally permitted to do
          so, we may refuse your request. If we refuse your request, we will tell you the reasons
          why.`}
        </Body2>
        <Body2>
          - <Typography.Caption2 as="span">Right to correct</Typography.Caption2> - the right to
          have your Data rectified if it is inaccurate or incomplete.
        </Body2>
        <Body2>
          - <Typography.Caption2 as="span">Right to erase </Typography.Caption2> - the right to
          request that we delete or remove your Data from our systems.
        </Body2>
        <Body2>
          -
          <Typography.Caption2 as="span">
            Right to restrict our use of your Data
          </Typography.Caption2>
          {` - the right to "block" us from using your Data or limit the way in which we can use it.`}
        </Body2>
        <Body2>
          - <Typography.Caption2 as="span">Right to data portability</Typography.Caption2> - the
          right to request that we move, copy or transfer your Data.
        </Body2>
        <Body2>
          - <Typography.Caption2 as="span">Right to object</Typography.Caption2> - the right to
          object to our use of your Data including where we use it for our legitimate interests.
        </Body2>
        <Body2 as="span">
          To make enquiries, exercise any of your rights set out above, or withdraw your consent to
          the processing of your Data (where consent is our legal basis for processing your Data),
          please contact us via this e-mail address:{' '}
        </Body2>
        <Typography.Link href="mailto:contactus@viewd.io">contactus@viewd.io</Typography.Link>
        <Body2>
          {`If you are not satisfied with the way a complaint you make in relation to your Data is
          handled by us, you may be able to refer your complaint to the relevant data protection
          authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's
          contact details can be found on their website at `}
          <a href="https://ico.org.uk/">https://ico.org.uk/.</a>
        </Body2>
        <Body2>
          It is important that the Data we hold about you is accurate and current. Please keep us
          informed if your Data changes during the period for which we hold it.
        </Body2>
        <H2>Links to other websites</H2>
        <Body2>
          This Website may, from time to time, provide links to other websites. We have no control
          over such websites and are not responsible for the content of these websites. This privacy
          policy does not extend to your use of such websites. You are advised to read the privacy
          policy or statement of other websites prior to using them.
        </Body2>
        <H2>Changes of business ownership and control</H2>
        <Body2>
          ViewD Limited may, from time to time, expand or reduce our business and this may involve
          the sale and/or the transfer of control of all or part of ViewD Limited. Data provided by
          Users will, where it is relevant to any part of our business so transferred, be
          transferred along with that part and the new owner or newly controlling party will, under
          the terms of this privacy policy, be permitted to use the Data for the purposes for which
          it was originally supplied to us. We may also disclose Data to a prospective purchaser of
          our business or any part of it. In the above instances, we will take steps with the aim of
          ensuring your privacy is protected.
        </Body2>
        <H2>Cookies</H2>
        <Body2>
          This Website may place and access certain Cookies on your computer. ViewD Limited uses
          Cookies to improve your experience of using the Website and to improve our range of
          services. ViewD Limited has carefully chosen these Cookies and has taken steps to ensure
          that your privacy is protected and respected at all times.
        </Body2>
        <Body2>
          All Cookies used by this Website are used in accordance with current UK and EU Cookie Law.
        </Body2>
        <Body2>
          Before the Website places Cookies on your computer, you will be presented with a message
          bar requesting your consent to set those Cookies. By giving your consent to the placing of
          Cookies, you are enabling ViewD Limited to provide a better experience and service to you.
          You may, if you wish, deny consent to the placing of Cookies; however certain features of
          the Website may not function fully or as intended.
        </Body2>
        <Body2>This Website may place the following Cookies:</Body2>
        <H2>Type of Cookie</H2>
        <H2>Purpose</H2>

        <Body2>Strictly necessary cookies</Body2>
        <Body2>
          These are cookies that are required for the operation of our website. They include, for
          example, cookies that enable you to log into secure areas of our website, make use of
          e-billing services or allow us to use various marketing channels to display our products
          and services.
        </Body2>
        <Body2>- You can find a list of Cookies that we use in the Cookies Schedule.</Body2>
        <Body2>
          - You can choose to enable or disable Cookies in your internet browser. By default, most
          internet browsers accept Cookies but this can be changed. For further details, please
          consult the help menu in your internet browser.
        </Body2>
        <Body2>
          - You can choose to delete Cookies at any time; however you may lose any information that
          enables you to access the Website more quickly and efficiently including, but not limited
          to, personalisation settings.
        </Body2>
        <Body2>
          - It is recommended that you ensure that your internet browser is up-to-date and that you
          consult the help and guidance provided by the developer of your internet browser if you
          are unsure about adjusting your privacy settings.
        </Body2>
        <Body2>
          - For more information generally on cookies, including how to disable them, please refer
          to <a href="https://aboutcookies.org">aboutcookies.org.</a> You will also find details on
          how to delete cookies from your computer.
        </Body2>
        <H2>General</H2>
        <Body2>
          You may not transfer any of your rights under this privacy policy to any other person. We
          may transfer our rights under this privacy policy where we reasonably believe your rights
          will not be affected.
        </Body2>
        <Body2>
          If any court or competent authority finds that any provision of this privacy policy (or
          part of any provision) is invalid, illegal or unenforceable, that provision or
          part-provision will, to the extent required, be deemed to be deleted, and the validity and
          enforceability of the other provisions of this privacy policy will not be affected.
        </Body2>
        <Body2>
          Unless otherwise agreed, no delay, act or omission by a party in exercising any right or
          remedy will be deemed a waiver of that, or any other, right or remedy.
        </Body2>
        <Body2>
          This Agreement will be governed by and interpreted according to the law of England and
          Wales. All disputes arising under the Agreement will be subject to the exclusive
          jurisdiction of the English and Welsh courts.
        </Body2>
        <H2>Changes to this privacy policy</H2>
        <Body2 as="span">
          ViewD Limited reserves the right to change this privacy policy as we may deem necessary
          from time to time or as may be required by law. Any changes will be immediately posted on
          the Website and you are deemed to have accepted the terms of the privacy policy on your
          first use of the Website following the alterations. You may contact ViewD Limited by email
          at{' '}
        </Body2>
        <Typography.Link href="mailto:contactus@viewd.io">contactus@viewd.io</Typography.Link>
        <H2>Attribution</H2>
        <Body2>This privacy policy was created on</Body2>
      </StyledCol>
    </Container>
  )
}

export default PrivacyPolicy
