import { mapValues } from 'lodash'
import { UNIT } from '@theme/themeConstants'

type ObjType = {
  [key: string]: string
}

const addUnit = (obj: ObjType): ObjType => mapValues(obj, (value) => `${value}${UNIT}`)

export default addUnit
