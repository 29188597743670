import React, { Dispatch } from 'react'
import {
  Container,
  DropdownContainer,
  Label,
  SearchContainer,
  TabButton,
  TabContainer,
} from './VisitInfoTitle.style'

import { Typography } from '@atoms'
import { Dropdown } from '@molecules'

import GlobalFilter from '../GlobalFilter'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { TitleTypes } from '@constants/types'

type VisitInfoTitleProps = Pick<
  TitleTypes,
  'defaultValue' | 'dropdownOptions' | 'setGlobalFilter' | 'globalFilter'
> & {
  selectedValue?: string
  setSelected?: Dispatch<string>
}

const VisitInfoTitle: React.FC<VisitInfoTitleProps> = ({
  defaultValue,
  dropdownOptions,
  setGlobalFilter,
  selectedValue,
  globalFilter,
  setSelected,
}) => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()

  return (
    <Container isMobile={isMobile}>
      {isMobile || isTabletPortrait ? (
        <DropdownContainer isMobile={isMobile}>
          <Dropdown
            modalDropDown
            handleValue={(optionValue: string) => {
              setSelected && setSelected(optionValue)
            }}
            selectedValue={
              dropdownOptions?.find((item) => item?.value === selectedValue)?.title || ''
            }
            value={defaultValue}
          >
            {dropdownOptions ? (
              dropdownOptions.map((item) => (
                <Dropdown.Option key={item.id} value={item.value} title={item.title}>
                  {item.title}
                </Dropdown.Option>
              ))
            ) : (
              <></>
            )}
          </Dropdown>
        </DropdownContainer>
      ) : (
        <TabContainer isMobile={isMobile}>
          {dropdownOptions ? (
            dropdownOptions.map((item) => (
              <TabButton
                onClick={() => {
                  setSelected(item.value)
                }}
                isMobile={isMobile}
                key={item.id}
              >
                <Label $active={item.value === selectedValue}>
                  <Typography.Caption4 $active={item.value === selectedValue} isMobile={isMobile}>
                    {item.title}
                  </Typography.Caption4>
                </Label>
              </TabButton>
            ))
          ) : (
            <></>
          )}
        </TabContainer>
      )}

      <SearchContainer isMobile={isMobile}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder={`Search visit`}
          noMargin
        />
      </SearchContainer>
    </Container>
  )
}

export default VisitInfoTitle
