import styled from 'styled-components'
import { Typography } from '@atoms'

export const TitleContainer = styled.div`
  margin-bottom: ${(p) => p.theme.indent.SM};
`

export const Title = styled(Typography.H2)`
  padding-right: ${(p) => (p.empty ? p.theme.indent.XXXS : p.theme.indent.MD)};
`
