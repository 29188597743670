export const MODAL_MESSAGES = {
  confirmModal: 'Are you sure you want to delete this property?',
  agencyDeleteAgentModal: 'Do you want to remove \n this user from the list of agents?',
  confirmStatus: {
    message: 'Please confirm',
    text: 'Are you sure you want to change the status of this property? Scheduled visits will be automatically canceled (if any)',
    labelYes: 'Yes, confirm',
    labelNo: 'No, cancel',
  },
  changedPasswordModal: {
    header: 'Password updated',
    message: `Your password has been successfully\nupdated`,
  },
  agentViewingConfirm: {
    pick: {
      question: {
        mobile: 'You have visits\n at the same time.',
        other: 'You have visits at the same time.',
      },
      suggestion: 'Please choose which visit you want to cancel or reschedule?',
    },
    cancel: {
      question: 'Do you want to cancel the visit?',
      suggestion: {
        mobile: 'Please leave the reason\n of canceling',
        other: 'Please leave the reason of canceling',
      },
      commentAreaPlaceholder: 'Enter the reason of canceling',
    },
    reschedule: {
      question: {
        mobile: 'Do you want\n to reschedule the visit?',
        other: 'Do you want to reschedule the visit?',
      },
      suggestion: {
        mobile: 'Please leave the reason\n of rescheduling',
        other: 'Please leave the reason of rescheduling',
      },
      commentAreaPlaceholder: 'Enter the reason of rescheduling',
    },
  },
}
