import { format, toDate, secondsToMilliseconds } from 'date-fns'
import { ViewingCancelInitiators } from '@constants/types'

const createVisitTime = (fromDateTime: number, toDateTime: number): string => {
  return `${format(toDate(secondsToMilliseconds(fromDateTime)), 'HH:mm')} - ${format(
    toDate(secondsToMilliseconds(toDateTime)),
    'HH:mm',
  )}`
}

const createVisitData = (fromDateTime: number): string => {
  return `${format(toDate(secondsToMilliseconds(fromDateTime)), 'dd/MM/yyyy')}`
}

const createStatusOfVisit = (isConfirmed: boolean, cancelled, review): string | number => {
  if (isConfirmed) {
    if (review) {
      return review.rating
    } else {
      return 'Confirmed'
    }
  } else {
    if (cancelled) {
      return 'Canceled'
    } else {
      return 'Wait for visit'
    }
  }
}

type Property = {
  id: number
  visitId: string
  date: string
  time: string
  name: string
  contact: string
  status: string | number
  shortList: string
  link: string
}

const createShortList = (isShortListed: boolean): boolean => {
  return isShortListed ? isShortListed : false
}
const cancelIniciator = (cancellationReasonId): string => {
  switch (cancellationReasonId) {
    case ViewingCancelInitiators.AGENT:
    case ViewingCancelInitiators.AGENT_FROM_ACTIVE:
      return 'Agent'
    case ViewingCancelInitiators.SELLER:
    case ViewingCancelInitiators.SELLER_PROP_UNDER_OFFER:
      return 'Seller'
    case ViewingCancelInitiators.BUYER:
      return 'Buyer'
    default:
      return ''
  }
}
export const getPropertyVisits = (firestoreList: { docs: Array }): Array<Property> =>
  firestoreList?.docs.map((visit) => {
    const data = visit?.data()
    return {
      id: data.id,
      date: createVisitData(data.fromDateTime.seconds),
      time: createVisitTime(data.fromDateTime.seconds, data.toDateTime.seconds),
      name: data.buyer.name,
      contact: data.buyer.email + data.buyer.phoneNumber,
      status: createStatusOfVisit(data.isConfirmed, data.cancelled, data.review),
      shortList: createShortList(visit?.review?.isShortListed),
      link: 'Resend book link',
      buyerId: data.buyer.id,
      propertyId: data.propertyId,
      propertyProfileId: data.propertyProfile.id,
      visitId: visit.id,
      modalBody: {
        name: data.buyer.name,
        visitsQuantity: data.visitsQuantity,
        title: data.propertyProfile.title,
        cancelledBy: data.cancelled?.cancellationReasonId
          ? cancelIniciator(data.cancelled.cancellationReasonId)
          : '',
        cancellationDescription: data.cancelled?.cancellationReasonMessage || '',
      },
    }
  })
