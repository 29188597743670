import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import { Typography } from '@atoms'

export const RowContainer = styled(Row)`
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.indent.LG};
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85%;
`

export const ButtonContainer = styled(Link)<{ isMobile: boolean }>`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const BackgroundContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  background-color: ${({ theme, confirmed }) => !confirmed && theme.color.greyBlue};
`
export const TopContainer = styled(Col)<{ isMobile: boolean }>`
  height: calc(100vh - 4.4rem);
  margin: auto;
  width: 100%;
  padding: ${({ theme, isMobile }) => (isMobile ? theme.indent.XL : theme.indent.XXXL)};
  position: relative;
`

export const ConfirmedImage = styled.img`
  display: flex;
  margin: auto;
  height: ${(p) => (p.isMobile ? '10rem' : '15rem')};
  margin-top: ${(p) => (p.isMobile ? '8rem' : 0)};
  margin-bottom: 0;
`

export const H1 = styled(Typography.H1)`
  text-align: center;
  white-space: pre-line;
`
export const CompletedImg = styled.img`
  width: 10rem;
  height: 10rem;
`
