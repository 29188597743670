import {
  getAuth,
  signOut,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth'
import { USER_TYPES, LS_CONTSTANTS, ROUTES } from '@constants/dictionary'

const setUserType = async (auth): Promise<void> => {
  if (auth.currentUser) {
    const userResult = await auth.currentUser.getIdTokenResult()
    if (!!USER_TYPES[userResult.claims.userType]) {
      localStorage.setItem(LS_CONTSTANTS.userType, userResult.claims.userType)
      localStorage.setItem(LS_CONTSTANTS.redirectLink, ROUTES.AGENT)
    }
  }
}
export const loginWithOutRememberMe = async (
  email: string,
  password: string,
): { result: boolean } => {
  const auth = getAuth()
  setPersistence(auth, browserSessionPersistence)

  try {
    await signInWithEmailAndPassword(auth, email, password)
    const uid = auth.currentUser ? auth.currentUser.uid : ''
    localStorage.setItem(LS_CONTSTANTS.uid, uid)
    setUserType(auth)

    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}

export const loginWithRememberMe = async (email: string, password: string): { result: boolean } => {
  const auth = getAuth()

  try {
    await signInWithEmailAndPassword(auth, email, password)
    const uid = auth.currentUser ? auth.currentUser.uid : ''
    localStorage.setItem(LS_CONTSTANTS.uid, uid)
    setUserType(auth)

    return { result: true }
  } catch (e) {
    return { e, result: false }
  }
}
export const signOutAgent = async (): Promise<boolean> => {
  const auth = getAuth()
  window.localStorage.clear()
  signOut(auth)
    .then(() => {
      localStorage.removeItem(LS_CONTSTANTS.agentIgnoredConflictedViewings)
      return true
    })
    .catch(() => {
      return false
    })
}
