import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const getFullLink = async (id: string): { error: string } => {
  const db = getFirestore()
  const docRef = doc(db, FS_COL_NAMES.URLS, id)
  try {
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return docSnap?.data()?.link
    }
    return undefined
  } catch (e) {
    return undefineds
  }
}
