import React from 'react'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import GlobalFilter from '../GlobalFilter'
import { SearchContainer, Header, ButtonContainer } from './AgencyListOfAgentTitle.style'
import { Button } from '@molecules'

type Props = {
  globalFilter: string
  setGlobalFilter: (filterValue: string) => void
  addAgent: (value: boolean) => void
}

const AgencyListOfAgent: React.FC<Props> = (props): JSX.Element => {
  const { globalFilter, setGlobalFilter, addAgent } = props
  const isMobile = useMobile()
  const isTablet = useTabletPortrait()

  return (
    <Header isMobile={isMobile}>
      <SearchContainer isTablet={isTablet} isMobile={isMobile}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder="Search agent"
        />
      </SearchContainer>
      <ButtonContainer isMobile={isMobile}>
        <Button label="Add Agent" height="4rem" onClick={addAgent} />
      </ButtonContainer>
    </Header>
  )
}

export default AgencyListOfAgent
