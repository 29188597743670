import { withoutCyrillicRegExp, withoutUnderscoreRegExp } from '@constants/regExp'
import { MIN_SYMBOLS, MAX_SYMBOLS } from '@constants/dictionary'
import * as yup from 'yup'

export const requiredRule = (): string => yup.string().trim().required()
export const schema = yup.object().shape({
  password: requiredRule()
    .min(MIN_SYMBOLS)
    .max(MAX_SYMBOLS)
    .matches(withoutCyrillicRegExp, withoutUnderscoreRegExp),
  email: requiredRule().email().matches(withoutCyrillicRegExp),
})
