import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Typography } from '@atoms'
import { AgencyInfo, DayPickRow } from '@molecules'
import { convertToPrice, initialState } from '@utils/functions'
import { useMobile } from '@utils/hooks'
import TimeSlotsList from '../TimeSlotsList'
import {
  ContainerInfo,
  BookingCardImage,
  Text,
  ContainerAgency,
  BottomContainer,
} from './SurveyBookingCard.style'
import { SurveyBookingCardTypes } from '@constants/types/organisms'

const SurveyBookingCard: React.FC<SurveyBookingCardTypes> = ({
  adress,
  nameProperty,
  cost,
  urlImage,
  agentName,
  agentPhone,
  timeSlotDetails,
  callback,
}): JSX.Element => {
  const isMobile = useMobile()
  const [chosenDay, setChosenDay] = useState('')
  const [timeSlots, setTimeSlots] = useState(
    timeSlotDetails.length ? timeSlotDetails[0].timeSlots : [],
  )
  const [selectedSlot, setSelectedSlot] = useState()
  const cardState = initialState(timeSlotDetails, chosenDay, timeSlots, selectedSlot)

  const handleSlotsChange = (day): undefined => {
    setTimeSlots(
      timeSlotDetails.filter((item) => format(item.dayOfWeek, 'd LLLL y') === day)[0].timeSlots,
    )
  }

  const handleDayChange = (date: Date): undefined => {
    setChosenDay(format(date, 'd LLLL y'))
    handleSlotsChange(format(date, 'd LLLL y'))
  }

  const handleSlot = (id: string): undefined => {
    setSelectedSlot(id)
  }

  useEffect(() => {
    callback(cardState.timeSlot)
  }, [callback, cardState.timeSlot])

  return (
    <>
      <BookingCardImage path={urlImage} />
      <ContainerAgency>
        <AgencyInfo transparently nameAgency={agentName} phoneAgency={agentPhone} />
      </ContainerAgency>
      <ContainerInfo>
        <Typography.H2 isMobile={isMobile}>{nameProperty}</Typography.H2>
        <Text isMobile={isMobile}>{adress}</Text>
        <Typography.Body2 isMobile={isMobile}>£ {convertToPrice(cost)}</Typography.Body2>
      </ContainerInfo>
      {timeSlotDetails.length ? (
        <>
          <DayPickRow
            isBookingCard
            daysList={cardState.days}
            selectedDay={chosenDay}
            onClick={handleDayChange}
          />
          <TimeSlotsList timeSlotsList={cardState.currentTimeSlots} onClick={handleSlot} />
        </>
      ) : (
        <BottomContainer isMobile={isMobile}>
          <Typography.Body2 disabled>
            No time slots available, for any questions contact your agent or the agency.
          </Typography.Body2>
        </BottomContainer>
      )}
    </>
  )
}

export default SurveyBookingCard
