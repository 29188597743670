import { Typography } from '@atoms'
import { Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const Container = styled.div<{ path: URL }>`
  height: 100vh;
  width: 100%;
  background-image: url(${({ path }) => path});
  background-size: cover;
  background-position: center;
  position: relative;
`
export const LinkContainer = styled.div`
  cursor: pointer;
`
export const StyledCol = styled(Col)`
  position: absolute;
  right: 0;
  background-color: ${(p) => p.theme.color.black_30};
  backdrop-filter: blur(0.4rem);
  height: 100vh;
`

export const FormContainer = styled.div<{ isMobile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: ${(p) => p.theme.indent.XXXL} ${(p) => (p.isMobile ? p.theme.indent.LG : '6rem')};
`

export const FieldsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  padding: ${(p) => p.theme.indent.XL};
  margin-top: ${(p) => p.theme.indent.LG};
  align-content: stretch;
  width: ${(p) => (p.isMobile ? '33.5rem' : '41.2rem')};
  max-width: ${(p) => (p.isMobile ? '33.5rem' : '41.2rem')};
`

export const H2 = styled(Typography.H2)`
  padding-bottom: ${(p) => p.theme.indent.MD};
`

export const ButtonContainer = styled.div`
  width: ${(p) => (p.isMobile ? '9.4rem' : '11.6rem')};
`

export const ContainerCheckBox = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: ${(p) => p.theme.indent.MD};
`

export const BottomContainer = styled.div<{ isMobile: boolean }>`
  margin-top: ${(p) => (p.isMobile ? p.theme.indent.SM : p.theme.indent.MD)};
  display: ${(p) => (p.isMobile ? 'block' : 'flex')};
  text-align: center;
`

export const Form = styled.form`
  width: 100%;
`

export const ErrorBlock = styled(Typography.Body2)`
  color: ${({ theme }) => theme.color.red};
  padding-top: ${(p) => p.theme.indent.SM};
`
export const Body3 = styled(Typography.Body3)`
  padding-right: ${({ theme, isMobile }) => (isMobile ? '0' : theme.indent.XS)};
`
