import { Typography } from '@atoms'
import styled from 'styled-components'

export const H2 = styled(Typography.H2)`
  padding-top: ${(p) => p.theme.indent.MD};
  padding-bottom: ${(p) => p.theme.indent.MD};
`

export const TextContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.indent.MD};
`
export const TextInfo = styled(Typography.Body2)`
  color: ${({ theme }) => theme.color.darkGrey};
`
