import styled from 'styled-components'

export const Header = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: ${({ theme }) => theme.indent.MD};
  ${({ isMobile }) =>
    isMobile &&
    `flex-direction: column-reverse;
    align-items: center;
  `};
`

export const SearchContainer = styled.div<{
  isTablet: boolean
  isMobile: boolean
}>`
  width: ${({ isTablet, isMobile }) => (isMobile ? '100%' : isTablet ? '27.5rem' : '31.5rem')};
`

export const ButtonContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '14.4rem')};
  padding-bottom: ${({ theme, isMobile }) => isMobile && theme.indent.MD};
`
