import styled from 'styled-components'
import { Typography } from '@atoms'

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${(p) => (p.link ? p.theme.indent.XL : p.theme.indent.MD)};
`
export const TitleInfo = styled(Typography.Body2)`
  padding-left: ${(p) => p.theme.indent.SM};
  ${({ color }) => color && `color: ${color}`};
`
export const TitleEvent = styled(Typography.Link)`
  padding-left: ${(p) => p.theme.indent.SM};
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.SM : p.theme.fontSize.LG)};
  font-weight: bold;
`
