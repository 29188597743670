import styled from 'styled-components'
import { Typography } from '@atoms'
import { Row } from 'styled-bootstrap-grid'

export const BodyContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTablet: boolean
}>`
  min-height: calc(100vh - 18rem);
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.MD} 5rem`
    if (isTablet) return `${theme.indent.MD} ${theme.indent.XXXL}`
    if (isMobile) return `${theme.indent.MD} 2rem`
    return `${theme.indent.MD} 5rem 0`
  }};
`
export const Container = styled(Row)`
  flex-direction: row;
  align-items: center;
  background-color: white;
`
export const LinkTo = styled(Typography.Link)<{ noUnderline: boolean; isMobile: boolean }>`
  text-decoration: ${(p) => (p.noUnderline ? 'none' : 'underline')};
`
export const IconContainer = styled.div`
  padding-left: ${(p) => p.theme.indent.SM};
`
export const InfoContainer = styled.div`
  display: flex;
  min-width: 11.5rem;
  justify-content: space-between;
`
export const Cell = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
