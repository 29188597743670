import styled from 'styled-components'

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
`
export const TimeSlotDropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex-grow: 1;
`
