import React from 'react'
import {
  AccountPreferences,
  Integrations,
  NotificationPreferences,
  AgencyListOfAgents,
  AgencySignInSecurity,
} from './components'

export const TITLES = {
  accPref: 'Account Preferences',
  listAgents: 'List of Agents',
  notPref: 'Notification Preferences',
  signSec: 'Sign In & Security',
  integr: 'Integrations',
}

export const BaseTabsList = [
  {
    title: TITLES.notPref,
    component: (): JSX.Element => <NotificationPreferences />,
    withHeader: true,
  },
  {
    title: TITLES.signSec,
    component: (): JSX.Element => <AgencySignInSecurity />,
    withHeader: true,
  },
  {
    title: TITLES.integr,
    component: (): JSX.Element => <Integrations />,
    withHeader: true,
  },
]

export const AgentTabsList = [
  {
    title: TITLES.accPref,
    component: (): JSX.Element => <AccountPreferences />,
    withHeader: true,
  },
  ...BaseTabsList,
]

export const AgencyTabsList = [
  {
    title: TITLES.accPref,
    component: (): JSX.Element => <AccountPreferences />,
    withHeader: true,
  },
  {
    title: TITLES.listAgents,
    component: (): JSX.Element => <AgencyListOfAgents />,
    withHeader: false,
  },
  ...BaseTabsList,
]
