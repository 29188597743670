export enum ActionsSellerSlotsReducer {
  addNewTimePeriod = 'ADD_NEW_TIME_PERIOD',
  removeTimePeriod = 'REMOVE_TIME_PERIOD',
  toNextDay = 'TO_NEXT_DAY',
  toPreviousDay = 'TO_PREVIOUS_DAY',
  chooseDay = 'CHOOSE_DAY',
  addFromTime = 'ADD_FROM_TIME',
  minusFromTime = 'MINUS_FROM_TIME',
  addUntilTime = 'ADD_UNTIL_TIME',
  minusUtilTime = 'MINUS_UNTIL_TIME',
  setFromTime = 'SET_FROM_TIME',
  setUntilTime = 'SET_UNTIL_TIME',
}
