import { remove } from 'lodash'

export const calculateOnCheck = (value: string, list: Array<string>): Array<string> => {
  if (list.includes(value)) {
    remove(list, (item) => item === value)
    return list
  }
  list.push(value)
  return list
}

export const calculateOnAll = (value: string, list: Array<string>): Array<string> => {
  if (list.includes(value)) {
    return list
  }
  list.push(value)
  return list
}
