import React from 'react'
import { Container, TitleBackground, BodyContainer, Title } from './AgentHelpPage.style'
import titleDesktop from '@assets/images/bg-help-desktop.png'
import titleTablet from '@assets/images/bg-help-tablet.png'
import titleMobile from '@assets/images/bg-help-mobile.png'
import { useDesktop, useMobile, useTabletPortrait } from '@utils/hooks'
import { Icon } from '@atoms'
import { AgentHelpCard } from '@organisms'

const AgentHelpPage: React.FC = () => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const isDesktop = useDesktop()

  const path = (): string => {
    if (isTabletPortrait) {
      return titleTablet
    }
    if (isMobile) {
      return titleMobile
    }
    return titleDesktop
  }

  return (
    <Container>
      <TitleBackground
        path={path()}
        isMobile={isMobile}
        isTabletPortrait={isTabletPortrait}
        isDesktop={isDesktop}
      >
        <Title>
          <Icon icon="help-message" size="28.8rem" />
        </Title>
      </TitleBackground>
      <BodyContainer isMobile={isMobile} isTabletPortrait={isTabletPortrait} isDesktop={isDesktop}>
        <AgentHelpCard />
      </BodyContainer>
    </Container>
  )
}

export default AgentHelpPage
