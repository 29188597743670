const FONT_SIZES = {
  XXXS: 1,
  XXS: 1.2,
  XS: 1.4,
  SM: 1.6,
  MD: 1.8,
  LG: 2.0,
  XL: 2.4,
  XXL: 3,
  XXXL: 3.2,
}

export default FONT_SIZES
