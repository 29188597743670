/* eslint-disable no-magic-numbers */
import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import { useMobile } from '@utils/hooks'
import { Icon, Typography, SimpleRatingStars, Loader } from '@atoms'
import { BaseModal } from '@modals'
import { ModalBodyProps } from '@constants/types'

import {
  ModalContainer,
  StyledIconContainer,
  MessageTitleContainer,
  StarsContainer,
  StyledRow,
  CardHeader,
  VisitSequenceNumber,
  BuyerName,
  CloseButton,
  PropertyTitle,
} from './AgentVisitInfoCard.style'

type InfoModalTemplatesType = {
  isShown: boolean
  toggle: () => void
  infoType: string
  modalBody: ModalBodyProps
  loadedModal: boolean
}

const InfoModalTemplates: React.FC<InfoModalTemplatesType> = ({
  isShown = false,
  toggle = () => {},
  infoType = '',
  modalBody = {},
  loadedModal = true,
}): React.FC => {
  const isMobile = useMobile()

  const render = (): JSX.Element => {
    switch (infoType) {
      case 'Cancelled':
        return (
          <>
            <Row>
              <StyledIconContainer>
                <Icon icon="notification-declined" size="1.4rem" color="darkGrey" />
              </StyledIconContainer>
              <Typography.Body3>{`Viewing was canceled by ${modalBody.cancelledBy}`}</Typography.Body3>
            </Row>
            <MessageTitleContainer>
              <Typography.Body3 color="darkGrey">Reason</Typography.Body3>
            </MessageTitleContainer>
            <Typography.Body3>{modalBody.cancellationDescription}</Typography.Body3>
          </>
        )
      case 'Confirmed':
        return (
          <Row>
            <StyledIconContainer>
              <Icon icon="notification-checked" size="1.4rem" color="darkGrey" />
            </StyledIconContainer>
            <Typography.Body3>
              Viewing was confirmed, but feedback has not been given
            </Typography.Body3>
          </Row>
        )
      case 'Rated':
        return (
          <Loader loaded={loadedModal} isTable>
            <MessageTitleContainer>
              <Row>
                <Typography.Body3 color="darkGrey">Viewing Rating</Typography.Body3>
                <StarsContainer>
                  <SimpleRatingStars count={modalBody.rating} size="1.4rem" />
                </StarsContainer>
              </Row>
            </MessageTitleContainer>
            <Row>
              <Col col={6}>
                <Typography.Body3>Price</Typography.Body3>
                <SimpleRatingStars count={modalBody.price} size="1.4rem" displayBlue />
              </Col>
              <Col col={6}>
                <Typography.Body3>Value for Money</Typography.Body3>
                <SimpleRatingStars count={modalBody.valueForMoney} size="1.4rem" displayBlue />
              </Col>
            </Row>
            <StyledRow>
              <Col col={6}>
                <Typography.Body3>Overall Condition</Typography.Body3>
                <SimpleRatingStars count={modalBody.overallCondition} size="1.4rem" displayBlue />
              </Col>
              <Col col={6}>
                <Typography.Body3>Location</Typography.Body3>
                <SimpleRatingStars count={modalBody.location} size="1.4rem" displayBlue />
              </Col>
            </StyledRow>
            <StyledRow>
              <Typography.Body3>Interested in this Property:</Typography.Body3>
            </StyledRow>
            <Typography.H3 color="originalBlue" isMobile>
              {modalBody.interestedInProperty ? 'Yes' : 'No'}
            </Typography.H3>
            <MessageTitleContainer>
              <Typography.Body3 color="darkGrey">Message</Typography.Body3>
            </MessageTitleContainer>
            <Typography.Body3>{modalBody.comment}</Typography.Body3>
          </Loader>
        )
      default:
        return null
    }
  }

  const calculateLetters = (count: number): string => {
    switch (count) {
      case 0:
        return ''
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const { name, title, visitsQuantity } = modalBody

  return (
    <BaseModal isShown={isShown} hide={toggle}>
      <ModalContainer isMobile={isMobile} onClick={(e) => e.stopPropagation()}>
        <CloseButton>
          <Icon icon="close" clickable size="1.8rem" color="originalBlue" onClick={toggle} />
        </CloseButton>
        <CardHeader isMobile={isMobile}>
          <BuyerName>{name}</BuyerName>
          <VisitSequenceNumber>{` - ${visitsQuantity}${calculateLetters(
            visitsQuantity,
          )} visit`}</VisitSequenceNumber>
        </CardHeader>
        <PropertyTitle isMobile shortString={isMobile}>
          {title}
        </PropertyTitle>
        {render()}
      </ModalContainer>
    </BaseModal>
  )
}

export default InfoModalTemplates
