import styled from 'styled-components'
import { Typography } from '@atoms'

export const TimeSlotButtonContainer = styled.div<{ isMobile: boolean }>`
  border: 0.1rem solid ${(p) => p.theme.color.grey};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  padding: 0.9rem;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`
export const TimeSlotLabel = styled(Typography.Body2)<{ isMobile: boolean }>`
  color: ${(p) => p.theme.color.darkBlack};
  font-weight: 600;
`
