import styled from 'styled-components'
import { Typography } from '@atoms'
import { Link } from 'react-router-dom'

export const ConfirmImage = styled.img`
  display: flex;
  margin: auto;
  height: ${(p) => (p.isMobile ? '18rem' : '25rem')};
  margin-top: ${(p) => (p.isMobile ? '8rem' : '13rem')};
  margin-bottom: 0;
`

export const H1 = styled(Typography.H1)`
  text-align: center;
  white-space: pre-line;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 4.4rem);
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
`

export const TextWrapper = styled.div`
  text-align: center;
  margin-top: ${(p) => p.theme.indent.SM};
  margin-bottom: ${(p) => p.theme.indent.XL};
`
export const LinkContainer = styled(Link)`
  text-decoration: none;
  justify-content: center;
  align-items: center;
`
export const ButtonRow = styled.div<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XXXL} 0 ${p.theme.indent.XXXL}`
      : `0 ${p.theme.indent.XXXL}  8rem 0 `};
  margin-top: auto;
`
