import styled from 'styled-components'
import { Typography } from '@atoms'
import { Link } from 'react-router-dom'

export const NotificationCardContainer = styled.div`
  margin-top: ${(p) => p.theme.indent.SM};
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: ${(p) => p.theme.indent.SM};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  padding: ${(p) => p.theme.indent.LG};
  width: 100%;
`
export const CardTime = styled(Typography.Body3)`
  color: ${(p) => p.theme.color.darkGrey};
`
export const CardDot = styled.div`
  position: absolute;
  right: ${(p) => p.theme.indent.XS};
  top: ${(p) => p.theme.indent.XS};
  border-radius: 50%;
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${(p) => p.theme.color.originalBlue};
`
export const NotificationMessage = styled.div<{ $isTabletLandscape: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => (p.$isTabletLandscape ? p.theme.fontSize.SM : p.theme.fontSize.XS)};
  line-height: ${(p) => (p.$isTabletLandscape ? p.theme.lineHeight.MD : p.theme.lineHeight.SM)};
`
export const NotificationText = styled(Typography.Body3)<{ $isTabletLandscape: boolean }>`
  line-height: ${(p) => (p.$isTabletLandscape ? p.theme.lineHeight.MD : p.theme.lineHeight.SM)};
  font-size: ${(p) => (p.$isTabletLandscape ? p.theme.fontSize.SM : p.theme.fontSize.XS)};
  color: ${(p) => p.theme.color.darkBlue};
`
export const NotificationLink = styled(Link)<{ $isTabletLandscape: boolean }>`
  font-size: ${(p) => (p.$isTabletLandscape ? p.theme.fontSize.SM : p.theme.fontSize.XS)};
  color: ${(p) => p.theme.color.originalBlue};
`
