import React from 'react'
import { useMobile } from '@utils/hooks'
import { Container } from './Counter.style'
import { CounterTypes } from '@constants/types/atoms'

const Counter = (props: CounterTypes): JSX.Element => {
  const { count } = props
  const isMobile = useMobile()
  if (count <= 0) {
    return null
  }
  return <Container isMobile={isMobile}>{count}</Container>
}

export default Counter
