import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import { useMobile } from '@utils/hooks'
import { Body2 } from './EmptyListText.style'

type EmptyListTextProps = {
  text: string
}

const EmptyListText: React.FC<EmptyListTextProps> = ({ text = '' }) => {
  const isMobile = useMobile()
  return (
    <Row justifyContent="center">
      <Col sm={10} md={8} lg={8} xl={8}>
        <Body2 isMobile={isMobile} disabled>
          {text}
        </Body2>
      </Col>
    </Row>
  )
}

export default EmptyListText
