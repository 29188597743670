export const BOOKING_STATUSES = {
  UNDEFINED: '',
  ACTIVE: 'active',
  UNDER_OFFER: 'underOffer',
  SOLD: 'sold',
  ARCHIVED: 'archived',
  OFF_MARKET: 'offMarket',
  SOLD_STC: 'soldStc',
  DELETED: 'deleted',
}

export const BOOKING_STATUSES_NAMES = {
  '': '',
  active: 'Active',
  underOffer: 'Under offer',
  sold: 'Sold',
  archived: 'Archive',
  offMarket: 'Off market',
  soldStc: 'Sold STC',
  deleted: 'Deleted',
}
