import React, { useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { StepProgressBar } from '@templates'
import { ROUTES, LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { Timestamp } from 'firebase/firestore'
import { useDocument, useCollection } from 'react-firebase-hooks/firestore'
import { getBuyerProfile } from '@utils/functions'
import { SortListTypes, ReviewTypes, ReviewForm } from '@constants/types'
import {
  getUserProfile,
  isPropertyInShortList,
  getPropertyProfileById,
  setReview,
  setToShortList,
  upDatePropertyShortList,
} from '@services/firebase'
import { BackgroundContainer } from './BuyerSurveyAnswer.style'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import { useMountEffect } from '@utils/hooks'

const BuyerSurvey = (): JSX.Element => {
  const visitId = localStorage.getItem(LS_CONTSTANTS.visitId)
  const buyerId = localStorage.getItem(LS_CONTSTANTS.uid)
  const agentId = localStorage.getItem(LS_CONTSTANTS.agentId)

  const countSteps = 2
  const [currnentStep, setStep] = useState(1)

  const addStep = useCallback(() => {
    setStep(currnentStep + 1)
  }, [currnentStep])

  const minusStep = useCallback(() => {
    setStep(currnentStep - 1)
  }, [currnentStep])

  const history = useHistory()
  const { state } = useLocation()
  const onClickBack = useCallback(() => history.push(ROUTES.BUYER_SURVEY), [history])
  const methods = useForm({
    mode: 'onChange',
  })

  const { handleSubmit, watch } = methods

  const requestSecondViewing = watch('requestSecondViewing')
  const addToShortlist = watch('addToShortlist')
  const price = watch('price')
  const valueForMoney = watch('valueForMoney')
  const overallCondition = watch('overallCondition')
  const location = watch('location')
  const rating = watch('rating')
  const comment = watch('comment')
  const interestedInProperty = watch('interestedInProperty')

  const disabledFirstStep = price && valueForMoney && overallCondition && location
  const disabledSecondStep = rating && interestedInProperty !== -1

  // get buyerProfile from firebase
  const buyerProfiles = getUserProfile(buyerId, agentId, USER_TYPES.buyer)
  const [valueBuyerProfiles, loadingBuyer] = useCollection(buyerProfiles)
  const buyerProfile = loadingBuyer ? { id: '' } : getBuyerProfile(valueBuyerProfiles)

  // check, is property alredy added to buyer's shortList
  const docShortListedProperty = isPropertyInShortList(state.propertyProfile.id)
  const [valueShortListedProperty, loadingShortListedProperty] = useDocument(docShortListedProperty)
  const isShortListedProperty = loadingShortListedProperty
    ? false
    : valueShortListedProperty?.exists()

  // get PropertyProfile from firebase
  const [propertyProfile, setPropertyProfile] = useState<{ price: number; status: string }>({
    price: 0,
    status: '',
  })
  const getPropertyProfile = useCallback(async () => {
    const propertyProfileDoc = await getPropertyProfileById(
      state.propertyId,
      state.propertyProfile.id,
    )
    setPropertyProfile({ price: propertyProfileDoc?.price, status: propertyProfileDoc?.status })
  }, [state.propertyId, state.propertyProfile.id])
  useMountEffect(getPropertyProfile)

  const review: ReviewTypes = {
    propertyId: state.propertyId,
    propertyProfileId: state.propertyProfile.id,
    buyerId,
    buyerProfileId: buyerProfile.id,
    detailedRatings: {
      location,
      price,
      valueForMoney,
      overallCondition,
    },
    rating,
    comment,
    createdAt: Timestamp.fromDate(new Date()),
    interestedInProperty,
    addToShortlist,
    requestSecondViewing,
  }

  const infoShortlist: SortListTypes = {
    agent: state.agent,
    propertyId: state.propertyId,
    rating,
    propertyProfile: {
      ...state.propertyProfile,
      price: propertyProfile.price,
      status: propertyProfile.status,
    },
    createdAt: new Date(),
  }

  const onSubmit: SubmitHandler<ReviewForm> = async () => {
    setReview(visitId, review)

    if (addToShortlist) {
      if (!isShortListedProperty) {
        setToShortList(state.propertyProfile.id, infoShortlist)
      }
    }
    if (!addToShortlist && isShortListedProperty) {
      upDatePropertyShortList(state.propertyProfile.id, rating)
    }

    if (requestSecondViewing) {
      localStorage.setItem(LS_CONTSTANTS.propertyId, state.propertyId)
      localStorage.setItem(LS_CONTSTANTS.agentId, state.agent.id)
      history.push({
        pathname: ROUTES.BUYER_BOOKING,
      })
    } else
      history.push({
        pathname: ROUTES.BUYER_SURVEY_COMPLETED,
      })
  }

  return (
    <BackgroundContainer>
      <StepProgressBar
        onClickComplete={handleSubmit(onSubmit)}
        onClickBack={onClickBack}
        addStep={addStep}
        minusStep={minusStep}
        countSteps={countSteps}
        disabledFirstStep={disabledFirstStep}
        disabledSecondStep={disabledSecondStep}
      >
        <FormProvider {...methods}>
          {currnentStep === 1 ? (
            <FirstStep />
          ) : (
            <SecondStep isShortListedProperty={isShortListedProperty} />
          )}
        </FormProvider>
      </StepProgressBar>
    </BackgroundContainer>
  )
}

export default BuyerSurvey
