import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`

export const HeaderContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTablet: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.MD} 5rem 0`
    if (isTablet) return `${theme.indent.MD} ${theme.indent.XXXL} 0`
    if (isMobile) return `${theme.indent.XL} ${theme.indent.LG} 0`
  }};
`

export const Title = styled.div<{ isMobile: boolean }>`
  display: flex;
  margin-bottom: ${({ isMobile, theme }) => (isMobile ? theme.indent.XL : theme.indent.MD)};
`
export const H1Container = styled.div`
  margin-left: ${({ theme }) => theme.indent.LG};
`

export const LinkContainer = styled(Link)`
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
`
