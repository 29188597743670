import React, { forwardRef, useCallback, useRef, useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { Icon } from '@atoms'
import {
  Option,
  OptionContainer,
  OptionContainerThin,
  OptionSelect,
  OptionsArea,
  SelectedOption,
  IconContainer,
  Body3,
} from './Dropdown.style'

import { DropdownTypes } from '@constants/types/molecules'

const Dropdown: React.FC<DropdownTypes> = forwardRef((props, ref): JSX.Element => {
  const {
    handleValue,
    testid,
    children,
    value,
    selectedValue,
    center,
    paginationDropdown,
    modalDropDown,
    renderThin,
    id = 0,
    backgroundColor,
    formDropdown,
    tableDropdown,
  } = props
  const clickRef = useRef()
  const theme = useTheme()
  const [isDropped, setDropped] = useState(false)
  const [dropDirection, setDropDirection] = useState('down')
  const [currentValue, setCurrentValue] = useState(value)
  const showOptions = useCallback(
    (event) => {
      const dropBreakpoint = 240 // implemented according to design
      // https://developer.mozilla.org/ru/docs/Web/API/Element/getBoundingClientRect
      const fromTop = event.target.getBoundingClientRect().bottom
      const fromBottom = window.innerHeight - fromTop
      if (isDropped === false) {
        if (fromBottom <= dropBreakpoint) setDropDirection('up')
        else if (fromTop <= dropBreakpoint || fromBottom > dropBreakpoint) setDropDirection('down')
      }
      setDropped(!isDropped)
    },
    [isDropped],
  )

  const $handleValue = useCallback(
    (event) => {
      const clickedElement = event.target
      if (clickedElement.children.length === 0) {
        const optionTitle = clickedElement.getAttribute('title')
        if (handleValue) {
          const optionValue = clickedElement.getAttribute('value')
          handleValue(optionValue)
        }
        setCurrentValue(optionTitle)
        setDropped(false)
        event.stopPropagation()
      }
    },
    [handleValue],
  )

  useEffect(() => {
    if (selectedValue) {
      setCurrentValue(selectedValue)
    }
  }, [selectedValue])

  const handleBlur = useCallback(() => {
    setDropped(false)
  }, [setDropped])

  const options = {
    container: {
      onClick: showOptions,
      ref: clickRef,
      testid: `${testid}DropdownOptionContainer`,
      isDropped: isDropped,
      onBlur: handleBlur,
      tabIndex: 0,
      drop: dropDirection,
      center: center,
      paginationDropdown: paginationDropdown,
      modalDropDown: modalDropDown,
      backgroundColor: backgroundColor,
      formDropdown: formDropdown,
    },
    selectedOption: {
      center: center,
      ref: ref,
      testid: `${testid}DropdownSelectedOption`,
      paginationDropdown: paginationDropdown,
      formDropdown: formDropdown,
    },
  }

  const renderOptionSelect = (): React.FC => (
    <OptionSelect testid={`${testid}DropdownOptionSelect`}>
      {renderThin ? (
        <SelectedOption {...options.selectedOption} paginationDropdown>
          {currentValue}
        </SelectedOption>
      ) : (
        <SelectedOption {...options.selectedOption}>{currentValue}</SelectedOption>
      )}
      <IconContainer isDropped={isDropped} testid={`${testid}DropdownIconContainer`}>
        <Icon icon="dropdown" color={theme.color.originalBlue} clickable size="1rem" />
      </IconContainer>
      <OptionsArea
        onClick={(e) => children.length > 1 && $handleValue(e)}
        isDropped={isDropped}
        drop={dropDirection}
        testid={`${testid}DropdownOptionsArea`}
        renderThin={renderThin}
        tableDropdown={tableDropdown}
      >
        {children.length > 1 ? children : <Body3 disabled>No options are available</Body3>}
      </OptionsArea>
    </OptionSelect>
  )

  return (
    <>
      {renderThin ? (
        <OptionContainerThin {...options.container}>{renderOptionSelect()}</OptionContainerThin>
      ) : (
        <OptionContainer id={id} {...options.container}>
          {renderOptionSelect()}
        </OptionContainer>
      )}
    </>
  )
})

Dropdown.Option = Option

export default Dropdown
