import { PropertyList, UserInfo } from '@templates'

export const tabsConfig = [
  {
    name: 'Seller info',
    path: '/seller-info',
    component: UserInfo,
    exact: true,
    id: 'seller-info',
  },
  {
    name: 'Property list',
    path: '/property-list',
    component: PropertyList,
    id: 'property-list',
  },
]
