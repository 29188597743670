import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AgentAddPropertyForm } from '@organisms'
import AddPropertyWrapper from './AddPropertyWrapper'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from '../../organisms/AgentAddPropertyForm/AgentAddPropertyFormValidation'
import { getImgUrl } from '@services/firebase'
import { loadAddressDetails } from '@utils/functions'
import { createProperty, updateProperty } from '@services/firebase'
import { ROUTES } from '@constants/dictionary'
import { useModal } from '@utils/hooks'
import { ConfirmModal } from '@modals'
import { yupResolver } from '@hookform/resolvers/yup'
import { BackgroundContainer } from './AgentAddPropertyPage.style'
import { MODAL_MESSAGES } from '@constants/dictionary'
import { AgentAddPropertyPageType } from '@constants/types'

const AgentAddPropertyPage = (props: AgentAddPropertyPageType): JSX.Element => {
  const { isEditProperty, propertyProfileId, propertyId, propertyProfile, sellerProfile, status } =
    props
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      isEditProperty: isEditProperty,
      title: '',
      address: '',
      apartmentNumber: '',
      agentId: '',
      price: '',
      imageUrl: '',
      seller: '',
      isApartment: false,
    },
  })
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods
  const { isShown, toggle } = useModal()
  const [selectedAddress, setSelected] = useState('')
  const [selectedSeller, setSelectedSeller] = useState(undefined)
  const [loader, setLoader] = useState(false)
  const [backendError, setBackendError] = useState(false)
  const [currentStatus, setStatus] = useState(status)
  const [soldBy, setSoldBy] = useState(undefined)

  const title = watch('title')
  const price = watch('price')
  const isApartment = watch('isApartment')
  const apartmentNumber = watch('apartmentNumber')
  const image = watch('image')
  const history = useHistory()

  const handleSelected = (id: string): string => setSelected(id)
  const handleSelectedSeller = (id: string): string => setSelectedSeller(id)
  useEffect(() => {
    if (isEditProperty) {
      setValue('title', propertyProfile.title)
      setValue('price', propertyProfile.price)
      setValue('address', propertyProfile.address)
      setValue('seller', sellerProfile[0].name)
      setValue('isEditProperty', true)
    } else {
      setValue('seller', selectedSeller)
    }
  }, [setValue, selectedSeller, isEditProperty, propertyProfile, sellerProfile])

  const submitResult = async (): Promise<undefined> => {
    if (isEditProperty) {
      if (currentStatus !== 'active') {
        toggle()
      } else {
        await submitUpdateProperty()
      }
    } else {
      setLoader(true)
      const addressDetails = await loadAddressDetails(selectedAddress)
      const imageUrl = await getImgUrl(image)
      const data = {
        title,
        price: parseInt(price),
        address: { ...addressDetails, ...(isApartment ? { apartmentNumber } : {}) },
        sellerId: selectedSeller,
        imageUrl: imageUrl,
      }
      const req = await Promise.all([addressDetails, imageUrl]).then(() => createProperty(data))
      setLoader(false)
      if (!req?.result) {
        setBackendError(true)
      } else {
        history.push(ROUTES.AGENT)
      }
    }
  }

  const submitUpdateProperty = async (): Promise<undefined> => {
    setLoader(true)
    const editedData = {
      propertyProfileId: propertyProfileId,
      propertyId: propertyId,
      title: title,
      price: parseInt(price),
      status: currentStatus,
      ...(currentStatus === 'sold' ? { soldByOurAgency: !!Number(soldBy) } : {}),
    }
    const req = await updateProperty(editedData)
    setLoader(false)
    if (!req?.result) {
      setBackendError(true)
    } else {
      history.push(ROUTES.AGENT)
    }
  }
  return (
    <BackgroundContainer>
      <FormProvider {...methods}>
        <AddPropertyWrapper
          isEditProperty={isEditProperty}
          header={isEditProperty ? 'Edit Property' : 'Add New Property'}
          onClickAdd={handleSubmit(submitResult)}
          applyingProperty={loader}
          onClickCancel={() => history.push(ROUTES.AGENT)}
          backendError={backendError}
        >
          <AgentAddPropertyForm
            errors={errors}
            isApartment={isApartment}
            onSelected={handleSelected}
            onSelectedSeller={handleSelectedSeller}
            loader={loader}
            isEditProperty={isEditProperty}
            imageUrl={isEditProperty ? propertyProfile.imageUrl : ''}
            sellerName={isEditProperty ? sellerProfile[0].name : ''}
            setStatus={setStatus}
            status={currentStatus ? currentStatus : ''}
            soldBy={soldBy}
            setSoldBy={setSoldBy}
          />
        </AddPropertyWrapper>
      </FormProvider>
      <ConfirmModal
        isShown={isShown}
        hide={toggle}
        onCancel={toggle}
        onConfirm={submitUpdateProperty}
        isStatusModal
        isClosedIcon
        message={MODAL_MESSAGES.confirmStatus.message}
        text={MODAL_MESSAGES.confirmStatus.text}
        labelYes={MODAL_MESSAGES.confirmStatus.labelYes}
        labelNo={MODAL_MESSAGES.confirmStatus.labelNo}
      />
    </BackgroundContainer>
  )
}

export default AgentAddPropertyPage
