import { Typography } from '@atoms'
import { Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div<{ path: URL }>`
  height: 100vh;
  width: 100%;
  background-image: url(${({ path }) => path});
  background-size: cover;
  background-position: center;
  position: relative;
`

export const StyledCol = styled(Col)`
  position: absolute;
  right: 0;
  background-color: ${(p) => p.theme.color.black_30};
  backdrop-filter: blur(${(p) => p.theme.indent.XXS});
  height: 100vh;
`

export const FormContainer = styled.div<{ isMobile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: ${(p) => p.theme.indent.XXXL} ${(p) => (p.isMobile ? p.theme.indent.LG : '6rem')};
`

export const FieldsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  padding: ${(p) => p.theme.indent.XL};
  margin-top: ${(p) => p.theme.indent.LG};
  align-content: stretch;
  width: ${(p) => (p.isMobile ? '33.5rem' : '41.2rem')};
  max-width: ${(p) => (p.isMobile ? '33.5rem' : '41.2rem')};
  min-height: ${(p) => (p.isMobile ? '38.8rem' : '37.3rem')};
`

export const H2 = styled(Typography.H2)`
  padding-top: ${(p) => p.theme.indent.MD};
  padding-bottom: ${(p) => p.theme.indent.MD};
`

export const ButtonContainer = styled.div`
  width: ${(p) => (p.isMobile ? '9.4rem' : '11.6rem')};
`

export const ContainerCheckBox = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: ${(p) => p.theme.indent.MD};
`

export const BottomContainer = styled.div<{ isMobile: boolean }>`
  margin-top: ${(p) => p.theme.indent.XXXL};
  display: ${(p) => (p.isMobile ? 'block' : 'flex')};
  text-align: center;
`

export const LinkContainer = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.color.originalBlue};
`
export const Body2 = styled(Typography.Body2)`
  padding-left: ${({ theme }) => theme.indent.XS};
`

export const Form = styled.form`
  width: 100%;
`

export const TextContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.indent.MD};
`
export const TextInfo = styled(Typography.Body2)`
  color: ${({ theme }) => theme.color.darkGrey};
  padding-bottom: ${({ theme }) => theme.indent.MD};
`
export const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4.2rem;
  width: 100%;
`
