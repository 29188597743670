import { LS_CONTSTANTS } from '@constants/dictionary'
import {
  collection,
  query,
  where,
  getFirestore,
  getDocs,
  QuerySnapshot,
  Timestamp,
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { FS_COL_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'
import { getMidnightTime } from '@utils/functions'

export const getSellerVisits = async (): Promise<QuerySnapshot> => {
  const auth = getAuth()
  const db = getFirestore()

  const uid = auth.currentUser ? auth.currentUser.uid : ''
  const propertyId: string = localStorage.getItem(LS_CONTSTANTS.propertyId)
  const col = collection(db, FS_COL_NAMES.PROPERTY_VISITS)

  const todayMidnight = getMidnightTime(new Date())
  const todayMidnightTimestamp = Timestamp.fromDate(todayMidnight)

  const visitsQuery = query(
    col,
    where(FS_Q_PARAMS.CREATED_BY, '==', uid),
    where(FS_Q_PARAMS.PROPERTY_ID, '==', propertyId),
    where(FS_Q_PARAMS.CANCELLED, '==', null),
    where(FS_Q_PARAMS.FROM_DATE_TIME, '>=', todayMidnightTimestamp),
  )
  const querySnapshot = await getDocs(visitsQuery)

  return querySnapshot
}
