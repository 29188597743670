import _ from 'lodash'
import { format } from 'date-fns'

export const mapShortLists = (firestoreList: { docs: Array }): Array => {
  const source = firestoreList.docs.map((visit) => ({
    ...visit.data(),
    compareDate: format(visit.data().createdAt.toDate(), 'y,L,d'),
    id: visit.id,
  }))
  return _(source)
    .groupBy('compareDate')
    .map((items, date) => {
      return {
        startDate: new Date(date),
        visits: items,
      }
    })
    .value()
}
