import React, { useEffect } from 'react'
import { Modal } from '../../helpers'
import { Overlay, ModalContainer } from './BaseModal.style'

import { BaseModalTypes } from '@constants/types/modals'
import { useMobile, useMobileLandscape } from '@utils/hooks'

const BaseModal: React.FC<BaseModalTypes> = ({
  children,
  isShown,
  hide,
  isNotification = false,
  marginNone = false,
}) => {
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()

  useEffect(() => {
    // prevent html scrolling while modal is open
    document.documentElement.style.overflowY = isShown ? 'hidden' : 'unset'
    return () => {
      document.documentElement.style.overflowY = 'unset'
    }
  }, [isShown, isMobile, isMobileLandscape])

  return (
    <Modal isShown={isShown}>
      <Overlay isNotification={isNotification} onClick={hide}>
        <ModalContainer
          marginNone={marginNone}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          onClick={hide}
        >
          {children}
        </ModalContainer>
      </Overlay>
    </Modal>
  )
}

export default BaseModal
