import React from 'react'
import { useTheme } from 'styled-components'
import { useMobile } from '@utils/hooks'
import { ROUTES } from '@constants/dictionary'
import { Icon } from '@atoms'
import { Typography } from '@atoms'
import { LinkContainer } from './BackToLoginHeader.style'

const BackToLoginHeader = (): JSX.Element => {
  const isMobile = useMobile()
  const theme = useTheme()

  return (
    <LinkContainer to={ROUTES.AGENT_LOGIN}>
      <Icon clickable icon="move_back" size="2rem" color={theme.color.originalBlue} />
      <Typography.Body2 isMobile={isMobile}>Back to Log-In</Typography.Body2>
    </LinkContainer>
  )
}

export default BackToLoginHeader
