import { mapToSlots, sortedInToWeek, combineSlotsIntoPeriods } from '@utils/functions'
import { setDayOfYear, getDayOfYear, setHours } from 'date-fns'
import { TimeLineTypes } from '@constants/types'
import { TWELVE_HOURS } from '@constants/dictionary'

type WeekWithPeriodsTypes = Array<{
  dayName: string
  slots: Array<{
    fromDateTime: Date
    toDateTime: Date
  }>
}>

const weeekPeriodsMapToTimeline = (weekWithPeriods: WeekWithPeriodsTypes): TimeLineTypes => {
  const firstWeekDay = weekWithPeriods[0] ? weekWithPeriods[0].day : null
  const dayOfYear = firstWeekDay ? getDayOfYear(firstWeekDay) : null
  return weekWithPeriods.map((day) => ({
    name: day.dayName,
    data: day.slots.length
      ? day.slots.map((slot) => ({
          x: day.dayName,
          y: [
            setDayOfYear(slot.fromDateTime, dayOfYear).getTime(),
            setDayOfYear(slot.toDateTime, dayOfYear).getTime(),
          ],
        }))
      : [
          {
            x: day.dayName,
            // set hours to 12, for correct display on chart
            y: [
              setHours(firstWeekDay, TWELVE_HOURS).getTime(),
              setHours(firstWeekDay, TWELVE_HOURS).getTime(),
            ],
          },
        ],
  }))
}

export const calculatePeriodsData = (
  value: Array,
  sellerWeek: Array,
  loading: boolean,
  byProfile: string,
): TimeLineTypes => {
  if (!loading) {
    const sellerSlots = mapToSlots(value, byProfile)
    const sortedWeek = sortedInToWeek(sellerSlots, sellerWeek)
    const weekWithPeriods = combineSlotsIntoPeriods(sortedWeek)
    return weeekPeriodsMapToTimeline(weekWithPeriods)
  }
  return []
}
