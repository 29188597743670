import styled from 'styled-components'

export const BodyContainer = styled.div<{
  isMobile: boolean
  isDesktop: boolean
  isTablet: boolean
}>`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 18rem);
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.MD} 5rem`
    if (isTablet) return `${theme.indent.MD} ${theme.indent.XXXL}`
    if (isMobile) return `${theme.indent.MD} 2rem`
    return `${theme.indent.MD} 5rem 0`
  }};
`
