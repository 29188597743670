import React from 'react'
import { useMobile } from '@utils/hooks'
import { dateToDayName } from '@utils/functions'
import { Typography } from '@atoms'
import { NotificationCard } from '@molecules'
import { NotificationsWrapper, NotificationsTitle, Caption2 } from './NotificationsList.style'
import { NotificationsListProps } from '@constants/types/organisms'

const NotificationsList: React.FC<NotificationsListProps> = ({
  notificationsList = [],
  hasUnread,
  onClickToRead = () => {},
  onClose = () => {},
}) => {
  const isMobile = useMobile()
  const markAll = 'Mark all as read'
  const defaultFormated = true
  return (
    <NotificationsWrapper>
      {notificationsList.map((period) => {
        return (
          <div key={period.startDate}>
            <NotificationsTitle>
              <Typography.H3 isMobile={isMobile}>
                {dateToDayName(period.startDate, defaultFormated)}
              </Typography.H3>
              {notificationsList[0].startDate === period.startDate && (
                <Caption2 isMobile={isMobile} disabled={!hasUnread} active onClick={onClickToRead}>
                  {markAll}
                </Caption2>
              )}
            </NotificationsTitle>

            {period.notifications.map((notification, v) => (
              <NotificationCard
                key={`${notification.body}${v}`}
                {...notification}
                onClose={onClose}
              />
            ))}
          </div>
        )
      })}
    </NotificationsWrapper>
  )
}

export default NotificationsList
