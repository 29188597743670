import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'

export const StyledCol = styled(Col)`
  padding: ${(p) => (p.isMobile ? `0 ${p.theme.indent.XS}` : `0 ${p.theme.indent.SM}`)};
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin: 0;
`
