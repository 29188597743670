/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useCallback, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useFirebaseAuth, useMobile, useTabletPortrait } from '@utils/hooks'
import { loginWithOutRememberMe, loginWithRememberMe } from '@services/firebase'
import { ROUTES, LOGIN, LS_CONTSTANTS, USER_TYPES, VALIDATION_ERRORS } from '@constants/dictionary'
import { Loader, Icon, Typography } from '@atoms'
import { Button, Checkbox } from '@molecules'
import bgDesctop from '@assets/images/bg-land-desctop.png'
import bgTablet from '@assets/images/bg-land-tablet.png'
import bgMobile from '@assets/images/bg-land-mobile.png'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm, PasswordInputForm } from '@helpers'
import {
  Container,
  StyledCol,
  FormContainer,
  H2,
  FieldsContainer,
  ContainerCheckBox,
  BottomContainer,
  Form,
  ErrorBlock,
  Body3,
  LinkContainer,
} from './AgentLogin.style'
import { schema } from './AgentLoginValidation'

const AgentLogin = (): JSX.Element => {
  const isMobile = useMobile()
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [showError, setShowError] = useState(false)

  const isTabletPortrait = useTabletPortrait()
  const [logedIn, loaded] = useFirebaseAuth()

  const [rememberMe, setRememberMe] = useState(false)
  const changeCheckBox = useCallback(() => {
    setRememberMe(!rememberMe)
  }, [setRememberMe, rememberMe])

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      email: '',
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods
  const email = watch('email').trim()
  const password = watch('password').trim()

  const path = (): string => {
    if (isTabletPortrait) {
      return bgTablet
    }
    if (isMobile) {
      return bgMobile
    }
    return bgDesctop
  }
  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const onSubmit = useCallback(async (): Promise<undefined> => {
    setLoader(true)

    if (rememberMe) {
      const req1 = await loginWithRememberMe(email, password)
      if (!req1.result) {
        setShowError(true)
      }
    } else {
      const req2 = await loginWithOutRememberMe(email, password)
      if (!req2.result) {
        setShowError(true)
      }
    }
    setLoader(false)
  }, [email, password, rememberMe])

  const toResetPassword = useCallback(() => {
    history.push({
      pathname: ROUTES.AGENT_RESET_PASSWORD,
    })
  }, [history])

  useEffect(() => {
    const listener = (event): (() => void) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(onSubmit)()
      }
    }
    document.addEventListener('keyup', listener)
    return () => {
      document.removeEventListener('keyup', listener)
    }
  }, [handleSubmit, onSubmit])
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)

  return (
    <Loader loaded={loaded}>
      {logedIn && (userType === USER_TYPES.agent || userType === USER_TYPES.agency) ? (
        <Redirect to={ROUTES.AGENT} />
      ) : (
        <Container path={path}>
          <StyledCol md={12} lg={6} xl={6} xxl={6}>
            <FormContainer isMobile={isMobile}>
              <Icon icon="logo" clickable size="20rem" color="white" />
              <FieldsContainer isMobile={isMobile}>
                <H2>Log In to ViewD</H2>
                <FormProvider {...methods}>
                  <Form autoComplete="off">
                    <InputForm
                      disabled={loader}
                      name="email"
                      errorMessage="Not a valid email"
                      placeholder="Enter your email"
                      inputLabel="Email"
                      error={hasError('email')}
                      isNoSpace
                      tabIndex={1}
                    />
                    <PasswordInputForm
                      disabled={loader}
                      placeholder="Enter your password"
                      inputLabel="Password"
                      errorMessage={VALIDATION_ERRORS.PASSWORD_NOT_VALID}
                      name="password"
                      error={hasError('password')}
                      tabIndex={2}
                      isNoSpace
                    />
                  </Form>
                </FormProvider>
                <ContainerCheckBox>
                  <Checkbox
                    value={rememberMe}
                    onChange={changeCheckBox}
                    fill={1}
                    label="Remember me"
                  />
                </ContainerCheckBox>
                <Button.Primary
                  loader={loader}
                  disabled={loader}
                  label="Log In"
                  onClick={handleSubmit(onSubmit)}
                />
                {showError ? <ErrorBlock> Email or password is incorrect</ErrorBlock> : null}
                <BottomContainer isMobile={isMobile}>
                  <Body3 isMobile={isMobile}>{LOGIN.QUESTION}</Body3>
                  <LinkContainer>
                    <Typography.Body3
                      onClick={toResetPassword}
                      color="originalBlue"
                      underline
                      isMobile={isMobile}
                      clickable
                    >
                      Reset password.
                    </Typography.Body3>
                  </LinkContainer>
                </BottomContainer>
              </FieldsContainer>
            </FormContainer>
          </StyledCol>
        </Container>
      )}
    </Loader>
  )
}

export default AgentLogin
