import React, { ReactNode } from 'react'
import { useMobile } from '@utils/hooks'
import { Container, StyledLoader, StyledSkeletonLoader } from './Loader.style'

import { LoaderTypes } from '@constants/types/atoms'

const Loader: React.FC<LoaderTypes> | ReactNode = ({
  loaded = false,
  color,
  children,
  skeleton,
  size,
  bgColor,
  isTable = false,
  noMargin = false,
}) => {
  const isMobile = useMobile()

  if (skeleton) {
    return <StyledSkeletonLoader size={size} isMobile={isMobile} color={color} />
  }

  return loaded ? (
    children
  ) : (
    <Container isTable={isTable} noMargin={noMargin}>
      <StyledLoader isMobile={isMobile} bgColor={bgColor} />
    </Container>
  )
}

export default Loader
