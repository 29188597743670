import { DayTypes, State, TimePeriodsTypes } from '@constants/types'
import { THIRTY_MINUTES } from '@constants/dictionary'
import { addMinutes } from 'date-fns'
import { TIME_PERIOD, SWITCH_DAY } from './sellerSlotsReducer.constants'

export const addNewperiod = (state: State): DayTypes => {
  return state.map((day: DayTypes) => {
    if (day.active) {
      return {
        ...day,
        timePeriods: [
          ...day.timePeriods,
          {
            fromDateTime:
              day.timePeriods.length > 0
                ? addMinutes(day.timePeriods[day.timePeriods.length - 1].toDateTime, THIRTY_MINUTES)
                : day.allHours[0],
            toDateTime: day.allHours[day.allHours.length - 1],
          },
        ],
      }
    }
    return day
  })
}
export const deletePeriod = (state: State, payload: number): DayTypes => {
  return state.map((day: DayTypes) => {
    if (day.active) {
      return {
        ...day,
        timePeriods: day.timePeriods.filter((_, index: number) => index !== payload),
      }
    }
    return day
  })
}

export const switchDay = (state: State, payload: number, action: string): State => {
  switch (action) {
    case SWITCH_DAY.TAP_ON_DAY:
      return state.map((day: DayTypes) => {
        return day.dayNumber === payload ? { ...day, active: true } : { ...day, active: false }
      })

    case SWITCH_DAY.TO_PREVIOUS_DAY:
      return state.map((day: DayTypes, index: number) => {
        return index === payload - 1 ? { ...day, active: true } : { ...day, active: false }
      })

    case SWITCH_DAY.TO_NEXT_DAY:
      return state.map((day: DayTypes, index: number) => {
        return index === payload + 1 ? { ...day, active: true } : { ...day, active: false }
      })

    default:
      return state
  }
}

export const editTimePeriod = (state: State, payload: number, action: string): State => {
  return state.map((day: DayTypes) => {
    if (day.active) {
      return {
        ...day,
        timePeriods: day.timePeriods.map((item: TimePeriodsTypes, index: number) => {
          if (index === payload) {
            switch (action) {
              case TIME_PERIOD.ADD_FROM_TIME:
                return { ...item, fromDateTime: addMinutes(item.fromDateTime, THIRTY_MINUTES) }

              case TIME_PERIOD.MINUS_FROM_TIME:
                return { ...item, fromDateTime: addMinutes(item.fromDateTime, -THIRTY_MINUTES) }

              case TIME_PERIOD.ADD_UNTIL_TIME:
                return { ...item, toDateTime: addMinutes(item.toDateTime, THIRTY_MINUTES) }

              case TIME_PERIOD.MINUS_UNTIL_TIME:
                return { ...item, toDateTime: addMinutes(item.toDateTime, -THIRTY_MINUTES) }
              default:
                return item
            }
          }
          return item
        }),
      }
    }
    return day
  })
}

export const setTimePeriod = (
  state: State,
  payload: { id: number; setDate: string },
  action: string,
): State => {
  return state.map((day: DayTypes) => {
    if (day.active) {
      return {
        ...day,
        timePeriods: day.timePeriods.map((item: TimePeriodsTypes, index: number) => {
          if (index === payload.id) {
            switch (action) {
              case TIME_PERIOD.SET_FROM_TIME:
                return { ...item, fromDateTime: new Date(Date.parse(payload.setDate)) }

              case TIME_PERIOD.SET_UNTIL_TIME:
                return { ...item, toDateTime: new Date(Date.parse(payload.setDate)) }

              default:
                return item
            }
          }
          return item
        }),
      }
    }
    return day
  })
}
