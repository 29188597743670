import formatDate from 'date-fns/format'
import { mapDateToString } from '@utils/functions'

export const getDateString = ({
  id,
  fromDateTime,
  toDateTime,
}: {
  id: number
  fromDateTime: Date
  toDateTime: Date
}): string => {
  const fromTime = formatDate(fromDateTime, 'H:mm')
  const toTime = formatDate(toDateTime, 'H:mm')

  // Transform JS Date type to string e.g. 22 Aug
  // Removing unnecessary comma and space
  // eslint-disable-next-line no-magic-numbers
  const toDate = mapDateToString([fromDateTime]).slice(0, -2)

  return `ID ${id} - ${fromTime} - ${toTime} • ${toDate}`
}
