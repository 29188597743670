import React, { useCallback } from 'react'
import { LinkTo } from './VisitInfo.style'

const SendLink = ({
  onClickResendLink,
  propertyId,
  propertyProfileId,
  buyerName,
  buyerId,
}: {
  propertyProfileId: string
  propertyId: string
  buyerId: string
  buyerName: string
  onClickResendLink: void
}): JSX.Element => {
  const onClicLink = useCallback(() => {
    onClickResendLink(propertyId, propertyProfileId, buyerName, buyerId)
  }, [onClickResendLink, propertyId, propertyProfileId, buyerName, buyerId])

  return (
    <div>
      <LinkTo onClick={onClicLink} isMobile>
        Resend book link
      </LinkTo>
    </div>
  )
}
export default SendLink
