import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'

export const StyledCol1 = styled(Col)`
  padding-right: ${(p) => p.theme.indent.XS};
`
export const StyledCol2 = styled(Col)`
  padding-left: ${(p) => p.theme.indent.XS};
`
export const ButtonContainer = styled(Row)<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XXXL} 0 ${p.theme.indent.XXXL}`
      : `0 ${p.theme.indent.XXXL}  8rem 0 `};
  margin-top: auto;
`
