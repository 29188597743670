import React, { useState } from 'react'
import { getPropertyProfile, getSellerProfile } from '@services/firebase'
import { AgentPropertyDetailsCard } from '@organisms'
import { Container, BodyContainer } from './AgentPropertyInfoCard.style'
import {
  useDesktop,
  useMobile,
  useTabletPortrait,
  useMountEffect,
  useTabletLandscape,
} from '@utils/hooks'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { Loader } from '@atoms'

const AgentPropertyInfoCard: React.FC = () => {
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTabletPortrait = useTabletPortrait()
  const isTabletLandscape = useTabletLandscape()
  const isTablet = isTabletPortrait || isTabletLandscape

  const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId)

  const [propertyProfile, setPropertyProfile] = useState(undefined)
  const [sellerProfile, setSellerProfile] = useState(undefined)

  const loadData = async (): Promise<undefined> => {
    const propertyDetails = await getPropertyProfile(propertyId)
    setPropertyProfile(propertyDetails)
    const sellerDetails = await getSellerProfile(propertyDetails?.sellerId)
    setSellerProfile(sellerDetails[0] || [])
  }
  useMountEffect(loadData)

  return (
    <Loader loaded={!!propertyProfile && sellerProfile !== undefined}>
      <BodyContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
        <Container>
          <AgentPropertyDetailsCard
            propertyProfileId={propertyProfile?.propertyProfileId}
            sellerId={propertyProfile?.sellerId}
            propertyId={propertyId}
            title={propertyProfile?.title}
            id={propertyProfile?.id}
            imageUrl={propertyProfile?.imageUrl}
            address={propertyProfile?.address}
            price={propertyProfile?.price}
            propertyStatus={propertyProfile?.status}
            contacts={{
              name: sellerProfile?.name,
              email: sellerProfile?.email,
              phoneNumber: sellerProfile?.phoneNumber,
            }}
            additionalContacts={{
              name: sellerProfile?.additionalName,
              email: sellerProfile?.additionalEmail,
              phoneNumber: sellerProfile?.additionalPhoneNumber,
            }}
          />
        </Container>
      </BodyContainer>
    </Loader>
  )
}

export default AgentPropertyInfoCard
