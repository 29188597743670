import { BOOKING_STATUSES } from '@constants/dictionary'

export const VISITS = [
  {
    id: '01',
    date: new Date(2021, 8, 21),
    visits: [
      {
        id: '1',
        agent: { name: 'Clan Gordon', phoneNumber: '+020 8577 2777' },
        propertyProfile: {
          address: 'Flat A, 52 Madeira Road, Streatham, London, SW16',
          title: '2 bedroom Flat for Sale',
        },
        fromDateTime: new Date(),
        toDateTime: new Date(),
        isConfirmed: false,
      },
      {
        id: '2',
        agent: { name: 'DBK Estate Agents', phoneNumber: '+0131 555 4444' },
        propertyProfile: {
          address: 'Upper Grosvenor Street, London, W1K',
          title: '5 bedroom House with beautiful garden',
        },
        fromDateTime: new Date(),
        toDateTime: new Date(),
        isConfirmed: false,
      },
    ],
  },
]

export const SHORT_LIST = [
  {
    id: '03',
    date: new Date(2021, 8, 21),
    meetings: [
      {
        id: '3',
        agent: { name: 'DBK Estate Agents', phoneNumber: '+0131 555 4444' },
        propertyProfile: {
          address: 'Upper Grosvenor Street, London, W1K',
          title: '5 bedroom House with beautiful garden',
        },
        fromDateTime: new Date(),
        toDateTime: new Date(),
        isConfirmed: false,
        price: '£ 860,000',
        rating: 5,
        status: BOOKING_STATUSES.ACTIVE,
      },
      {
        id: '4',
        agent: { name: 'DBK Estate Agents', phoneNumber: '+0131 555 4444' },
        propertyProfile: {
          address: 'Upper Grosvenor Street, London, W1K',
          title: '5 bedroom House with beautiful garden',
        },
        fromDateTime: new Date(),
        toDateTime: new Date(),
        isConfirmed: false,
        price: '£ 1,060,000',
        rating: 1,
        status: BOOKING_STATUSES.UNDER_OFFER,
      },
    ],
  },
]
