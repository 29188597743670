import React, { useEffect, useState } from 'react'
import { Col } from 'styled-bootstrap-grid'

import {
  ButtonWrapperModal,
  Header,
  SearchContainer,
  SearchWrapper,
  StyledCol,
} from './AddModalTitle.style'
import { Button, Dropdown } from '@molecules'

import GlobalFilter from '../GlobalFilter'
import { useMobile } from '@utils/hooks'
import { TitleTypes } from '@constants/types'

type AddModalTitleProps = Pick<
  TitleTypes,
  | 'dropdownOptions'
  | 'setGlobalFilter'
  | 'user'
  | 'addButtonDisabled'
  | 'onConfirm'
  | 'onCancel'
  | 'defaultValue'
  | 'globalFilter'
> & {
  selectedValue?: string
  callback: () => void
}

const AddModalTitle: React.FC<AddModalTitleProps> = ({
  selectedValue,
  dropdownOptions,
  globalFilter,
  setGlobalFilter,
  user,
  addButtonDisabled,
  onCancel,
  onConfirm,
  defaultValue,
  callback,
}) => {
  const isMobile = useMobile()

  const [selected, setSelected] = useState<string | number | undefined>(selectedValue)

  useEffect(() => {
    callback && callback(selected)
  }, [callback, selected])

  return (
    <Header isMobile={isMobile} isModalHeader>
      <SearchWrapper isMobile={isMobile}>
        <StyledCol isMobile={isMobile} lg={6} sm={6} md={6} xs={12}>
          <Dropdown
            modalDropDown
            handleValue={(optionValue: string) => {
              setSelected(optionValue)
            }}
            selectedValue={selected}
            value={defaultValue}
            testId="Added"
          >
            {!!dropdownOptions ? (
              dropdownOptions.map((item) => (
                <Dropdown.Option key={item.id} value={item.value} title={item.title}>
                  {item.title}
                </Dropdown.Option>
              ))
            ) : (
              <></>
            )}
          </Dropdown>
        </StyledCol>
        <Col lg={6} sm={6} md={6} xs={12}>
          <SearchContainer isMobile={isMobile} marginLeft isModal>
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder={`Search ${user.toLowerCase()}`}
            />
          </SearchContainer>
        </Col>
      </SearchWrapper>
      <ButtonWrapperModal isMobile={isMobile}>
        <Button.Primary
          label={`Add ${user}`}
          onClick={onConfirm}
          disabled={addButtonDisabled}
          height="4rem"
          width={!isMobile && '14.8rem'}
        />
        <Button.Secondary
          label="Cancel"
          onClick={onCancel}
          height="4rem"
          width={!isMobile && '11.6rem'}
        />
      </ButtonWrapperModal>
    </Header>
  )
}

export default AddModalTitle
