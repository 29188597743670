import React from 'react'
import { useMobile } from '@utils/hooks'
import { RatingForm, TextAreaForm, CheckBoxForm, RadioButtonForm } from '@helpers'
import {
  TopContainer,
  ButtonContainer,
  TypographyContainer,
  CheckboxContainer,
  Container,
} from './BuyerSurveyAnswer.style'

type SecondStepProps = {
  isShortListedProperty: boolean
}
const SecondStep: React.FC<SecondStepProps> = ({ isShortListedProperty }): JSX.Element => {
  const isMobile = useMobile()

  return (
    <Container>
      <TopContainer isMobile={isMobile} lg={7} xs={12} md={10} noGutter>
        <RatingForm name="rating" label="Viewing Rating" />
        <TypographyContainer>Leave us feedback (optional)</TypographyContainer>
        <TextAreaForm maxLength={150} name="comment" placeholder="What are your thoughts?" />
        <TypographyContainer>Interested in this Property</TypographyContainer>
        <ButtonContainer isMobile={isMobile}>
          <RadioButtonForm name="interestedInProperty" />
        </ButtonContainer>
        <CheckboxContainer>
          <CheckBoxForm
            label="Add property to my short list"
            name="addToShortlist"
            disabled={isShortListedProperty}
          />
        </CheckboxContainer>
        <CheckBoxForm name="requestSecondViewing" label="Request Second Viewing" />
      </TopContainer>
    </Container>
  )
}

export default SecondStep
