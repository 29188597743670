import { BOOKING_STATUSES } from '@constants/dictionary'

type ThemeType = {
  color: {
    [key: string]: string
  }
}

export const getColorByStatus = (status: number, theme: ThemeType): string => {
  switch (status) {
    case BOOKING_STATUSES.ACTIVE:
      return theme.color.green
      break
    case BOOKING_STATUSES.UNDER_OFFER:
      return theme.color.yellow
      break
    case BOOKING_STATUSES.SOLD:
      return theme.color.red
      break
    case BOOKING_STATUSES.ARCHIVED:
      return theme.color.darkGrey
      break
    case BOOKING_STATUSES.OFF_MARKET:
      return theme.color.lightBlack
      break
    case BOOKING_STATUSES.SOLD_STC:
      return theme.color.darkYellow
      break
    case BOOKING_STATUSES.DELETED:
      return theme.color.black
      break
    default:
      return 'initial'
      break
  }
}

export const getLightColorByStatus = (status: number, theme: ThemeType): string => {
  switch (status) {
    case BOOKING_STATUSES.ACTIVE:
      return theme.color.green_15
      break
    case BOOKING_STATUSES.UNDER_OFFER:
      return theme.color.yellow_15
      break
    case BOOKING_STATUSES.SOLD:
      return theme.color.red_15
      break
    case BOOKING_STATUSES.ARCHIVE:
      return theme.color.darkGrey_15
      break
    case BOOKING_STATUSES.OFF_MARKET:
      return theme.color.lightBlack_15
      break
    case BOOKING_STATUSES.SOLD_STC:
      return theme.color.darkYellow_15
      break
    default:
      return 'initial'
      break
  }
}
