type CardType = {
  icon: {
    name: string
    desktopSize: string
    tabletMobileSize: string
  }
  title: 'Questions' | 'Technical support' | 'Cooperation'
  name: string
  phoneNumber: string
  email: string
}

export const cardData: Array<CardType> = [
  {
    icon: {
      name: 'question-circle',
      desktopSize: '8rem',
      tabletMobileSize: '6rem',
    },
    title: 'Questions',
    name: 'Rowena Wilson',
    phoneNumber: '+44 1111 111111',
    email: 'contactus@viewd.io',
  },
  {
    icon: {
      name: 'settings-circle',
      desktopSize: '8rem',
      tabletMobileSize: '6rem',
    },
    title: 'Technical support',
    name: 'Rowena Wilson',
    phoneNumber: '+44 1111 111111',
    email: 'contactus@viewd.io',
  },
  {
    icon: {
      name: 'label-circle',
      desktopSize: '8rem',
      tabletMobileSize: '6rem',
    },
    title: 'Cooperation',
    name: 'Rowena Wilson',
    phoneNumber: '+44 1111 111111',
    email: 'contactus@viewd.io',
  },
]
