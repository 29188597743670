import { getFirestore, doc, setDoc } from 'firebase/firestore'
import { ReviewForm } from '@constants/types'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const setReview = async (visitId: string, review: ReviewForm): { error: string } => {
  const db = getFirestore()

  const propertyReviewsRef = doc(db, FS_COL_NAMES.PROPERTY_REVIEWS, visitId)
  await setDoc(propertyReviewsRef, review)
}
