import React, { useCallback, useMemo, useState } from 'react'
import { useDesktop, useMobile, useModal, useTabletPortrait } from '@utils/hooks'
import { mapVisits, refactorBuyerVisits } from '@utils/functions'
import { Icon, Loader, SimpleRatingStars } from '@atoms'
import { BOOKING_STATUSES, SEND_MODAL } from '@constants/dictionary'
import { Table } from '@organisms'
import { useCollection } from 'react-firebase-hooks/firestore'
import {
  getAllBuyerVisits,
  getPropertyBuyerTimeSlots,
  getPropertyProfileById,
  getReviewById,
  getSellerProfile,
} from '@services/firebase'
import { useParams } from 'react-router-dom'
import { BodyContainer, Cell, Container, IconContainer, InfoContainer } from './VisitInfo.style'
import { compareAsc } from 'date-fns'
import { getDatesSorting, getStatusSorting } from '@utils/sorting'
import { TableRowType } from '@organisms/Table/types'
import SendLink from './SendLink'
import {
  allOptions,
  defaultModalBody,
  MESSAGE_TYPE,
  defaultData,
  defaultContacts,
} from './VisitInfo.constants'
import { TimeSlotsModal, StatusModal, SendModal } from '@modals'
import InfoModalTemplates from '../AgentVisitInfoCard/InfoModalTemplates'
import { ModalBodyTypes, ContactsType, DataVisitType } from '@constants/types'

// TODO: reduce component complexity (separate to components)
const VisitInfo = (): JSX.Element => {
  const { id }: { id: string } = useParams()
  const buyerId = id.split('-')[0]

  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTablet = useTabletPortrait()
  const allBuyerVisits = getAllBuyerVisits(buyerId)
  const [valueBuyerVisits, loadingBuyerVisits] = useCollection(allBuyerVisits)
  const refactoredBuyerVisits = useMemo(() => {
    if (loadingBuyerVisits) return []
    const mapBuyerVisits = mapVisits(valueBuyerVisits)
    return refactorBuyerVisits(mapBuyerVisits)
  }, [valueBuyerVisits, loadingBuyerVisits])

  const [selectedOption, setSelectedOption] = useState<string>(allOptions[0].id)

  const getFilterState = (selectedOption: string): unknown => {
    const FILTER_STATES = {
      allVisits: refactoredBuyerVisits,
      upcomingVisits: refactoredBuyerVisits.filter(
        (visit) => compareAsc(new Date(visit.dateTime), new Date()) === 1,
      ),
      pastVisits: refactoredBuyerVisits.filter(
        (visit) => compareAsc(new Date(visit.dateTime), new Date()) <= 0,
      ),
      cancelledVisits: refactoredBuyerVisits.filter(
        (visit) => visit.statusOrRating === 'Cancelled',
      ),
      shortList: refactoredBuyerVisits.filter((visit) => visit.shortList === 'YES'),
    }

    return FILTER_STATES[selectedOption] ?? refactoredBuyerVisits
  }
  const changeOption = useCallback((selected: string) => {
    setSelectedOption(selected)
  }, [])

  const { isShown: isShownModalStatus, toggle: toggleModalStatus } = useModal()
  const [infoType, setInfoType] = useState<string>('')
  const [modalBody, setModalBody] = useState<ModalBodyTypes>(defaultModalBody)
  const toggleInfoModal = useCallback(
    async (type: string, visitId: string): undefined => {
      if (type === 'Rated') {
        setLoaded(false)
        const reviewDoc = await getReviewById(visitId)
        refactoredBuyerVisits.forEach((visit) => {
          if (visit.visitId === visitId) {
            setModalBody({
              ...visit.modalBody,
              location: reviewDoc.detailedRatings.location,
              overallCondition: reviewDoc.detailedRatings.overallCondition,
              price: reviewDoc.detailedRatings.price,
              valueForMoney: reviewDoc.detailedRatings.valueForMoney,
              comment: reviewDoc.comment,
              interestedInProperty: reviewDoc.interestedInProperty,
              rating: reviewDoc.rating,
            })
          }
        })
        setLoaded(true)
      } else {
        refactoredBuyerVisits.forEach((visit) => {
          if (visit.visitId === visitId) {
            setModalBody(visit.modalBody)
          }
        })
      }
      setInfoType(type)
      toggleModalStatus()
    },
    [toggleModalStatus, refactoredBuyerVisits],
  )

  const renderIcon = (onClick): JSX.Element => (
    <IconContainer>
      <Icon
        icon={'propertydetails'}
        color="originalBlue"
        size="1.6rem"
        onClick={onClick}
        clickable
      />
    </IconContainer>
  )
  //logic for send reminder
  const [loaded, setLoaded] = useState(true)
  const [status, setStatus] = useState('')
  const [timeSlots, setTimeSlots] = useState([])
  const [request, setRequest] = useState('')
  const { isShown, toggle } = useModal()
  const [data, setData] = useState<DataVisitType>(defaultData)
  const [contacts, setContacts] = useState<ContactsType>(defaultContacts)

  const onClickResendLink = useCallback(
    async (propertyId, propertyProfileId, buyerName, buyerId) => {
      setLoaded(false)
      const DocPropertyProfile = await getPropertyProfileById(propertyId, propertyProfileId)
      setStatus(DocPropertyProfile.status)
      if (DocPropertyProfile.status === BOOKING_STATUSES.ACTIVE) {
        setData({
          name: buyerName,
          id: buyerId,
          propertyId: propertyId,
          sellerId: DocPropertyProfile.sellerId,
          propertyProfileId: propertyProfileId,
        })
        const visits = await getPropertyBuyerTimeSlots(propertyId)
        if (!!visits.length) {
          setTimeSlots(visits)
          sendRequest(MESSAGE_TYPE.LINK)
        } else {
          const sellerProfile = await getSellerProfile(DocPropertyProfile.sellerId)
          if (!!sellerProfile.length) {
            setContacts({
              name: sellerProfile[0].name,
              phoneNumber: sellerProfile[0].phoneNumber,
              email: sellerProfile[0].email,
            })
          }
        }
        setLoaded(true)
        toggle()
      } else {
        setLoaded(true)
        toggle()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle],
  )

  const sendRequest = (type: string): (() => void) => {
    setRequest(type)
    return toggle()
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        percentWidth: 10,
        Cell: ({ row: { original } }: TableRowType<Property>) => original?.id,
      },
      {
        Header: 'Date',
        accessor: 'date',
        percentWidth: 10,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => original?.date,
        sortType: getDatesSorting('date'),
      },
      {
        Header: 'Time',
        accessor: 'time',
        percentWidth: 11,
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => original.time,
      },
      {
        Header: 'Property Title',
        accessor: 'propertyTitle',
        percentWidth: 28,
        Cell: ({ row: { original } }: TableRowType<Property>) => (
          <Cell>{original?.propertyTitle}</Cell>
        ),
      },
      {
        Header: 'Status/Rating ',
        accessor: 'statusOrRating',
        percentWidth: 15,
        disableGlobalFilter: true,
        sortType: getStatusSorting('status'),
        Cell: ({ row: { original } }: TableRowType<Property>) => {
          if (Number.isInteger(original.statusOrRating)) {
            return (
              <InfoContainer>
                <SimpleRatingStars size="1.5rem" count={original.statusOrRating} />
                {renderIcon(() => toggleInfoModal('Rated', original.visitId))}
              </InfoContainer>
            )
          }
          switch (original.statusOrRating) {
            case 'Confirmed':
              return (
                <InfoContainer>
                  {original.statusOrRating}
                  {renderIcon(() => toggleInfoModal('Confirmed', original.visitId))}
                </InfoContainer>
              )
            case 'Cancelled':
              return (
                <InfoContainer>
                  {original.statusOrRating}
                  {renderIcon(() => toggleInfoModal('Cancelled', original.visitId))}
                </InfoContainer>
              )
            default:
              return original.statusOrRating
          }
        },
      },
      {
        Header: 'Short list',
        accessor: 'shortList',
        sortInverted: true,
        percentWidth: 12,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => original?.shortList,
      },
      {
        Header: '',
        accessor: 'buyerId',
        percentWidth: 14,
        disableSortBy: true,
        disableGlobalFilter: true,
        Cell: ({ row: { original } }: TableRowType<Property>) => (
          <SendLink
            buyerName={original?.buyerName}
            buyerId={original?.buyerId}
            propertyId={original?.propertyId}
            propertyProfileId={original?.propertyProfileId}
            onClickResendLink={onClickResendLink}
          />
        ),
      },
    ],
    [onClickResendLink, toggleInfoModal],
  )

  return (
    <Loader loaded={!loadingBuyerVisits && loaded}>
      <BodyContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
        {isShownModalStatus && (
          <InfoModalTemplates
            infoType={infoType}
            isShown={isShownModalStatus}
            toggle={toggleModalStatus}
            modalBody={modalBody}
          />
        )}
        <Container>
          <Table
            columns={columns}
            selectedValue={selectedOption}
            callback={changeOption}
            dropdownOptions={allOptions}
            isTableVisit
            typeUser="visit"
            data={getFilterState(selectedOption)}
            title="VisitInfo"
          />
        </Container>
        {status !== BOOKING_STATUSES.ACTIVE && (
          <StatusModal
            onConfirm={() => toggle()}
            isShown={status !== BOOKING_STATUSES.ACTIVE && isShown}
          />
        )}
        {!timeSlots.length && status === BOOKING_STATUSES.ACTIVE && (
          <TimeSlotsModal
            isShown={isShown}
            onCancel={toggle}
            propertyId={data.propertyId}
            sellerId={data.sellerId}
            contacts={contacts}
            onConfirm={() => sendRequest(MESSAGE_TYPE.REMINDER)}
          />
        )}
        {!!request && status === BOOKING_STATUSES.ACTIVE && (
          <SendModal
            isShown={!!request}
            onConfirm={() => setRequest('')}
            type={request}
            propertyId={data.propertyId}
            buyerId={data.id}
            propertyProfileId={data.propertyProfileId}
            header={SEND_MODAL.header}
            message={
              request === MESSAGE_TYPE.REMINDER
                ? SEND_MODAL.message.reminder
                : SEND_MODAL.message.link
            }
          />
        )}
      </BodyContainer>
    </Loader>
  )
}

export default VisitInfo
