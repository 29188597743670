import React, { useEffect, useState } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useFirebaseAuth } from '@utils/hooks'
import { ROUTES, LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { Loader } from '@atoms'
import { signOutAgent } from '@services/firebase'

import { PrivateRouteProps } from '@constants/types/helpers'

const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, exact, component }) => {
  const [logedIn, loaded] = useFirebaseAuth()
  const history = useHistory()
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const uid = localStorage.getItem(LS_CONTSTANTS.uid)

  const [user, setUser] = useState(userType)
  window.addEventListener('storage', () => {
    setUser(window.localStorage.getItem(LS_CONTSTANTS.userType))
  })

  useEffect(() => {
    if (!Boolean(user) && !uid) {
      if (signOutAgent()) {
        history.push({
          pathname: ROUTES.AGENT_LOGIN,
        })
      } else {
        history.push({
          pathname: ROUTES.ERROR,
        })
      }
    }
  }, [user, history, uid])

  if (userType === USER_TYPES.buyer || userType === USER_TYPES.seller) {
    history.push({
      pathname: ROUTES.ERROR,
    })
  }
  return (
    <Loader loaded={loaded}>
      {logedIn ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <Redirect to={ROUTES.AGENT_LOGIN} />
      )}
    </Loader>
  )
}

export default PrivateRoute
