/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useState } from 'react'
import { Container, StyledBody3, FormContainer, ErrorContainer } from './AgencySignInSecurity.style'
import { useMobile, useModal } from '@utils/hooks'
import { Button, ErrorWithIcon } from '@molecules'
import { PasswordInputForm } from '@helpers'
import { AGENCY_SIGN_IN_SECURITY, MODAL_MESSAGES } from '@constants/dictionary'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './AgencySignInSecurityValidation'
import {
  VIEWING_USER_MAX_SYMBOLS,
  MIN_SYMBOLS,
  VALIDATION_ERRORS_FORM_ADD,
} from '@constants/dictionary'
import { setNewPassword, verifyOldPassword } from '@services/firebase'
import { SuccessModal } from '@modals'
import PasswordChangedImage from '@assets/images/PasswordChangedModal.svg'

export type FormType = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const AgencySignInSecurity: React.FC = () => {
  const isMobile = useMobile()
  const [backendError, setBackendError] = useState(false)
  const [loader, setLoader] = useState(false)
  const { isShown: isShownSuccessModal, toggle: toggleSuccessModal } = useModal()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    reset,
  } = methods

  const oldPassword = watch('oldPassword')
  const newPassword = watch('newPassword')
  const confirmPassword = watch('confirmPassword')

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const getErrorMessage = useCallback(
    (field) => {
      if (errors) {
        if (errors[field]) {
          return errors[field].message
        }
      }
      return ''
    },
    [errors],
  )

  const onSubmit = useCallback(async (): Promise<void> => {
    if (newPassword !== confirmPassword) {
      setError('confirmPassword', { message: 'Not match' })
    } else {
      setLoader(true)
      try {
        const resultCheckOldPass = await verifyOldPassword(oldPassword)
        if (resultCheckOldPass) {
          setBackendError(false)
          const resultSetNewPass = await setNewPassword(newPassword)
          if (resultSetNewPass) {
            reset()
            toggleSuccessModal()
          }
        }
      } catch (error) {
        setBackendError(true)
      } finally {
        setLoader(false)
      }
    }
  }, [toggleSuccessModal, confirmPassword, newPassword, oldPassword, reset, setError])

  const isMinLength =
    oldPassword.length >= MIN_SYMBOLS &&
    newPassword.length >= MIN_SYMBOLS &&
    confirmPassword.length >= MIN_SYMBOLS

  return (
    <Container isMobile={isMobile}>
      <StyledBody3 isMobile={isMobile}>{AGENCY_SIGN_IN_SECURITY.precept}</StyledBody3>
      <FormProvider {...methods}>
        <FormContainer isMobile={isMobile}>
          <PasswordInputForm
            name="oldPassword"
            disabled={loader}
            errorMessage={getErrorMessage('oldPassword')}
            placeholder="Enter old password"
            inputLabel="Old Password"
            error={hasError('oldPassword')}
            maxLength={VIEWING_USER_MAX_SYMBOLS}
            tabIndex={1}
          />
          <PasswordInputForm
            name="newPassword"
            disabled={loader}
            errorMessage={getErrorMessage('newPassword')}
            placeholder="Enter new password"
            inputLabel="New Password"
            error={hasError('newPassword')}
            maxLength={VIEWING_USER_MAX_SYMBOLS}
            tabIndex={2}
          />
          <PasswordInputForm
            name="confirmPassword"
            disabled={loader}
            errorMessage={getErrorMessage('confirmPassword')}
            placeholder="Repeat new password"
            inputLabel="Confirm Password"
            error={hasError('confirmPassword')}
            maxLength={VIEWING_USER_MAX_SYMBOLS}
            withOutIcon
            tabIndex={3}
          />
        </FormContainer>
      </FormProvider>
      <Button.Primary
        label="Change Password"
        height="4rem"
        width="20rem"
        onClick={handleSubmit(onSubmit)}
        disabled={!isMinLength || loader}
        loader={loader}
      />
      {backendError && (
        <ErrorContainer>
          <ErrorWithIcon text={VALIDATION_ERRORS_FORM_ADD.OLD_PASSWORDS_INCORRECT} />
        </ErrorContainer>
      )}
      <SuccessModal
        image={{
          src: PasswordChangedImage,
          alt: 'Password updated',
        }}
        header={MODAL_MESSAGES?.changedPasswordModal?.header ?? ''}
        message={MODAL_MESSAGES?.changedPasswordModal?.message ?? ''}
        isShown={isShownSuccessModal}
        onConfirm={() => toggleSuccessModal()}
        withBaseModal
      />
    </Container>
  )
}

export default AgencySignInSecurity
