import React from 'react'
import { useMobile } from '@utils/hooks'
import ConfirmImg from '@assets/images/UOConfirmImg.svg'
import { Button, TwoButtonRow } from '@molecules'
import { Typography } from '@atoms'
import { UNDER_OFFER, ROUTES } from '@constants/dictionary'
import { Container, ConfirmImage, H1, TextWrapper, LinkContainer } from './UnderOffer.styled'

type ConfirmProps = {
  handleConfirmed: () => void
}
const Confirm: React.FC<ConfirmProps> = ({ handleConfirmed }): JSX.Element => {
  const isMobile = useMobile()
  return (
    <Container isMobile={isMobile}>
      <ConfirmImage isMobile={isMobile} src={ConfirmImg} />
      <H1 isMobile={isMobile}>{UNDER_OFFER.CONFIRM.HEADER}</H1>
      <TextWrapper>
        <Typography.Body2 isMobile={isMobile}>{UNDER_OFFER.CONFIRM.MESSAGES}</Typography.Body2>
      </TextWrapper>
      <TwoButtonRow
        firstButton={
          <LinkContainer to={ROUTES.SELLER_CANCEL_VISIT}>
            <Button.Secondary isMobile={isMobile} label="Back" width="100%" />
          </LinkContainer>
        }
        secondButton={
          <Button.Primary
            isMobile={isMobile}
            label="Confirm"
            width="100%"
            onClick={() => handleConfirmed()}
          />
        }
      />
    </Container>
  )
}

export default Confirm
