import { Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.indent.XS};
  margin: ${(p) => p.theme.indent.XXS} 0 ${(p) => p.theme.indent.LG};
`

export const DropContainer = styled(Row)`
  gap: ${(p) => p.theme.indent.XS};
  cursor: pointer;
`

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 ${(p) => p.theme.indent.XS};
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
  border: 0.4rem solid ${(p) => p.theme.color.lightGrey};
`

export const StyledImg = styled.img`
  display: flex;
  height: 8.2rem;
  width: 8.2rem;
  border-radius: 50%;
`
