import React, { useCallback, useState } from 'react'
import { useMobile, useDate, useMountEffect } from '@utils/hooks'
import { Typography, Loader } from '@atoms'
import { Collapse, Checkbox, Button } from '@molecules'
import { NoSlots } from '@organisms'
import { BOOKING_STATUSES, LS_CONTSTANTS, ROUTES } from '@constants/dictionary'
import {
  checkToShowBlock,
  mapToSlots,
  sortedInToTwoWeeks,
  getWeek,
  checkToShowWeek,
} from '@utils/functions'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getPropertyProfileCollection, getSellerSlots } from '@services/firebase'
import {
  Container,
  LinkContainer,
  TextComponent,
  BottomContainer,
  ButtonContainer,
  CheckBoxContainer,
  Divider,
} from './SellerSlots.style'
import FirstWeek from './FirstWeek'
import SecondWeek from './SecondWeek'

const SellerSlots = (): JSX.Element => {
  const isMobile = useMobile()
  const questionTitle = `Can't show your property?`
  const [cheked, setChecked] = useState(false)
  const querySellerSlots = getSellerSlots()
  const [value, loading] = useCollection(querySellerSlots)
  const [isArchived, setIsArchived] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const getSellerPropertyProfiles = async (): undefined => {
    setLoaded(false)
    const propertyId = localStorage.getItem(LS_CONTSTANTS.propertyId) || ''
    const profiles = await getPropertyProfileCollection(propertyId)
    if (!!profiles.length) {
      setIsArchived(profiles.every((el) => el.status === BOOKING_STATUSES.ARCHIVED))
    }
    setLoaded(true)
  }
  useMountEffect(getSellerPropertyProfiles)
  const sellerSlots = loading ? [] : mapToSlots(value)
  const { getSellersWeek, getSellersNextWeek } = useDate()
  const sellerWeek = getSellersWeek()
  const sellerNextWeek = getSellersNextWeek()
  const currentWeek = getWeek(sellerWeek)
  const nextWeek = getWeek(sellerNextWeek)

  const initialState = sortedInToTwoWeeks({ sellerSlots, sellerWeek, sellerNextWeek })

  const isNotEmptySecondWeek = checkToShowWeek(initialState.secondWeek)
  const isNotEmptyFirstWeek = checkToShowWeek(initialState.firstWeek)

  const onClickCheckbox = useCallback(() => {
    setChecked(!cheked)
  }, [setChecked, cheked])

  return (
    <Loader loaded={!loading && loaded}>
      {!isNotEmptyFirstWeek || isArchived ? (
        <NoSlots isArchived={isArchived} emptyFirstWeek={!isNotEmptyFirstWeek} />
      ) : (
        <>
          <Container isMobile={isMobile}>
            <Typography.Body2 disabled isMobile={isMobile}>
              {questionTitle}
            </Typography.Body2>
            <LinkContainer to={ROUTES.SELLER_CANCEL_VISIT}>
              <Typography.Link as="span" isMobile={isMobile}>
                Cancel visits
              </Typography.Link>
            </LinkContainer>
          </Container>
          <Divider />
          <Collapse label={currentWeek}>
            <FirstWeek initialState={initialState} />
          </Collapse>
          {checkToShowBlock() && (
            <>
              <Divider />
              {isNotEmptySecondWeek ? (
                <Collapse collapsed label={nextWeek}>
                  <SecondWeek initialState={initialState} />
                </Collapse>
              ) : (
                <BottomContainer isMobile={isMobile}>
                  <Typography.Caption2 isMobile={isMobile}>{nextWeek}</Typography.Caption2>
                  <TextComponent isMobile={isMobile}>
                    Please add your free time slots for this period.
                  </TextComponent>
                  <CheckBoxContainer>
                    <Checkbox
                      isMobile={isMobile}
                      value={cheked}
                      label="Use settings as last week"
                      onChange={onClickCheckbox}
                    />
                  </CheckBoxContainer>
                  <ButtonContainer
                    to={{
                      pathname: ROUTES.SELLER_CONFIG_SLOTS,
                      state: {
                        emptyFirstWeek: false,
                        usePastWeek: cheked,
                        pastWeek: initialState.firstWeek,
                      },
                    }}
                  >
                    <Button.Primary label="Add" />
                  </ButtonContainer>
                </BottomContainer>
              )}
            </>
          )}
        </>
      )}
    </Loader>
  )
}

export default SellerSlots
