export const URGENT_MATTERS = {
  DAY_CHOOSE: {
    FIRST_HEADER: 'Choose a day you want to cancel:',
    SECOND_HEADER: 'Note:',
  },
  CONFIRM: {
    HEADER: 'Please leave us a comment about the reason:',
    TEXTAREA_PLACEHOLDER: 'What is your reason? (Optional)',
  },
  CONFIRMED: {
    HEADER: `Selected days 
    have been canceled`,
  },
}
