export const ADD_PROPERTY_CONSTANTS = {
  infoTitle: '1. General info',
  infoText:
    'Please, provide detailed info about the property. It will help potential buyers to differentiate the property among others.',
  photoTitle: '2. Photo',
  photoText: 'Add one photo that will differ the property.',
  ownerTitle: '3. Owner',
  ownerText: 'Provide an access for property owner to set up available time for viewing.',
  statusTitle: '4. Property Status',
  errorMessage: 'Value is required',
}
