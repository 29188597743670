import styled from 'styled-components'

export const Container = styled.div<{ isMobile: boolean; isMobileLandscape: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  padding: ${(p) => `5.1rem ${p.theme.indent.XXXL} 4rem`};
  border-radius: ${(p) => p.theme.borderRadius.MD};
  min-height: 36.7rem;
  width: ${(p) => (p.isMobile ? '33.5rem' : '35.4rem')};
  ${({ isMobileLandscape }) => isMobileLandscape && 'overflow-y: scroll'};
`
export const StyledImg = styled.img``

export const MessageWrapper = styled.div`
  margin-top: 3.8rem;
  margin-bottom: ${(p) => p.theme.indent.LG};
  text-align: center;
`

export const WithLineBreak = styled.span`
  white-space: pre-wrap;
`
