import LinkExpired from '@assets/images/LinkExpired.svg'
import PageNotFound from '@assets/images/PageNotFound.svg'

const ERROR_MESSAGE_TEXT = {
  INCORRECT_TOKEN_ERROR: {
    title: 'Link was expired',
    description: 'Please contact your agency',
    img: LinkExpired,
  },
  PAGE_NOT_FOUND_ERROR: {
    title: 'Page Not Found',
    description: 'Something went wrong,\n try to reload the page',
    img: PageNotFound,
  },
}

export const ERROR_MESSAGE_TEXT_PROPERTY = {
  INCORRECT_TOKEN_ERROR: 'INCORRECT_TOKEN_ERROR',
  PAGE_NOT_FOUND_ERROR: 'PAGE_NOT_FOUND_ERROR',
}

export default ERROR_MESSAGE_TEXT
