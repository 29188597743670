import { isFriday, isSaturday, isThursday } from 'date-fns'
import { DayTypes, State } from '@constants/types'
import { TIME_TO_SEND_SMS } from '@constants/dictionary'

export const checkToShowBlock = (): boolean => {
  const isTodayFriday = isFriday(new Date())
  const isTodayThursday = isThursday(new Date())

  const isTodaySaturday = isSaturday(new Date())

  const objDate = new Date()

  const hours = objDate.getHours()
  if ((hours >= TIME_TO_SEND_SMS && isTodayThursday) || isTodaySaturday || isTodayFriday) {
    return true
  }
  return false
}

export const checkToShowWeek = (week: State): boolean => {
  const isBelowThreshold = (day: DayTypes): boolean => {
    return day.slots.length > 0
  }

  const showSecondWeek = (): boolean => {
    return week.some(isBelowThreshold)
  }
  return showSecondWeek()
}
export const isButtonDisabled = (week: State): boolean => {
  const isBelowThreshold = (day: DayTypes): boolean => {
    return day.timePeriods.length > 0
  }

  const showSecondWeek = (): boolean => {
    return week.some(isBelowThreshold)
  }
  return showSecondWeek()
}
