import React, { useRef } from 'react'
import Carousel from 'react-elastic-carousel'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Icon } from '@atoms'
import { Container, Arrow, SlidesContainer, Slide } from './Carousel.styles'

import { CarouselProps } from '@constants/types/molecules'

const MyCarousel: React.FC<CarouselProps> = ({ children, setActive }) => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const carousel = useRef()

  const renderPagination = ({ pages, activePage, onClick }): React.FC => {
    return (
      <Container border={!isMobile && !isTabletPortrait} isMobile={isMobile}>
        <Arrow isMobile={isMobile} onClick={() => carousel.current.slidePrev()}>
          <Icon
            icon="carousel-arrow-left"
            clickable
            size={isMobile ? '0.9rem' : '1.4rem'}
            color="white"
          />
        </Arrow>
        <SlidesContainer isMobile={isMobile}>
          {pages.map((page) => (
            <Slide
              isMobile={isMobile}
              type="button"
              key={page}
              onClick={() => {
                onClick(page)
                setActive(page)
              }}
              active={activePage === page}
            />
          ))}
        </SlidesContainer>
        <Arrow isMobile={isMobile} onClick={() => carousel.current.slideNext()}>
          <Icon
            icon="carousel-arrow-right"
            clickable
            size={isMobile ? '0.9rem' : '1.4rem'}
            color="white"
          />
        </Arrow>
      </Container>
    )
  }

  return (
    <Carousel
      itemsToShow={1}
      disableArrowsOnEnd={false}
      showArrows={false}
      renderPagination={({ pages, activePage, onClick }) => {
        return <>{renderPagination({ pages, activePage, onClick })}</>
      }}
      ref={carousel}
      onChange={(_, pageIndex) => setActive(pageIndex)}
    >
      {children}
    </Carousel>
  )
}

export default MyCarousel
