import { State, TimePeriodsTypes, TimeSlotsTypes } from '@constants/types'
import { generateSlots, getAllTimePeriodsOfWeek } from '../../functions'

const useCreateSlots = (state: State): Array<Array<TimeSlotsTypes>> => {
  const allPeriods = getAllTimePeriodsOfWeek(state)

  return allPeriods
    .map((period: TimePeriodsTypes) => {
      return generateSlots(period)
    })
    .flat()
}

export default useCreateSlots
