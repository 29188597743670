import React from 'react'
import { useMobile } from '@utils/hooks'
import { Typography } from '@atoms'
import { TimePeriod } from '@molecules'
import { DayTypes, TimeSlotsTypes } from '@constants/types'
import { StyledCol, ContainerNoSlots } from './SellerSlots.style'

const TimePeriods = ({ day }: DayTypes): JSX.Element => {
  const isMobile = useMobile()

  if (day.slots.length === 0) {
    return (
      <ContainerNoSlots isMobile={isMobile}>
        <Typography.Body2 disabled isMobile={isMobile}>
          No time periods added for this day
        </Typography.Body2>
      </ContainerNoSlots>
    )
  }

  return day.slots.map((slot: TimeSlotsTypes, index: number) => (
    <StyledCol isMobile={isMobile} xs={12} sm={6} key={`${day.dayNumber + index}`}>
      <TimePeriod slot={slot} id={index} />
    </StyledCol>
  ))
}

export default TimePeriods
