import { isToday } from 'date-fns'
import { LS_CONTSTANTS, ViewingTime } from '@constants/dictionary'

import { ViewingCancelInitiators } from '@constants/types'
import { MIN_HIGHLIGHTED_VIEWING_CONFLICTS } from '@constants/dictionary'
export const getConflictedViewings = (viewingData: unknown): Record<number, Array<number>> => {
  const duplicatedViewings = {}
  const currentDate = new Date()
  viewingData.forEach(({ viewing }) => {
    const fromDateTime = viewing?.fromDateTime?.toDate()?.getTime()
    const uid = localStorage.getItem(LS_CONTSTANTS?.uid)
    if (fromDateTime >= currentDate && !viewing?.cancelled && viewing?.agent?.id === uid) {
      if (Array.isArray(duplicatedViewings[fromDateTime])) {
        duplicatedViewings[fromDateTime]?.push(viewing?.id)
      } else {
        duplicatedViewings[fromDateTime] = [viewing?.id]
      }
    }
  })

  Object.keys(duplicatedViewings).forEach((timeKey) => {
    if (duplicatedViewings[timeKey].length < MIN_HIGHLIGHTED_VIEWING_CONFLICTS) {
      delete duplicatedViewings[timeKey]
    }
  })

  return duplicatedViewings
}

export const mapViewingsData = (
  viewingData: unknown,
  conflictedViewings: Record<number, Array<number>>,
): [] => {
  return viewingData.map((viewingData) => {
    const {
      viewing = {
        fromDateTime: new Date(),
        toDateTime: new Date(),
        id: 0,
        propertyProfile: {
          title: '',
          address: '',
        },
        buyer: {
          name: '',
          id: '',
        },
        agent: {
          name: '',
          id: '',
        },
        isConfirmed: false,
        cancelled: false,
      },
    } = viewingData
    const conflictedIdsByTime = conflictedViewings[viewing.fromDateTime.toDate().getTime()]
    const isConflicted =
      Array.isArray(conflictedIdsByTime) && conflictedIdsByTime.includes(viewing.id)
    return {
      data: {
        fromDateTime: viewing?.fromDateTime?.toDate(),
        toDateTime: viewing?.toDateTime?.toDate(),
        id: viewing?.id,
        title: viewing?.propertyProfile?.title,
        address: viewing?.propertyProfile?.address,
        buyerName: viewing?.buyer?.name,
        buyerId: viewing?.buyer?.id,
        agentId: viewing?.agent?.id,
        isConfirmed: viewing?.isConfirmed,
        hasCancelInfo: !!viewing?.cancelled,
        cancellationReasonId: viewing?.cancelled?.cancellationReasonId,
      },
      hasConflict: isConflicted,
    }
  })
}

export const sortViewings = (viewings: []): Array =>
  viewings.sort((a, b) => a.data.fromDateTime - b.data.fromDateTime)

export const filterViewingsAlgolia = (viewings: [], filteredData = []): Array => {
  const currentDate = new Date()
  return viewings.filter((viewing) => {
    let found = false
    filteredData.forEach((d) => {
      const viewingDate = new Date(d?.fromDateTime)
      if ((viewingDate >= currentDate || isToday(viewingDate)) && d?.objectID === viewing?.id) {
        found = true
      }
    })
    return found
  })
}

export const filterViewings = (viewings: [], viewingTime: ViewingTime): [] => {
  switch (viewingTime) {
    case ViewingTime.TODAY:
      return viewings.filter(({ viewing }) => isToday(viewing.fromDateTime.toDate()))
    case ViewingTime.ALL_TIME:
      return viewings
    default:
      return viewings
  }
}

export const getViewingById = (viewingsList: Array<unknown>, id: number): string =>
  viewingsList.find((viewingData: unknown) => viewingData.viewing.id === id)

export const getViewingIndexByStringId = (
  viewingsList: Array<unknown>,
  viewingId: number,
): number => viewingsList.findIndex((element) => element.id === viewingId)

export const getCancelInitiator = (initId: number): string => {
  switch (initId) {
    case ViewingCancelInitiators.AGENT:
    case ViewingCancelInitiators.AGENT_FROM_ACTIVE:
      return 'Agent'
    case ViewingCancelInitiators.SELLER:
    case ViewingCancelInitiators.SELLER_PROP_UNDER_OFFER:
      return 'Seller'
    case ViewingCancelInitiators.BUYER:
      return 'Buyer'
    default:
      return ''
  }
}
