import styled from 'styled-components'

export const ConfirmMessage = styled.div`
  margin: 0 5.3rem;
  text-align: center;
`
export const Container = styled.div`
  background-color: ${(p) => p.theme.color.white};
  padding: ${(p) => p.theme.indent.MD};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  max-width: 38.3rem;
  min-height: 16rem;
  position: relative;
`
export const ButtonWrapper = styled.div<{ isStatusModal: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.isStatusModal ? 'column' : 'row-reverse')};
  column-gap: 1.1rem;
  gap: ${(p) => p.theme.indent.SM};
  margin-top: ${(p) => p.theme.indent.XL};
`

export const ConfirmText = styled.div`
  margin: 0 auto;
  text-align: center;
`

export const ImgWrapper = styled.div`
  margin: 0 auto ${(p) => p.theme.indent.LG} auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledImg = styled.img`
  height: 10.4rem;
  width: 12.3rem;
`

export const CloseWrapper = styled.div`
  position: absolute;
  right: ${(p) => p.theme.indent.XL};
`
