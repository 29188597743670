import {
  CANCELATION_BY_AGENT,
  CANCELATION_BY_BUYER,
  CANCELATION_BY_SELLER,
} from '@constants/dictionary'
import { TypesVisits } from '@constants/types'
import { compareAsc, format } from 'date-fns'

export const refactorBuyerVisits = (buyerVisits: Array<TypesVisits>): Array<TypesVisits> => {
  return buyerVisits.map((visit: TypesVisits) => {
    const getStatusOrRating = (): string => {
      if (visit.cancelled !== null) return 'Cancelled'
      if (visit.isConfirmed && !visit.review) return 'Confirmed'
      if (visit.isConfirmed && visit.review) return visit.review.rating
      if (compareAsc(new Date(visit.fromDateTime.toDate()), new Date()) === 1)
        return 'Wait for visit'
      if (compareAsc(new Date(visit.fromDateTime.toDate()), new Date()) <= 0 && !visit.isConfirmed)
        return 'Wait for visit'
    }
    const getShortList = (): string => {
      if (!visit.review) return 'NO'
      if (visit.review) {
        if (visit.review.isShortListed) return 'YES'
        if (!visit.review.isShortListed) return 'NO'
      }
    }

    const cancelIniciator = (cancellationReasonId): string => {
      switch (cancellationReasonId) {
        case CANCELATION_BY_BUYER:
          return 'Buyer'
          break
        case CANCELATION_BY_SELLER:
          return 'Seller'
          break
        case CANCELATION_BY_AGENT:
          return 'Agent'
          break
        default:
          return ''
      }
    }
    const refactorVisit = {
      id: visit.id || '',
      date: format(visit.fromDateTime.toDate(), 'dd/MM/yy') || '',
      time:
        `${format(visit.fromDateTime.toDate(), 'HH:mm')} -
        ${format(visit.toDateTime.toDate(), 'HH:mm')}` || '',
      propertyTitle: visit.propertyProfile.title || '',
      statusOrRating: getStatusOrRating(),
      shortList: getShortList(),
      dateTime: visit.fromDateTime.toDate(),
      buyerId: visit.buyer.id || '',
      propertyId: visit.propertyId || '',
      propertyProfileId: visit.propertyProfile.id || '',
      buyerName: visit.buyer.name || '',
      visitId: visit.visitId || '',
      modalBody: {
        name: visit.buyer.name,
        visitsQuantity: visit.visitsQuantity,
        title: visit.propertyProfile.title,
        cancelledBy: visit.cancelled?.cancellationReasonId
          ? cancelIniciator(visit.cancelled.cancellationReasonId)
          : '',
        cancellationDescription: visit.cancelled?.cancellationReasonMessage || '',
      },
    }
    return refactorVisit
  })
}
