import React, { useState, useEffect } from 'react'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Typography, Icon } from '@atoms'
import imageDrop from '@assets/images/imageDrop.svg'
import { useDropzone } from 'react-dropzone'
import Button from '../Button'
import { DROP_ZONE_CONSTANTS } from '@constants/dictionary'
import { customValidation, createImgPreview, revokePreview } from './DropZone.utils'
import {
  DropZoneContainer,
  BaseWrapper,
  DropZoneImg,
  TextWrapper,
  StyledRow,
  LoadedImg,
  DragActiveWrapper,
  CloseWrapper,
  ImageWrapper,
} from './DropZone.style'

import { DropZoneProps } from '@constants/types/molecules'

const DropZone: React.FC<DropZoneProps> = ({
  desricpiton = DROP_ZONE_CONSTANTS.DESCRIPTION,
  caption = DROP_ZONE_CONSTANTS.CAPTION,
  filesDescription = DROP_ZONE_CONSTANTS.FILES_DESCRIPTION,
  onChange,
  disabled,
  getError,
}) => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  /* using an array to edit number of pictures in future */
  const [files, setFiles] = useState([])
  /* implement logic using react-dropzone */
  const {
    getRootProps,
    getInputProps,
    open,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    maxFiles: 1,
    maxSize: 10485760 /* 10mb in bytes */,
    validator: customValidation,
    noClick: isMobile || isTabletPortrait || files.length > 0,
    noKeyboard: isMobile || isTabletPortrait,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          const imageFile = createImgPreview(file)
          onChange(imageFile)
          return imageFile
        }),
      )
    },
  })

  const errorMessage = fileRejections.map((file) => file.errors)

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => revokePreview(file))
      if (errorMessage.length && getError) {
        getError(errorMessage[0][1].message)
      }
      if (errorMessage.length === 0) {
        getError('')
      }
    },
    [files, errorMessage, getError],
  )

  return (
    <DropZoneContainer
      {...getRootProps({ isDragActive, isDragAccept, isDragReject, fileRejections })}
      isMobile={isMobile}
      isTabletPortrait={isTabletPortrait}
    >
      <input disabled={disabled} {...getInputProps({ onChange })} />
      {files?.length > 0 ? (
        <ImageWrapper>
          <LoadedImg src={files[0].preview} alt="photo" />
          <CloseWrapper>
            <Icon
              icon="close"
              size="1.25rem"
              color="originalBlue"
              clickable
              onClick={() => setFiles([])}
            />
          </CloseWrapper>
        </ImageWrapper>
      ) : (
        <BaseWrapper>
          {!isDragActive && <DropZoneImg src={imageDrop} alt="imageDrop" />}
          <StyledRow>
            {isMobile || isTabletPortrait ? (
              <>
                <Button.Secondary label="Select image" onClick={open} />
              </>
            ) : (
              <>
                {isDragActive ? (
                  <DragActiveWrapper>
                    <Typography.H3 color="darkGrey">
                      {DROP_ZONE_CONSTANTS.DRAG_ACTIVE.title}
                    </Typography.H3>
                    <Typography.Body3 color="darkGrey">
                      {DROP_ZONE_CONSTANTS.DRAG_ACTIVE.description}
                    </Typography.Body3>
                  </DragActiveWrapper>
                ) : (
                  <TextWrapper>
                    <Typography.Body3>{desricpiton}</Typography.Body3>
                    <Typography.Caption2 $clickable active>
                      {caption}
                    </Typography.Caption2>
                  </TextWrapper>
                )}

                {!isDragActive && <Typography.Body3 disabled>{filesDescription}</Typography.Body3>}
              </>
            )}
          </StyledRow>
        </BaseWrapper>
      )}
    </DropZoneContainer>
  )
}

export default DropZone
