import React, { useEffect, useState } from 'react'
import { mapSellerVisits } from '@utils/functions'
import { getSellerVisits } from '@services/firebase'
import { useMobile } from '@utils/hooks'
import { Loader, Typography } from '@atoms'
import { BookingDayTitle, VisitInfoCard } from '@molecules'
import { Container, VisitsContainer, MessageContainer } from './SellerVisits.style'

const SellerVisits = (): JSX.Element => {
  const isMobile = useMobile()

  const [visitsLoading, setVisitsLoading] = useState<boolean>(false)
  const [visitsListDetails, setVisitsListDetails] = useState<[]>([])

  useEffect(() => {
    ;(async function () {
      setVisitsLoading(true)
      const visits = await getSellerVisits()
      setVisitsListDetails(mapSellerVisits(visits))
      setVisitsLoading(false)
    })()
  }, [])

  const noVisit = 'You have no scheduled visits yet'

  return (
    <Container>
      <Loader loaded={!visitsLoading}>
        {visitsListDetails.length === 0 ? (
          <MessageContainer isMobile={isMobile}>
            <Typography.Body1 disabled isMobile={isMobile}>
              {noVisit}
            </Typography.Body1>
          </MessageContainer>
        ) : (
          visitsListDetails.map(({ day, date, visits }) => (
            <VisitsContainer isMobile={isMobile} key={day}>
              <BookingDayTitle dayName={+day} date={+date} key={date} />
              {visits.map((visit) => (
                <VisitInfoCard {...visit} key={visit.id} />
              ))}
            </VisitsContainer>
          ))
        )}
      </Loader>
    </Container>
  )
}

export default SellerVisits
