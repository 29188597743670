import styled from 'styled-components'

export const RadioButtonsContainer = styled.div<{ isHorizontal: boolean }>`
  display: flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  gap: ${({ theme }) => theme.indent.LG};
`

export const RadioButtonItem = styled.div`
  display: flex;
  cursor: pointer;
`
export const RadioBullet = styled.div<{ selected: boolean }>`
  border: 0.1rem solid ${({ theme }) => theme.color.darkGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0.2rem;
  margin-top: ${({ theme }) => theme.indent.XXS};
  background-clip: content-box;
  ${({ theme, selected }) => selected && `background-color:${theme.color.originalBlue};`}
`

export const RadioLabel = styled.div`
  margin-left: ${({ theme }) => theme.indent.SM};
`
