import _ from 'lodash'
import { format } from 'date-fns'

export const mapToVisits = (firestoreList: { docs: Array } = { docs: [] }): Array => {
  const source = firestoreList.docs.map((visit) => ({
    ...visit.data(),
    compareDate: format(visit.data().fromDateTime.toDate(), 'y,L,d'),
  }))
  const list = _(source)
    .groupBy('compareDate')
    .map((items, date) => {
      return {
        startDate: new Date(date),
        visits: _.sortBy(items, (item) => item.fromDateTime),
      }
    })
    .value()
  return _.sortBy(list, (dateObj) => dateObj.startDate)
}

export const mapToSlots = (
  firestoreList: { docs: Array } = { docs: [] },
  byProfile: string | undefined,
): Array => {
  const source = firestoreList.docs.map((visit) => ({
    ...visit.data(),
  }))

  if (byProfile) {
    // only booked
    source.filter((visit) => visit.buyer !== null)
    return source.filter((visit) => visit.propertyProfile?.id === byProfile)
  }

  return source
}

export const mapVisits = (firestoreList: { docs: Array } = { docs: [] }): Array => {
  const source = firestoreList.docs.map((visit) => ({
    visitId: visit.id,
    ...visit.data(),
  }))
  return source
}
