export enum ItemsID {
  Homepage = 'Homepage',
  Help = 'Help',
  Notifications = 'Notifications',
  ProfileSettings = 'ProfileSettings',
  SignOut = 'SignOut',
}

export interface NavigationProps {
  id: ItemsID
  title: string
  linkTo?: string
  matchRoute?: Array<string>
  action?(): void
}
