import { GETADRESS } from '@constants/getAddressIO'
import { AddressType } from '@constants/types'

const removeEmptyKeys = (obj): AddressType => {
  return Object.keys(obj)
    .filter(function (k) {
      return obj[k]?.length > 0
    })
    .reduce(function (acc, k) {
      acc[k] = obj[k]
      return acc
    }, {})
}

const addressDetails = (result, addressId: string): AddressType => {
  const address = {
    id: addressId,
    streetNumber: result?.building_number,
    street: result?.thoroughfare || result?.address_line1,
    city: result?.town_or_city,
    country: result?.country,
    postalCode: result?.postcode,
  }

  return removeEmptyKeys(address)
}

export const loadAddressDetails = async (addressId: string): Promise<object> => {
  try {
    if (!addressId) {
      return false
    }
    const response = await fetch(
      `${GETADRESS.GET_ADDRESS_DETAILS}/${addressId}?api-key=${GETADRESS.API_KEY}`,
      {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    const result = await response.json()
    return addressDetails(result, addressId)
  } catch (error) {
    return false
  }
}
