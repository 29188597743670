import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div<{ isMobile: boolean; isMobileLandscape: boolean }>`
  background-color: ${(p) => p.theme.color.white};
  padding: ${(p) => p.theme.indent.XXXL}
    ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  max-width: 42rem;

  ${({ isMobileLandscape }) => isMobileLandscape && 'overflow-y: scroll'};
  ${({ isMobileLandscape }) => isMobileLandscape && 'max-height: 47rem'};
`

export const Img = styled.img`
  width: 27rem;
  padding: ${(p) => p.theme.indent.XXXL} 0;
`

export const H2 = styled(Typography.H2)`
  padding-bottom: ${(p) => p.theme.indent.LG};
`

export const Body2 = styled(Typography.Body2)`
  padding-top: ${(p) => p.theme.indent.MD};
`

export const CheckContainer = styled.div`
  padding-bottom: ${(p) => p.theme.indent.LG};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.indent.XS};
`
