import styled from 'styled-components'
import { Typography } from '@atoms'

export const RadioButtonLabel = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: ${(p) => p.theme.indent.MD};
`
export const RadioButtonContainer = styled.div`
  border: 0.1rem solid ${(p) => (p.selected ? p.theme.color.originalBlue : p.theme.color.darkBlack)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  width: ${({ theme }) => theme.indent.LG};
  height: ${({ theme }) => theme.indent.LG};
  ${({ theme, selected }) => selected && `background-color:${theme.color.originalBlue};`}
`
export const TextWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: ${({ theme }) => theme.indent.SM};
`
export const StyledBody2 = styled(Typography.Body2)<{ isMobile: boolean }>`
  ${({ isMobile }) => isMobile && 'white-space: normal;'};
`
