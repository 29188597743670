import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div<{ isMobile: boolean; isMobileLandscape: boolean }>`
  ${({ isMobile, isMobileLandscape, theme }) => ({
    position: 'relative',
    width: '44.2rem',
    padding: theme.indent.XXXL,
    borderRadius: theme.borderRadius.SM,
    backgroundColor: theme.color.white,
    boxShadow: `0 0.4rem 1rem ${theme.color.black_20}`,
    ...(isMobile && {
      width: '33.5rem',
      padding: `${theme.indent.XXXL} ${theme.indent.MD} ${theme.indent.LG}`,
    }),
    ...(isMobileLandscape && {
      overflowY: 'scroll',
    }),
  })};
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const QuestionBlock = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, isMobile }) => (isMobile ? theme.indent.SM : theme.indent.XXS)};
  margin-top: ${({ theme }) => theme.indent.LG};
`

export const Question = styled(Typography.Caption6)`
  display: block;
  white-space: pre-wrap;
`

export const Suggestion = styled(Typography.Caption6)`
  display: inline-block;
  text-align: center;
  white-space: pre-wrap;
`

export const Main = styled.main`
  margin-top: ${({ theme }) => theme.indent.LG};
`

export const ControlButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.indent.MD};
  margin-top: ${({ theme }) => theme.indent.LG};
`

export const CloseButton = styled.div`
  position: absolute;
  right: 2.3rem;
  top: 1.8rem;
`

export const CancelReasonChoose = styled.div`
  margin-top: ${({ theme }) => theme.indent.MD};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.indent.SM};
`
