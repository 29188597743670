import React, { Dispatch, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getPropertyProfile, getPropertyBuyerTimeSlotsCol, getUserById } from '@services/firebase'
import { mapTimeSlots } from '@utils/functions'
import { useMobile, useMountEffect } from '@utils/hooks'
import { SurveyBookingCard } from '@organisms'
import { Button } from '@molecules'
import { Loader } from '@atoms'
import { ROUTES, LS_CONTSTANTS } from '@constants/dictionary'
import { useHistory } from 'react-router'
import { Container } from './BuyerBooking.style'

const BuyerBooking = (): JSX.Element => {
  const isMobile = useMobile()

  const agentId: string = localStorage.getItem(LS_CONTSTANTS.agentId)
  const propertyId: string = localStorage.getItem(LS_CONTSTANTS.propertyId)

  const [propertyProfile, setPropertyProfile] = useState(undefined)
  const [agentProfile, setAgentProfile] = useState(undefined)

  const loadData = async (): Promise<undefined> => {
    const propertyDetails = await getPropertyProfile(propertyId, true)
    setPropertyProfile(propertyDetails)
    const agentDetails = await getUserById(agentId)
    setAgentProfile(agentDetails)
  }
  useMountEffect(loadData)

  const [visitId, setVisitId] = useState<string>('')
  const [timeSlotVisits, loadingVisits] = useCollection(getPropertyBuyerTimeSlotsCol(propertyId))
  const timeSlotDetails = loadingVisits ? [] : mapTimeSlots(timeSlotVisits)
  const getState = (selectedSlot): Dispatch<string> => setVisitId(selectedSlot)
  const history = useHistory()
  const handleSubmit = (): undefined => {
    history.push(ROUTES.BUYER_CONFIRM, { agentId, visitId })
  }

  return (
    <Loader loaded={!!propertyProfile && !!agentProfile && !loadingVisits}>
      {propertyProfile && agentProfile && (
        <SurveyBookingCard
          adress={propertyProfile?.address}
          urlImage={propertyProfile?.imageUrl}
          nameProperty={propertyProfile?.title}
          cost={propertyProfile?.price}
          agentName={agentProfile?.name}
          agentPhone={agentProfile?.phoneNumber}
          timeSlotDetails={timeSlotDetails}
          callback={getState}
        />
      )}
      {!!timeSlotDetails.length && (
        <Container isMobile={isMobile}>
          <Button.Primary disabled={!visitId} label="Confirm" onClick={handleSubmit} />
        </Container>
      )}
    </Loader>
  )
}

export default BuyerBooking
