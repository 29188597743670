import { VisitInfo, UserInfo } from '@templates'

export const tabsConfig = [
  {
    name: 'Buyer info',
    path: '/buyer-info',
    component: UserInfo,
    exact: true,
    id: 'buyer-info',
  },
  {
    name: 'Visits info',
    path: '/visits-info',
    component: VisitInfo,
    id: 'visits-info',
  },
]
