import React from 'react'
import { Row } from 'styled-bootstrap-grid'
import { useHistory } from 'react-router'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { PROPERTY_STATUS_FILER, ROUTES } from '@constants/dictionary'
import { Button, Dropdown, SearchInput } from '@molecules'
import { Icon } from '@atoms'
import {
  FilterContainer,
  IconContainer,
  DropdownContainer,
  SearchContainer,
  ButtonContainer,
  StyledRow,
} from './AgentAllProperties.style'

type FilterBarTypes = {
  isListView: boolean
  setListView: () => void
  handleDropdownValue: () => void
  searchValue: string
  setSearchValue: () => void
}

const FilterBar: React.FC<FilterBarTypes> = ({
  isListView = false,
  setListView = () => {},
  handleDropdownValue = () => {},
  searchValue = '',
  setSearchValue = () => {},
}): React.FC => {
  const history = useHistory()
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()

  const renderDropdown = (): React.FC => (
    <Row>
      <IconContainer padding isMobile={isMobile}>
        <Icon
          icon="grid-view"
          size="2rem"
          clickable
          color={isListView ? 'lightGrey' : 'originalBlue'}
          onClick={() => setListView(false)}
          key={`grid-view${isListView}`}
        />
      </IconContainer>
      {!isMobile && (
        <IconContainer>
          <Icon
            icon="list-view"
            size="2rem"
            clickable
            color={isListView ? 'originalBlue' : 'lightGrey'}
            onClick={() => setListView(true)}
            key={`list-view${isListView}`}
          />
        </IconContainer>
      )}
      <DropdownContainer isMobile={isMobile}>
        <Dropdown
          selectedValue={PROPERTY_STATUS_FILER[0].title}
          testid="AgentPropertiesStatus"
          handleValue={handleDropdownValue}
          renderThin
        >
          {PROPERTY_STATUS_FILER.map((item) => (
            <Dropdown.Option key={item.id} value={item.value} title={item.title} renderThin>
              {item.title}
            </Dropdown.Option>
          ))}
        </Dropdown>
      </DropdownContainer>
    </Row>
  )

  const renderSearch = (): React.FC => (
    <SearchContainer isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
      <SearchInput
        key="propertiesSearch"
        value={searchValue || ''}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search Property"
        noMargin
      />
    </SearchContainer>
  )

  const renderButton = (): React.FC => (
    <ButtonContainer isMobile={isMobile}>
      <Button.Primary
        label="Add Property"
        onClick={() => history.push(ROUTES.AGENT_ADD_PROPERTY)}
        renderMobile
      />
    </ButtonContainer>
  )

  return isMobile ? (
    <>
      {renderDropdown()}
      {renderSearch()}
      {renderButton()}
    </>
  ) : (
    <FilterContainer
      justifyContent="between"
      isMobile={isMobile}
      isTabletPortrait={isTabletPortrait}
    >
      <StyledRow>
        {renderDropdown()}
        {renderSearch()}
      </StyledRow>
      {renderButton()}
    </FilterContainer>
  )
}

export default FilterBar
