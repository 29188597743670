import { getFirestore, doc } from 'firebase/firestore'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const getPropertVisit = (visitId: string): { error: string } => {
  const db = getFirestore()
  return doc(db, FS_COL_NAMES.PROPERTY_VISITS, visitId)
}
export const getPropertyReviews = (visitId: string): { error: string } => {
  const db = getFirestore()
  return doc(db, FS_COL_NAMES.PROPERTY_REVIEWS, visitId)
}
