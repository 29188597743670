/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { Dispatch, useState, useCallback, useEffect } from 'react'
import { Typography } from '@atoms'
import { USER_TYPES } from '@constants/dictionary'
import { Row, Col } from 'styled-bootstrap-grid'
import { useTheme } from 'styled-components'
import { useMobile, useTabletPortrait, useModal } from '@utils/hooks'
import { Button, Dropdown, RadioButton } from '@molecules'
import { ADD_PROPERTY_CONSTANTS, EDITING_STATUS, BOOKING_STATUSES } from '@constants/dictionary'
import { AddModal } from '@modals'
import { InputForm, CheckBoxForm, DropZoneForm, SearchForm, UserAddedLabelForm } from '@helpers'
import {
  FormWrapper,
  StyledRow,
  PlugWrapper,
  StyledCol,
  StyledInputCol,
  CheckBoxWrapper,
  ButtonWrapper,
  StyledImg,
  EditImgWrapper,
} from './AgentAddPropertyForm.style'
import { PropertyFormTypes } from './AgentPropertyForm.types'
import { radioButtons } from './AgentPropertyForm.constants'
import {
  checkSeller,
  checkTitle,
  filterEmptyValue,
  getStatusDescription,
} from './AgentPropertyForm.utils'

const AgentAddPropertyForm: React.FC<PropertyFormTypes> = (props): JSX.Element => {
  const {
    errors,
    isApartment,
    onSelected,
    onSelectedSeller,
    loader,
    isEditProperty,
    imageUrl,
    sellerName,
    setStatus,
    status,
    soldBy,
    setSoldBy,
  } = props
  const { isShown, toggle } = useModal()
  const isMobile = useMobile()
  const errorUrl = errors.image ? errors.image.message : ''
  const errorSeller = errors.seller ? errors.seller.message : ''
  const [errorDropZone, setErrorDropZone] = useState<string>('')
  const color = useTheme().color
  const isTabletPortrait = useTabletPortrait()
  const [seller, setSeller] = useState<{ id: string; name: string }>({ id: '', name: '' })
  const getDropZoneError = (message: string): Dispatch<string> => setErrorDropZone(message)
  const getSeller = (name: string, id: string): Dispatch<{ id: string; name: string }> =>
    setSeller({ name, id })

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const getError = useCallback((field: string): string => errors[field]?.message ?? '', [errors])
  useEffect(() => {
    if (onSelectedSeller) {
      onSelectedSeller(seller.id)
    }
  }, [onSelectedSeller, seller])

  return (
    <FormWrapper>
      <Row>
        <StyledCol xl={7} lg={7} xs={12} md={12} isRightPadding={!isMobile}>
          <StyledRow>
            <Typography.H3>{ADD_PROPERTY_CONSTANTS.infoTitle}</Typography.H3>
          </StyledRow>
          <StyledRow>
            <StyledRow>
              <Col col={8}>
                <Typography.Body3>{ADD_PROPERTY_CONSTANTS.infoText}</Typography.Body3>
              </Col>
            </StyledRow>
            <Row>
              <StyledInputCol isMobile={isMobile} col={8} noGutter md={8} xs={12}>
                <InputForm
                  name="title"
                  disabled={loader}
                  errorMessage="Value is required"
                  placeholder="Fill property name"
                  inputLabel="Property Name"
                  error={hasError('title')}
                  minLength={1}
                  maxLength={50}
                  tabIndex={1}
                  isPropertyTitle
                />
              </StyledInputCol>
              <Col col={4} xs={12}>
                <InputForm
                  name="price"
                  errorMessage=""
                  placeholder="Fill price"
                  inputLabel="Price"
                  disabled={loader}
                  error={hasError('price')}
                  type="number"
                  minLength={1}
                  maxLength={10}
                  isPrice
                  tabIndex={2}
                />
              </Col>
              <StyledInputCol isMobile={isMobile} col={8} noGutter md={8} xs={12}>
                <SearchForm
                  errorMessage={getError('address')}
                  name="address"
                  placeholder="Fill address of the property"
                  inputLabel="Address"
                  isEditProperty={isEditProperty}
                  disabled={isEditProperty || loader}
                  error={hasError('address')}
                  maxLength={50}
                  onSelected={onSelected}
                  tabIndex={3}
                />
              </StyledInputCol>
              <Col col={4} xs={12}>
                <CheckBoxWrapper>
                  <CheckBoxForm
                    disabled={isEditProperty}
                    name="isApartment"
                    label="Apartment #"
                    fill={1}
                  />
                </CheckBoxWrapper>
                {isApartment ? (
                  <InputForm
                    name="apartmentNumber"
                    errorMessage=""
                    placeholder="Fill Apartment #"
                    inputLabel=""
                    error={hasError('apartmentNumber')}
                    disabled={!isApartment || loader}
                    minLength={1}
                    maxLength={4}
                    tabIndex={4}
                  />
                ) : (
                  <PlugWrapper isCentered>
                    <Typography.Body2>-</Typography.Body2>
                  </PlugWrapper>
                )}
              </Col>
            </Row>
          </StyledRow>
          <StyledRow>
            <Col xl={4} lg={4} xs={12} md={12}>
              <Typography.H3>{ADD_PROPERTY_CONSTANTS.photoTitle}</Typography.H3>
              <Typography.Body3>{ADD_PROPERTY_CONSTANTS.photoText}</Typography.Body3>
              {(errorUrl || errorDropZone) && (
                <Typography.Body3 color="red">{errorUrl || errorDropZone}</Typography.Body3>
              )}
            </Col>
            <Col xl={8} lg={8} xs={12} md={12}>
              {isEditProperty && imageUrl?.length ? (
                <EditImgWrapper>
                  <StyledImg src={imageUrl} alt="property" />
                </EditImgWrapper>
              ) : (
                <DropZoneForm
                  name="image"
                  disabled={loader}
                  errorMessage=""
                  error={hasError('image')}
                  getError={getDropZoneError}
                />
              )}
            </Col>
          </StyledRow>
        </StyledCol>
        <StyledCol xl={5} lg={5} xs={12} md={12} isRightPadding={isTabletPortrait}>
          <StyledRow>
            <Typography.H3>{ADD_PROPERTY_CONSTANTS.ownerTitle}</Typography.H3>
          </StyledRow>
          {!isEditProperty && (
            <StyledRow>
              <Typography.Body3>{ADD_PROPERTY_CONSTANTS.ownerText}</Typography.Body3>
            </StyledRow>
          )}
          <StyledRow>
            {checkSeller(seller.name, isEditProperty) ? (
              <Col>
                <ButtonWrapper>
                  <Button.WithIcon
                    icon="contact-plus"
                    label="Add Seller"
                    width="15rem"
                    color={color.originalBlue}
                    onClick={toggle}
                  />
                  {errorSeller && <Typography.Body3 color="red">{errorSeller}</Typography.Body3>}
                </ButtonWrapper>
              </Col>
            ) : (
              <Col col={8} xs={12}>
                <UserAddedLabelForm
                  name="seller"
                  error={hasError('seller')}
                  onClick={() => setSeller({ name: '', id: '' })}
                  sellerName={seller.name || sellerName}
                  isEditProperty={isEditProperty}
                  value={seller.name || sellerName}
                  disabled={loader}
                />
              </Col>
            )}
          </StyledRow>
          <StyledRow>
            <Typography.H3>{ADD_PROPERTY_CONSTANTS.statusTitle}</Typography.H3>
          </StyledRow>
          <StyledRow>
            <Col col={5} xs={6}>
              <Row>
                {isEditProperty ? (
                  <Dropdown
                    handleValue={(item) => setStatus(item)}
                    testid="Default"
                    value={checkTitle(EDITING_STATUS, status)}
                  >
                    {filterEmptyValue(EDITING_STATUS).map((item) => (
                      <Dropdown.Option key={item.id} value={item.value} title={item.title}>
                        {item.title}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                ) : (
                  <PlugWrapper>
                    <Typography.Body2>Active</Typography.Body2>
                  </PlugWrapper>
                )}
                <Typography.Body3 disabled>
                  {getStatusDescription(EDITING_STATUS, status)}
                </Typography.Body3>
                {status === BOOKING_STATUSES.SOLD && (
                  <div>
                    <RadioButton
                      RadioButtonItems={radioButtons}
                      selected={soldBy ? 1 : 0}
                      onChange={setSoldBy}
                      isEditRadioButton
                    />
                  </div>
                )}
              </Row>
            </Col>
          </StyledRow>
        </StyledCol>
      </Row>
      <AddModal
        isShown={isShown}
        onCancel={toggle}
        type={USER_TYPES.seller}
        user="Seller"
        getSelected={getSeller}
        typeUser={USER_TYPES.seller}
      />
    </FormWrapper>
  )
}

export default AgentAddPropertyForm
