import styled from 'styled-components'
import { Typography } from '@atoms'
import { Col, Row } from 'styled-bootstrap-grid'

export const Container = styled.div<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.SM} ${p.theme.indent.LG}`
      : `${p.theme.indent.SM} ${p.theme.indent.XXXL}`};
  margin: ${(p) => (p.isMobile ? `0 -${p.theme.indent.XS}` : `0 -${p.theme.indent.SM}`)};
`

export const Title = styled(Typography.H3)<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XL} 0 0  ${p.theme.indent.MD}`
      : `${p.theme.indent.XL} 0 0 ${p.theme.indent.XXXL}`};
`

export const StyledCol = styled(Col)<{ isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? `0 ${p.theme.indent.XS}` : `0 ${p.theme.indent.SM}`)};
`

export const StyledRow = styled(Row)`
  margin: 0;
`
