export const VALIDATION_ERRORS = {
  INVALID_EMAIL: 'Invalid email address',
  EMAIL_WAS_ADDED: 'Email has already been added ',
  PASSWORD_NOT_VALID: 'Not a valid password',
}

export const VALIDATION_ERRORS_FORM_ADD = {
  BUYER_EXISTS: 'This buyer already exists',
  SELLER_EXISTS: 'This seller already exists',
  AGENT_EXISTS: 'This agent already exists',
  PASSWORDS_INCORRECT: 'Password is incorrect',
  OLD_PASSWORDS_INCORRECT: 'Old Password is incorrect',
}
