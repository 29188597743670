import { ViewingCancelInitiators } from '@constants/types'

export const AGENT_VIEWING_CONFIRM_RADIO = [
  {
    label: 'Buyer',
    value: ViewingCancelInitiators.BUYER,
  },
  {
    label: 'Seller',
    value: ViewingCancelInitiators.SELLER,
  },
  {
    label: 'Agent',
    value: ViewingCancelInitiators.AGENT,
  },
]
