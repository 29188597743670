/* eslint-disable no-magic-numbers */
import { phoneNumber } from '@constants/regExp'

export const formatPhoneNumber = (phoneNumberString: string): string | null => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(phoneNumber)

  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`
  }
  return null
}
