import React from 'react'
import { Icon, Typography } from '@atoms'
import { useMobile, useTabletPortrait, useDesktop } from '@utils/hooks'
import { IconContainer, InfoCard, TextContainer } from './AgentHelpCard.style'
import { cardData } from './AgentHelpCard.constant'

const AgentHelpCard: React.FC = () => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const isDesktop = useDesktop()
  return (
    <>
      {cardData &&
        cardData.map((card) => (
          <InfoCard
            key={card.title}
            isTabletPortrait={isTabletPortrait}
            isDesktop={isDesktop}
            isMobile={isMobile}
          >
            <IconContainer isDesktop={isDesktop}>
              <Icon
                icon={card.icon.name}
                size={isDesktop ? card.icon.desktopSize : card.icon.tabletMobileSize}
              />
            </IconContainer>
            <TextContainer isDesktop={isDesktop}>
              <Typography.H2>{card.title}</Typography.H2>
            </TextContainer>
            <TextContainer isDesktop={isDesktop}>
              <Typography.Body2>{card.name}</Typography.Body2>
            </TextContainer>
            <TextContainer>
              <Typography.Body2 disabled>{card.phoneNumber}</Typography.Body2>
            </TextContainer>
            <TextContainer clickable>
              <Typography.Link noUnderline href={`mailto:${card.email}?subject=ViewD:support`}>
                {card.email}
              </Typography.Link>
            </TextContainer>
          </InfoCard>
        ))}
    </>
  )
}

export default AgentHelpCard
