import React from 'react'
import { useDesktop, useMobile, useTabletPortrait } from '@utils/hooks'
import { getBuyerProfile } from '@utils/functions'
import { Loader } from '@atoms'
import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { AgentUserDetailsCard } from '@organisms'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { getUserStatistic, getUserProfile } from '@services/firebase'
import { useLocation, useParams } from 'react-router-dom'
import { BodyContainer } from './UserInfo.style'

const UserInfo = (): JSX.Element => {
  const { id }: { id: string } = useParams()
  const userId = id.split('-')[0]
  const userProfilleId = id.split('-')[1]
  const agentId = localStorage.getItem(LS_CONTSTANTS.uid)
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const isTablet = useTabletPortrait()
  const { pathname } = useLocation()
  const urlToArray = pathname.split('/')
  const userType =
    urlToArray[urlToArray.length - 1] === 'buyer-info' ? USER_TYPES.buyer : USER_TYPES.seller

  const userFileResponse = getUserProfile(userId, agentId, userType)
  const [valueUserFile, loadingUserFile] = useCollection(userFileResponse)
  const userFile = loadingUserFile ? { id: '' } : getBuyerProfile(valueUserFile)
  const getStatistic = (): undefined => {
    if (userProfilleId) {
      return getUserStatistic(userId, userProfilleId)
    }
    return null
  }
  const userStatistic = getStatistic()
  const [valueUserStatistic, loadingUserStatistic] = useDocument(userStatistic)
  const statistic = loadingUserStatistic ? null : valueUserStatistic?.data()

  return (
    <Loader loaded={!loadingUserFile && !loadingUserStatistic}>
      <BodyContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTablet}>
        <AgentUserDetailsCard
          statistic={statistic}
          userType={userType}
          userFile={userFile}
          userId={userId}
        />
      </BodyContainer>
    </Loader>
  )
}

export default UserInfo
