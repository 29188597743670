import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const Container = styled.div`
  width: 100%;
  min-height: ${({ isMobile }) => `calc(100vh - ${isMobile ? '16rem' : '20rem'});`}
  background-color: ${({ theme }) => theme.color.greyBlue};
  padding: ${({ isMobile, isDesktop, isTablet, theme }) => {
    if (isDesktop) return `${theme.indent.LG} 5rem 1rem`
    if (isTablet) return `${theme.indent.LG} ${theme.indent.XXXL} 1rem`
    if (isMobile) return `${theme.indent.LG} ${theme.indent.LG} 1rem`
  }};
`

export const BookLink = styled.div`
  color: ${(p) => p.theme.color.originalBlue};
  cursor: pointer;
  text-decoration: underline;
`

export const EmailPhone = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
`

export const IconContainer = styled.div`
  padding-left: ${(p) => p.theme.indent.SM};
`

export const ModalContainer = styled.div`
  position: relative;
  width: ${(p) => (p.isMobile ? '33.5rem' : '45rem')};
  padding: ${(p) => `${p.theme.indent.LG} ${p.theme.indent.XL}`};
  background-color: ${(p) => p.theme.color.white};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  box-shadow: 0 0.4rem 1rem ${(p) => p.theme.color.black_20};
`

export const StyledIconContainer = styled.div`
  padding-right: ${(p) => p.theme.indent.XS};
  display: flex;
`

export const MessageTitleContainer = styled.div`
  padding: ${(p) => `${p.theme.indent.SM} 0 ${p.theme.indent.XS}`};
  border-bottom: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  margin-bottom: ${(p) => p.theme.indent.XS};
`

export const StyledRow = styled(Row)`
  padding-top: ${(p) => p.theme.indent.SM};
`
export const StarsContainer = styled(Row)`
  padding-left: ${(p) => p.theme.indent.XS};
`
export const InfoContainer = styled.div`
  display: flex;
  min-width: 11.5rem;
  justify-content: space-between;
`
export const Cell = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const LinkTo = styled(Typography.Link)<{ noUnderline: boolean; isMobile: boolean }>`
  text-decoration: ${(p) => (p.noUnderline ? 'none' : 'underline')};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CardHeader = styled(Typography.Body3)<{ isMobile: boolean }>`
  display: flex;
  white-space: nowrap;
  width: ${({ isMobile }) => (isMobile ? 26 : 36.6)}rem;
`

export const BuyerName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 17rem;
`

export const VisitSequenceNumber = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  max-width: 22rem;
`

export const CloseButton = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 1.9rem;
`

export const PropertyTitle = styled(Typography.H2)<{ shortString: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ shortString }) => shortString && `width: 26rem`};
`
