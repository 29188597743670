import React, { useCallback } from 'react'
import { LinkTo } from './PropertyList.style'

const ViewInfoLink = ({
  propertyProfileId,
  viewProfile,
}: {
  propertyProfileId: string
  viewProfile: (id: string) => void
}): JSX.Element => {
  const onClick = useCallback(() => {
    viewProfile(propertyProfileId)
  }, [viewProfile, propertyProfileId])
  return (
    <div>
      <LinkTo isMobile onClick={onClick}>
        View info
      </LinkTo>
    </div>
  )
}
export default ViewInfoLink
