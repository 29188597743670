import React, { useCallback, useState } from 'react'

import {
  VisitCardContainer,
  SubContainer,
  Header,
  Body,
  Footer,
  InfoIcon,
  ConflictIcon,
  InfoContainer,
  ViewDetailsButton,
  Buyer,
  BuyerName,
  ViewingTitle,
  Address,
  StyledRow,
  ViewDetailsLoader,
  BuyerNameLink,
  BuyerLoader,
} from './AgentVisitCard.style'
import { Icon, Loader, Typography } from '@atoms'
import { ViewingStatus } from '@molecules'
import { getUserProfile } from '@services/firebase'
import { getBuyerProfile } from '@utils/functions'
import { VIEWING_STATUSES, LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getDateString } from './getDateString'
import { getViewingStatus } from '@utils/functions'
import { useTheme } from 'styled-components'
import { AgentVisitCardProps } from '@constants/types/organisms'
import { buildBuyerLink } from './AgentVisitCard.utild'

const AgentVisitCard: React.FC<AgentVisitCardProps> = ({
  conflictHandler,
  confirmHandler,
  viewDetailsCb,
  cancellationReasonId,
  hasConflict,
  data,
  className,
}) => {
  const {
    fromDateTime = new Date(),
    toDateTime = new Date(),
    id = '',
    title = '',
    address = '',
    buyerName = '',
    isConfirmed = false,
    hasCancelInfo = false,
    buyerId = '',
    agentId = '',
  } = data
  const status = getViewingStatus(fromDateTime, isConfirmed, hasCancelInfo)
  const [loadingViewDetails, setLoadingViewDetails] = useState(false)
  const [confirming, setConfirming] = useState<boolean>(false)
  const theme = useTheme()
  const uid = localStorage.getItem(LS_CONTSTANTS.uid)
  const buyerProfiles = getUserProfile(buyerId, uid, USER_TYPES.buyer)
  const [valueBuyerProfiles, loadingBuyer] = useCollection(buyerProfiles)
  const buyerProfile = loadingBuyer ? { id: '' } : getBuyerProfile(valueBuyerProfiles)
  const profileId = buyerProfile.id

  const confirmView = async (): Promise<void> => {
    if (status.statusKey === VIEWING_STATUSES.CONFIRM_VIEW.statusKey) {
      setConfirming(true)
      const confirmed = await confirmHandler({ id })
      if (confirmed) {
        setConfirming(false)
      }
    }
  }

  const viewDetails = useCallback(async () => {
    setLoadingViewDetails(true)
    await viewDetailsCb(id, { status: status, cancelledBy: cancellationReasonId })
    setLoadingViewDetails(false)
  }, [id, status, cancellationReasonId, viewDetailsCb])

  const renderLink = (hasConflict: boolean): JSX.Element => {
    if (hasConflict) {
      return <BuyerName hasCancelInfo={hasCancelInfo}>{buyerName}</BuyerName>
    } else {
      return (
        <BuyerNameLink
          onClick={() => localStorage.setItem(LS_CONTSTANTS.selectedAgentId, agentId)}
          to={buildBuyerLink({ buyerId, profileId })}
        >
          <BuyerName hasCancelInfo={hasCancelInfo}>{buyerName}</BuyerName>
        </BuyerNameLink>
      )
    }
  }

  return (
    <VisitCardContainer hasConflict={hasConflict} className={className}>
      <SubContainer>
        <Header hasCancelInfo={hasCancelInfo}>
          <StyledRow hasConflict={hasConflict}>
            <Typography.Caption5>{getDateString({ fromDateTime, toDateTime })}</Typography.Caption5>
            {hasConflict && (
              <ConflictIcon>
                <Icon
                  icon="propertydetails"
                  size="1.5rem"
                  color="originalBlue"
                  clickable
                  onClick={() => conflictHandler({ fromDateTime })}
                />
              </ConflictIcon>
            )}
          </StyledRow>
          <Typography.Caption5>ID: {id}</Typography.Caption5>
        </Header>
        <Body>
          <InfoIcon>
            <Icon icon="house" size="2rem" color="darkGrey" />
          </InfoIcon>
          <InfoContainer>
            <ViewingTitle hasCancelInfo={hasCancelInfo}>{title}</ViewingTitle>
          </InfoContainer>
          <InfoIcon>
            <Icon icon="viewing-location" size="1.6rem" color="darkGrey" />
          </InfoIcon>
          <InfoContainer>
            <Address hasCancelInfo={hasCancelInfo}>{address}</Address>
          </InfoContainer>
          <InfoIcon>
            <Icon icon="avatar" size="2rem" color="darkGrey" />
          </InfoIcon>
          <InfoContainer>
            <Buyer hasCancelInfo={hasCancelInfo}>Buyer:</Buyer>
            {loadingBuyer ? (
              <BuyerLoader>
                <Loader skeleton color={theme.color.originalBlue} />
              </BuyerLoader>
            ) : (
              renderLink(hasCancelInfo)
            )}
          </InfoContainer>
        </Body>
        <Footer>
          <ViewDetailsButton onClick={viewDetails}>
            {loadingViewDetails ? (
              <ViewDetailsLoader>
                <Loader skeleton color={theme.color.originalBlue} />
              </ViewDetailsLoader>
            ) : (
              <>
                <Icon icon="propertydetails" size="1.5rem" color="originalBlue" />
                <Typography.Link>View details</Typography.Link>
              </>
            )}
          </ViewDetailsButton>
          <ViewingStatus status={status} onClick={confirmView} isLoading={confirming} />
        </Footer>
      </SubContainer>
    </VisitCardContainer>
  )
}

export default AgentVisitCard
