import { format } from 'date-fns'

export const initialState = (
  timeSlotDetails: Array<T>,
  chosenDay: string,
  timeSlots: Array<T>,
  selectedSlot: Date,
): Array => {
  return {
    days: timeSlotDetails
      .map((item) => {
        return {
          dayName: format(new Date(+item.dayOfWeek), 'ccc'),
          dayNumber: format(new Date(+item.dayOfWeek), 'dd'),
          date: new Date(item.dayOfWeek),
          today: timeSlotDetails.indexOf(item) === 0,
        }
      })
      .sort((a, b) => a.date - b.date),
    currentDay: chosenDay,
    currentTimeSlots: timeSlots.map((slot) => {
      return {
        ...slot,
        active: slot.id === selectedSlot,
      }
    }),
    timeSlot: selectedSlot,
  }
}
