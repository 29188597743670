import React from 'react'
import { format } from 'date-fns'
import { Typography } from '@atoms'
import { SelectPeriodButtons } from '@molecules'
import { StyledRow } from './PeriodByButton.style'
import { PeriodByButtonProps } from '@constants/types/organisms'

const PeriodByButton: React.FC<PeriodByButtonProps> = ({
  slot,
  id,
  addFromTime,
  minusFromTime,
  addUntilTime,
  minusUtilTime,
  allHours,
  disabledminusFromTime,
  disabledaddFromTime,
  disabledminusUtilTime,
  disabledaddUntilTime,
}) => {
  return (
    <StyledRow>
      <SelectPeriodButtons
        disabledminusTime={disabledminusFromTime}
        disabledaddTime={disabledaddFromTime}
        minusTime={minusFromTime}
        addTime={addFromTime}
        id={id}
        allHours={allHours}
        selectTime={format(new Date(slot.fromDateTime), 'H:mm')}
      />
      <Typography.Body2>—</Typography.Body2>
      <SelectPeriodButtons
        minusTime={minusUtilTime}
        addTime={addUntilTime}
        id={id}
        selectTime={format(new Date(slot.toDateTime), 'H:mm')}
        allHours={allHours}
        disabledminusTime={disabledminusUtilTime}
        disabledaddTime={disabledaddUntilTime}
      />
    </StyledRow>
  )
}

export default PeriodByButton
