import React from 'react'
import { format } from 'date-fns'
import { useMobile } from '@utils/hooks'
import { dateToDayName } from '@utils/functions'
import { TitleContainer, Title } from './BookingDayTitle.style'

const BookingDayTitle = ({ date }: { date: Date }): JSX.Element => {
  const isMobile = useMobile()
  const dayName = dateToDayName(date)
  const disabled = !!dayName.length

  return (
    <TitleContainer isMobile={isMobile}>
      <Title as="span" empty={!disabled} isMobile={isMobile}>
        {dayName}
      </Title>
      <Title as="span" disabled={disabled} isMobile={isMobile}>
        {format(date, 'd LLLL y')}
      </Title>
    </TitleContainer>
  )
}

export default BookingDayTitle
