import styled from 'styled-components'
import { getColorByStatus, getLightColorByStatus } from '@utils/styles'
import Typography from '../Typography'

export const Container = styled.div<{ isPropertyCard: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${(p) => {
    if (p.isPropertyCard && p.isTabletPortrait && p.isListView) {
      return 0
    }
    if (!!p.radius) {
      return p.radius
    }
    return '10rem'
  }};
  ${(p) => p.isPropertyCard && p.isTabletPortrait && 'border-top-left-radius: ' + p.radius};
  width: ${(p) => (p.isPropertyCard && p.isTabletPortrait ? '100%' : '11rem')};
  border: 0.1rem solid ${(p) => getColorByStatus(p.status, p.theme)};
  background-color: ${(p) =>
    p.isPropertyCard
      ? getColorByStatus(p.status, p.theme)
      : getLightColorByStatus(p.status, p.theme)};
  ${(p) => p.shadow && 'box-shadow: 0 0.4rem 1rem ' + p.theme.color.black_20}
`

export const Text = styled(Typography.Body2)<{ isPropertyCard: boolean }>`
  color: ${(p) => (p.isPropertyCard ? p.theme.color.white : getColorByStatus(p.status, p.theme))};
`
