/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { initializeApp, FirebaseApp } from 'firebase/app'
import 'firebase/performance'
import 'firebase/auth'
import 'firebase/firestore'

import firebaseConfig from './firebaseConfig'

export const firebaseInitialization = (): FirebaseApp => {
  initializeApp(firebaseConfig)
}
