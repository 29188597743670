import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${(p) => (p.border ? `border-left: 0.1rem solid ${p.theme.color.lightGrey};` : '')}
  ${(p) => (p.border ? `border-right: 0.1rem solid ${p.theme.color.lightGrey};` : '')}

  border-bottom: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  padding: ${(p) => p.theme.indent.MD}
    ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
`

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => (p.isMobile ? '4.4rem' : '6.4rem')};
  height: ${(p) => (p.isMobile ? '2.4rem' : '4.4rem')};
  border-radius: ${(p) => (p.isMobile ? p.theme.borderRadius.LG : p.theme.borderRadius.XXL)};
  background-color: ${(p) => p.theme.color.originalBlue};
  border: none;
  cursor: pointer;
`

export const SlidesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL)};
`

export const Slide = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${(p) => (p.isMobile ? '2.5rem' : '6.2rem')};
  height: ${(p) => (p.isMobile ? '0.4rem' : '1rem')};
  margin: 0 ${(p) => (p.isMobile ? p.theme.indent.XXS : p.theme.indent.SM)};
  border-radius: ${(p) => p.theme.borderRadius.SM};
  background-color: ${(p) => (p.active ? p.theme.color.originalBlue : p.theme.color.lightGrey)};
  border: none;
  cursor: pointer;
`
