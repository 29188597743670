import styled from 'styled-components'
import { Typography } from '@atoms'

const getWidth = (p) => {
  if (p.isTabletPortrait || p.isTabletLandscape) {
    return 'width: 70.4rem;'
  }
  if (p.isMobile) {
    return 'width: 100%;'
  }
  if (p.isDesktop) {
    return 'max-width: 126.6rem;'
  }
  return null
}

export const Container = styled.div<{
  isMobile: boolean
  isMobileLandscape: boolean
  isDesktop: boolean
  isTabletLandscape: boolean
  isTabletPortrait: boolean
}>`
  ${getWidth}
  ${({ isMobile, isMobileLandscape, theme }) => ({
    backgroundColor: theme.color.white,
    border: `0.1rem solid ${theme.color.lightGrey}`,
    borderRadius: theme.borderRadius.SM,
    margin: '6.4rem 5rem',
    ...(isMobile && {
      overflow: 'scroll',
      height: '100%',
      margin: 0,
    }),
    ...(isMobileLandscape && {
      overflowY: 'scroll',
      margin: 0,
    }),
  })};
`
export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const LinkTo = styled(Typography.Link)<{ noUnderline: boolean; isMobile: boolean }>`
  text-decoration: ${(p) => (p.noUnderline ? 'none' : 'underline')};
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Cell = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
