import { getFirestore, doc, setDoc } from 'firebase/firestore'
import { SortListTypes } from '@constants/types'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { FS_COL_NAMES, FS_COL_GROUP_NAMES } from '@constants/dictionary/firebase'

export const setToShortList = async (
  propertyProfileId: string,
  payload: SortListTypes,
): { error: string } => {
  const db = getFirestore()
  const buyerId = localStorage.getItem(LS_CONTSTANTS.uid)

  const user = doc(db, FS_COL_NAMES.USERS, buyerId)

  const shortPropertyRef = doc(user, FS_COL_GROUP_NAMES.SHORT_LISTED_PROPERTIES, propertyProfileId)
  await setDoc(shortPropertyRef, payload)
}
