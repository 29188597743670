import { format } from 'date-fns'
import { UserType } from '@constants/types'

export const refactoredData = (data: Array<UserType>): Array<UserType> => {
  return data.map((item) => {
    return {
      ...item,
      startDate: format(item.createdAt, 'dd/MM/yy'),
      lastActivity: item.lastActivity
        ? format(item.lastActivity.toDate(), 'dd/MM/yy')
        : format(item.createdAt, 'dd/MM/yy'),
    }
  })
}
