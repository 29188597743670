/* eslint-disable no-magic-numbers */
import React, { useCallback, useState, forwardRef, useEffect } from 'react'
import { Typography } from '@atoms'
import {
  InputContainer,
  InputLabel,
  InputBase,
  InputAdornment,
  ErrorMessage,
  InputBaseContainer,
  PhoneCode,
  OptionContainer,
  OptionWrapper,
} from './Input.style'
import {
  deleteNumber,
  withoutSpace,
  phoneForm,
  firstLetterUppercase,
  onlyLatin,
} from '@constants/regExp'
import { loadAddressOptions } from '@utils/functions'
import { customFormatValue } from '@utils/functions'
import { phoneCode, REQUEST_TIME_DELAY } from '@constants/dictionary'
import _ from 'lodash'
import { InputTypes } from '@constants/types'

// eslint-disable-next-line react/display-name
const Input = forwardRef((props: InputTypes, ref) => {
  const {
    value,
    type,
    name,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    testID,
    icon: InputIcon,
    isLeftIcon = false,
    maxLength,
    error,
    disabled,
    errorMessage,
    isPassword,
    isTabNavigation,
    inputLabel = '',
    isNoSpace,
    isPhone,
    isSearchAddress = false,
    isPrice,
    isName = false,
    isPropertyTitle = false,
    onSelected,
    tabIndex,
  } = props
  const [focused, setFocused] = useState(false)
  const [inputData, setInputData] = useState(value)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState()
  const showPhoneCode = isPhone && (focused || inputData)
  const CorrectValue = (
    value: string | number = '',
    isNoSpace?: boolean,
    isPhone?: boolean,
    isPrice?: boolean,
    isName?: boolean,
    isPropertyTitle?: boolean,
  ): string => {
    const valueToString = String(value)
    if (isNoSpace) return customFormatValue(valueToString, withoutSpace, '')
    if (isPhone)
      return _.trimEnd(
        customFormatValue(customFormatValue(valueToString, deleteNumber, ''), phoneForm, '$1 $2'),
      )
    if (isPrice && valueToString.length > 10) {
      if (valueToString.startsWith('0')) {
        return valueToString.slice(1, 11)
      }
      return valueToString.slice(0, 10)
    }
    if (isName) {
      return valueToString.replace(firstLetterUppercase, (c: string) => c.toUpperCase())
    }
    if (isPropertyTitle) {
      return customFormatValue(
        valueToString,
        onlyLatin,
        valueToString.charAt(0).toUpperCase() + valueToString.slice(1),
      )
    }

    return valueToString
  }
  const handleClickOption = useCallback((option) => setInputData(CorrectValue(option.address)), [])
  const loadAddresses = async (value): Promise<T> => {
    await loadAddressOptions(value).then((option) => {
      if (option.suggestions) {
        return setOptions(option.suggestions)
      } else {
        return setOptions([{ address: 'Not found' }])
      }
    })
  }

  useEffect(() => {
    if (onSelected) {
      onSelected(selected)
    }
  }, [onSelected, selected])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSearchAddress && !!value) {
        loadAddresses(value)
      }
    }, REQUEST_TIME_DELAY)
    setInputData(CorrectValue(value, isNoSpace, isPhone, isPrice, isName, isPropertyTitle))
    return () => clearTimeout(delayDebounceFn)
  }, [value, isNoSpace, isPhone, isPrice, isSearchAddress, isName, isPropertyTitle])

  const $onFocus = useCallback(
    (event) => {
      setFocused(true)
      if (onFocus) onFocus(event)
    },
    [onFocus],
  )
  const $onBlur = useCallback(
    (event) => {
      setFocused(false)
      if (onBlur) onBlur(event)
    },
    [onBlur],
  )
  const $onInput = useCallback(
    (event) => {
      setInputData(CorrectValue(event.target.value, isNoSpace, isPhone))
    },
    [isNoSpace, isPhone],
  )

  // firefox input type=number workaround
  const $onKeyPress = useCallback(
    (event) => {
      // check keycode to prevent non-numeric values in firefox input type=number
      if (type === 'number' && (event.which < 48 || event.which > 57)) {
        event.preventDefault()
      }
    },
    [type],
  )

  return (
    <>
      <InputContainer data-testid={`${testID}InputContainer`}>
        <InputLabel data-testid={inputLabel}>{inputLabel}</InputLabel>
        {error && <ErrorMessage data-testid={`${testID}ErrorMessage`}>{errorMessage}</ErrorMessage>}
        <InputBaseContainer
          disabled={disabled}
          focused={focused}
          testID={`${testID}InputBaseContainer`}
          error={error}
        >
          {InputIcon && isLeftIcon ? (
            <InputAdornment
              isSearchAddress={isSearchAddress}
              data-testid={`${testID}InputAdornment`}
            >
              {InputIcon}
            </InputAdornment>
          ) : null}
          {showPhoneCode && <PhoneCode>{phoneCode}</PhoneCode>}
          <InputBase
            disabled={disabled}
            placeholder={placeholder}
            ref={ref}
            name={name}
            error={error}
            value={inputData}
            type={type}
            focused={focused}
            onChange={onChange}
            onFocus={$onFocus}
            onBlur={$onBlur}
            onInput={$onInput}
            testID={`${testID}InputBase`}
            maxLength={maxLength}
            onKeyPress={$onKeyPress}
            autoComplete={isPassword ? 'new-password' : 'off'}
            isPassword={isPassword}
            tabIndex={isTabNavigation ? 0 : tabIndex || -1}
            showPhoneCode={showPhoneCode}
          />

          {InputIcon && !isLeftIcon ? (
            <InputAdornment data-testid={`${testID}InputAdornment`}>{InputIcon}</InputAdornment>
          ) : null}
        </InputBaseContainer>
        {!!options.length && !!value && (
          <OptionContainer>
            {options.map((option) => {
              return (
                <OptionWrapper
                  onClick={() => {
                    setSelected(option.id)

                    handleClickOption(option)
                    setOptions([])
                  }}
                  key={option.id}
                >
                  <Typography.Body3>{option.address}</Typography.Body3>
                </OptionWrapper>
              )
            })}
          </OptionContainer>
        )}
      </InputContainer>
    </>
  )
})

export default Input
