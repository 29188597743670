export enum ViewingTime {
  TODAY = 'TODAY',
  ALL_TIME = 'ALL_TIME',
}

export const VIEWING_TIME_FILTER: Record<
  ViewingTime,
  { id: string; title: string; value: ViewingTime }
> = {
  [ViewingTime.TODAY]: {
    id: 'today',
    title: 'Today',
    value: ViewingTime.TODAY,
  },
  [ViewingTime.ALL_TIME]: {
    id: 'allTime',
    title: 'All time',
    value: ViewingTime.ALL_TIME,
  },
}
