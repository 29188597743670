import React, { forwardRef } from 'react'
import { useTheme } from 'styled-components'
import { Icon, Typography } from '@atoms'
import { LabelWrapper, StyledInput } from './SellerAddedLabel.style'
import { SellerAddedLabelTypes } from '@constants/types/molecules'

const SellerAddedLabel = forwardRef((props: SellerAddedLabelTypes, ref) => {
  const { sellerName, onClick, onChange, value, disabled, isEditProperty } = props
  const { color } = useTheme()
  return (
    <LabelWrapper>
      <StyledInput disabled value={value} onChange={onChange} ref={ref} />
      <Typography.Caption2 active>{sellerName}</Typography.Caption2>
      {!disabled && !isEditProperty && (
        <Icon icon="close" color={color.originalBlue} clickable onClick={onClick} />
      )}
    </LabelWrapper>
  )
})

export default SellerAddedLabel
