import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Container = styled.div<{ isMobile: boolean }>`
  padding-left: ${({ theme, isMobile }) => (isMobile ? theme.indent.LG : theme.indent.XXXL)};
  padding-right: ${({ theme, isMobile }) => (isMobile ? theme.indent.LG : theme.indent.XXXL)};
  padding-bottom: ${({ theme }) => theme.indent.XXXL};
`
