import React, { useCallback } from 'react'

import GlobalFilter from '../GlobalFilter'
import { Button } from '@molecules'
import { useMobile } from '@utils/hooks'

import { TitleContainer, ButtonContainer, SearchContainer } from './AgentUsersTitle.style'
import { TitleTypes } from '@constants/types'

type AgentUsersTitleProps = Pick<
  TitleTypes,
  'setAddUser' | 'globalFilter' | 'setGlobalFilter' | 'user'
>

const AgentUsersTitle: React.FC<AgentUsersTitleProps> = ({
  setAddUser,
  globalFilter,
  setGlobalFilter,
  user,
}) => {
  const isMobile = useMobile()

  const clickButton = useCallback(() => {
    setAddUser && setAddUser()
  }, [setAddUser])

  return (
    <TitleContainer isMobile={isMobile}>
      <SearchContainer isMobile={isMobile}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder={`Search ${user[0].toLowerCase() + user.slice(1)}`}
        />
      </SearchContainer>
      <ButtonContainer isMobile={isMobile}>
        <Button onClick={clickButton} label={`Add ${user}`} />
      </ButtonContainer>
    </TitleContainer>
  )
}

export default AgentUsersTitle
