import React, { useCallback } from 'react'
import { Switch, Route, useLocation, useParams, useHistory } from 'react-router-dom'
import { useDesktop, useMobile, useTabletPortrait } from '@utils/hooks'
import {
  LabelContainer,
  Label,
  Container,
  Title,
  H1,
  HeaderContainer,
  LabelText,
  H1Container,
} from './AgentTabs.style'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { Icon } from '@atoms'
import { ROUTES } from '@constants/dictionary'
import { scrollVisibilityApiType, TabsTypes } from '@constants/types/organisms'

const AgentTabs: React.FC<TabsTypes> = ({
  config,
  baseRoute,
  dynamicUrl,
  isPropertyTab,
  userTab,
}): JSX.Element => {
  const { pathname } = useLocation()
  const isMobile = useMobile()
  const history = useHistory()
  const isDesktop = useDesktop()
  const isTabletPortrait = useTabletPortrait()
  const { id: ID }: { id: string } = useParams()
  const indexTab =
    config.findIndex(
      (tab) =>
        pathname === (dynamicUrl ? `${baseRoute}/${ID}${tab.path}` : `${baseRoute}${tab.path}`),
    ) || 0
  const clickBack = useCallback(() => {
    history.push({
      pathname: userTab === 'Buyer' ? ROUTES.AGENT_BUYERS : ROUTES.AGENT_SELLERS,
    })
  }, [history, userTab])

  const TabLabel = ({
    name,
    active,
    path,
  }: {
    name: string
    active: boolean
    path: string
  }): JSX.Element => {
    return (
      <Container isMobile={isMobile} $isPropertyTab={isPropertyTab} $active={active}>
        <Label
          $isPropertyTab={isPropertyTab}
          $active={active}
          to={dynamicUrl ? `${baseRoute}/${ID}${path}` : `${baseRoute}${path}`}
          key={path}
        >
          <LabelText isMobile={isMobile} $active={active} $isPropertyTab={isPropertyTab}>
            {name}
          </LabelText>
        </Label>
      </Container>
    )
  }
  // this function get from https://www.npmjs.com/package/react-horizontal-scrolling-menu
  function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const MAX_DELTA_Y = 15
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < MAX_DELTA_Y

    if (isThouchpad) {
      ev.stopPropagation()
      return
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext()
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev()
    }
  }
  return (
    <>
      <LabelContainer isMobile={isMobile} isTabletPortrait={isTabletPortrait} userTab={userTab}>
        {userTab && (
          <HeaderContainer isMobile={isMobile} isDesktop={isDesktop} isTablet={isTabletPortrait}>
            <Title isMobile={isMobile} onClick={clickBack}>
              <Icon icon="move_back" clickable size="2rem" color="originalBlue" />
              <H1Container>
                <H1 isMobile={isMobile}>{userTab} Information</H1>
              </H1Container>
            </Title>
          </HeaderContainer>
        )}
        <ScrollMenu LeftArrow={null} RightArrow={null} onWheel={onWheel}>
          {config.map(({ id, name, path }) => {
            const active =
              pathname === (dynamicUrl ? `${baseRoute}/${ID}${path}` : `${baseRoute}${path}`)
            return <TabLabel key={id} name={name} active={active} path={path} />
          })}
        </ScrollMenu>
      </LabelContainer>
      <Switch>
        <Route
          path={dynamicUrl ? `${baseRoute}/:id/:tab` : `${baseRoute}/:tab`}
          component={config[indexTab]?.component}
        />
      </Switch>
    </>
  )
}

export default AgentTabs
