import React from 'react'
import { Typography, Icon } from '@atoms'
import { useTheme } from 'styled-components'
import {
  RadioButtonLabel,
  RadioButtonContainer,
  TextWrapper,
  StyledBody2,
} from './RadioButton.style'

import { RadioButtonTypes } from '@constants/types/molecules'

const RadioButton: React.FC<RadioButtonTypes> = ({
  onChange,
  RadioButtonItems,
  selected,
  isMobile,
  isEditRadioButton,
}) => {
  const theme = useTheme()
  return (
    <>
      {RadioButtonItems.map((item) => (
        <React.Fragment key={item.label}>
          <RadioButtonLabel onClick={() => onChange(item.value)}>
            <RadioButtonContainer selected={item.value === selected}>
              {item.value === selected && (
                <Icon icon="check" color={theme.color.white} size={theme.indent.SM} clickable />
              )}
            </RadioButtonContainer>
            <Typography.CheckboxText>{item.label}</Typography.CheckboxText>
          </RadioButtonLabel>
          {!isEditRadioButton && item.value && (
            <TextWrapper isMobile={isMobile}>
              <StyledBody2 isMobile={isMobile} disabled>
                {item.message}
              </StyledBody2>
            </TextWrapper>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default RadioButton
