import { ROUTES, LS_CONTSTANTS } from '@constants/dictionary'

export const getRedirectLinkBuyer = (pathname: string): string => {
  switch (pathname) {
    case ROUTES.BUYER:
      return ROUTES.BUYER_VISITS
    case ROUTES.BUYER_BOOKING:
      return ROUTES.BUYER_VISITS
    case ROUTES.BUYER_SURVEY:
      return ROUTES.BUYER_VISITS
    default:
      return ROUTES.ERROR
  }
}

export const getRedirectLinkSeller = (pathname: string): string => {
  switch (pathname) {
    case ROUTES.SELLER:
      return ROUTES.SELLER_SLOTS
    default:
      return ROUTES.ERROR
  }
}

export const getRedirectLinkAgent = (pathname: string): string => {
  switch (pathname) {
    case ROUTES.AGENT:
      return ROUTES.AGENT_PROPERTIES
    case ROUTES.AGENT_LOGIN:
      return ROUTES.AGENT_LOGIN
    default:
      return ROUTES.ERROR
  }
}

type SetAdditionalPropsTypes = {
  agentId: string
  propertyId: string
  remove: boolean
  visitId: string
}

export const setAdditionalProps = ({
  agentId = '',
  propertyId = '',
  visitId = '',
  remove = false,
}: SetAdditionalPropsTypes): undefined => {
  if (remove) {
    window.localStorage.clear()
    return
  }
  if (agentId.length) {
    localStorage.setItem(LS_CONTSTANTS.agentId, agentId)
  }
  if (propertyId.length) {
    localStorage.setItem(LS_CONTSTANTS.propertyId, propertyId)
  }
  if (visitId.length) {
    localStorage.setItem(LS_CONTSTANTS.visitId, visitId)
  }
}
