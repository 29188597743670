import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const ContainerList = styled.div`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.SM} ${p.theme.indent.LG}`
      : `${p.theme.indent.SM} ${p.theme.indent.XXXL}`};
  margin: ${(p) => (p.isMobile ? `0 -${p.theme.indent.XS}` : `0 -${p.theme.indent.SM}`)};
`
export const StyledCol1 = styled(Col)`
  padding: 0 ${(p) => p.theme.indent.XS} 0 0;
`
export const StyledCol2 = styled(Col)`
  padding: 0 0 0 ${(p) => p.theme.indent.XS};
`

export const ButtonContainer = styled(Row)<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XXXL} ${p.theme.indent.LG} ${p.theme.indent.XXXS}`
      : `${p.theme.indent.SM} ${p.theme.indent.XXXL}`};
  margin: 0;
`
export const BottomContainer = styled.div<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.LG} ${p.theme.indent.LG} ${p.theme.indent.XXXS}`
      : `${p.theme.indent.SM} ${p.theme.indent.XXXL}`};
`

export const BottomTitle = styled(Typography.Body2)`
  padding-bottom: ${(p) => p.theme.indent.SM};
`
export const TopTitle = styled(Typography.H1)`
  padding: ${(p) => `${p.theme.indent.MD} ${p.theme.indent.LG} ${p.theme.indent.SM}`};
`
export const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.isMobile ? '' : `${p.theme.indent.XS} 0 ${p.theme.indent.LG}`)};
`
export const DayPickRowContainer = styled.div<{ isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? '' : `${p.theme.indent.MD} ${p.theme.indent.XXL} 0 `)};
`
export const H1 = styled(Typography.H1)<{ isMobile: boolean }>`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.MD} ${p.theme.indent.LG} ${p.theme.indent.SM}`
      : `${p.theme.indent.XL} ${p.theme.indent.XXL} ${p.theme.indent.XS}`};
`
