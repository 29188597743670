import { collection, getFirestore, doc, query, where } from 'firebase/firestore'
import { LS_CONTSTANTS, USER_TYPES } from '@constants/dictionary'
import { FS_COL_NAMES, FS_COL_GROUP_NAMES, FS_Q_PARAMS } from '@constants/dictionary/firebase'

export const getUserProfile = (
  userId: string,
  agentId: string,
  userTypeProp: string,
): { error: string } => {
  const db = getFirestore()

  const user = doc(db, FS_COL_NAMES.USERS, userId)
  const colUser = collection(user, FS_COL_GROUP_NAMES.USER_PROFILES)
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const prop = userType === USER_TYPES.agency ? FS_Q_PARAMS.AGENCY_ID : FS_Q_PARAMS.CREATED_BY
  const userProfile = query(
    colUser,
    where(prop, '==', agentId),
    where(FS_Q_PARAMS.USER_TYPE, '==', userTypeProp),
  )

  return userProfile
}
export const getUserStatistic = (buyerId: string, id: string): { error: string } => {
  const db = getFirestore()
  const user = doc(db, FS_COL_NAMES.USERS, buyerId)
  const colUser = doc(user, FS_COL_GROUP_NAMES.USER_PROFILES, id)
  const userProfilesStatistics = collection(colUser, FS_COL_GROUP_NAMES.USER_PROFILES_STATISTICS)
  const docStatistic = doc(userProfilesStatistics, FS_COL_GROUP_NAMES.VIEWING_PERFORMANCE)
  return docStatistic
}
export const getBuyerStatistic = (buyerId: string, id: string): { error: string } => {
  const db = getFirestore()
  const user = doc(db, FS_COL_NAMES.USERS, buyerId)
  const colUser = doc(user, FS_COL_GROUP_NAMES.USER_PROFILES, id)
  const userProfilesStatistics = collection(colUser, FS_COL_GROUP_NAMES.USER_PROFILES_STATISTICS)
  const docStatistic = doc(userProfilesStatistics, FS_COL_GROUP_NAMES.VIEWING_PERFORMANCE)
  return docStatistic
}
