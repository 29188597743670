import React from 'react'
import { createPortal } from 'react-dom'

import { ModalTypes } from '@constants/types/helpers'

const modalRoot = document.createElement('div')
modalRoot.setAttribute('id', 'modal-root')
document.querySelector('body')?.appendChild(modalRoot)
const Modal: React.FC<ModalTypes> = ({ children, isShown }) =>
  isShown ? createPortal(children, modalRoot) : null

export default Modal
