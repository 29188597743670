import { CANCEL_VISIT } from '@constants/dictionary'

export const RadioButtonItems = [
  {
    label: CANCEL_VISIT.UNDER_OFFER,
    value: CANCEL_VISIT.UNDER_OFFER,
    message: CANCEL_VISIT.UNDER_OFFER_MESSAGE,
  },
  {
    label: CANCEL_VISIT.URGENT_MATTERS,
    value: CANCEL_VISIT.URGENT_MATTERS,
    message: CANCEL_VISIT.URGENT_MATTERS_MESSAGE,
  },
]
