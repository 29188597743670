/* stylelint-disable property-no-vendor-prefix */
import { Typography } from '@atoms'
import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  padding: 0;
  min-width: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.indent.LG};
`

export const InputLabel = styled(Typography.Body3)`
  transition: all 0.2s ease-out;
  padding-bottom: ${({ theme }) => theme.indent.XXS};
`

export const InputBase = styled.input<{ showPhoneCode: boolean }>`
  border: none;
  outline: none;
  padding: ${({ theme, showPhoneCode }) => `1rem ${showPhoneCode ? '4.6rem' : theme.indent.MD}`};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  width: 100%;
  resize: none;
  line-height: ${(p) => p.theme.lineHeight.SM};
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};
  color: ${({ theme }) => theme.color.darkBlack};

  :focus {
    outline: 0;
    border-color: ${({ theme }) => theme.color.originalBlue};

    ::placeholder {
      color: transparent;
    }
  }

  ${({ disabled, theme }) =>
    disabled &&
    `pointer-events: none;
    background-color: ${theme.color.greyBlue};`};

  ::placeholder {
    color: ${({ theme }) => theme.color.grey};
    font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
    font-size: ${(p) => p.theme.fontSize.XS};
    line-height: ${(p) => p.theme.lineHeight.SM};
    font-weight: normal;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const InputAdornment = styled.div<{ isSearchAddress: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-right: ${({ theme, isSearchAddress }) =>
    isSearchAddress ? theme.indent.XXS : theme.indent.MD};
  ${(p) =>
    p.isSearchAddress &&
    `padding-left: ${p.theme.indent.XS}; border-radius: ${p.theme.indent.XXS};
    height: 100%; background-color: ${p.theme.color.white};`}
`

export const ErrorMessage = styled(Typography.Body3)`
  transition: all 0.2s ease-out;
  padding-bottom: ${({ theme }) => theme.indent.XXS};
  color: ${({ theme }) => theme.color.red};
`
export const InputBaseContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  transition: border-color 0.2s linear;
  border: 0.1rem solid;
  border-radius: ${({ theme }) => theme.indent.XXS};
  border-color: ${(p) => {
    if (p.error) return p.theme.color.red
    if (p.focused && !p.disabled) return p.theme.color.originalBlue
    return p.theme.color.lightGrey
  }};

  ${({ disabled, theme }) =>
    disabled &&
    `pointer-events: none;
    background-color: ${theme.color.greyBlue};

  `};
`

export const PhoneCode = styled(Typography.Body3)`
  padding-left: ${({ theme }) => theme.indent.MD};
  position: absolute;
`

export const OptionContainer = styled.div`
  bottom: 0;
  background-color: white;
  width: 100%;
  border-bottom-right-radius: ${({ theme }) => theme.indent.XXS};
  border-bottom-left-radius: ${({ theme }) => theme.indent.XXS};
  border: 0.1rem solid ${(p) => p.theme.color.greyBlue};
  border-top: none;
  padding: ${({ theme }) => theme.indent.MD};
`
export const OptionWrapper = styled.div`
  &:hover {
    background-color: ${(p) => p.theme.color.lightGrey};
    cursor: pointer;
  }
`
