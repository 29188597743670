import React, { useState, useEffect, useCallback } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { updateUserData, getUserByIdDoc } from '@services/firebase'
import { LS_CONTSTANTS, ROUTES, USER_TYPES } from '@constants/dictionary'
import { Button, Checkbox } from '@molecules'
import { Typography } from '@atoms'
import { useMobile, useMobileLandscape } from '@utils/hooks'
import BaseModal from '../BaseModal'
import imgSrc from '@assets/images/PrivacyPolicyModal.svg'
import { Container, Img, Body2, CheckContainer } from './PrivacyPolicyModal.style'
import CreateStartDataModal from './CreateStartDataModal'

type PrivacyPolicyProps = {
  setIsPolicy: () => void
  policy: unknown
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyProps> = ({ setIsPolicy = () => {}, policy }) => {
  const isMobile = useMobile()
  const isMobileLandscape = useMobileLandscape()
  const id = localStorage.getItem(LS_CONTSTANTS.uid) || ''
  const userType = localStorage.getItem(LS_CONTSTANTS.userType) || ''

  const userDocRef = getUserByIdDoc(id)
  const [userValue, loading] = useDocument(userDocRef)
  const userData = loading ? undefined : userValue?.data()

  const [accept, setAccept] = useState(true)
  const [isConfirming, setIsConfirming] = useState(false)

  const confirm = useCallback(async (): (() => void) => {
    setIsConfirming(true)
    await updateUserData({ isPrivacyPolicyApproved: true }, id)
    document.location.reload()
  }, [id])

  const isAdmin = userType === USER_TYPES.agent || userType === USER_TYPES.agency

  useEffect(() => {
    const profileImgUrl = localStorage.getItem(LS_CONTSTANTS.profileImgUrl)
    if (!profileImgUrl && userData?.profilePictureImageUrl && isAdmin) {
      localStorage.setItem(LS_CONTSTANTS.profileImgUrl, userData.profilePictureImageUrl)
    }
    if (!!userData?.isPrivacyPolicyApproved && !policy) {
      setIsPolicy(true)
    }
  }, [isAdmin, policy, setIsPolicy, userData])

  return (
    !loading && (
      <>
        <BaseModal isShown={!userData?.isPrivacyPolicyApproved || isConfirming}>
          <Container isMobileLandscape={isMobileLandscape} isMobile={isMobile}>
            <Typography.H2 textCenter>Welcome to ViewD</Typography.H2>
            <Body2 textCenter>
              {'We appreciate your choice and hope \n your experience with us will be great.'}
            </Body2>
            <Img src={imgSrc} />
            <CheckContainer>
              <Checkbox value={accept} onChange={() => setAccept(!accept)} fill={1} size={2} />
              <Typography.Body3 noUnderline>{' I accept terms of '}</Typography.Body3>{' '}
              <Typography.Link isMobile noUnderline target="_blank" href={ROUTES.PRIVACY_POLICY}>
                Privacy Policy
              </Typography.Link>
            </CheckContainer>
            <Button.Primary
              disabled={!accept || isConfirming}
              label="Continue"
              onClick={confirm}
              loader={isConfirming}
            />
          </Container>
        </BaseModal>
        <CreateStartDataModal
          isShown={
            isAdmin &&
            userData?.isPrivacyPolicyApproved &&
            !userData?.name &&
            !userData?.phoneNumber
          }
          id={id}
        />
      </>
    )
  )
}

export default PrivacyPolicyModal
