import styled from 'styled-components'

export const Overlay = styled.div<{ isNotification: boolean }>`
  background: ${(p) => (p.isNotification ? 'none' : p.theme.color.black_50)};
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`

export const ModalContainer = styled.div<{
  isMobile: boolean
  isMobileLandscape: boolean
  marginNone: boolean
}>`
  display: flex;
  ${({ isMobileLandscape }) => !isMobileLandscape && 'align-items: center'};
  justify-content: center;
  margin: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : 'auto')};
  ${(p) => (p.marginNone && p.isMobile ? 'margin: 0;' : null)}
  height: 100%;
`
