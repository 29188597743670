import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div`
  min-height: calc(100vh - 6.4rem * 2);
`

export const Header = styled(Typography.Title)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6.3rem;
  padding: 0 ${(p) => (p.isMobile ? p.theme.indent.LG : '5rem')};
  border-bottom: 0.1rem solid ${(p) => p.theme.color.lightGrey};
`
