import styled from 'styled-components'

const textColorChaining = (p): string => {
  if (p.disabled) {
    return p.theme.color.grey
  }
  if (p.active) {
    return p.theme.color.originalBlue
  }
  return p.theme.color.darkBlack
}

const Typography = styled.p`
  ${(p) => p.uppercase && 'text-transform: uppercase;'}
  ${(p) => p.textCenter && 'text-align: center;'}

  text-decoration: ${(p) => p.textDecoration};
  font-style: normal;
  white-space: pre-line;
`

const H1 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansBold}, sans-serif;
  font-weight: bold;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.LG : p.theme.fontSize.XXL)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.LG : p.theme.lineHeight.XL)};
  color: ${(p) => p.theme.color.darkBlack};
  ${(p) => p.color && `color:  ${p.theme.color[p.color]}`};
`

const H2 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansBold}, sans-serif;
  font-weight: bold;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.SM : p.theme.fontSize.LG)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.MD : p.theme.lineHeight.LG)};
  color: ${(p) => (p.disabled ? p.theme.color.grey : p.theme.color.darkBlack)};
`

const H3 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansBold}, sans-serif;
  font-weight: bold;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.XS : p.theme.fontSize.SM)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.SM : p.theme.lineHeight.MD)};
  color: ${(p) => (p.disabled ? p.theme.color.grey : p.theme.color.darkBlack)};
  ${(p) => p.color && `color:  ${p.theme.color[p.color]}`};
`

const Title = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-weight: 600;
  font-size: ${(p) => p.theme.fontSize.MD};
  line-height: ${(p) => p.theme.lineHeight.MD};
  color: ${(p) => p.theme.color.black};
`

const TitleH1 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.quicksandBold}, sans-serif;
  font-weight: bold;
  font-size: ${({ isMobile, theme }) => (isMobile ? theme.fontSize.LG : theme.fontSize.XL)};
  line-height: ${({ theme, isMobile }) => (isMobile ? theme.fontSize.MD : theme.lineHeight.XL)};
  color: ${({ theme }) => theme.color.originalBlue};
`

const ButtonMobile = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansBold}, sans-serif;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};
  color: ${(p) => (p.disabled ? p.theme.color.grey : p.theme.color.darkBlack)};
`

const Body1 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.SM : p.theme.fontSize.LG)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.MD : p.theme.lineHeight.LG)};
  color: ${(p) => (p.disabled ? p.theme.color.grey : p.theme.color.darkBlack)};
`

const Body2 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.XS : p.theme.fontSize.SM)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.SM : p.theme.lineHeight.MD)};
  color: ${(p) => textColorChaining(p)};
  ${(p) => p.color && `color:  ${p.theme.color[p.color]}`};
`

const Body3 = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};
  color: ${(p) => (p.disabled ? p.theme.color.grey : p.theme.color.darkBlack)};
  ${(p) => p.color && `color:  ${p.theme.color[p.color]}`};
  ${(p) => p.underline && 'text-decoration:  underline;'};
`

const Caption1 = styled(Typography)<{ isMobile: boolean }>`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.SM : p.theme.fontSize.LG)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.MD : p.theme.lineHeight.LG)};
  color: ${(p) => (p.$active ? p.theme.color.originalBlue : p.theme.color.darkBlue)};
`
const Caption2 = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansBold}, sans-serif;
  font-weight: bold;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.XS : p.theme.fontSize.SM)};
  line-height: ${(p) => (p.isMobile ? p.theme.lineHeight.SM : p.theme.lineHeight.MD)};
  color: ${(p) => textColorChaining(p)};
  ${(p) => p.disabled && 'pointer-events: none'};
  cursor: ${(p) => (p.$clickable ? 'pointer' : 'auto')};
`

const Caption3 = styled(Typography)<{ isMobile: boolean; isTitle: boolean }>`
  font-family: ${(p) =>
      p.isTitle ? p.theme.fontFamily.quicksandBold : p.theme.fontFamily.openSansBold},
    sans-serif;
  font-weight: bold;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.LG : p.theme.fontSize.XL)};
  line-height: ${(p) => p.theme.lineHeight.XL};
  color: ${(p) => (p.active ? p.theme.color.originalBlue : p.theme.color.darkBlue)};
  ${(p) => p.color && `color:  ${p.theme.color[p.color]}`};
`

const Caption4 = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.SM};
  line-height: ${(p) => p.theme.lineHeight.MD};
  color: ${(p) => (p.$active ? p.theme.color.black : p.theme.color.darkGrey)};
`

const Caption5 = styled(Typography)`
  font-family: ${({ theme }) => theme.fontFamily.openSansSemiBold};
  font-weight: 600;
`

const Caption6 = styled(Typography)<{ textAlign: string; color: string }>`
  font-family: ${({ theme }) => theme.fontFamily.openSansBold};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.LG};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color, theme }) => (!!color ? color : theme.color.black)};
`

const Link = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.XS : p.theme.fontSize.SM)};
  line-height: ${(p) => p.theme.lineHeight.SM};
  color: ${(p) => (p.disabled ? p.theme.color.darkGrey : p.theme.color.originalBlue)};
  ${(p) => (p.noUnderline ? 'text-decoration: none' : '')};

  cursor: pointer;
`

const CheckboxText = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  padding-left: ${({ theme }) => theme.indent.SM};
  font-size: ${(p) => (p.isMobile ? p.theme.fontSize.XS : p.theme.fontSize.SM)};
  color: ${({ theme, disabled }) => (disabled ? theme.color.grey : theme.color.darkPurple)};
  ${(props) => props.disabled && 'pointer-events: none;'}
`

const CheckboxText1 = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansBold}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.SM};
  line-height: ${(p) => p.theme.lineHeight.MD};
  color: ${({ theme }) => theme.color.darkBlack};
`

const CheckboxText2 = styled(Typography)`
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.MD};
  color: ${({ theme }) => theme.color.darkBlack};
`

H1.defaultProps = {
  as: 'h1',
}

H2.defaultProps = {
  as: 'h2',
}

H3.defaultProps = {
  as: 'h3',
}

Link.defaultProps = {
  as: 'a',
}

Typography.H1 = H1
Typography.H2 = H2
Typography.H3 = H3
Typography.ButtonMobile = ButtonMobile
Typography.Title = Title
Typography.TitleH1 = TitleH1
Typography.Body1 = Body1
Typography.Body2 = Body2
Typography.Body3 = Body3
Typography.Caption1 = Caption1
Typography.Caption2 = Caption2
Typography.Caption3 = Caption3
Typography.Caption4 = Caption4
Typography.Caption5 = Caption5
Typography.Caption6 = Caption6
Typography.Link = Link
Typography.CheckboxText = CheckboxText
Typography.CheckboxText1 = CheckboxText1
Typography.CheckboxText2 = CheckboxText2

export default Typography
