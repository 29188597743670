import { Column } from 'react-table'

export enum TitleCases {
  AgentUsersTitle = 'AgentUsersTitle',
  AddModal = 'AddModal',
  VisitInfo = 'VisitInfo',
  AgentPropertyVisitInfoTitle = 'AgentPropertyVisitInfoTitle',
  AgencyListOfAgent = 'AgencyListOfAgent',
}

export type TableTypes = {
  columns: Column[]
  data: Array
  title?: string
  onConfirm?: () => void
  onCancel?: () => void
  defaultValue?: string
  dropdownOptions?: { id: string; value: string | number; title: string }[]
  isTableModal?: boolean
  isAddModal?: boolean
  selectedValue?: string
  callback?: (selected: string) => void
  user: string
  sortByDropDown?: { id: string; desc: boolean }[]
  typeUser: string
  setAddUser?: () => void
  bigRow?: boolean
  isTableVisit?: boolean
  defaultSorting?: Array<{ id: string; desc: boolean }>
  noItemHeight?: string
  addButtonDisabled?: boolean
  isListOfAgents?: boolean
}
export type PaginationTypes = {
  canNextPage: boolean
  canPreviousPage: boolean
  pageOptions: boolean
  gotoPage: (page: number) => void
  nextPage: () => void
  previousPage: () => void
  setPageSize: (size: number) => void
  state: { pageIndex: number; pageSize: number }
}
export type TableRowType<OriginalType> = {
  row: TableRowFilling<OriginalType>
}

export type TableRowFilling<OriginalType> = {
  original: OriginalType
}

export type GlobalFilterType = {
  globalFilter: string
  setGlobalFilter: (filterValue: string) => void
  placeholder: string
}

export type TitleTypes = {
  globalFilter: string
  setGlobalFilter: (filterValue: string) => void
  title?: TitleCases
  onConfirm?: () => void
  onCancel?: () => void
  dropdownOptions?: { id: string; value: string | number; title: string }[]
  defaultValue?: string
  selectedValue?: string
  callback?: (option: string | undefined) => void
  user: string
  setAddUser?: () => void
  setFilter: (tab: string) => void
  addButtonDisabled: () => void
}

export type TabsType = {
  allVisits: 'All visits'
  upcomingVisits: 'Upcoming visits'
  pastVisits: 'Past visits'
  cancelVisits: 'Cancelled visits'
}
