// email, if needs max symbols - add after ^ (?=.{1,30}$)
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const currencyAmountRegExp = new RegExp(/^(?!0{1,}(\.0*)?$)\d+(\.?\d{2,2})?$/)
export const withoutUnderscoreRegExp = new RegExp(/^[^_]*$/)
export const withoutUnderscoreAndNumberRegExp = new RegExp(/^[^0-9_]*$/)
export const withoutCyrillicRegExp = new RegExp(/^[^а-я]*$/i)
export const latinSpecialSymbolRegExp = new RegExp(/^[a-z-'‘’ ]*$/i)
export const withoutNumberRegExp = new RegExp(/^[^0-9]*$/)
export const passRegExp = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
export const withoutSpace = new RegExp(/\s/g)
export const onlyNumbers = new RegExp(/^[0-9]+$/)
export const phoneForm = new RegExp(/([\d]{4})([\d]*)/g)
export const onlyLatin = new RegExp(/^[A-Za-z ]+$/)
export const deleteNumber = new RegExp(/[^0-9+]/g)
export const firstLetterUppercase = new RegExp(/^(.)|\s+(.)/g)
