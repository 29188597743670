import React from 'react'
import { useHistory } from 'react-router'
import { ROUTES } from '@constants/dictionary'
import { signOutAgent } from '@services/firebase'
import { Icon } from '@atoms'
import { ToggleMenuContainer, StyledRow, Body2, HR } from './AgentHeader.style'

type ToggleMenuTypes = {
  toggleMenu?: () => void
}

const ToggleMenu: React.FC<ToggleMenuTypes> = ({ toggleMenu }) => {
  const history = useHistory()
  return (
    <ToggleMenuContainer onClick={(e) => e.stopPropagation()} onFocus={(e) => e.stopPropagation()}>
      <StyledRow
        onClick={() => {
          history.push(ROUTES.AGENT_SETTINGS)
          toggleMenu(false)
        }}
      >
        <Icon icon="settings" size="2.4rem" color="darkBlack" />
        <Body2>Profile Settings</Body2>
      </StyledRow>
      <HR />
      <StyledRow onClick={() => signOutAgent()}>
        <Icon icon="logout" size="2.4rem" color="darkBlack" />
        <Body2>Sign Out</Body2>
      </StyledRow>
    </ToggleMenuContainer>
  )
}

export default ToggleMenu
