import React from 'react'
import { ROUTES } from '@constants/dictionary'
import { AgentTabs } from '@organisms'
import { PrivacyPolicyModal } from '@modals'
import { tabsConfig } from './AgentHome.constants'

const AgentHome = (): JSX.Element => (
  <>
    <AgentTabs config={tabsConfig} baseRoute={ROUTES.AGENT} />
    <PrivacyPolicyModal />
  </>
)

export default AgentHome
