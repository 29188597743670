import styled, { css } from 'styled-components'
import { Typography } from '@atoms'
import { Link } from 'react-router-dom'

const CropText = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const TextColor = css<{ hasCancelInfo: boolean }>`
  color: ${({ theme, hasCancelInfo }) =>
    !hasCancelInfo ? theme.color.darkBlack : theme.color.darkGrey};
`

export const VisitCardContainer = styled.div<{ hasConflict: boolean }>`
  width: 100%;
  height: 21.8rem;
  border: 0.1rem solid
    ${({ theme, hasConflict }) => (hasConflict ? theme.color.red : theme.color.lightGrey)};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  background-color: ${({ theme }) => theme.color.white};
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.indent.SM} ${theme.indent.SM} 0`};
  font-size: ${({ theme }) => theme.fontSize.MD};
`

export const ViewDetailsButton = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.indent.XXS};
  justify-content: center;
  cursor: pointer;
`

export const Header = styled.div<{ hasCancelInfo: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3.8rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
  ${TextColor};
`

export const Body = styled.div`
  display: grid;
  grid-template-columns: 2.4rem auto;
  height: 12rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.lightGrey};
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.6rem;
`

export const StyledRow = styled.div<{ hasConflict: boolean }>`
  display: flex;
  ${({ theme, hasConflict }) => hasConflict && `color: ${theme.color.red}`};
`

export const ConflictIcon = styled.div`
  margin-left: ${({ theme }) => theme.indent.SM};
`

export const InfoIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 3.9rem;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.indent.XS};
  margin-left: 1.2rem;
  overflow: hidden;
`

export const ViewingTitle = styled(Typography.H3)<{ hasCancelInfo: boolean }>`
  ${TextColor};
  ${CropText};
`

export const Address = styled(Typography.Body2)<{ hasCancelInfo: boolean }>`
  ${TextColor};
  ${CropText};
`

export const Buyer = styled(Typography.Caption5)<{ hasCancelInfo: boolean }>`
  ${TextColor};
  font-size: ${({ theme }) => theme.fontSize.SM};
`

export const BuyerName = styled(Typography.Link)<{ hasCancelInfo: boolean }>`
  ${CropText};
  color: ${({ theme, hasCancelInfo }) =>
    !hasCancelInfo ? theme.color.originalBlue : theme.color.darkGrey};
  ${({ hasCancelInfo }) => hasCancelInfo && `cursor: default;`}
`

export const BuyerNameLink = styled(Link)`
  text-decoration: none;
`

export const ViewDetailsLoader = styled.div`
  margin-left: 4.2rem;
`

export const BuyerLoader = styled.div`
  margin-left: 2.2rem;
`
