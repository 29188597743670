import { differenceInCalendarDays, format } from 'date-fns'
import _ from 'lodash'

export const filterTimeSlots = (timeSlots: Array<object>, today: Date): Array<object> => {
  return timeSlots.length
    ? timeSlots.filter(
        (item) => format(item.dayOfWeek, 'd LLLL y') === format(today, 'd LLLL y'),
      )[0]?.timeSlots
    : []
}

const isSameDay = (a: Date, b: Date): boolean => {
  return differenceInCalendarDays(a, b) === 0
}

const disabledDates = (week: Date[], dayWithSlots: Date[]): Date[] => {
  return _.remove(week, (day) => {
    const isDisabled = dayWithSlots.find((d) => isSameDay(d, day))
    return !isDisabled
  })
}

export const tileDisabled = ({
  date,
  view,
  timeSlotDetails,
  week,
}: {
  date: Date
  view: string
  timeSlotDetails: []
  week: Date[]
}): Date | undefined => {
  if (view === 'month') {
    return disabledDates(
      week,
      timeSlotDetails.map((item) => item.dayOfWeek),
    ).find((dDate) => isSameDay(dDate, date))
  }
}
