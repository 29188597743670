import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`

export const StyledCol = styled(Col)`
  max-width: 76.8rem;
`
