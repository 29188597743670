import React, { useCallback } from 'react'
import { LS_CONTSTANTS } from '@constants/dictionary'
import { LinkTo } from './UsersTabContent.style'

const ViewProfileComponent = ({
  id,
  viewProfile,
  profileId,
  agentId,
}: {
  id: string
  profileId: string
  agentId: string
  viewProfile: (id: string) => void
}): JSX.Element => {
  const onClick = useCallback(() => {
    localStorage.setItem(LS_CONTSTANTS.selectedAgentId, agentId)
    viewProfile(id, profileId)
  }, [agentId, viewProfile, id, profileId])

  return (
    <div>
      <LinkTo onClick={onClick} isMobile>
        View Profile
      </LinkTo>
    </div>
  )
}
export default ViewProfileComponent
