import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useDate, useMobile } from '@utils/hooks'
import {
  mapToSlots,
  sortedInToTwoWeeks,
  getWeek,
  checkToShowWeek,
  mapDateToString,
} from '@utils/functions'
import { getSellerSlots } from '@services/firebase'
import { URGENT_MATTERS, CANCEL_VISIT } from '@constants/dictionary'
import { Loader, Typography } from '@atoms'
import { DayPickRow } from '@molecules'
import { DayChooseH2, DayChooseContainer, HR } from './UrgentMatters.styled'

const DayChoose: React.FC = ({ onCheck }: { onCheck: () => void }): JSX.Element => {
  const isMobile = useMobile()
  const querySellerSlots = getSellerSlots()
  const [value, loading] = useCollection(querySellerSlots)

  const sellerSlots = loading ? [] : mapToSlots(value)
  const { getSellersWeek, getSellersNextWeek } = useDate()
  const sellerWeek = getSellersWeek()
  const sellerNextWeek = getSellersNextWeek()
  const currentWeek = getWeek(sellerWeek)
  const nextWeek = getWeek(sellerNextWeek)

  const { firstWeek, secondWeek } = sortedInToTwoWeeks({
    sellerSlots,
    sellerWeek,
    sellerNextWeek,
    filterBooked: true,
  })

  const isNotEmptySecondWeek = checkToShowWeek(secondWeek.week)

  const notification = (days): string =>
    CANCEL_VISIT.YOU_HAVE_BOOKED_SLOTS_1 + days + CANCEL_VISIT.YOU_HAVE_BOOKED_SLOTS_2

  const bookedDaysFirst = mapDateToString(firstWeek.bookedSlots)
  const bookedDaysSecond = mapDateToString(secondWeek.bookedSlots)

  return (
    <Loader loaded={!loading}>
      <DayChooseContainer>
        <DayChooseH2 isMobile={isMobile}>{URGENT_MATTERS.DAY_CHOOSE.FIRST_HEADER}</DayChooseH2>
        <DayPickRow
          currentWeek={currentWeek}
          daysList={firstWeek.week}
          withCheckbox
          onCheck={onCheck}
        />
        {bookedDaysFirst && (
          <>
            <DayChooseH2 isMobile={isMobile}>{URGENT_MATTERS.DAY_CHOOSE.SECOND_HEADER}</DayChooseH2>
            <Typography.Body2 isMobile={isMobile}>{notification(bookedDaysFirst)}</Typography.Body2>
          </>
        )}
        {isNotEmptySecondWeek && (
          <>
            <HR />
            <DayPickRow
              currentWeek={nextWeek}
              daysList={secondWeek.week}
              withCheckbox
              onCheck={onCheck}
            />
            {bookedDaysSecond && (
              <Typography.Body2 isMobile={isMobile}>
                {notification(bookedDaysSecond)}
              </Typography.Body2>
            )}
          </>
        )}
      </DayChooseContainer>
    </Loader>
  )
}

export default DayChoose
