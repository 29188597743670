import React from 'react'
import { useMobile } from '@utils/hooks'
import { RatingForm } from '@helpers'
import { TopContainer, Container } from './BuyerSurveyAnswer.style'

const FirstStep = (): JSX.Element => {
  const isMobile = useMobile()

  return (
    <Container>
      <TopContainer isMobile={isMobile} lg={7} xs={12} md={10} noGutter>
        <RatingForm name="price" label="Price" />
        <RatingForm name="valueForMoney" label="Value for Money" />
        <RatingForm name="overallCondition" label="Overall Condition" />
        <RatingForm name="location" label="Location" />
      </TopContainer>
    </Container>
  )
}

export default FirstStep
