import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'
import { Typography } from '@atoms'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: ${(p) => p.theme.indent.XXXL};
`

export const StyledCol = styled(Col)`
  max-width: 80rem;
  padding: ${(p) => {
    if (p.isMobile) return `5rem ${p.theme.indent.LG} 0`
    if (p.isTablet) return `5rem ${p.theme.indent.XXXL} 0`
    return `5rem ${p.theme.indent.XXXS} 0`
  }};
`
export const MainTitle = styled(Typography.H1)`
  text-align: center;
  padding-bottom: ${(p) => p.theme.indent.LG};
`
export const Body2 = styled(Typography.Body2)`
  padding-top: ${(p) => p.theme.indent.XS};
`
export const H2 = styled(Typography.H2)`
  padding-top: ${(p) => p.theme.indent.LG};
`
