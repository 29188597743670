import React, { useCallback } from 'react'
import { useMobile } from '@utils/hooks'
import { TimeSlotButtonContainer, TimeSlotLabel } from './TimeSlotButton.style'
import { TimeSlotButtonProps } from '@constants/types/molecules'

const TimeSlotButton: React.FC<TimeSlotButtonProps> = ({
  id,
  startTime = '',
  endTime = '',
  onClick,
  active = false,
  disabled,
  noHover = false,
}) => {
  const isMobile = useMobile()
  return (
    <TimeSlotButtonContainer
      id={id}
      onClick={useCallback(() => onClick(id), [id, onClick])}
      active={active}
      disabled={disabled}
      noHover={noHover}
    >
      <TimeSlotLabel isMobile={isMobile} active={active} disabled={disabled} noHover={noHover}>
        {startTime} - {endTime}
      </TimeSlotLabel>
    </TimeSlotButtonContainer>
  )
}

export default TimeSlotButton
