import styled from 'styled-components'

const getWidth = (p) => {
  if (p.isTableModal && !p.typeUse) {
    return 'min-width: 100%;'
  }
  if (p.isTableModal && p.isAddModal && p.typeUser) {
    return 'width: 100%;'
  } else {
    return 'min-width: 126.6rem;'
  }
}

export const Container = styled.div<{ isTableModal: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};
  font-weight: normal;
  background-color: ${(p) => p.theme.color.white};
`
export const WithPaginationContainer = styled.div``

export const TableContainer = styled.table<{ isTableModal: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-y: unset;
  padding: 0;
  border-top: ${(p) => `0.1rem solid ${p.theme.color.lightGrey}`};
  border-left: ${(p) => `0.1rem solid ${p.theme.color.lightGrey}`};
  border-right: ${(p) => `0.1rem solid ${p.theme.color.lightGrey}`};
  min-height: ${(p) => {
    if (!p.isTableModal && !p.isTableVisit) {
      return 'calc(100vh - 26rem);'
    }
    if (!p.isTableModal && p.isTableVisit) {
      return 'calc(100vh - 35rem);'
    }
  }};
  ${(p) => p.isTableModal && 'max-height: 45rem;'}
`
export const TableHead = styled.thead`
  display: flex;
  width: 100%;
  flex-direction: column;
  tr:nth-child(odd) {
    border-bottom: ${(p) => `0.1rem solid ${p.theme.color.lightGrey} `};
  }
`
export const TableBody = styled.tbody`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  tr:nth-child(odd) {
    background-color: ${(p) => p.theme.color.lightGrey_20};
  }
`
export const NoItem = styled.tr<{ noItemHeight: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(p) => p.theme.color.darkGrey};
  min-height: ${({ noItemHeight }) => (noItemHeight ? noItemHeight : 'calc(100vh - 38rem)')};
`

export const Tr = styled.tr<{ isTableModal: boolean; isAddModal: boolean }>`
  display: flex;
  ${(p) => getWidth(p)}
  height: 5.2rem;
  align-items: center;
`
export const Th = styled.th`
  display: flex;
  position: relative;
  font-family: ${(p) => p.theme.fontFamily.openSansRegular}, sans-serif;
  font-size: ${(p) => p.theme.fontSize.XS};
  line-height: ${(p) => p.theme.lineHeight.SM};
  font-weight: normal;
  color: ${(p) => p.theme.color.originalBlue};
  cursor: ${(p) => p.disableSortBy && 'pointer'};

  svg {
    margin-left: ${(p) => p.theme.indent.XS};
  }

  &:first-of-type {
    padding-left: ${(p) => p.theme.indent.XXXL};
  }
`

export const Td = styled.td`
  position: relative;
  color: ${(p) => p.theme.color.darkBlack};

  &:first-of-type {
    padding-left: ${(p) => p.theme.indent.XXXL};
  }
  &:last-of-type {
    padding-right: ${(p) => p.theme.indent.XL};
  }
`

export const CellWrapper = styled.div<{ isHorizontalCentered?: boolean }>`
  width: 100%;
  height: 100%;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  align-items: center;
  ${({ isHorizontalCentered }) =>
    isHorizontalCentered && {
      justifyContent: 'center',
    }};
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
