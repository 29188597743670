import React from 'react'
import { useMobile } from '@utils/hooks'
import star from '@assets/images/star.png'
import { getSecureCount } from '@utils/functions'
import Icon from '../Icon'
import { Container, Image, IconContainer } from './SimpleRatingStars.style'

import { SimpleRatingStarsTypes } from '@constants/types/atoms'

const SimpleRatingStars: React.FC<SimpleRatingStarsTypes> = ({ count = 0, size, displayBlue }) => {
  const isMobile = useMobile()
  const maxCount = 5
  return (
    <Container>
      {
        // create array of stars use count
        [...Array(getSecureCount(count))].map((_, id) =>
          displayBlue ? (
            <IconContainer key={`${id + 1}`}>
              <Icon icon="filled-star" size={size} color="originalBlue" />
            </IconContainer>
          ) : (
            <Image src={star} size={size} isMobile={isMobile} key={`${id + 1}`} />
          ),
        )
      }
      {displayBlue &&
        [...Array(maxCount - getSecureCount(count))].map((_, id) => (
          <IconContainer key={`${id + 1}`}>
            <Icon size={size} icon="empty-star" color="originalBlue" />
          </IconContainer>
        ))}
    </Container>
  )
}

export default SimpleRatingStars
