import styled from 'styled-components'

export const LabelWrapper = styled.div`
  background-color: ${(p) => p.theme.color.greyBlue};
  border-radius: ${(p) => p.theme.borderRadius.MD};
  border: 0.1rem solid ${(p) => p.theme.color.lightGrey};
  padding: ${(p) => p.theme.indent.XS} ${(p) => p.theme.indent.MD}; /* 10px */
  display: flex;
  justify-content: space-between;
`
export const StyledInput = styled.input`
  visibility: hidden;
  position: absolute;
  z-index: -10;
`
