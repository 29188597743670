import React, { useCallback } from 'react'
import { useMobile } from '@utils/hooks'
import { Icon } from '@atoms'
import { TimeSlotButtonContainer, TimeSlotLabel } from './SelectPeriodButtons.style'
import { SelectPeriodButtonsProps } from '@constants/types/molecules'

const SelectPeriodButtons: React.FC<SelectPeriodButtonsProps> = ({
  id,
  selectTime,
  addTime,
  minusTime,
  disabledminusTime,
  disabledaddTime,
}) => {
  const isMobile = useMobile()
  const onClickMinus = useCallback(() => minusTime(id), [id, minusTime])
  const onClickAdd = useCallback(() => addTime(id), [id, addTime])
  return (
    <TimeSlotButtonContainer>
      <Icon
        testid="icon-remove-TimeSlotButtonContainer"
        icon="remove"
        clickable={!disabledminusTime}
        size="2rem"
        color={disabledminusTime ? 'darkGrey' : 'originalBlue'}
        onClick={onClickMinus}
        key={`remove${disabledminusTime ? '1' : '0'}`}
      />
      <TimeSlotLabel isMobile={isMobile}>{selectTime}</TimeSlotLabel>
      <Icon
        testid="icon-add-TimeSlotButtonContainer"
        icon="add"
        clickable={!disabledaddTime}
        size="2rem"
        color={disabledaddTime ? 'darkGrey' : 'originalBlue'}
        onClick={onClickAdd}
        key={`add${disabledaddTime ? '1' : '0'}`}
      />
    </TimeSlotButtonContainer>
  )
}

export default SelectPeriodButtons
