import React, { useEffect, useState, useCallback } from 'react'
import { getAgentPrivateData, getAgentPropertiesProfiles } from '@services/firebase'
import { getAuth } from 'firebase/auth'
import algoliasearch from 'algoliasearch/lite'
import { useMobile, useTabletPortrait, useMountEffect } from '@utils/hooks'
import {
  EMPTY_LIST_TEXT_AGENT,
  PROPERTY_STATUS_FILER,
  REQUEST_TIME_DELAY,
} from '@constants/dictionary'
import { AgentPropertyCard } from '@organisms'
import { Loader } from '@atoms'
import FilterBar from './FilterBar'
import { FilterContainer, PropertyContainer, StyledBody2 } from './AgentAllProperties.style'
import { filterProfiles } from './AgentAllProperties.utils'

const AgentAllProperties: React.FC = () => {
  const { currentUser } = getAuth()
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()

  const [filteredData, setFilteredData] = useState<Array>([])
  const [propertiesProfiles, setPropertiesProfiles] = useState<Array>([])
  const [privateData, setUserPrivateData] = useState<Array>([])
  const [isListView, setListView] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [status, setStatus] = useState<string>(PROPERTY_STATUS_FILER[0].value)

  const [loaded, setLoaded] = useState<boolean>(false)

  const agentPrivateData = useCallback(async (): Promise<void> => {
    const userPrivateData = await getAgentPrivateData(currentUser?.uid)
    setUserPrivateData(userPrivateData)
  }, [currentUser?.uid])

  const getAgentPropProfiles = useCallback(async (): Promise<void> => {
    setLoaded(false)
    await agentPrivateData()
    const profiles = await getAgentPropertiesProfiles()
    setPropertiesProfiles(profiles)
  }, [agentPrivateData])
  // grab all profiles data only once
  useMountEffect(getAgentPropProfiles)

  const searchAlgolia = useCallback(async (): Promise<void> => {
    // restrict many calls to algolia search
    let filtered: Array<unknown> = []
    // prevent calls if search values are default
    if (
      privateData.algoliaApiKey &&
      (searchValue !== '' || status !== PROPERTY_STATUS_FILER[0].value)
    ) {
      const algolia = algoliasearch(process.env.ALGOLIA_APP_ID || '', privateData.algoliaApiKey)
      const { hits } = await algolia.initIndex('PPI').search(searchValue, {
        filters: !!status ? `status:${status}` : '',
      })
      // trigger filtering profiles if search is empty
      filtered = hits.length ? hits : [{}]
    }
    setFilteredData(filtered)
    setLoaded(true)
  }, [privateData.algoliaApiKey, searchValue, status])

  useEffect(() => {
    setLoaded(false)
    // send request to algolia after debounce
    const delayDebounceFn = setTimeout(() => searchAlgolia(), REQUEST_TIME_DELAY)
    return () => clearTimeout(delayDebounceFn)
  }, [status, searchValue, searchAlgolia])

  useEffect(() => {
    if (isMobile && isListView) {
      setListView(false)
    }
  }, [isListView, isMobile])

  const handleDropdownValue = (value): undefined => {
    setStatus(value)
  }

  const renderList: React.FC = () => {
    let filteredValue = propertiesProfiles
    if (!!filteredData.length) {
      filteredValue = filterProfiles(propertiesProfiles, filteredData)
    }
    if (loaded && !!filteredValue.length) {
      return (
        <FilterContainer margin isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
          {filteredValue.map(({ id, propertyProfile }) => {
            return (
              <PropertyContainer
                key={id}
                isListView={isListView}
                isMobile={isMobile}
                isTabletPortrait={isTabletPortrait}
              >
                <AgentPropertyCard
                  propertyStatus={propertyProfile.status}
                  {...propertyProfile}
                  isListView={isListView}
                  profileId={id}
                />
              </PropertyContainer>
            )
          })}
        </FilterContainer>
      )
    } else
      return (
        <StyledBody2 isMobile={isMobile}>
          {!!searchValue || !!status
            ? EMPTY_LIST_TEXT_AGENT.AGENT_NO_RESULTS
            : EMPTY_LIST_TEXT_AGENT.AGENT_NO_PROPERTIES}
        </StyledBody2>
      )
  }

  return (
    <>
      <FilterBar
        handleDropdownValue={handleDropdownValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isListView={isListView}
        setListView={setListView}
      />
      <Loader loaded={loaded}>{renderList()}</Loader>
    </>
  )
}

export default AgentAllProperties
