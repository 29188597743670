import { AgentAllProperties, BuyersTabContent, SellersTabContent, AgentViewings } from '@templates'

export const tabsConfig = [
  {
    name: 'All Properties',
    path: '/properties',
    component: AgentAllProperties,
    exact: true,
    id: 'all-properties',
  },
  {
    name: 'Viewings',
    path: '/viewings',
    component: AgentViewings,
    id: 'viewings',
  },
  {
    name: 'Buyers',
    path: '/buyers',
    component: BuyersTabContent,
    id: 'buyers',
  },
  {
    name: 'Sellers',
    path: '/sellers',
    component: SellersTabContent,
    id: 'sellers',
  },
  // {
  //   name: 'Analytics',
  //   path: '/analytics',
  //   component: AgentAllProperties,
  //   id: 'analytics',
  // },
]
