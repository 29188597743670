export const SELLER_VISITS = [
  {
    id: 'S-01',
    date: new Date(2021, 8, 21),
    visits: [
      {
        id: 'V1',
        agencyName: 'DBK Estate Agents',
        agencyPhone: '020 8570 4848',
        visitNumber: '1',
        visitTime: '09:00 - 10:00',
        visitDuration: '1h',
        disabled: true,
      },
      {
        id: 'V2',
        agencyName: 'Clan Gordon',
        agencyPhone: '0131 555 4444',
        visitNumber: '2',
        visitTime: '10:00 - 11:00',
        visitDuration: '1h',
        visitNotification: '30 min to visit',
      },
      {
        id: 'V3',
        agencyName: 'Landmark Property Services',
        agencyPhone: '020 8570 4848',
        visitNumber: '3',
        visitTime: '12:00 - 13:00',
        visitDuration: '1h',
      },
      {
        id: 'V4',
        agencyName: 'Clan Gordon',
        agencyPhone: '031 555 444',
        visitNumber: '4',
        visitTime: '14:00 - 15:00',
        visitDuration: '1h',
      },
    ],
  },
  {
    id: 'S-02',
    date: new Date(2021, 8, 22),
    visits: [
      {
        id: 'V5',
        agencyName: 'DBK Estate Agents',
        agencyPhone: '020 8570 4848',
        visitNumber: '1',
        visitTime: '09:00 - 10:00',
        visitDuration: '1h',
      },
      {
        id: 'V2',
        agencyName: 'Clan Gordon',
        agencyPhone: '0131 555 4444',
        visitNumber: '2',
        visitTime: '10:00 - 11:00',
        visitDuration: '1h',
      },
    ],
  },
]
