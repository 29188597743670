import React from 'react'
import { ROUTES } from '@constants/dictionary'
import { AgentTabs } from '@organisms'
import { tabsConfig } from './Sellers.constants'

const Sellers = (): JSX.Element => {
  return (
    <AgentTabs
      config={tabsConfig}
      baseRoute={`${ROUTES.AGENT_SELLERS}`}
      dynamicUrl
      isPropertyTab
      userTab="Seller"
    />
  )
}

export default Sellers
