/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useEffect, useState } from 'react'
import { useDesktop, useMobile, useModal } from '@utils/hooks'
import { deleteAgent } from '@services/firebase'
import { Button, ErrorWithIcon } from '@molecules'
import {
  ButtonRow,
  FormContainer,
  Container,
  ErrorContainer,
  DeleteContainer,
} from './AgencyAddAgentCard.style'
import { InputForm } from '@helpers'
import { FIRST_THREE, VALIDATION_ERRORS_FORM_ADD } from '@constants/dictionary'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from './AgencyAddAgentCardValidation'
import { onSubmit } from './AgencyAddAgentCard.utils'
import { customFormatValue } from '@utils/functions'
import { phoneForm } from '@constants/regExp'
import { Icon, Typography } from '@atoms'
import { AgencyDeleteAgent } from '@modals'
import { AddAgentCardProps } from '@constants/types/organisms'

const AgencyAddAgentCard: React.FC<AddAgentCardProps> = (props) => {
  const { editAgent, onCancel } = props
  const isMobile = useMobile()
  const isDesktop = useDesktop()
  const [backendError, setBackendError] = useState(false)
  const [loader, setLoader] = useState(false)
  const [isDeletingAgent, setIsDeletingAgent] = useState(false)
  const { isShown, toggle } = useModal()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: '',
      email: '',
      phone: '',
    },
  })
  const {
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = methods

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  useEffect(() => {
    if (!!editAgent) {
      setValue('fullName', editAgent.name)
      setValue('email', editAgent.email)
      setValue(
        'phone',
        customFormatValue(editAgent.phoneNumber.slice(FIRST_THREE), phoneForm, '$1 $2'),
      )
    }
  }, [editAgent, setValue])

  const confirmDeleteAgent = async (): Promise<void> => {
    setIsDeletingAgent(true)
    const res = await deleteAgent(editAgent?.id)
    if (res.result) {
      onCancel()
      setIsDeletingAgent(false)
    }
  }

  const confirmAddAgent = useCallback(
    () => handleSubmit((data) => onSubmit(data, setLoader, setBackendError, onCancel, editAgent)),
    [editAgent, handleSubmit, onCancel],
  )

  return (
    <Container isMobile={isMobile} isDesktop={isDesktop}>
      <FormProvider {...methods}>
        <FormContainer isMobile={isMobile}>
          <InputForm
            name="fullName"
            disabled={loader}
            errorMessage={errors?.fullName?.message || 'Value is required'}
            placeholder="Enter name"
            inputLabel="Full Name"
            error={hasError('fullName')}
            maxLength={50}
            tabIndex={1}
            isName
          />
          <InputForm
            name="email"
            disabled={!!editAgent || loader}
            errorMessage={errors?.email?.message || 'Value is required'}
            placeholder="Enter email"
            inputLabel="Contact Email"
            error={hasError('email')}
            isNoSpace
            tabIndex={2}
          />
          <InputForm
            name="phone"
            disabled={loader}
            errorMessage={errors?.phone?.message || 'Value is required'}
            placeholder="Enter phone number"
            inputLabel="Phone Number"
            error={hasError('phone')}
            maxLength={11}
            isPhone
            tabIndex={3}
          />
        </FormContainer>
      </FormProvider>
      <ButtonRow isMobile={isMobile}>
        <Button.Primary
          label={editAgent ? 'Save' : 'Add Agent'}
          height="4rem"
          onClick={confirmAddAgent()}
          loader={loader}
          disabled={!isDirty || loader}
        />
        <Button.Secondary label="Cancel" height="4rem" onClick={onCancel} />
      </ButtonRow>
      {backendError && (
        <ErrorContainer>
          <ErrorWithIcon text={VALIDATION_ERRORS_FORM_ADD.AGENT_EXISTS} />
        </ErrorContainer>
      )}
      {!!editAgent && (
        <DeleteContainer onClick={toggle}>
          <Icon icon="delete" />
          <Typography.Body3 color="red" underline>
            Delete this account
          </Typography.Body3>
        </DeleteContainer>
      )}
      <AgencyDeleteAgent
        isDeleting={isDeletingAgent}
        isShown={isShown}
        onCancel={toggle}
        onConfirm={confirmDeleteAgent}
      />
    </Container>
  )
}

export default AgencyAddAgentCard
