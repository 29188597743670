export const headerMessage = (editAgent?: boolean, isAddAgent?: boolean): string => {
  switch (true) {
    case editAgent:
      return 'Edit Agent profile'
    case isAddAgent:
      return 'Add New Agent profile'
    default:
      return 'List of Agents'
  }
}

export const sortAgents = (listOfAgents: Array<unknown>, isDesc: boolean): Array<unknown> =>
  [...listOfAgents].sort((firstAgent, secondAgent) => {
    const firstAgentTime = firstAgent?.agent?.createdAt?.toDate()
    const secondAgentTime = secondAgent?.agent?.createdAt?.toDate()
    return isDesc ? secondAgentTime - firstAgentTime : firstAgentTime - secondAgentTime
  })
