import React from 'react'
import { useTheme } from 'styled-components'
import { useMobile } from '@utils/hooks'
import { actualStatus, convertToPrice, setAdditionalProps } from '@utils/functions'
import { Icon, BookingStatus, SimpleRatingStars } from '@atoms'
import { BOOKING_STATUSES, LS_CONTSTANTS, ROUTES } from '@constants/dictionary'
import {
  LinkContainer,
  LinkText,
  TextContainer,
  Body2,
  StyledRow,
} from './BookingSelectionRow.style'

import { BookingSelectionRowTypes } from '@constants/types/molecules'

const BookingSelectionRow: React.FC<BookingSelectionRowTypes> = ({
  price = '',
  rating = 5,
  status = BOOKING_STATUSES.UNDEFINED,
  bookingDisabled = false,
  agentId = '',
  propertyId = '',
}): JSX.Element => {
  const isMobile = useMobile()
  const colors = useTheme().color
  const color = bookingDisabled ? colors.darkGrey : colors.originalBlue
  const location = (): string => {
    setAdditionalProps({ agentId, propertyId })
    return ROUTES.BUYER_BOOKING
  }
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)
  const currentStatus = actualStatus(userType, status)

  return (
    <>
      <StyledRow justifyContent="between">
        <SimpleRatingStars count={rating} />
        <BookingStatus status={currentStatus} />
      </StyledRow>
      <TextContainer>
        <Body2 disabled={bookingDisabled} isMobile={isMobile}>
          £ {convertToPrice(price)}
        </Body2>
        <LinkContainer to={location()}>
          <LinkText disabled={bookingDisabled}>Book new visit</LinkText>
          <Icon icon="arrow-right" clickable size="0.6rem" color={color} />
        </LinkContainer>
      </TextContainer>
    </>
  )
}

export default BookingSelectionRow
