import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { FS_COL_NAMES } from '@constants/dictionary/firebase'

export const getUserByIdDoc = (id: string): { error: string } => {
  const db = getFirestore()
  return doc(db, FS_COL_NAMES.USERS, id)
}

export const getUserById = async (id: string): { error: boolean } => {
  const snapshot = await getDoc(getUserByIdDoc(id))
  if (snapshot?.exists()) {
    return snapshot.data()
  }
  return { error: true }
}
