import React from 'react'
import { Tabs } from '@organisms'
import { ROUTES } from '@constants/dictionary'
import { sellerTabsConfig } from './SellerHome.constants'

const SellerHome = (): JSX.Element => {
  return <Tabs config={sellerTabsConfig} baseRoute={ROUTES.SELLER} />
}

export default SellerHome
