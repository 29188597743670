import React, { useCallback } from 'react'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Typography, Icon } from '@atoms'
import { Col, Row } from 'styled-bootstrap-grid'
import { ROUTES, USER_TYPES } from '@constants/dictionary'
import {
  CardWrapper,
  DetailsRow,
  ContactsWrapper,
  EditWrapper,
  ColStyled,
  NotesRow,
  AdditionalBlock,
  CardWrapperStatistic,
  DetailsRowStatistic,
  DetailsRowCalendar,
  DetailsSecondRow,
  Caption2,
  Body3,
  Link,
} from './AgentUserDetailsCard.style'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { AgentUserDetailsCardTypes } from '@constants/types/organisms'

const AgentUserDetailsCard: React.FC<AgentUserDetailsCardTypes> = (props): JSX.Element => {
  const { userFile, statistic, userType, userId } = props
  const isMobile = useMobile()
  const history = useHistory()
  const isTabletPortrait = useTabletPortrait()
  const onEditClick = useCallback(() => {
    history.push({
      pathname: `${ROUTES.AGENT}/${userType}/${userId}-${userFile.id}/edit-user`,
      state: {
        userFile: userFile,
        userType,
      },
    })
  }, [history, userId, userFile, userType])
  return (
    <Col>
      <CardWrapper isMobile={isMobile}>
        <Row>
          <Col xl={6} md={12}>
            <Row>
              <Col xs={12} sm={6}>
                <ContactsWrapper>
                  <Typography.Body1>
                    {userType === USER_TYPES.buyer ? 'Buyer' : 'Seller'} contacts
                  </Typography.Body1>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="avatar" color="darkGrey" size="1.6rem" />
                    <Caption2 isMobile>{userFile.profile.name}</Caption2>
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="email" color="darkGrey" size="1.6rem" />
                    <Link isMobile>{userFile.profile.email}</Link>
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="phone" color="darkGrey" size="1.6rem" />
                    <Body3>{userFile.profile.phoneNumber}</Body3>
                  </DetailsRow>
                </ContactsWrapper>
              </Col>
              <Col xs={12} sm={6}>
                <ContactsWrapper>
                  <AdditionalBlock isMobile={isMobile}>
                    <Typography.Body1>Additional contacts</Typography.Body1>
                  </AdditionalBlock>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="avatar" color="darkGrey" size="1.6rem" />
                    <Caption2 isMobile>{userFile.profile.additionalName}</Caption2>
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="email" color="darkGrey" size="1.6rem" />
                    <Link isMobile>{userFile.profile.additionalEmail}</Link>
                  </DetailsRow>
                  <DetailsRow isMobile={isMobile}>
                    <Icon icon="phone" color="darkGrey" size="1.6rem" />
                    <Body3>{userFile.profile.additionalPhoneNumber}</Body3>
                  </DetailsRow>
                </ContactsWrapper>
              </Col>
            </Row>
          </Col>
          <ColStyled
            statistic={!!statistic || !!userFile.profile.note}
            xl={6}
            xs={12}
            isMobile={isMobile}
            isTabletPortrait={isTabletPortrait}
          >
            {userFile.profile.note && (
              <>
                <NotesRow>
                  <Typography.Body1>Notes</Typography.Body1>
                </NotesRow>
                <Typography.Body3>{userFile.profile.note}</Typography.Body3>
              </>
            )}
          </ColStyled>
          <EditWrapper onClick={onEditClick} isMobile={isMobile}>
            <Icon icon="edit" color="originalBlue" clickable size="2rem" />
          </EditWrapper>
        </Row>
      </CardWrapper>

      <CardWrapperStatistic isMobile={isMobile}>
        <Row>
          {!!statistic && (
            <>
              <DetailsRowStatistic isMobile={isMobile}>
                <Icon icon="eye-opened" color="darkGrey" size="1.6rem" />
                <Typography.Body3>
                  Viewings: {statistic.cancelledVisits || 0 + statistic.confirmedVisits || 0}
                </Typography.Body3>
              </DetailsRowStatistic>
              <DetailsRowStatistic isMobile={isMobile}>
                <Icon icon="notification-checked" color="darkGrey" size="1.6rem" />
                <Typography.Body3>Confirmed: {statistic.confirmedVisits || 0}</Typography.Body3>
              </DetailsRowStatistic>
              <DetailsSecondRow isMobile={isMobile}>
                <DetailsRowStatistic isMobile={isMobile}>
                  <Icon icon="notification-declined" color="darkGrey" size="1.6rem" />
                  <Typography.Body3>Canceled: {statistic.cancelledVisits || 0}</Typography.Body3>
                </DetailsRowStatistic>
                {userType === USER_TYPES.buyer && (
                  <DetailsRowStatistic isMobile={isMobile}>
                    <Icon icon="star" color="darkGrey" size="1.6rem" />
                    <Typography.Body3> Short List: {statistic.shortlist || 0}</Typography.Body3>
                  </DetailsRowStatistic>
                )}
              </DetailsSecondRow>
            </>
          )}
          <DetailsRowCalendar
            statistic={!!statistic}
            isMobile={isMobile}
            isTabletPortrait={isTabletPortrait}
          >
            <DetailsRow isMobile={isMobile} isTabletPortrait={isTabletPortrait}>
              <Icon icon="calendar" color="darkGrey" size="1.6rem" />
              <Typography.Body3>
                Last activity:
                {userFile.profile.lastActivity
                  ? format(userFile.profile.lastActivity.toDate(), 'dd/MM/yy')
                  : format(userFile.profile.createdAt.toDate(), 'dd/MM/yy')}
              </Typography.Body3>
            </DetailsRow>
          </DetailsRowCalendar>
        </Row>
      </CardWrapperStatistic>
    </Col>
  )
}

export default AgentUserDetailsCard
