import { DayTypes, State, TimeSlotsTypes } from '@constants/types'

export const getAllTimePeriodsOfWeek = (state: State): Array<TimeSlotsTypes> => {
  return state.map((day: DayTypes) => day.timePeriods).flat()
}

export const getTimePeriodBefore = (day: DayTypes, index: number): TimeSlotsTypes | null => {
  return day.timePeriods.length > 0 ? day.timePeriods[index - 1] : null
}
export const getTimePeriodNext = (day: DayTypes, index: number): TimeSlotsTypes | null => {
  return day.timePeriods[index + 1] ? day.timePeriods[index + 1] : null
}
