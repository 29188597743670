import styled from 'styled-components'
import { Typography } from '@atoms'

export const ViewingsContainer = styled.div<{ isMobile: boolean; isTabletPortrait: boolean }>`
  background-color: ${({ theme }) => theme.color.greyBlue};
  min-height: calc(100vh - 6.4rem * 2 - 5.5rem);
  ${({ theme, isMobile, isTabletPortrait }) => ({
    padding: `0 5rem 5rem`,
    ...(isMobile && { padding: `0 ${theme.indent.LG} ${theme.indent.LG}` }),
    ...(isTabletPortrait && { padding: `0 ${theme.indent.XXXL} ${theme.indent.XXXL}` }),
  })};
`

export const ViewingsGrid = styled.div<{ isMobile: boolean; isDesktop: boolean }>`
  margin-top: ${({ theme, isMobile }) => (isMobile ? theme.indent.MD : theme.indent.LG)};
  column-gap: ${({ theme }) => theme.indent.LG};
  row-gap: ${({ isDesktop, theme }) => (isDesktop ? theme.indent.LG : theme.indent.MD)};
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ isMobile }) => (isMobile ? '30rem' : '35rem')}, 1fr)
  );
`

export const DropdownContainer = styled.div<{ isMobile: boolean }>`
  min-width: 14.6rem;
  z-index: 999;
`

export const SearchContainer = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => ({
    minWidth: '31rem',
    ...(isMobile && {
      minWidth: '100%',
    }),
  })};
`

export const ViewingsControls = styled.div<{ isMobile?: boolean }>`
  padding-top: ${({ theme }) => theme.indent.MD};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: 100%;
  gap: ${({ theme, isMobile }) => (isMobile ? theme.indent.SM : theme.indent.LG)};
`

export const StyledBody2 = styled(Typography.Body2)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.color.darkGrey};
  height: calc(100vh - 26rem);
`

export const PageContainer = styled.div`
  min-height: calc(100vh - 6.4rem - 6.4rem);
`
