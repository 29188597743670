import { compareAsc } from 'date-fns'
import { DayTypes, State, TimePeriodsTypes } from '@constants/types'
import { ONE_THOUSAND, ONE_WEEK_IN_MILISECOND, EVEN_NUMBER } from '@constants/dictionary'

export const combineSlotsIntoPeriods = (state: State): State => {
  const arrayOfSlots: Array<TimePeriodsTypes> = []
  state.forEach((day: DayTypes) => {
    const dayperiod = []
    if (day.slots.length > 0) {
      dayperiod.push(day.slots[0].fromDateTime.toDate())
      day.slots.forEach((item: TimePeriodsTypes, i: number) => {
        if (day.slots.length > i + 1) {
          if (compareAsc(item.toDateTime.toDate(), day.slots[i + 1].fromDateTime.toDate()) !== 0) {
            dayperiod.push(item.toDateTime.toDate())
            dayperiod.push(day.slots[i + 1].fromDateTime.toDate())
          }
        }
      })
      dayperiod.push(day.slots[day.slots.length - 1].toDateTime.toDate())
    }
    arrayOfSlots.push(dayperiod)
  })

  const allWeekPeriods = arrayOfSlots.map((day) => {
    if (day.length > 0) {
      return day
        .map((item: TimePeriodsTypes, i: number) => {
          return (
            i % EVEN_NUMBER === 0 && {
              fromDateTime: item,
              toDateTime: day[i + 1],
            }
          )
        })
        .filter((item: TimePeriodsTypes | boolean) => item !== false)
    }

    return day
  })

  return state.map((day: DayTypes, i: number) => {
    return { ...day, slots: allWeekPeriods[i] }
  })
}
export const combineSlotsIntoPeriodsToNextWeek = (state: State): State => {
  const arrayOfSlots: Array<TimePeriodsTypes> = []

  state.forEach((day: DayTypes) => {
    const dayperiod = []
    if (day.slots.length > 0) {
      dayperiod.push(
        new Date(day.slots[0].fromDateTime.seconds * ONE_THOUSAND + ONE_WEEK_IN_MILISECOND),
      )
      day.slots.forEach((item: TimePeriodsTypes, i: number) => {
        if (day.slots.length > i + 1) {
          if (
            compareAsc(
              new Date(item.toDateTime.seconds * ONE_THOUSAND + ONE_WEEK_IN_MILISECOND),
              new Date(
                day.slots[i + 1].fromDateTime.seconds * ONE_THOUSAND + ONE_WEEK_IN_MILISECOND,
              ),
            ) !== 0
          ) {
            dayperiod.push(
              new Date(item.toDateTime.seconds * ONE_THOUSAND + ONE_WEEK_IN_MILISECOND),
            )
            dayperiod.push(
              new Date(
                day.slots[i + 1].fromDateTime.seconds * ONE_THOUSAND + ONE_WEEK_IN_MILISECOND,
              ),
            )
          }
        }
      })
      dayperiod.push(
        new Date(
          day.slots[day.slots.length - 1].toDateTime.seconds * ONE_THOUSAND +
            ONE_WEEK_IN_MILISECOND,
        ),
      )
    }
    arrayOfSlots.push(dayperiod)
  })

  const allWeekPeriods = arrayOfSlots.map((day) => {
    if (day.length > 0) {
      return day
        .map((item: TimePeriodsTypes, i: number) => {
          return (
            i % EVEN_NUMBER === 0 && {
              fromDateTime: item,
              toDateTime: day[i + 1],
            }
          )
        })
        .filter((item: TimePeriodsTypes | boolean) => item !== false)
    }

    return day
  })

  return state.map((day: DayTypes, i: number) => {
    return { ...day, slots: allWeekPeriods[i] }
  })
}
