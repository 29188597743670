import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div<{ isMobile: boolean }>`
  ${(p) => !p.isMobile && `border-bottom: 0.1rem solid ${p.theme.color.lightGrey}`};
  ${(p) => !p.isMobile && `border-top: 0.1rem solid ${p.theme.color.lightGrey}`};

  border-left: ${(p) => (p.isMobile ? '0' : `0.1rem solid ${p.theme.color.lightGrey}`)};
  border-right: ${(p) => (p.isMobile ? '0' : `0.1rem solid ${p.theme.color.lightGrey}`)};
  border-radius: ${(p) => (p.isMobile ? '0' : p.theme.borderRadius.XS)};
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.SM} ${p.withCheckbox ? '0' : p.theme.indent.LG}`
      : `${p.theme.indent.XS}`};
  margin: ${(p) =>
    p.isMobile ? `0 ` : `${p.theme.indent.MD} 0 ${p.theme.indent.XS} ${p.withCheckbox && '0'} `};
`

export const DaysContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DayLabelContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${(p) =>
    p.active && !p.isMobile ? p.theme.color.originalBlue : 'transparent'};
  border-radius: ${(p) => p.theme.borderRadius.XS};
  padding: ${(p) => (p.isMobile ? '0' : `${p.theme.indent.XS} ${p.theme.indent.SM}`)};
  min-width: ${(p) => (p.isMobile ? '0' : '6rem')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  cursor: ${(p) => !p.withCheckbox && 'pointer'};
`

export const DayLabel = styled(Typography.Body2)`
  color: ${(p) => {
    if (p.active && !p.isMobile) return p.theme.color.white
    if (p.today) return p.theme.color.originalBlue
    if (p.disabled) return p.theme.color.grey
    return p.theme.color.darkBlack
  }};
  padding-bottom: ${(p) => p.theme.indent.XS};
  text-align: center;
`

export const DayLabelNumber = styled(Typography.Body2)`
  background-color: ${(p) => (p.active && p.isMobile ? p.theme.color.originalBlue : 'transparent')};
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  color: ${(p) => {
    if (p.active) return p.theme.color.white
    if (p.today) return p.theme.color.originalBlue
    if (p.disabled) return p.theme.color.grey
    return p.theme.color.darkBlack
  }};
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
`

export const TitleContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1;
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.XXXS} ${p.theme.indent.XXXS} ${p.theme.indent.SM}  ${p.theme.indent.XXXS}`
      : `${p.theme.indent.MD} 0 ${p.theme.indent.XXXS} ${p.withCheckbox && '0'}`};
`
export const LinkContainer = styled.span`
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.color.originalBlue};
  cursor: pointer;
`
export const BookingTitleContainer = styled.div`
  padding: ${(p) =>
    p.isMobile
      ? `${p.theme.indent.SM} ${p.theme.indent.MD}`
      : `${p.theme.indent.MD} ${p.theme.indent.LG}`};
`
