import styled from 'styled-components'

export const ConfirmMessage = styled.div`
  padding: ${(p) => `${p.theme.indent.XS} 0 ${p.theme.indent.MD}`};
  text-align: center;
`
export const Container = styled.div`
  text-align: center;
  background-color: ${(p) => p.theme.color.white};
  padding: ${(p) =>
    `${p.theme.indent.XXXL} ${p.isMobile ? p.theme.indent.LG : p.theme.indent.XXXL}`};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  max-width: 33.5rem;
  min-height: 31.5rem;
`
export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: ${(p) => p.theme.indent.XS};
  margin-top: ${(p) => p.theme.indent.XL};
`
export const StyledImg = styled.img`
  width: 9.8rem;
`
