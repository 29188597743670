import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  ${({ isMobile }) => !isMobile && 'height: calc(100vh - 25.1rem)'};
  padding: ${({ theme }) => `0 ${theme.indent.LG} ${theme.indent.XXXL}`};
`

export const StyledBody3 = styled(Typography.Body3)<{ isMobile: boolean }>`
  ${({ isMobile }) => isMobile && 'white-space: normal;'};
`
export const FormContainer = styled.div<{ isMobile: boolean }>`
  padding-top: ${({ theme }) => theme.indent.LG};
  ${({ isMobile }) => !isMobile && 'max-width: 36rem '};
`

export const ErrorContainer = styled.div`
  padding-top: ${({ theme }) => theme.indent.MD};
  width: 26rem;
`
