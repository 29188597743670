import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'

export const FormWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  background-color: ${(p) => p.theme.color.greyBlue};
  padding: ${(p) => p.theme.indent.XS} ${(p) => p.theme.indent.XXXL};
`
export const StyledRow = styled(Row)`
  padding-bottom: ${(p) => p.theme.indent.MD};
`
export const PlugWrapper = styled.div<{ isCentered: boolean }>`
  width: 100%;
  padding: ${(p) => p.theme.indent.XS};
  background-color: ${(p) => p.theme.color.lightGrey};
  border-radius: ${(p) => p.theme.borderRadius.MD};
  margin-bottom: ${(p) => p.theme.indent.SM};

  ${(p) => (p.isCentered ? 'text-align: center;' : null)}
`
export const StyledCol = styled(Col)<{ isRightPadding: boolean }>`
  padding-right: ${(p) => (p.isRightPadding ? '8rem' : p.theme.indent.XS)};
`
export const StyledInputCol = styled(Col)<{ isMobile: boolean }>`
  padding-right: ${(p) => (p.isMobile ? p.theme.indent.XXXS : p.theme.indent.LG)};
`
export const CheckBoxWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.indent.XXS};
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  gap: ${(p) => p.theme.indent.SM};
`

export const StyledImg = styled.img`
  height: 19.2rem;
  width: 100%;
`

export const EditImgWrapper = styled.div`
  width: 100%;
`
