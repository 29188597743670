import React from 'react'
import { useTheme } from 'styled-components'
import { useMobile } from '@utils/hooks'
import { Loader, Icon } from '@atoms'

import {
  ButtonContainer,
  ButtonText,
  ButtonTextYesNo,
  Cicle,
  ButtonContainerRed,
  ButtonRedText,
  IconContainer,
} from './Button.style'

import { ButtonProps } from '@constants/types/molecules'

const Button: React.FC<ButtonProps> = (props) => {
  const theme = useTheme()
  const isMobile = useMobile()

  const {
    label,
    onClick,
    backgroundColor = theme.color.originalBlue,
    secondary,
    width,
    height,
    disabled,
    loader,
    renderMobile,
  } = props
  return (
    <ButtonContainer
      secondary={secondary}
      backgroundColor={backgroundColor}
      onClick={onClick}
      width={width}
      height={height}
      disabled={disabled}
      isMobile={isMobile | renderMobile}
    >
      {loader ? (
        <Loader skeleton />
      ) : (
        <ButtonText
          disabled={disabled}
          isMobile={isMobile}
          renderMobile={renderMobile}
          secondary={secondary}
        >
          {label}
        </ButtonText>
      )}
    </ButtonContainer>
  )
}
const Primary: React.FC<ButtonProps> = ({
  label,
  backgroundColor,
  onClick,
  width,
  height,
  disabled,
  loader,
  renderMobile,
}) => {
  const theme = useTheme()

  return (
    <Button
      label={label}
      disabled={disabled}
      backgroundColor={disabled ? theme.color.darkGrey : backgroundColor}
      width={width}
      height={height}
      onClick={onClick}
      loader={loader}
      renderMobile={renderMobile}
    />
  )
}

const Secondary: React.FC<ButtonProps> = ({ height, label, onClick, width, disabled }) => {
  return (
    <Button
      secondary
      disabled={disabled}
      backgroundColor="transparent"
      width={width}
      height={height}
      label={label}
      onClick={onClick}
    />
  )
}

const YesNo: React.FC<ButtonProps> = ({ onClick, label, width, checked }) => {
  const isMobile = useMobile()

  return (
    <ButtonContainer
      isMobile={isMobile}
      secondary
      backgroundColor="transparent"
      onClick={onClick}
      width={width}
    >
      <Cicle isMobile={isMobile} checked={checked} />
      <ButtonTextYesNo isMobile={isMobile} secondary>
        {label}
      </ButtonTextYesNo>
    </ButtonContainer>
  )
}

const Red: React.FC<ButtonProps> = ({ onClick, label, width, disabled }) => {
  const isMobile = useMobile()
  const theme = useTheme()
  return (
    <ButtonContainerRed
      isMobile={isMobile}
      label={label}
      onClick={onClick}
      width={width}
      backgroundColor={theme.color.coralRed}
      disabled={disabled}
    >
      <Icon icon="Attention" size="2rem" />
      <ButtonRedText>{label}</ButtonRedText>
    </ButtonContainerRed>
  )
}

const WithIcon: React.FC<ButtonProps> = ({ onClick, label, width, disabled, icon, color }) => {
  const isMobile = useMobile()
  return (
    <IconContainer
      secondary
      backgroundColor="transparent"
      onClick={onClick}
      width={width}
      disabled={disabled}
      isMobile={isMobile}
    >
      <Icon icon={icon} size="2rem" color={color} />
      <ButtonText disabled={disabled} isMobile={isMobile} secondary>
        {label}
      </ButtonText>
    </IconContainer>
  )
}

Button.Primary = Primary
Button.Secondary = Secondary
Button.YesNo = YesNo
Button.Red = Red
Button.WithIcon = WithIcon

export default Button
