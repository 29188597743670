import React from 'react'
import iconSet from '@assets/icons/selection.json'
import { StyledIcomoonReact } from './Icon.style'

import { IconTypes } from '@constants/types/atoms'

const Icon: React.FC<IconTypes> = ({
  color,
  size = '2rem',
  icon,
  clickable = false,
  testid = '',
  onClick,
  rotate,
}) => (
  <StyledIcomoonReact
    iconSet={iconSet}
    color={color}
    width={size}
    height="100%"
    icon={icon}
    $clickable={clickable}
    data-testid={testid}
    onClick={onClick}
    $rotate={rotate}
  />
)

export default Icon
