const BORDER_RADIUS = {
  XXXS: 0,
  XXS: 0.2,
  XS: 0.4,
  SM: 0.6,
  MD: 0.8,
  LG: 1,
  XL: 1.2,
  XXL: 1.4,
  XXXL: 1.6,
}

export default BORDER_RADIUS
