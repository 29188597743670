import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Icon, Typography } from '@atoms'
import { useMobile, useTabletPortrait } from '@utils/hooks'
import { Container, StyledImg, ImgContainer, DropContainer } from './ChangeProfileImage.style'
import { customValidation, createImgPreview, revokePreview } from '../DropZone/DropZone.utils'

import { DropZoneTypes } from '@constants/types/molecules'

const DropZone: React.FC<DropZoneTypes> = ({
  onChange,
  getError,
  disabled,
  imageUrl,
  children,
}) => {
  const isMobile = useMobile()
  const isTabletPortrait = useTabletPortrait()
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    maxFiles: 1,
    maxSize: 10485760 /* 10mb in bytes */,
    validator: customValidation,
    noKeyboard: isMobile || isTabletPortrait,
    noDrag: true,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => createImgPreview(file)))
    },
  })
  const errorMessage = fileRejections.map((file) => file.errors)

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => revokePreview(file))
    if (errorMessage.length && getError) {
      getError(errorMessage[0][1].message)
    }
    if (errorMessage.length === 0) {
      getError('')
    }
  })

  return (
    <Container>
      <Typography.Body3>Profile Picture</Typography.Body3>
      <ImgContainer>
        {imageUrl || files.length > 0 ? (
          <StyledImg src={files[0]?.preview || imageUrl} alt="" />
        ) : (
          <Icon icon="contact" size="3.6rem" color="lightGrey" />
        )}
      </ImgContainer>
      <DropContainer {...getRootProps({ fileRejections })}>
        <input disabled={disabled} {...getInputProps({ onChange })} />
        <Icon icon="upload-arrow" size="1rem" color="originalBlue" />
        <Typography.Body3 color="originalBlue">Upload image</Typography.Body3>
      </DropContainer>
      {children}
    </Container>
  )
}

export default DropZone
