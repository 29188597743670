import styled from 'styled-components'
import { Typography } from '@atoms'

export const Container = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: ${({ isMobile }) => (isMobile ? '33.5rem' : '44.2rem')};
  min-height: 32.2rem;
  padding: ${({ theme, isMobile }) =>
    isMobile ? `${theme.indent.XXXL} ${theme.indent.MD} ${theme.indent.LG}` : theme.indent.XXXL};
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0.4rem 1rem ${({ theme }) => theme.color.black_20};
`

export const Header = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${({ isMobile }) => isMobile && 'padding: 0 4rem'};
  margin-top: ${({ theme }) => theme.indent.XS};
  svg:first-of-type {
    padding-bottom: ${(p) => p.theme.indent.LG};
  }
`
export const H2 = styled(Typography.H2)<{ $isMobile: boolean }>`
  ${({ $isMobile }) => $isMobile && 'white-space: normal'};
`

export const CloseButton = styled.div`
  position: absolute;
  right: 2.3rem;
  top: 1.8rem;
`

export const ButtonContainer = styled.div`
  padding-top: ${({ theme }) => theme.indent.MD};
`
