import React, { useState, useEffect, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { useFirebaseAuth, useMobile, useTabletPortrait } from '@utils/hooks'
import { forgotPassword } from '@services/firebase'
import { LOGIN, LS_CONTSTANTS, ROUTES, USER_TYPES } from '@constants/dictionary'
import { Loader, Icon, Typography } from '@atoms'
import { BackToLoginHeader } from '@organisms'
import { Button } from '@molecules'
import bgDesctop from '@assets/images/bg-land-desctop.png'
import bgTablet from '@assets/images/bg-land-tablet.png'
import bgMobile from '@assets/images/bg-land-mobile.png'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { InputForm } from '@helpers'
import {
  Container,
  StyledCol,
  FormContainer,
  H2,
  FieldsContainer,
  BottomContainer,
  Form,
  TextInfo,
  ErrorBlock,
  ErrorContainer,
} from './AgentResetPassword.style'
import { schema } from './AgentResetPasswordValidation'

const AgentResetPassword = (): JSX.Element => {
  const isMobile = useMobile()
  const [loader, setLoader] = useState(false)
  const [sendedLink, setSendedLink] = useState(false)
  const [showError, setShowError] = useState(false)

  const isTabletPortrait = useTabletPortrait()
  const [logedIn, loaded] = useFirebaseAuth()

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods

  const email = watch('email')

  const hasError = useCallback(
    (field) => {
      return field in errors
    },
    [errors],
  )

  const onSubmit = useCallback(async (): Promise<undefined> => {
    window.localStorage.clear()

    setLoader(true)
    const resultSuccess = await forgotPassword(email)
    setLoader(false)

    if (resultSuccess.result) {
      setSendedLink(true)
    } else {
      setShowError(true)
    }
  }, [email])

  const path = (): string => {
    if (isTabletPortrait) {
      return bgTablet
    }
    if (isMobile) {
      return bgMobile
    }
    return bgDesctop
  }

  useEffect(() => {
    const listener = (event): (() => void) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(onSubmit)()
      }
    }
    document.addEventListener('keyup', listener)
    return () => {
      document.removeEventListener('keyup', listener)
    }
  }, [handleSubmit, onSubmit])
  const userType = localStorage.getItem(LS_CONTSTANTS.userType)

  return (
    <Loader loaded={loaded}>
      {logedIn && (userType === USER_TYPES.agent || userType === USER_TYPES.agency) ? (
        <Redirect to={ROUTES.AGENT} />
      ) : (
        <Container path={path}>
          <StyledCol md={12} lg={6} xl={6} xxl={6}>
            <FormContainer isMobile={isMobile}>
              <Icon icon="logo" clickable size="20rem" color="white" />
              <FieldsContainer isMobile={isMobile}>
                <BackToLoginHeader />
                {sendedLink ? (
                  <>
                    <H2 isMobile={isMobile}>Check your mail</H2>
                    <TextInfo isMobile={isMobile}>{LOGIN.RESET_PASSWORD_MESSAGE}</TextInfo>
                    <Typography.Link
                      noUnderline
                      href="mailto:viewd-support@-gmail.com?subject=Reset your password for ViewD"
                    >
                      viewd-support@-gmail.com
                    </Typography.Link>
                  </>
                ) : (
                  <>
                    <H2 isMobile={isMobile}>Reset password</H2>
                    <TextInfo isMobile={isMobile}>{LOGIN.RESET_PASSWORD_INSTRUCTION}</TextInfo>
                    <FormProvider {...methods}>
                      <Form autoComplete="off">
                        <InputForm
                          disabled={loader}
                          name="email"
                          errorMessage="Not a valid email"
                          placeholder="Enter your email"
                          inputLabel="Email"
                          error={hasError('email')}
                          isNoSpace
                        />
                      </Form>
                    </FormProvider>
                    <Button.Primary
                      disabled={!email || loader}
                      loader={loader}
                      label="Send Instructions"
                      onClick={handleSubmit(onSubmit)}
                    />
                    <ErrorContainer>
                      {showError ? <ErrorBlock>Email not found</ErrorBlock> : null}
                    </ErrorContainer>
                    <BottomContainer isMobile={isMobile} />
                  </>
                )}
              </FieldsContainer>
            </FormContainer>
          </StyledCol>
        </Container>
      )}
    </Loader>
  )
}

export default AgentResetPassword
