/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '@atoms'
import { Col } from 'styled-bootstrap-grid'

export const Container = styled.div<{ isMobile: boolean; isDesktop: boolean }>`
  padding: 0
    ${({ isMobile, isDesktop, theme }) => (isMobile ? 0 : isDesktop ? '5rem' : theme.indent.XXXL)};
  margin-bottom: ${({ isMobile, theme }) => (isMobile ? 0 : theme.indent.XXXL)};
`
export const Title = styled.div<{
  isMobile: boolean
  isTabletPortrait: boolean
  backendError: boolean
}>`
  display: flex;
  flex-direction: ${({ backendError, isTabletPortrait }) =>
    backendError && isTabletPortrait ? 'column' : 'row'};
  width: 100%;
  padding: ${({ isMobile, theme }) =>
    isMobile
      ? `${theme.indent.LG} ${theme.indent.LG} ${theme.indent.MD}`
      : `${theme.indent.MD} 0 ${theme.indent.MD}`};
  justify-content: space-between;
  ${({ isMobile }) => isMobile && 'flex-direction: column'};
`

export const LinkContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  align-content: center;
  align-items: center;
`

export const ButtonContainerFirst = styled.div<{ isMobile: boolean }>`
  height: 4rem;
  width: ${({ isMobile }) => (isMobile ? '100%' : '14.5rem')};
  margin-right: ${({ theme }) => theme.indent.MD};
`

export const ButtonContainerSecond = styled.div<{ isMobile: boolean }>`
  height: 4rem;
  width: ${({ isMobile }) => (isMobile ? '100%' : '11.5rem')};
`

export const H1 = styled(Typography.H1)`
  font-family: ${({ theme }) => theme.fontFamily.quicksandBold}, sans-serif;
  font-size: ${({ theme }) => theme.fontSize.XL};
  line-height: ${({ theme }) => theme.lineHeight.XL};
  color: ${({ theme }) => theme.color.originalBlue};
  padding-bottom: ${({ isMobile, theme }) => (isMobile ? theme.indent.MD : 0)};
`

export const H2 = styled(Typography.H2)`
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: ${({ theme }) => theme.lineHeight.MD};
  font-weight: 600;
  padding-left: 1rem;
`

export const CardBody = styled.form<{ isDesktop: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.color.greyBlue};
  display: flex;
  padding: ${({ theme }) => `${theme.indent.MD} ${theme.indent.XXXL} ${theme.indent.XXXL}`};
  padding: ${({ isDesktop, theme }) =>
    `${theme.indent.MD} ${isDesktop ? theme.indent.XXXL : theme.indent.LG} ${theme.indent.XXXL}`};
  ${({ isDesktop }) => !isDesktop && 'flex-wrap: wrap;'}
`

export const ButtonRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  ${({ isMobile }) => isMobile && 'flex-direction: column-reverse;'}
`
export const ButtonsContainer = styled.div`
  display: flex;
`

export const ColumnTitle = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.indent.LG};
`
export const Column = styled(Col)<{ isDesktop: boolean; isTabletPortrait: boolean }>`
  ${({ isTabletPortrait, theme }) =>
    isTabletPortrait &&
    `flex: 1 1 calc(50% - 3rem);
    margin-top: ${theme.indent.XXXL};
  &:first-of-type {
  padding-right: ${theme.indent.LG}};
  `};
  &:nth-of-type(2n) {
    ${({ isDesktop }) => isDesktop && 'padding: 0 4rem;'};
`
export const NotesHeader = styled(Typography.Body2)`
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: ${({ theme }) => theme.lineHeight.SM};
  padding-bottom: ${({ theme }) => theme.indent.XS};
`

export const ErrorContainer = styled.div<{ isMobile: boolean }>`
  ${({ theme, isMobile }) =>
    isMobile
      ? `margin:  ${theme.indent.MD} 0 ${theme.indent.XXS} ;`
      : `margin-right:  ${theme.indent.MD};`}
`
