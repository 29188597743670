import { add, eachMinuteOfInterval, compareAsc } from 'date-fns'
import { TimePeriodsTypes, TimeSlotsTypes } from '@constants/types'

export const generateSlots = (period: TimePeriodsTypes): Array<TimeSlotsTypes> => {
  return compareAsc(period.fromDateTime, period.toDateTime) < 0
    ? eachMinuteOfInterval(
        {
          start: period.fromDateTime,
          end: period.toDateTime,
        },
        { step: 30 },
      )
        .map((time) => ({
          fromDateTime: time,
          toDateTime: add(time, { minutes: 30 }),
        }))
        .slice(0, -1)
    : []
}
