import React from 'react'
import { Typography } from '@atoms'
import { UserLayout } from '@templates'
import { useMobile } from '@utils/hooks'
import { ERROR_MESSAGE_TEXT, LS_CONTSTANTS } from '@constants/dictionary'
import { Container, StyledImage, Body1 } from './ErrorPage.style'

const ErrorPage = (): JSX.Element => {
  const isMobile = useMobile()
  const error = localStorage.getItem(LS_CONTSTANTS.error) || ''
  const src = error.length
    ? ERROR_MESSAGE_TEXT[error].img
    : ERROR_MESSAGE_TEXT.PAGE_NOT_FOUND_ERROR.img
  const title = error.length
    ? ERROR_MESSAGE_TEXT[error].title
    : ERROR_MESSAGE_TEXT.PAGE_NOT_FOUND_ERROR.title
  const description = error.length
    ? ERROR_MESSAGE_TEXT[error].description
    : ERROR_MESSAGE_TEXT.PAGE_NOT_FOUND_ERROR.description
  return (
    <UserLayout>
      <Container>
        <StyledImage src={src} isMobile={isMobile} />
        <Typography.H1 isMobile={isMobile}>{title}</Typography.H1>
        <Body1 disabled isMobile={isMobile}>
          {description}
        </Body1>
      </Container>
    </UserLayout>
  )
}

export default ErrorPage
