import { useState } from 'react'

const useModal = (): { isShown: boolean; toggle: (setHard?: boolean) => void } => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const toggle = (setHard?: boolean): void => {
    if (typeof setHard === 'boolean') {
      return setIsShown(setHard)
    }
    return setIsShown(!isShown)
  }
  return {
    isShown,
    toggle,
  }
}

export default useModal
