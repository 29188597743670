export const NOTIFICATIONS = [
  {
    body: 'You have a new visit at 07:00 AM - 07:30 AM, November 12, 2021, for "Beautiful apartment in city centre"',
    userId: 'id1',
    createdAt: new Date(2021, 10, 12, 12, 0),
    isRead: false,
    type: 'visit',
  },
  {
    body: 'You have a new visit at 05:00 PM - 05:30 PM, November 11, 2021, for "Fishing House with the Lake"',
    userId: 'id2',
    createdAt: new Date(2021, 10, 12, 14, 30),
    isRead: false,
    type: 'visit',
  },
  {
    body: 'You have a new visit at 07:30 PM - 08:00 PM, November 11, 2021, for "Small house in countryside"',
    userId: 'id3',
    createdAt: new Date(2021, 10, 10, 9, 0),
    isRead: true,
    type: 'visit',
  },
  {
    body: 'The seller of the property "this is a new title" has marked the property under offer.',
    userId: 'id4',
    createdAt: new Date(2021, 10, 9, 13, 0),
    isRead: false,
    type: 'feedback',
  },
  {
    body: 'Please update your time slots.',
    userId: 'id5',
    createdAt: new Date(2021, 10, 8, 18, 0),
    isRead: false,
    type: 'reminder',
  },
]
