import { BOOKING_STATUSES, USER_TYPES } from '@constants/dictionary'

export const actualStatus = (userType: string, status: string): string => {
  if (
    (userType === USER_TYPES.buyer || userType === USER_TYPES.seller) &&
    status === BOOKING_STATUSES.DELETED
  ) {
    return BOOKING_STATUSES.OFF_MARKET
  }
  return status
}
