import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import RadioButton from '../../organisms/RadioButton'

import { RadioButtonFormProps } from '@constants/types/helpers'

const RadioButtonForm: React.FC<RadioButtonFormProps> = ({ name }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={-1}
      render={({ field: { onChange } }) => <RadioButton onChange={onChange} />}
    />
  )
}
export default RadioButtonForm
